export const findFaqGroupAncestors = (searchResult, faqGroupAllHref) => {
  if (searchResult.contentType === 'SHARED_FAQ') {
    return searchResult.includedIn.filter((faqGroup) =>
      faqGroup.ancestors.some((ancestor) => ancestor.permalink === faqGroupAllHref)
    );
  }
  return searchResult.ancestors[0].filter((ancestor) => ancestor.contentType === 'FAQ_GROUP');
};

export default findFaqGroupAncestors;
