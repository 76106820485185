import React from 'react';
import PropTypes from 'prop-types';
import {
  socialCookiesAccepted,
  openCookieModal as openCookieModalHelper,
} from '@helpers/cookieHelper';

const VideoItem = ({ item, openCookieModal = undefined }) => {
  const showVideo = socialCookiesAccepted();
  const openCookieModalFunc = openCookieModal || openCookieModalHelper;

  return item.visible ? (
    <div>
      {showVideo && (
        <div className="media-wrapper video clearfix">
          <div className=" video-gallery">
            <div className="col-xs-12">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  width=""
                  height=""
                  src={item.videoPlayback}
                  frameBorder="0"
                  allowFullScreen=""
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {!showVideo && (
        <div className="disabled-by-cookies">
          <p>
            Hier staat ingevoegde content uit een social media netwerk dat cookies wil schrijven of
            uitlezen. Je hebt hiervoor geen toestemming gegeven.
            {
              <a onClick={() => openCookieModalFunc()} href="#">
                Klik hier om dit alsnog toe te laten.
              </a>
            }
          </p>
        </div>
      )}
    </div>
  ) : null;
};

VideoItem.propTypes = {
  item: PropTypes.object,
  openCookieModal: PropTypes.func,
};

export default VideoItem;
