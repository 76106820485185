import React from 'react';
import SearchInput from '@UI/searchInput/searchInput';
import PropTypes from 'prop-types';

const BlogSearchInput = ({ searchTerm, onSearch, onSearchTermChange, onSearchTermClear }) => (
  <div className="kov-pro-mini-search input-group" style={{ margin: 0 }}>
    <SearchInput
      value={searchTerm}
      onSearch={onSearch}
      onChange={onSearchTermChange}
      onClear={onSearchTermClear}
      placeholder="Zoek"
      inputClassName="form-control"
    />
    <span className="input-group-btn">
      <button className="btn btn-default" type="button" onClick={onSearch}>
        <i className="icon-kov-pro-search" />
      </button>
    </span>
  </div>
);

BlogSearchInput.propTypes = {
  searchTerm: PropTypes.string,
  onSearch: PropTypes.func,
  onSearchTermChange: PropTypes.func,
  onSearchTermClear: PropTypes.func,
};

export default BlogSearchInput;
