export const OrderMethods = {
  RELEVANCE: 'relevantie',
  NEWEST: 'nieuwste',
  OLDEST: 'oudste',
  ALPHABETICAL: 'title',
  CHRONOLOGICAL: 'series.eventDates',
  RECENT_PUBLISHED: 'issued.startDate',
};

export const SEARCH_LIMIT = 30;
