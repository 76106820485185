import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  llinkidItems: [],
};

const curriculaState = createSlice({
  name: 'curricula',
  initialState,
  reducers: {
    setCurricula: (state, action) => {
      state.items = action.payload;
    },
  },
});

export const curriculaStateActions = curriculaState.actions;
export default curriculaState;
