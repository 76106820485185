import { isVisibleWithViewOptions } from '@src/helpers/viewOptionsHelper';

/**
 * This function will return the tabs available for the theme depending on the theme configuration
 * @param {*} theme
 * @param {Boolean} isStandalonePage
 * @returns {Array<*>}
 */
export const getThemeTabs = (theme, isStandalonePage) => {
  const themeHasTrainings = theme?.hasTrainings;
  const isStandalonePageWithTrainings = isStandalonePage && themeHasTrainings;

  const themeChildren = theme?.children;
  if (!isStandalonePage) {
    return themeChildren;
  }
  if (isStandalonePageWithTrainings) {
    return [{ ...theme, parent: theme, type: 'SECTION' }, ...themeChildren];
  }
  return [];
};

export const mapTreeRecursive = (
  tree,
  viewOptions,
  forceVisibilityTrue,
  parentVisibility = null
) => {
  const treeItemVisibility = forceVisibilityTrue || isVisibleWithViewOptions(tree, viewOptions);
  const newParent = tree.parent &&
    parentVisibility !== null && {
      ...tree.parent,
      visible: parentVisibility,
    };

  return {
    ...tree,
    visible: treeItemVisibility,
    ...(newParent ? { parent: newParent } : {}),
    children: tree.children?.map((child) =>
      mapTreeRecursive(child, viewOptions, forceVisibilityTrue, treeItemVisibility)
    ),
  };
};
