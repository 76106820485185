class wsThemeHome2 {
  constructor(settings, $scope, $element, $stateParams, $timeout, ngMeta) {
    'ngInject';

    this.settings = settings;
    this.$scope = $scope;
    this.$element = $element;
    this.ngMeta = ngMeta;
    this.$timeout = $timeout;
  }

  $onInit() {
    if (this.sTheme) {
      this.$scope.$emit('dynamicMetaDataUpdated', this.sTheme);
    }
  }
}

module.exports = {
  template: require('./wsThemeHome2.html'),
  controllerAs: 'ctrl',
  controller: wsThemeHome2,
  bindings: {
    sTheme: '<',
  },
};
