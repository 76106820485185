import contentUtils from '@src/app/contentUtils';

const getParentCurriculumTheme = (item, allItems) => {
  const parentHref = item.$$relationsFrom.find((a) => a.$$expanded.relationtype === 'IS_PART_OF')
    ?.$$expanded.to.href;

  return (
    parentHref &&
    allItems.find((i) => i.$$meta.permalink === parentHref && i.type === 'CURRICULUM_THEME')
  );
};

const buildElement = (item, allItems) => {
  // this is used to add the parentCurriculumTheme when we find "subCurriculumThemes"
  const parentCurriculumTheme =
    item.type === 'CURRICULUM_THEME' && getParentCurriculumTheme(item, allItems);

  return {
    href: item.$$meta.permalink,
    type: item.type,
    title: item.title,
    color: item.color,
    themes: item.themes,
    attachments: item.attachments,
    icon: contentUtils.getImage(item, 'ICON'),
    ...(parentCurriculumTheme && { parentCurriculumTheme }),
  };
};

const transformContent = (results, currentRelations, isProTheme) => {
  const existingRelations = currentRelations.reduce((map, cur) => {
    map[cur.href] = true;
    return map;
  }, {});
  const newContent = [];
  const newRelations = [];
  results.forEach((result) => {
    if (result.$$meta.type !== 'CONTENT') {
      newContent.push({
        href: result.$$meta.permalink,
        type: result.$$meta.type,
        title: result.title || result.name,
      });
    } else {
      newContent.push({
        ...buildElement(result, results),
        isProTheme,
      });
      [...result.$$relationsFrom, ...result.$$relationsTo].forEach(
        ({ $$expanded: relation, href }) => {
          if (!existingRelations[href]) {
            existingRelations[href] = true;
            newRelations.push({
              href: relation.$$meta.permalink,
              from: relation.from.href,
              to: relation.to.href,
              type: relation.relationtype,
              readOrder: relation.readorder,
            });
          }
        }
      );
    }
  });
  return [newContent, newRelations];
};

export default transformContent;
