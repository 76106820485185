import React from 'react';
import Icon from '@UI/icon/icon';
import ModalProvider from '@UI/modal/modalProvider';
import useModal from '@hooks/useModal';
import TrainingEventInfo from '@UI/trainings/trainingResults/trainingResult/trainingEventInfo';
import { stripHtml } from '@src/app/utils';
import trackPiwikEvent from '@src/reduxStore/analytics/analyticsThunks';

import { useDispatch } from 'react-redux';
import SearchPersonModal from './searchPersonModal';

const resultAnchor = (result, onClickHandler) => (
  <a
    href={result.goTo.href}
    onClick={onClickHandler}
    className={`kov-pro-result__title${result.type === 'MENU_ITEM' ? ' accent-1' : ''}`}
    target={result.goTo.target === '_blank' ? result.goTo.target : undefined}
  >
    {result.iconClassName && <i className={result.iconClassName} />}
    {result.icon && !result.iconClassName && (
      <Icon icon={result.icon} style={{ fill: result.icon.color, width: '18px', height: '18px' }} />
    )}
    {result.attachmentIcon && <img src={result.attachmentIcon} />}
    <h4>
      {result.titlePrefix && (
        <span className="kov-pro-result__title-prefix">{result.titlePrefix}</span>
      )}
      <span dangerouslySetInnerHTML={{ __html: stripHtml(result.title) }} />
    </h4>
  </a>
);

const GlobalSearchResult = ({ result, searchResultIndex = undefined, searchTerm = '' }) => {
  const dispatch = useDispatch();

  const { open: openPersonModal, ...personModalProps } = useModal();

  const trackAndClick = () => {
    dispatch(
      trackPiwikEvent('select_search_result', {
        search_term: searchTerm?.toLowerCase(),
        search_result_index: searchResultIndex + 1,
        search_result_type: result.type === 'PRO_ATTACHMENT' ? 'document' : 'page',
      })
    );
  };

  const onClickHandler = result.type === 'PERSON' ? openPersonModal : trackAndClick;

  return (
    <React.Fragment>
      {result.type === 'PERSON' && (
        <ModalProvider {...personModalProps}>
          <SearchPersonModal person={result.person} />
        </ModalProvider>
      )}
      <li
        className={`kov-pro-result ${
          result.extraKovProResultClass ? result.extraKovProResultClass : ''
        }`}
      >
        <div className="kov-pro-result__info" style={{ position: 'relative' }}>
          {result.showDebugInfo && (
            <div
              style={{
                right: '0px',
                top: '-20px',
                position: 'absolute',
                color: '#DDD',
                fontSize: '7px',
              }}
            >
              {result.type}: {result.contentHref}
            </div>
          )}
          {result.themePages && result.themePages.length > 0 && (
            <ul className="kov-pro-result__path">
              {result.themePages.map((themePage) => (
                <li key={themePage.href}>
                  <a href={themePage.href}>{themePage.title}</a>
                </li>
              ))}
            </ul>
          )}
          {resultAnchor(result, onClickHandler)}
          {result.relevantAlias && (
            <ul className="kov-pro-result__themename">
              <li className="kov-pro-result__themename-tag">
                <a href="#">{result.relevantAlias.key}</a>
              </li>
              <li className="kov-pro-result__themename-tag">
                <a href="#">{result.relevantAlias.value}</a>
              </li>
            </ul>
          )}
          {result.publicationDate && (
            <span className="kov-pro-result__date">{result.publicationDate}</span>
          )}
          {(result.context || result.eventInfo) && (
            <div className="kov-pro-result__excerpt">
              {result.context && <p dangerouslySetInnerHTML={{ __html: result.context }} />}
              {result.eventInfo && (
                <TrainingEventInfo
                  eventInfo={result.eventInfo}
                  wrapperClass={'kov-pro-result__specs'}
                />
              )}
            </div>
          )}
          {result.error && result.showDebugInfo && (
            <div style={{ color: 'red' }}>{result.error.message}</div>
          )}
        </div>
      </li>
    </React.Fragment>
  );
};
export default GlobalSearchResult;
