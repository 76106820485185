import { useCallback, useEffect, useState } from 'react';
import { elmYEndPosition, elmYPosition, offsetFromTop } from '@src/app/utils';

const UseThemeTextToc = (page) => {
  const [tocItems, setTocItems] = useState(
    page && page.children
      ? page.children.filter(
          (o) => ['SUMMARY', 'SECTION'].includes(o.type) && o.importance === 'MEDIUM'
        )
      : []
  );

  // Listener to set the nearest section in the window to active in the TOC
  useEffect(() => {
    const scrollListener = (e) => {
      const position = e.target.defaultView.scrollY;
      const setActive = (contents) =>
        contents.filter((content, i) => {
          if (content.children?.length > 0) {
            content.children = setActive(content.children);
          }
          const prevPosition = elmYPosition(content.key) - 1;
          const nextPosition = elmYEndPosition(content.key);
          content.active =
            prevPosition < position + offsetFromTop && nextPosition > position + offsetFromTop;

          // first item is active if none
          if (i === 0 && prevPosition > position) {
            content.active = true;
          }
          return content;
        });
      const activeTocItems = setActive(tocItems);
      setTocItems(activeTocItems);
    };
    window.addEventListener('scroll', scrollListener, { passive: true });
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, [tocItems]);

  // Scroll to the selected contents item in the window
  const itemSelected = useCallback((selectedKey) => {
    const target = document.getElementById(selectedKey);
    if (target) {
      const y = target.getBoundingClientRect().top + window.pageYOffset - offsetFromTop;

      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, []);

  return {
    tocItems,
    itemSelected,
  };
};

export default UseThemeTextToc;
