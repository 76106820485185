class wsThemeTrainings {
  constructor($scope, $location) {
    'ngInject';

    this.$scope = $scope;
    this.$location = $location;
  }

  async $onInit() {
    this.$scope.$emit('setPageSettings', this.sTheme);

    this.$scope.$emit('dynamicMetaDataUpdated', {
      title: 'Professionalisering',
      description: null,
    });
  }

  $onDestroy() {
    this.$scope.$emit('setPageSettings');
  }
}

export default {
  template: require('./wsThemeTrainings.html'),
  controllerAs: 'ctrl',
  controller: wsThemeTrainings,
  bindings: {
    sTheme: '<',
  },
};
