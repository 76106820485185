import React from 'react';
import PropTypes from 'prop-types';
import {
  findForbiddenAttachmentsGroup,
  findForbiddenItemInAttachmentGroup,
  isAttachmentsGroupForbidden,
} from '@src/helpers/accessHelper';
import RestrictedContent from '@src/reactComponents/secureAccess/restrictedContent/restrictedContent';
import ThemeDownloadGroup from '../themeDownloadGroup/themeDownloadGroup';

const ThemeDownloadGroupList = ({ downloadGroups, showForbiddenPlaceholder, color = 'green' }) => {
  let forbiddenItemSecurityStatus;
  const forbiddenAttachmentGroup = findForbiddenAttachmentsGroup(downloadGroups);

  if (forbiddenAttachmentGroup) {
    forbiddenItemSecurityStatus =
      findForbiddenItemInAttachmentGroup(forbiddenAttachmentGroup).securityStatus;
  }

  const styleTag = `.dl-item-title a:hover {color: ${color} !important;}`;
  return (
    <>
      {showForbiddenPlaceholder && (
        <div className="kov-pro-xl-downloadlist-item kov-pro-xl-downloadlist-item--not-logged-in">
          <div className="row">
            <div className="col-12">
              <RestrictedContent status={forbiddenItemSecurityStatus} />
            </div>
          </div>
        </div>
      )}

      {downloadGroups
        .filter(
          (downloadGroup) => downloadGroup.children && !isAttachmentsGroupForbidden(downloadGroup)
        )
        .map((downloadGroup) => (
          <ThemeDownloadGroup key={downloadGroup.key} downloadGroup={downloadGroup} />
        ))}

      <style dangerouslySetInnerHTML={{ __html: styleTag }} />
    </>
  );
};

ThemeDownloadGroupList.propTypes = {
  downloadGroups: PropTypes.array,
  showForbiddenPlaceholder: PropTypes.bool,
  color: PropTypes.string,
};

export default ThemeDownloadGroupList;
