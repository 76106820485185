import { configureStore } from '@reduxjs/toolkit';
import customLogger from '@src/reduxStore/customLogger';
import user from '@src/reduxStore/user/userState';
import content from '@src/reduxStore/content/contentState';
import { init as initUser } from '@src/reduxStore/user/userThunks';
import trainings from '@src/reduxStore/trainings/trainingsState';
import database from '@src/reduxStore/database/databaseSlice';
import theme from '@src/reduxStore/theme/themeSlice';
import curricula from '@src/reduxStore/curricula/curriculaSlice';
import ui from '@src/reduxStore/ui/uiState';
import analytics from '@src/reduxStore/analytics/analyticsSlice';
import suggestions from '@src/reduxStore/suggestions/suggestionsSlice';
import news from '@src/reduxStore/news/newsSlice';
import navigation from '@src/reduxStore/navigation/navigationSlice';
import awaitableState from 'redux-awaitable-state';
import { init as initContent } from './content/contentThunks';

const keyObjectSliceToTypeTitleSummarizedData = (keyObjectStateSlice) =>
  Object.keys(keyObjectStateSlice).reduce((prev, curr) => {
    const contentElement = keyObjectStateSlice[curr];
    prev[curr] = { type: contentElement.type, title: contentElement.title };
    return prev;
  }, {});

/**
 * This method will create a new array based on the given one (first parameter) taking only the properties provided in the "propertiesToExtract" array (second parameter) of every item.
 */
const arraySliceToPropertiesProvidedSummarizedData = (arrayStateSlice, propertiesToExtract = []) =>
  arrayStateSlice.map((item) =>
    propertiesToExtract.reduce(
      (accItem, propertyToExtract) => ({
        ...accItem,
        [propertyToExtract]:
          item[propertyToExtract] || (propertyToExtract === 'href' && item.$$meta?.permalink),
      }),
      {}
    )
  );

// This will make the showed state on the redux extension to be shown differently as it is in the application
// We are doing this in order to make the extension to work while we improve what we save on the redux store
// The store was breaking on routes like: /identiteitsontwikkeling, /identiteitsontwikkeling/leeftocht
const stateSanitizer = (state) => {
  const { content: themeContent, relations, rawCurrentThemeItems } = state.theme;
  const { proThemes, studyProgrammes, primaryReferenceFrame } = state.content;
  const { teasers, currentThemes } = state.news;
  const { navigationItems, latestClusterChildren } = state.navigation;
  const { userNewsletterPreferencesResponse } = state.user;

  return {
    ...state,
    theme: {
      ...state.theme,
      content: `<< ${Object.keys(themeContent).length} content items >>`,
      rawCurrentThemeItems: keyObjectSliceToTypeTitleSummarizedData(rawCurrentThemeItems),
      relations: `<< ${Object.keys(relations).length} relations >>`,
    },
    news: {
      ...state.news,
      teasers: `<< ${Object.keys(teasers).length} teasers >>`,
      currentThemes: `<< ${currentThemes?.length} currentThemes >>`,
    },
    navigation: {
      ...state.navigation,
      navigationItems: arraySliceToPropertiesProvidedSummarizedData(navigationItems, [
        'type',
        'title',
        'href',
      ]),
      ...(latestClusterChildren
        ? {
            latestClusterChildren: arraySliceToPropertiesProvidedSummarizedData(
              latestClusterChildren,
              ['type', 'title', 'href']
            ),
          }
        : latestClusterChildren),
    },
    content: {
      ...state.content,
      proThemes: arraySliceToPropertiesProvidedSummarizedData(proThemes, [
        'type',
        'title',
        'href',
        'themes',
      ]),
      studyProgrammes: studyProgrammes?.map((studyProgramme) => ({
        ...studyProgramme,
        educationTypes: studyProgramme.educationTypes.map((educationType) => ({
          ...educationType,
          leerplanPages: arraySliceToPropertiesProvidedSummarizedData(educationType.leerplanPages, [
            'type',
            'title',
            'href',
            'themes',
          ]),
        })),
      })),
      primaryReferenceFrame: `<< ${primaryReferenceFrame?.length} primaryReferenceFrame items >>`,
    },
    user: {
      ...state.user,
      userNewsletterPreferencesResponse: arraySliceToPropertiesProvidedSummarizedData(
        userNewsletterPreferencesResponse,
        ['newsletterType', 'subscribed', 'href', 'themes']
      ),
    },
  };
};

// This will make the payload not to appear in the redux extension (only the title of the action will appear)
// We are doing this in order to make the extension to work while we improve what we save on the redux store
// The store was breaking on routes like: /identiteitsontwikkeling, /identiteitsontwikkeling/leeftocht
const actionSanitizer = (action) => ({ type: action.type });

const store = configureStore({
  reducer: {
    theme: theme.reducer,
    database: database.reducer,
    trainings: trainings.reducer,
    user: user.reducer,
    curricula: curricula.reducer,
    analytics: analytics.reducer,
    ui: ui.reducer,
    suggestions: suggestions.reducer,
    news: news.reducer,
    navigation: navigation.reducer,
    content: content.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat(customLogger),
  devTools: {
    stateSanitizer,
    actionSanitizer,
    latency: 300,
    maxAge: 10,
  },
});

awaitableState(store);
store.dispatch(initUser());
store.dispatch(initContent());

export default store;
