import { useReducer, useMemo, useEffect, useCallback } from 'react';
import useUrlSearchParams from '@hooks/useUrlSearchParams';

import { getAngularService } from 'NgMigrationUtils/angular-react-helper';
import {
  getCoverageFromViewOptions,
  getMainstructuresOuTypeCombinationsFromViewOptions,
} from '@src/helpers/viewOptionsHelper';
import { OrderMethods } from '@components/database/constants';
import { transformTags } from '@components/news/helpers/newsDatabank';
import { useSearchApi } from '@components/searchApi';
import { curriculumSitesLogic } from '@kathondvla/shared-logic';

import { getOrderMethod } from '@components/database/helpers/helpers';
import { useSelector } from 'react-redux';
import { selectUserViewOptions } from '@src/reduxStore/user/userSelectors';

const initialState = {
  searchParams: {
    order: null,
    q: '',
    van: null,
    tot: null,
    curricula: [],
    menuItem: null,
  },
  filters: {
    order: null,
    q: '',
    van: null,
    tot: null,
    curricula: [],
    menuItem: null,
  },
  limit: 30,
};

function newsDatabaseReducer(state, action) {
  switch (action.type) {
    case 'setLimit': {
      return {
        ...state,
        limit: action.payload,
      };
    }
    case 'setSearchParams': {
      return {
        ...state,
        searchParams: action.payload || state.filters,
      };
    }
    case 'setFilter': {
      let fieldName;
      let value;
      switch (action.fieldName) {
        case 'dateFrom':
          fieldName = 'van';
          value = action.payload ? new Date(+action.payload).getTime() : null;
          break;
        case 'dateTo':
          fieldName = 'tot';
          value = action.payload ? new Date(+action.payload).getTime() : null;

          break;
        default:
          fieldName = action.fieldName;
          value = action.payload;
          break;
      }
      return {
        ...state,
        filters: { ...state.filters, [fieldName]: value },
      };
    }
    default:
      return state;
  }
}

const useNewsDatabase = (proTheme) => {
  const navigationService = getAngularService('navigationService');

  const userViewOptions = useSelector(selectUserViewOptions);

  const [state, dispatch] = useReducer(newsDatabaseReducer, initialState);

  const { getSearchResults, searchResults, searchCount, isLoading, setIsLoading } = useSearchApi();
  const filterObjectIntoParamStateObj = useMemo(
    () =>
      Object.entries({ ...state.searchParams, limit: state.limit }).map((param) => {
        // when the user did not setup an order we don't show the
        // order we selected on the URL
        // const doNotUpdateUrl = param[0] === 'order';
        if (param[0] === 'limit') {
          return {
            key: param[0],
            state: param[1] === 30 ? undefined : param[1],
            updateState: (value) => dispatch({ type: 'setLimit', payload: value }),
          };
        }
        return {
          key: param[0],
          state: param[1],
          updateState: (value) =>
            dispatch({ type: 'setFilter', fieldName: param[0], payload: value }),
        };
      }),
    [state.searchParams, state.limit]
  );
  const { updateUrlSearchParams, urlParamsProcessed } = useUrlSearchParams(
    filterObjectIntoParamStateObj,
    true
  );
  const getReferenceFrameItemHrefs = useCallback(
    async (menuItem) => {
      if (menuItem) {
        const navMenuItem = await navigationService.getItemAsync(menuItem);

        return navMenuItem?.theme && navMenuItem?.theme.type === 'THEME'
          ? navMenuItem?.theme.$$meta.permalink
          : navMenuItem?.theme?.theme?.$$meta?.permalink ||
              navigationService.getRecursiveThemes(navMenuItem).join(',');
      }
      if (
        proTheme != null &&
        proTheme.referenceFrameItem &&
        proTheme.referenceFrameItem.some((refItem) => refItem.$$meta?.permalink != null)
      ) {
        return proTheme.referenceFrameItem.map((ref) => ref.$$meta.permalink);
      }
      return null;
    },
    [proTheme, navigationService]
  );

  const issueSearch = useCallback(
    async (filters) => {
      if (!urlParamsProcessed || !userViewOptions) return;
      updateUrlSearchParams();
      const referenceFrameItemHrefs = await getReferenceFrameItemHrefs(filters.menuItem);

      if (filters.menuItem && referenceFrameItemHrefs === '') {
        return;
      }
      const tempLimit = window.location.hash
        ? Math.ceil(parseInt(window.location.hash.replace('#', ''), 10) / 10) * 10
        : state.limit;
      const mainstructuresOuTypeCombinations = getMainstructuresOuTypeCombinationsFromViewOptions(
        userViewOptions,
        false
      );

      const coverage = getCoverageFromViewOptions(userViewOptions);
      const orderToUse = getOrderMethod(filters.order, filters.q);

      const searchApiParams = {
        q: filters.q,
        types: 'pro_web_page',
        expand: 'FULL',
        proWebPageTypes: 'TEASER',
        limit: tempLimit,
        ...(orderToUse === OrderMethods.RELEVANCE
          ? {}
          : {
              orderby: 'issued.startDate',
              descending: `${orderToUse === OrderMethods.NEWEST}`,
            }),
        ...(filters.van == null ? {} : { dateFrom: filters.van }),
        ...(filters.tot == null ? {} : { dateTo: filters.tot }),
        ...(orderToUse === OrderMethods.RELEVANCE
          ? {}
          : {
              orderby: 'issued.startDate',
              descending: `${orderToUse === OrderMethods.NEWEST}`,
            }),
        ...(!(
          window.sessionStorage.getItem('preview') === 'true' || window.location.search.preview
        ) && { issuedOn: new Date().toISOString() }),
        ...(filters.curricula && { themeRoots: filters.curricula.join(',') }),
        themePageRootOrThemeRoot: referenceFrameItemHrefs ? `;${referenceFrameItemHrefs}` : '',
        minScore: referenceFrameItemHrefs ? 1 : undefined, // news items are getting a very low score after a few weeks, but in local news we are searching specifically for news and within a theme so we are less strict
        mainstructuresOuTypeCombinations,
        coverage,
      };

      getSearchResults(searchApiParams, (res) => ({
        ...res,
        tags: transformTags(userViewOptions)(res),
      }));
    },
    [
      getReferenceFrameItemHrefs,
      updateUrlSearchParams,
      urlParamsProcessed,
      getSearchResults,
      userViewOptions,
    ]
  );

  useEffect(() => {
    const initSearch = async () => {
      if (Object.keys(state.searchParams).length > 0) {
        await issueSearch(state.searchParams);
      }
    };
    setIsLoading(true);
    initSearch();
  }, [state.searchParams, issueSearch, setIsLoading]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const newPayload = {};
    Object.entries(state.searchParams).forEach(([key, value]) => {
      if (key === 'order') {
        newPayload.order = state.filters.order;
      } else if (urlParams.has(key)) {
        if (key === 'curricula') {
          newPayload[key] = curriculumSitesLogic.decodeThemes(urlParams.get(key));
        } else {
          newPayload[key] = decodeURIComponent(urlParams.get(key));
        }
      } else {
        newPayload[key] = value;
      }
    });

    dispatch({ type: 'setSearchParams', payload: newPayload });
  }, [state.filters.order, state.limit]);
  const updateOrder = (value) => {
    dispatch({ type: 'setLimit', payload: 30 });
    dispatch({ type: 'setFilter', fieldName: 'order', payload: value });
  };

  const clearFilters = () => {
    Object.keys(state.filters).forEach((key) => {
      if (key === 'order') return;
      let payload;
      switch (key) {
        case 'curricula':
          payload = [];
          break;
        case 'q':
          payload = '';
          break;
        default:
          payload = null;
          break;
      }
      dispatch({ type: 'setFilter', fieldName: key, payload });
    });
    dispatch({ type: 'setLimit', payload: 30 });
  };

  const clearSearchQuery = () => {
    dispatch({ type: 'setLimit', payload: 30 });
    dispatch({ type: 'setFilter', fieldName: 'q', payload: '' });
  };

  const loadMoreSearchResults = () => {
    dispatch({ type: 'setLimit', payload: parseInt(state.limit, 10) + 30 });
  };
  return {
    doSearch: () => dispatch({ type: 'setSearchParams', payload: null }),
    filterUpdate: (filterName, value) =>
      dispatch({ type: 'setFilter', fieldName: filterName, payload: value }),
    updateOrder,
    clearFilters,
    clearSearchQuery,
    filters: {
      ...state.filters,
      mainstructuresOuTypeCombinations:
        userViewOptions &&
        getMainstructuresOuTypeCombinationsFromViewOptions(userViewOptions, false),
      coverage: userViewOptions && getCoverageFromViewOptions(userViewOptions),
    },
    searchResults,
    searchCount,
    isLoading,
    loadMoreSearchResults,
  };
};

export default useNewsDatabase;
