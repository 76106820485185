import React from 'react';
import PropTypes from 'prop-types';
import Banner from '@src/reactComponents/sections/banner/banner';
import { dateToString } from '@helpers/utils';
import ApplicabilityLabels from '@UI/applicabilityLabels/ApplicabilityLabels';
import ThemeText from '@src/reactComponents/theme/themeText/themeText';
import { stripHtml } from '@src/app/utils';
import { useSelector } from 'react-redux';
import {
  selectCurrentRelevantTeaserWithApplicabiltyLabels,
  selectRelatedNewsTeasers,
  selectCurrentThemePaths,
} from '@src/reduxStore/news/newsSelectors';
import RelatedTeasers from './relatedTeasers/relatedTeasers';

require('./newsItemDetail.scss');

const NewsItemDetail = ({ newsItem }) => {
  const rootTeaser = useSelector(selectCurrentRelevantTeaserWithApplicabiltyLabels);
  const relatedNewsItems = useSelector(selectRelatedNewsTeasers);
  const themePaths = useSelector(selectCurrentThemePaths);

  return (
    <>
      <Banner menuItem={{ websiteType: 'news', title: 'Nieuws', extra: newsItem }} />

      <section id="news-page" className="kov-pro-news-page">
        <div className="container">
          <div className="row">
            <div className="kov-news-page col-12">
              <div className="row">
                <div className="kov-pro-news__item kov-pro-theme-main-content col-12 col-md-12 col-lg-8">
                  <div className="row">
                    <div className="kov-pro-news__info col-12">
                      <div className="kov-pro-news__info-title">{stripHtml(newsItem.title)}</div>
                      <div className="kov-pro-news__info-specs">
                        <p className="text-date-time">
                          {newsItem.issued && (
                            <span className="date">
                              <i className="icon-kov-pro-calendar" />
                              {dateToString(newsItem.issued)}
                            </span>
                          )}
                          {newsItem.author && (
                            <span className="author">
                              <i className="icon-kov-pro-profile" />
                              {newsItem.author}
                            </span>
                          )}
                        </p>
                        <ApplicabilityLabels labels={rootTeaser?.tags} />
                      </div>
                    </div>
                    <div className="kov-pro-theme-content kov-pro-news__content col-12">
                      {newsItem.type === 'TEASER' && (
                        <div className="row no-gutters">
                          {newsItem.description && (
                            <div dangerouslySetInnerHTML={{ __html: newsItem.description }} />
                          )}
                          <div className="flex">
                            <figure>
                              <div className="img-article-holder right">
                                <div className="img-article-img">
                                  {newsItem.thumbImage && (
                                    <img src={newsItem.thumbImage.href} className="img-fluid" />
                                  )}
                                </div>
                              </div>
                            </figure>
                          </div>
                        </div>
                      )}
                      {newsItem.type === 'STRUCTURED_DOCUMENT' && (
                        <ThemeText item={newsItem} color={'#ae2081'} />
                      )}
                    </div>
                    <div className="col-12">
                      <div className="divider-line mb0" />
                    </div>
                    <div className="kov-pro-news__redirect col-12">
                      <div>
                        <a
                          href={
                            window.location.hash !== ''
                              ? `/nieuwsoverzicht${window.location.hash}`
                              : '/nieuwsoverzicht'
                          }
                          type="button"
                          className="btn kov-pro-btn kov-pro-btn__icon--left kov-pro-btn--dark kov-pro-btn--inverted btn-primary"
                        >
                          <i className="icon-kov-pro-bookmark-full" />
                          Nieuwsoverzicht
                        </a>
                      </div>
                      {themePaths?.map((path, index) => (
                        <div key={index}>
                          <a
                            href={`${path.path}/nieuwsoverzicht`}
                            type="button"
                            className="btn kov-pro-btn kov-pro-btn__icon--left kov-pro-btn--dark kov-pro-btn--inverted btn-primary"
                          >
                            <i className="icon-kov-pro-bookmark-full" />
                            {stripHtml(path.title)}
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {relatedNewsItems && relatedNewsItems.length !== 0 && (
                  <div className="kov-pro-theme-side-content col-12 col-md-12 col-lg-4">
                    <RelatedTeasers teasers={relatedNewsItems} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

NewsItemDetail.propTypes = {
  newsItem: PropTypes.object,
};

export default NewsItemDetail;
