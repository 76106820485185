class wsTemp {
  constructor($scope, $stateParams) {
    'ngInject';

    this.$scope = $scope;
    this.$stateParams = $stateParams;
  }

  updateMetaData = (page) => {
    this.$scope.$emit('dynamicMetaDataUpdated', page);
  };
}

export default {
  template: require('./wsTemp.html'),
  controllerAs: 'ctrl',
  controller: wsTemp,
  bindings: {
    sTheme: '<',
  },
};
