import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Banner from '@src/reactComponents/sections/banner/banner';
import ThemeToc from '@UI/themeComponents/themeTextToc/ThemeToc';
import { getAngularService } from '@src/module/reactMigrationUtils/angular-react-helper';
import { offsetFromTop, stripHtml } from '@src/app/utils';
import { ThemeMenu } from '@components/database/components';
import { shouldShowDownloadsSection } from '@components/theme/themeDownloadSection/helpers/downloadSectionHelper';
import useLocationContacts from '@src/hooks/useLocationContacts';
import { getThemeTabs } from '@src/components/theme/templates/helpers/helpers';
import { selectItemInCurrentThemeTree } from '@src/reduxStore/theme/themeSelectors';
import { useSelector } from 'react-redux';
import ThemeText from '../themeText/themeText';

import ThemeDownloadSection from '../themeDownloadSection/themeDownloadSection';
import ThemeContacts from '../themeContacts/themeContacts';
import NewsSection from '../newsSection/newsSection';

const findPageItemFromSub = (subitem) => {
  if (subitem.parent) {
    if (
      [
        'MINI_DATABASE',
        'BLOG',
        'THEME_HOME',
        'THEME_HOME_TEXT',
        'THEME_HOME_PICTURE',
        'THEME_HOME_FULL',
      ].includes(subitem.parent.pageType)
    ) {
      return subitem;
    }

    if (['THEME_DETAIL_ROOT', 'THEME_DETAIL'].includes(subitem.parent.pageType)) {
      return subitem.parent;
    }

    return findPageItemFromSub(subitem.parent);
  }
  return null;
};

const ThemeDetail = ({
  theme,
  source,
  locationPath,
  updateMetaData,
  openCookieModal,
  transitionTo,
  stateParams,
}) => {
  // `theme` in this case will always be the root of the redactie document
  // So far we know that the `theme` in this case can only be:
  //  - THEME_DETAIL_ROOT
  //  - THEME_HOME
  //  - THEME_DETAIL? (we couldn't find any example)
  //  - FAQ? (we couldn't find any example)

  const routerService = getAngularService('routerService');

  const [scrollToPos, setScrollToPos] = useState(
    new URL(document.location).searchParams.get('scrollTo')
  );

  const itemInCurrentThemeTree = useSelector((state) =>
    selectItemInCurrentThemeTree(state, source)
  );

  let page = useMemo(
    () =>
      // this is an exception becuase of this ticket => https://katholiek-onderwijs-vlaanderen.monday.com/boards/5059664922/pulses/5256281427
      // Everything is introduced here (main branch): https://bitbucket.org/vskovzw/pro/src/f9d00eefe21fcc608f25548d32ba6d765b2b4ba8/src/services/themeService.js#lines-456
      // Obviusly that is also present after the themeService refactor but in a different line.
      // We should try to detect the original problem and fix it or use below as a temporary fix
      // for those pages and fix it later (ASAP)
      (itemInCurrentThemeTree &&
        itemInCurrentThemeTree.children?.find((a) => a.href === itemInCurrentThemeTree.href)) ||
      itemInCurrentThemeTree,
    [itemInCurrentThemeTree]
  );

  if (page) {
    // If miniDatabaseItem redirect to other state:
    if (page.pageType === 'MINI_DATABASE_ITEM') {
      transitionTo(
        'theme.databaseItem',
        {
          source,
          facets: stateParams.facets,
          requirements: stateParams.requirements,
          q: stateParams.q,
          sortingType: stateParams.sortingType,
          zillItem: stateParams.zillItem,
          ageRangeFrom: stateParams.ageRangeFrom,
          ageRangeTo: stateParams.ageRangeTo,
          scrollToPos,
        },
        { location: false }
      );
      // If blogItem redirect to proper state:
    } else if (page.pageType === 'BLOG_ITEM') {
      transitionTo(
        'theme.blogItem',
        {
          source,
          style: stateParams.style,
          requirements: stateParams.requirements,
          q: stateParams.q,
          scrollToPos,
        },
        { location: false }
      );
    } else if (page.parent) {
      page = findPageItemFromSub(page);
      if (page && page.isPathExternal) {
        window.location.href = page.pagePath;
      }
    }
  }

  const isStandalonePage = theme.pageType !== 'THEME_HOME';
  const pageTabs = getThemeTabs(theme, isStandalonePage);
  const showTabs = pageTabs.length > 0;
  const showPageTitle = page?.href !== theme.href;
  const pageTitle = page?.title;

  const { contacts } = useLocationContacts(page, theme);
  const showContactsSection = contacts.length > 0;

  useEffect(() => {
    if (scrollToPos) {
      setTimeout(() => {
        routerService.scrollTo(scrollToPos, offsetFromTop);
      }, 500);
      setScrollToPos(null);
      const url = new URL(document.location);
      url.searchParams.delete('scrollTo');
      window.history.replaceState({}, '', url);
    }
  }, [scrollToPos, routerService]);

  updateMetaData(page);

  const showDownloadSection = useMemo(() => shouldShowDownloadsSection(page), [page]);

  return page != null ? (
    <>
      <Banner menuItem={page} theme={theme} />

      <section id="theme-page">
        {showTabs && <ThemeMenu theme={theme} items={pageTabs} current={page?.href} />}
        <div className="container">
          <div className="row">
            <div className="kov-pro-theme-page col-12" id="kov-pro-theme-page">
              <div className="row col-12">
                {showPageTitle && (
                  <div className="kov-pro-theme-main-content-title col-12 d-none d-md-block">
                    <h1 dangerouslySetInnerHTML={{ __html: stripHtml(pageTitle) }} />
                  </div>
                )}

                <ThemeToc
                  page={page}
                  theme={theme}
                  showDownloadSection={showDownloadSection}
                  showContactsSection={showContactsSection}
                />

                <div className="kov-pro-theme-main-content col-12 col-md-8 order-md-1">
                  <ThemeText
                    item={page}
                    color={theme.root.color}
                    openCookieModal={openCookieModal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {showDownloadSection && <ThemeDownloadSection item={page} />}

      {showContactsSection && <ThemeContacts contacts={contacts} />}

      {theme.referenceFrameItem && (
        <NewsSection
          sReferenceFrame={theme.referenceFrameItem}
          sThemePath={theme.pagePath}
          locationPath={locationPath}
        />
      )}
    </>
  ) : null;
};

ThemeDetail.propTypes = {
  theme: PropTypes.object,
  source: PropTypes.string,
  locationPath: PropTypes.string,
  updateMetaData: PropTypes.func,
  openCookieModal: PropTypes.func,
  transitionTo: PropTypes.func,
  stateParams: PropTypes.object,
};

export default ThemeDetail;
