import React from 'react';
import PropTypes from 'prop-types';
import Compiler from '@kathondvla/react-shared-components/src/components/compiler/Compiler';
import Loader from '@UI/loader/loader';

const ThemeBlogFilters = ({
  selectedCategory,
  categories,
  isLoadingCategories,
  color,
  onCategorySelected,
}) => (
  <div className="kov-pro-blog-nav">
    <ul className="kov-pro-blog-nav__list">
      {!isLoadingCategories &&
        categories &&
        categories.map((category) => (
          <li
            key={category.href}
            className={
              category.href === selectedCategory
                ? 'kov-pro-blog-nav__list-item active'
                : 'kov-pro-blog-nav__list-item'
            }
          >
            <a
              style={{ color: category.href === selectedCategory ? color : '' }}
              onClick={() => onCategorySelected(category.href)}
            >
              <Compiler text={category.title} />
              <span className="kov-pro-pill">{category.children.length}</span>
            </a>
          </li>
        ))}
      {isLoadingCategories && <Loader />}
    </ul>
  </div>
);

ThemeBlogFilters.propTypes = {
  selectedCategory: PropTypes.string,
  categories: PropTypes.array,
  isLoadingCategories: PropTypes.bool,
  color: PropTypes.string,
  onCategorySelected: PropTypes.func,
};

export default ThemeBlogFilters;
