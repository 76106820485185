import React from 'react';
import ContentBlockAttachments from '@components/content/contentBlockAttachments/contentBlockAttachments';
import { calculateSize, getAttachmentHref } from '@src/app/contentUtils';

const FaqAttachmentsGroup = ({ content }) => (
  <ContentBlockAttachments
    item={{
      ...content,
      visible: true,
      securityStatus: 'permitted',
      children: content?.children?.map((child) => ({
        ...child,
        title: child?.attachment?.name,
        attachment: {
          ...child?.attachment,
          size: calculateSize(child?.attachment?.size),
          href: getAttachmentHref(child?.attachment),
        },
        visible: true,
        securityStatus: 'permitted',
      })),
    }}
  />
);

export default FaqAttachmentsGroup;
