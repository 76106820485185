import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_COMMON_PROPERTIES = {
  user_id: undefined,
  user_authstatus: undefined,
  user_login_method: undefined,
  user_region: undefined,
  user_edu_level: undefined,
  user_target_group: undefined,
  page_hostname: undefined,
  page_pathname: undefined,
  page_referrer: undefined,
  page_template: undefined,
  page_title: undefined,
  page_category_1: 'home',
  page_category_2: undefined,
  page_category_3: undefined,
  page_category_4: undefined,
  page_category_5: undefined,
  page_category_6: undefined,
  page_access: undefined,
  page_status_code: undefined,
};

export const ORDERED_USER_TARGET_GROUPS = [
  '/namedsets/813197eb-a73b-4183-9072-d3b5d1041f38', // bestuurder
  '/namedsets/c952becd-8e84-4eda-b0d7-967e7033d6da', // directeur
  '/namedsets/2eac4ef8-f06f-41ac-a676-579b9eaa1b6a', // middenkader
  '/namedsets/451cca0f-5fd4-445a-ac56-e6b3de012e15', // leraar
  '/namedsets/e56c4d81-8bf5-4f6c-a36b-0604f37f94ca', // preventie-adviseur
  '/namedsets/9e4b4caf-6cd4-42aa-8ba9-92e819b54b10', // ondersteunend personeel
];

const initialState = {
  commonProperties: INITIAL_COMMON_PROPERTIES,
  breadcrumbsReady: false,
  isPiwikTagReady: false,
};

const analyticsState = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    updateCommonProperties: (state, action) => {
      state.commonProperties = { ...state.commonProperties, ...action.payload };
    },
    updateBreadcrumbReadyStatus: (state, action) => {
      state.breadcrumbsReady = action.payload;
    },
    cleanUpBreadcrumbs: (state) => {
      const defaultBreadcrumbs = Object.keys(INITIAL_COMMON_PROPERTIES)
        .filter((key) => key.includes('page_category_'))
        .reduce(
          (acc, curr) => ({
            ...acc,
            [curr]: INITIAL_COMMON_PROPERTIES[curr],
          }),
          {}
        );

      state.commonProperties = {
        ...state.commonProperties,
        ...defaultBreadcrumbs,
      };
    },
    updateIsPiwikTagReady: (state, action) => {
      state.isPiwikTagReady = action.payload;
    },
  },
});

export const analyticsStateActions = analyticsState.actions;
export default analyticsState;
