import React from 'react';
import { logoutCurrentUser } from '@helpers/userHelper';
import { useDispatch } from 'react-redux';
import trackPiwikEvent from '@src/reduxStore/analytics/analyticsThunks';
import { transformViewOptionsToAnalyticsObject } from '@src/helpers/viewOptionsHelper';
import useEntryFlowViewOptionsHook from '../useEntryFlowViewOptionsHook';
import ViewOptionDropdown from '../viewOptionDropdown/viewOptionDropdown';

require('./loggedInFlow.scss');

const LoggedInFlow = ({ user, onComplete }) => {
  const reduxDispatch = useDispatch();
  const { state, dispatch } = useEntryFlowViewOptionsHook();
  const settings = require('@src/config/settings');

  const submitUserPreferences = async () => {
    if (
      !state.regions.some((reg) => reg.checked) ||
      !state.educationLevels.some((lvl) => lvl.checked)
    ) {
      dispatch({ type: 'setInvalid', payload: true });
      return;
    }

    const viewOptions = {
      EDUCATION_LEVELS: state.educationLevels,
      REGIONS: state.regions,
    };

    reduxDispatch(
      trackPiwikEvent('set_view_filter', transformViewOptionsToAnalyticsObject(viewOptions))
    );

    onComplete(viewOptions);
  };

  const logOutUser = () => {
    window.location.href = logoutCurrentUser();
  };
  return (
    <div className="view-options-first view-options-first--logged-in">
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6">
          <div className="kov-pro-login" style={{ height: 'auto' }}>
            <div className="kov-modal-title">
              <i className="icon-kov-pro-profile"></i> Je bent aangemeld als...
            </div>
            {user && (
              <div className="kov-profile">
                {user.$$profilePicture ? (
                  <a
                    href={`${settings.apisAndUrls.mijn}#!/profiel?wijzig-profiel-foto`}
                    target="_blank"
                    className="kov-profile__image"
                    style={{
                      background: `url('${settings.apisAndUrls.api}${user.$$profilePicture.$$expanded.href}') center center no-repeat`,
                    }}
                    rel="noreferrer"
                  ></a>
                ) : (
                  <a className="kov-profile__image" style={{ backgroundColor: '#F3F6F7' }}>
                    {user.initials && user.initials.length === 2 && (
                      <div className="name-wrapper">
                        <span className="kov-initials__prename ng-binding ng-scope">
                          {user.initials[0]}
                        </span>
                        <span className="kov-initials__surname ng-binding ng-scope">
                          {user.initials[1]}
                        </span>
                      </div>
                    )}
                  </a>
                )}

                <div className="kov-profile__data">
                  <div className="kov-profile__name">{user.username}</div>
                </div>
              </div>
            )}
          </div>

          <p>
            Bevestig hiernaast minstens één regio en één niveau. We gebruiken deze <b>kijkfilter</b>{' '}
            om de website te personaliseren en te filteren voor jou.{' '}
          </p>

          <p>
            <a onClick={logOutUser} href="">
              meld aan met andere account
            </a>
          </p>
        </div>

        <div className="col-12 col-md-6 col-lg-6">
          <div className="kov-pro-viewer-options">
            <div className="kov-modal-title">
              <i className="icon-kov-pro-filter"></i> Kijkfilter instellen
            </div>
            <p>
              Stel minstens één regio en één niveau in. We gebruiken deze info om de website te
              personaliseren en te filteren voor jou.{' '}
            </p>

            <ViewOptionDropdown
              placeholder={'Kies minstens een regio'}
              invalidFeedback={'Je moet minstens één regio kiezen.'}
              invalid={state.optionsInvalid}
              options={state.regions}
              initialOptionsToSet={state.regionsToSet}
              assignValues={(selectedRegions) =>
                dispatch({
                  type: 'assignValues',
                  fieldName: 'regions',
                  payload: selectedRegions,
                })
              }
            ></ViewOptionDropdown>

            <ViewOptionDropdown
              placeholder={'Kies minstens een niveau'}
              invalidFeedback={'Je moet minstens één niveau kiezen.'}
              invalid={state.optionsInvalid}
              options={state.educationLevels}
              initialOptionsToSet={state.educationLevelsToSet}
              assignValues={(selectedEducationLevels) =>
                dispatch({
                  type: 'assignValues',
                  fieldName: 'educationLevels',
                  payload: selectedEducationLevels,
                })
              }
            ></ViewOptionDropdown>
            <button
              type="button"
              disabled={
                !state.educationLevels.some((lvl) => lvl.checked) &&
                !state.regions.some((reg) => reg.checked)
              }
              className={`kov-pro-btn kov-pro-btn--block kov-pro-btn__icon--right ${
                state.educationLevels.some((lvl) => lvl.checked) &&
                state.regions.some((reg) => reg.checked)
                  ? 'kov-pro-btn--green'
                  : 'kov-pro-btn--light-light disabled'
              }`}
              onClick={submitUserPreferences}
            >
              SURF VERDER<i className="icon-kov-pro-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoggedInFlow;
