const { default: analyticsState } = require('@src/reduxStore/analytics/analyticsSlice');

require('./ws404.scss');

class ws404 {
  constructor($window, $location, $ngRedux, $scope, kovSentry) {
    'ngInject';
    this.$window = $window;
    this.$location = $location;
    this.searchTerm = '';
    this.$ngRedux = $ngRedux;
    this.$scope = $scope;
    this.kovSentry = kovSentry;
  }

  $onInit() {
    this.$scope.$emit('dynamicMetaDataUpdated', {
      title: '404',
      description: null,
    });
    this.$ngRedux.dispatch(analyticsState.actions.updateBreadcrumbReadyStatus(true));
    this.kovSentry.endLog('load Pro');
  }
  search() {
    if (this.searchTerm !== '') {
      this.$location.path('/zoeken').search({
        q: this.searchTerm,
      });
    }
  }
}

module.exports = {
  template: require('./ws404.html'),
  controllerAs: 'ctrl',
  controller: ws404,
};
