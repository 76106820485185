import { OrderMethods } from '@src/reactComponents/database/constants';
import socialMediaImage from '../assets/images/theme_logo_ko_vl-socials.png';

const settings = require('../config/settings.js');

/* NAVIGATIONS */
const NAVIGATION = {
  PRIMARY_REFERENCE_FRAME: '/content/dfa63c53-a63c-4f61-90bd-6ab13644c538',
};

const AN_VALUES_THEME_ROOT = '/content/16c3e386-54c7-4b74-80e4-1c699ed067b3';

const SEARCH_API_LIMIT_MAX = 500;

const FILE_TYPES = {
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template': 'word',
  'application/msword': 'word',
  'application/pdf': 'pdf',
  'application/xml': 'xml',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template': 'excel',
  'application/vnd.ms-excel': 'excel',
  'video/mp4': 'video',
  'audio/mp3': 'audio',
  'audio/mpeg': 'audio',
  'application/vnd.ms-powerpoint': 'powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.template': 'powerpoint',
  'image/jpg': 'image',
  'image/png': 'image',
  'image/jpeg': 'image',
  'image/gif': 'image',
};

/* BLOGS */
const BLOG = {
  ALL_CATEGORY_HREF: 'Alle',
  STYLES: ['list', 'tiles'],
  ITEMS_PER_PAGES: 10,
  DEFAULT_STYLE: 'list',
};

const DOWNLOAD_PAGE = {
  ALL_CATEGORY_HREF: 'Alle',
};

const SECURITYLEVELS = {
  PUBLIC: '/namedsets/1ab3becd-1c84-5ada-c3d7-457e7033d612',
  SENSITIVE: '/namedsets/2bb3becd-1c84-5ada-c3d7-457e7033d612',
  VERYSENSITIVE: '/namedsets/4db3becd-1c84-5ada-c3d7-457e7033d612',
  BESTUURDERS: '/namedsets/4ff3a4f1-c252-4604-aa31-7cda279a8431',
};

const IMPORTANCELEVELS = {
  VERY_LOW: 'VERY_LOW',
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
  HIGH: 'HIGH',
  VERY_HIGH: 'VERY_HIGH',
};

const CONTACT_FILTERS = {
  ant: ['/organisations/8b5232c1-6707-4ad4-922a-c45242b50674'],
  'm-b': ['/organisations/a0bc315c-b16f-451a-83a1-932968d56f88'],
  lim: ['/organisations/6684d4ee-6612-4765-b004-f9793b989015'],
  'o-vl': ['/organisations/315c0766-e882-4111-9f72-12461c864179'],
  'w-vl': ['/organisations/2b8bd98d-5356-4047-956a-09f10d010c8f'],
  '(bu)bao': [
    '/organisations/abd7fb87-60a8-46cd-8eae-2a66693bf3ef',
    '/organisations/c3fcce3c-13a2-4590-8fc1-d491a6394746',
    '/organisations/900cc19d-2e55-4e04-9c69-e2936a4562db',
    '/organisations/59fb84a1-d793-4014-bedb-f450a57d13c1',
    '/organisations/4619d5e2-7bfd-4cb9-a59e-5885479fcc46',
    '/organisations/b45e3bf8-8330-4407-a7f8-7b2fa46e8fed',
    '/organisations/e499468a-ee11-4c5b-be7c-c0c0367de0cb',
    '/organisations/dc4795fe-225e-4da9-89ab-2b2402fc3fb2',
    '/organisations/960ff417-b5e2-4582-95bd-c3e1a83b2800',
    '/organisations/9d6bb2a7-ae50-4fd5-b02d-0e4ae8bd4e5e',
  ],
  '(bu)so': [
    '/organisations/c3fcce3c-13a2-4590-8fc1-d491a6394746',
    '/organisations/a3b0d734-86fe-4f2c-8075-99668cc23392',
    '/organisations/59fb84a1-d793-4014-bedb-f450a57d13c1',
    '/organisations/ef90cd23-51c6-486c-9770-17f7a2306bee',
    '/organisations/b45e3bf8-8330-4407-a7f8-7b2fa46e8fed',
    '/organisations/7c84bb37-0f8d-4a6e-9bb0-3b0d5ed66102',
    '/organisations/dc4795fe-225e-4da9-89ab-2b2402fc3fb2',
    '/organisations/bd753516-23ee-4629-9267-0d51a259e313',
    '/organisations/9d6bb2a7-ae50-4fd5-b02d-0e4ae8bd4e5e',
    '/organisations/4e8c02cd-4adf-49fe-8da6-2ff22088fa8b',
  ],
};

const REGIONS = [
  {
    href: '/dioceses/1',
    tag: 'ant',
    title: 'Antwerpen',
    readOrder: 1,
  },
  {
    href: '/dioceses/2',
    tag: 'm-b',
    title: 'Mechelen-Brussel',
    readOrder: 3,
  },
  {
    href: '/dioceses/3',
    tag: 'lim',
    title: 'Limburg',
    readOrder: 2,
  },
  {
    href: '/dioceses/4',
    tag: 'o-vl',
    title: 'Oost-Vlaanderen',
    readOrder: 4,
  },
  {
    href: '/dioceses/5',
    tag: 'w-vl',
    title: 'West-Vlaanderen',
    readOrder: 5,
  },
];

const EDUCATION_LEVELS = [
  {
    mainstructures: [
      '/mainstructures/a3eb0c96-a3a4-11e3-ace8-005056872b95',
      '/mainstructures/a3eb0f5c-a3a4-11e3-ace8-005056872b95',
      '/mainstructures/a3eb10b0-a3a4-11e3-ace8-005056872b95',
      '/mainstructures/a3eb151a-a3a4-11e3-ace8-005056872b95',
    ],
    ouType: 'SCHOOL',
    mainstructuresOuTypeCombination: '/namedsets/6366f518-1a00-4445-b064-2df1d889fa0b',
    tag: '(bu)bao',
    title: '(Buitengewoon) basisonderwijs',
    readOrder: 1,
    type: 'EDUCATION_LEVEL',
  },
  {
    mainstructures: [
      '/mainstructures/a3eb1646-a3a4-11e3-ace8-005056872b95',
      '/mainstructures/a3eb1c72-a3a4-11e3-ace8-005056872b95',
    ],
    ouType: 'SCHOOL',
    mainstructuresOuTypeCombination: '/namedsets/0dba1aec-ee5d-4c85-98ab-1bef27afb673',
    tag: '(bu)so',
    title: '(Buitengewoon) secundair onderwijs',
    readOrder: 2,
    type: 'EDUCATION_LEVEL',
  },
  {
    mainstructures: ['/mainstructures/a3eb2212-a3a4-11e3-ace8-005056872b95'],
    ouType: 'SCHOOL',
    mainstructuresOuTypeCombination: '/namedsets/2e1122a2-3cff-4860-840d-57c578038d67',
    tag: 'dko',
    title: 'Deeltijds kunstonderwijs',
    readOrder: 3,
    trainingReadOrder: 8,
    type: 'EDUCATION_LEVEL',
  },
  {
    mainstructures: [
      '/mainstructures/a3eb1b50-a3a4-11e3-ace8-005056872b95',
      '/mainstructures/a3eb1eac-a3a4-11e3-ace8-005056872b95',
    ],
    mainstructuresOuTypeCombination: '/namedsets/854bd7c7-051d-4667-ab2f-3f7186c5aa54',
    noMainstructureNeeded: true,
    ouType: 'CVO',
    tag: 'vwo',
    title: 'Volwassenenonderwijs',
    readOrder: 5,
    trainingReadOrder: 10,
    type: 'EDUCATION_LEVEL',
  },
  {
    mainstructures: [
      '/mainstructures/a3eb1d94-a3a4-11e3-ace8-005056872b95',
      '/mainstructures/a3eb20fa-a3a4-11e3-ace8-005056872b95',
    ],
    mainstructuresOuTypeCombination: '/namedsets/c5cdd16f-b79d-4656-82a4-8f187c2d321a',
    ouType: 'SCHOOL',
    tag: 'ho',
    title: 'Hoger onderwijs',
    readOrder: 4,
    trainingReadOrder: 9,
    type: 'EDUCATION_LEVEL',
  },
  {
    mainstructures: [],
    mainstructuresOuTypeCombination: '/namedsets/75fea99a-e3d6-4b99-b7ae-dbdfeddd2093',
    ouType: 'BOARDING',
    tag: 'int',
    title: 'Internaten',
    readOrder: 6,
    trainingReadOrder: 11,
    type: 'EDUCATION_LEVEL',
  },
];
const TRAININGS_GLOBAL_DATABASE_SPOTLIGHTED_THEMES_REFERENCE_FRAME =
  '/content/3e33a9f5-248c-4a6e-bc41-f773504ea557';

const TRAINING_TYPES = [
  {
    tag: 'INDIVIDUGERICHT',
    title: 'Individugericht',
  },
  {
    tag: 'TEAMGERICHT',
    title: 'Teamgericht',
  },
  {
    tag: 'CONGRES',
    title: 'Congres',
  },
  {
    tag: 'DAGENVAN',
    title: 'Inspiratiedag (dagen van...)',
  },
  {
    tag: 'LEERPAD',
    title: 'Leerpad',
  },
  {
    tag: 'LERENDNETWERK',
    title: 'Lerend netwerk',
  },
  {
    tag: 'MEERDAAGSE',
    title: 'Meerdaagse',
  },
  {
    tag: 'NETWERKDAG',
    title: 'Netwerkdag',
  },
];

const TRAINING_MENU = {
  Kalenders: `${settings.apisAndUrls.trainings.nascholing}${settings.apisAndUrls.trainings.schoolYear}kalenders.aspx`,
  Attesten: `${settings.apisAndUrls.trainings.nascholing}${settings.apisAndUrls.trainings.schoolYear}attesten.aspx`,
  Modaliteiten: 'https://pro.katholiekonderwijs.vlaanderen/professionalisering-modaliteiten',
  FAQ: 'https://pro.katholiekonderwijs.vlaanderen/professionalisering-veelgestelde-vragen',
};

const EXTRA_EDUCATION_LEVELS = [
  // //// extra
  {
    mainstructures: ['/mainstructures/a3eb0c96-a3a4-11e3-ace8-005056872b95'],
    ouType: 'SCHOOL',
    tag: 'gk',
    mainstructuresOuTypeCombination: '/namedsets/4bb3768c-8f0a-4064-9660-324d43c94b18',
    title: 'Gewoon kleuteronderwijs',
    parentValue: '/namedsets/6366f518-1a00-4445-b064-2df1d889fa0b',
    trainingReadOrder: 2,
  },
  {
    mainstructures: ['/mainstructures/a3eb17b8-a3a4-11e3-ace8-005056872b95'],
    ouType: 'SCHOOL',
    mainstructuresOuTypeCombination: '/namedsets/cad6b8a2-496b-4f01-b4ca-919ab848bafb',
    tag: 'dbso',
    title: 'Deeltijds beroepssecundair onderwijs',
    parentValue: '/namedsets/0dba1aec-ee5d-4c85-98ab-1bef27afb673',
    trainingReadOrder: 7,
    type: 'EDUCATION_LEVEL',
  },
  {
    mainstructures: ['/mainstructures/a3eb0f5c-a3a4-11e3-ace8-005056872b95'],
    ouType: 'SCHOOL',
    tag: 'bk',
    mainstructuresOuTypeCombination: '/namedsets/a89ebfb1-8f67-4f8b-a558-8efb5b50b148',
    title: 'Buitengewoon kleuteronderwijs',
    parentValue: '/namedsets/6366f518-1a00-4445-b064-2df1d889fa0b',
    trainingReadOrder: 1,
  },
  {
    mainstructures: ['/mainstructures/a3eb10b0-a3a4-11e3-ace8-005056872b95'],
    ouType: 'SCHOOL',
    tag: 'glo',
    mainstructuresOuTypeCombination: '/namedsets/910dfae9-e385-46ee-9ba3-9dd037482672',
    title: 'Gewoon lager onderwijs',
    parentValue: '/namedsets/6366f518-1a00-4445-b064-2df1d889fa0b',
    trainingReadOrder: 4,
  },
  {
    mainstructures: ['/mainstructures/a3eb151a-a3a4-11e3-ace8-005056872b95'],
    ouType: 'SCHOOL',
    tag: 'blo',
    mainstructuresOuTypeCombination: '/namedsets/cacf67b4-6b46-4f6a-813a-0057dbf3abda',
    title: 'Buitengewoon lager onderwijs',
    parentValue: '/namedsets/6366f518-1a00-4445-b064-2df1d889fa0b',
    trainingReadOrder: 3,
  },
  {
    mainstructures: ['/mainstructures/a3eb1646-a3a4-11e3-ace8-005056872b95'],
    ouType: 'SCHOOL',
    tag: 'gso',
    mainstructuresOuTypeCombination: '/namedsets/a23cc576-52ff-406f-92a4-a5012b2f410f',
    title: 'Gewoon secundair onderwijs',
    parentValue: '/namedsets/0dba1aec-ee5d-4c85-98ab-1bef27afb673',
    trainingReadOrder: 6,
  },
  {
    mainstructures: ['/mainstructures/a3eb1c72-a3a4-11e3-ace8-005056872b95'],
    ouType: 'SCHOOL',
    tag: 'bso',
    mainstructuresOuTypeCombination: '/namedsets/3e531ad3-1227-45d1-adac-dde38b5938db',
    title: 'Buitengewoon secundair onderwijs',
    parentValue: '/namedsets/0dba1aec-ee5d-4c85-98ab-1bef27afb673',
    trainingReadOrder: 5,
  },
];

const TRAINING_POSITIONS = [
  {
    href: '/namedsets/451cca0f-5fd4-445a-ac56-e6b3de012e15',
    title: 'Leraar',
    tags: ['doelgroepen'],
    readOrder: 5,
  },
  {
    href: '/namedsets/c952becd-8e84-4eda-b0d7-967e7033d6da',
    title: 'Directeur',
    tags: ['doelgroepen'],
    readOrder: 3,
  },
  {
    href: '/namedsets/2eac4ef8-f06f-41ac-a676-579b9eaa1b6a',
    title: 'Middenkader',
    description: 'Bijvoorbeeld zorgcoördinator, technisch adviseur(-coördinator), inkoper ...',
    tags: ['doelgroepen'],
    readOrder: 6,
  },
  {
    href: '/namedsets/813197eb-a73b-4183-9072-d3b5d1041f38',
    title: 'Bestuurder',
    tags: ['doelgroepen'],
    readOrder: 2,
  },
  {
    href: '/namedsets/c29d408e-6107-4fd1-b719-e18b2628280a',
    title: 'Opvoedend personeel',
    tags: ['doelgroepen'],
    readOrder: 7,
  },
  {
    href: '/namedsets/dc8bc3ab-b675-48cf-90b0-21afd4d00b53',
    title: 'Administratief personeel',
    tags: ['doelgroepen'],
    readOrder: 1,
  },
  {
    href: '/namedsets/e56c4d81-8bf5-4f6c-a36b-0604f37f94ca',
    title: 'Preventieadviseur',
    tags: ['doelgroepen'],
    readOrder: 8,
  },
  {
    href: '/namedsets/326fcbc1-0805-46dc-9c0d-5ed5e47a9fa2',
    title: 'Andere',
    description: 'Zij die zich niet herkennen in één van de andere categorieën van functies',
    tags: ['doelgroepen'],
    readOrder: 9,
  },
  {
    href: '/namedsets/bb4676fc-fa17-471b-88df-751f1ba69065',
    title: 'ICT-coördinator',
    tags: ['doelgroepen'],
    readOrder: 4,
  },
];

const TRAINING_SORTS = [
  {
    title: 'recent gepubliceerd',
    sortType: 'dateSort',
    searchTag: OrderMethods.RECENT_PUBLISHED,
  },
  {
    title: 'relevantie',
    sortType: 'relevantSort',
    searchTag: OrderMethods.RELEVANCE,
  },
  {
    title: 'alfabetisch',
    sortType: 'alphabeticalSort',
    searchTag: OrderMethods.ALPHABETICAL,
  },
  {
    title: 'chronologisch',
    sortType: 'startDateSort',
    searchTag: OrderMethods.CHRONOLOGICAL,
  },
];

const TRAINING_LOCATION_TYPES = [
  {
    title: 'Fysieke bijeenkomst',
    locationType: 'FYSICAL',
  },
  {
    title: 'Online initiatief',
    locationType: 'ONLINE',
  },
  {
    title: 'Mix van fysiek en online',
    locationType: 'MIXED',
  },
];

const TRAINING_THEME_FILTERS = [
  '/content/7927c9c6-8398-4c5f-a6d3-3f5d9d73977a',
  '/content/4a9d11fb-920f-4b83-835c-509211ed0246',
  '/content/a6d4a03b-a426-4e77-a6ee-ebdb59cb4655',
  '/content/8b008943-4bcf-4ca3-b25d-3ef5ac85df1d',
  '/content/d7641988-5bf1-4c7f-984b-dabcb2987725',
  '/content/493b8f10-da27-4714-81e1-21247b0c45c8',
  '/content/8d56d179-ae11-44af-90b4-08824d2fce48',
  '/content/d0ab2555-0f09-493d-9292-f012b91ea156',
  '/content/a9fa3fd1-3d85-4a8d-844d-88983e31b580',
  '/content/c6f0d87a-a08e-4a79-ab7e-291170ea2472',
];

// Disclaimer -> https://pro.katholiekonderwijs.vlaanderen/disclaimer
// Privacy -> https://pro.katholiekonderwijs.vlaanderen/pro-privacy
// Cookie-instellingen -> https://pro.katholiekonderwijs.vlaanderen/cookies

const FOOTER_LINKS_SOCIAL = [
  {
    aClass: 'kov-pro-footer__copyright-social--facebook',
    iClass: 'fab fa-facebook-f',
    href: 'https://www.facebook.com/KatholiekOnderwijsVlaanderen/',
  },
  {
    aClass: 'kov-pro-footer__copyright-social--twitter',
    iClass: 'fab fa-twitter',
    href: 'https://twitter.com/KathOndVla',
  },
  {
    aClass: 'kov-pro-footer__copyright-social--instagram',
    iClass: 'fab fa-instagram',
    href: 'https://www.instagram.com/kathondvla',
  },
  {
    aClass: 'kov-pro-footer__copyright-social--linkedin',
    iClass: 'fab fa-linkedin-in',
    href: 'https://www.linkedin.com/company/katholiekonderwijsvlaanderen/',
  },
  {
    aClass: 'kov-pro-footer__copyright-social--youtube',
    iClass: 'fab fa-youtube',
    href: 'https://www.youtube.com/channel/UCqY2nhXwDdXkrDxXAVWkA7A',
  },
];

const FOOTER_LINKS_COPYRIGHT_DISCLAIMERS = [
  {
    title: 'Disclaimer',
    href: '/disclaimer',
  },
  {
    title: 'Privacy',
    href: '/pro-privacy',
  },
  {
    title: 'Cookie-instellingen',
    href: '/cookies',
  },
];

const TRAINING_CLEAR_ALL = 'wis alle';
const TRAINING_INDIVIDUAL = 'INDIVIDUGERICHT,INDIVIDUAL';
const TRAINING_TO_BE_DEFINED = 'Te bepalen';

const TEASERS_DEV_MODE = false;

const NEWS_SECTION = {
  MAIN_TEASERS: 3,
  SIDE_TEASERS: 5,
};

const SEARCH_SCOPE = {
  GLOBAL: 'Volledige website',
  LOCAL: 'Huidige themasite',
};

const COOKIE_TYPE = {
  ANALYTIC: 'cookies__analytic', // allow analytics
  SOCIAL: 'cookies__social', // allow socialmedia
  CONSENT: 'cookies__consent', // allow cookies
};

const COOKIE_CONTENT = {
  NECESSARY: '015afa35-b939-456c-b5f6-abacbc56ed6f',
  ANALYTIC: 'd5c51eb1-4954-40c3-8f8b-dc5997139f47',
  SOCIAL: '100bca36-6c24-40b6-9fda-8f553f38a763',
};

const getRegions = () =>
  [...REGIONS].sort((a, b) => a.readOrder - b.readOrder).map((reg) => ({ ...reg, checked: false }));

const getEducationLevels = (training = false) => {
  if (training === true) {
    return [
      ...EDUCATION_LEVELS.filter((el) => el.trainingReadOrder),
      ...EXTRA_EDUCATION_LEVELS,
    ].sort((a, b) => a.trainingReadOrder - b.trainingReadOrder);
  }
  return [...EDUCATION_LEVELS]
    .sort((a, b) => a.readOrder - b.readOrder)
    .map((lvl) => ({
      ...lvl,
      checked: false,
    }));
};

const FOOTER = {
  SNELNAAR: '/content/ce10bb6e-94cf-488a-96f8-5ab1e263068e',
  CONTACT: '/content/c44b570a-c0f0-4ec2-93d6-5ddfd9fd2133',
};

const HOMEPAGE_COVER_IMAGE = {
  href: socialMediaImage,
  dimension: {
    w: '1000',
    h: '1000',
  },
};

const HOMEPAGE_REFERENCES = {
  MENU_ITEMS: '/content/83adbb9e-0d7f-4a60-9a2e-6ccedf67e750',
  NEWS: '/content/de934c57-c938-4d34-95b5-28ef638ee6f9',
  DOSSIERS: '/content/6ae30712-32d5-45e6-81ac-174e6c1c3554',
  FOOTER: '/content/68a5183e-e6d3-437a-8df4-ffa9a58430a5',
  SNELLINKS: '/content/2e1c8b07-e990-4445-929b-cc1b4b200d38',
  SPOTLIGHTTHEMES: '/content/0e4b50a0-2ecd-4e19-bf1b-f8ed85bedad4',
  AGENDA: '/content/091ebf91-12cb-42a2-9661-a6c6ec5b9662',
  BLOG: '/content/9b0091e7-4bc6-4d05-8c8b-7a4f190e2c17',
};

export {
  NAVIGATION,
  FILE_TYPES,
  BLOG,
  getRegions,
  getEducationLevels,
  TRAININGS_GLOBAL_DATABASE_SPOTLIGHTED_THEMES_REFERENCE_FRAME,
  TRAINING_TYPES,
  TRAINING_MENU,
  TRAINING_POSITIONS,
  TRAINING_SORTS,
  TRAINING_LOCATION_TYPES,
  TRAINING_THEME_FILTERS,
  TRAINING_CLEAR_ALL,
  TRAINING_INDIVIDUAL,
  TRAINING_TO_BE_DEFINED,
  TEASERS_DEV_MODE,
  NEWS_SECTION,
  FOOTER_LINKS_COPYRIGHT_DISCLAIMERS,
  FOOTER_LINKS_SOCIAL,
  CONTACT_FILTERS,
  SEARCH_SCOPE,
  COOKIE_TYPE,
  COOKIE_CONTENT,
  SECURITYLEVELS,
  IMPORTANCELEVELS,
  FOOTER,
  DOWNLOAD_PAGE,
  HOMEPAGE_COVER_IMAGE,
  HOMEPAGE_REFERENCES,
  SEARCH_API_LIMIT_MAX,
  AN_VALUES_THEME_ROOT,
};
