/**
 * This function checks the localStorage searching for the tab a user selected in purpose in the past so we can select that by default when the user visits "didaktiek en leerplanen" page, so we understand this is the one he is interested in so he doesn't need to select it every time.
 * That value on the localStorage will be updated every time a user selects a tab in the navigation page or the quickNavigation menu.
 * @param {Array<string>} availableTabsHrefs
 * @returns
 */
export const getUserStoredSelectedTabHref = (availableTabsHrefs) => {
  const userSelectedTabHref = window.localStorage.getItem('userSelectedTabHref');

  if (userSelectedTabHref)
    return availableTabsHrefs?.find((availableTabHref) => availableTabHref === userSelectedTabHref);

  return null;
};

/**
 * This function will save in the localStorage the tab selected by the user when he actually selects it in purpose (not when we select one by default).
 * This will be triggered both from the navigation page and the quickNavigation menu
 * @param {string} selectedTabHref
 */
export const setUserStoredSelectedTabHref = (selectedTabHref) => {
  window.localStorage.setItem('userSelectedTabHref', selectedTabHref);
};
