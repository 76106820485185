import React from 'react';
import PropTypes from 'prop-types';
import DateRangePicker from '@UI/dateRangePicker/dateRangePicker';
import SearchInput from '@UI/searchInput/searchInput';
import NewsMenuItemSelector from './newsMenuItemSelector/newsMenuItemSelector';

const NewsFilters = ({
  proTheme,
  filters,
  onUpdateFilter,
  onSearch,
  onClearFilters,
  onClearSearchQuery,
  secondaryFilterValue,
  setSecondaryFilterValue,
}) => {
  const today = new Date();
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

  return (
    <div className="kov-pro-filter">
      <div className="kov-pro-filter__title-wrapper" onClick={onClearFilters}>
        <h2 className="kov-pro-filter__title">Filter</h2>
        <button className="filter-tag">
          wis alle <i className="icon-kov-pro-close" />
        </button>
      </div>

      <div className="filter-block">
        <div className="title">Zoek tot 12 maanden terug</div>
        <div className="filter-items collapse show">
          <div className="kov-pro-mini-search input-group">
            <SearchInput
              placeholder="Zoek"
              value={filters.q}
              onChange={(newValue) => {
                onUpdateFilter('q', newValue);
              }}
              onSearch={onSearch}
              inputClassName="form-control"
              onClear={onClearSearchQuery}
              showClear={false}
            />
          </div>
          <DateRangePicker
            startDate={filters.van ? new Date(+filters.van) : undefined}
            endDate={filters.tot ? new Date(+filters.tot) : undefined}
            onDaySelected={onUpdateFilter}
            minDate={oneYearAgo}
            maxDate={today}
          />
        </div>
      </div>

      {!proTheme && (
        <NewsMenuItemSelector
          filters={filters}
          updateThemeFilter={(menuItem) => onUpdateFilter('menuItem', menuItem)}
          updateCurriculaFilter={onUpdateFilter}
          secondaryFilterValue={secondaryFilterValue}
          setSecondaryFilterValue={setSecondaryFilterValue}
        />
      )}

      <button
        className="kov-pro-btn kov-pro-btn--block kov-pro-btn--darkgreen"
        onClick={() => onSearch()}
      >
        Toon resultaten
      </button>
    </div>
  );
};

NewsFilters.propTypes = {
  proTheme: PropTypes.object,
  filters: PropTypes.object,
  onUpdateFilter: PropTypes.func,
  onSearch: PropTypes.func,
  onClearFilters: PropTypes.func,
  onClearSearchQuery: PropTypes.func,
  secondaryFilterValue: PropTypes.string,
  setSecondaryFilterValue: PropTypes.func,
};

export default NewsFilters;
