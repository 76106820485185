import * as settings from '@src/config/settings';
import { useCallback, useState } from 'react';
import { SEARCH_LIMIT } from '@components/database/constants';
import { getAPISearchResults } from '@src/reduxStore/content/contentDataAccess';

const searchClient = require('@kathondvla/sri-client/fetch-sri-client')({
  baseUrl: settings.apisAndUrls.searchApi,
});

let currentAbortController = null;

export const useSearchApi = ({ previewDataToUse = null } = {}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [count, setCount] = useState(0);
  const [nextLink, setNextLink] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [transformResultFunction, setTransformResultFunction] = useState();

  const getSearchResults = useCallback(
    async (params, transformResultFunc) => {
      setIsLoading(true);
      setError(null);
      setNextLink(null);
      setTransformResultFunction(() => transformResultFunc);

      try {
        if (currentAbortController) currentAbortController.abort();
        currentAbortController = new AbortController();

        let result;
        let counter;
        let next = null;

        if (previewDataToUse) {
          result = {
            results: previewDataToUse,
          };
          counter = result.results.length;
        } else {
          result = await getAPISearchResults(
            {
              ...params,
              issuedOn: Date.now(),
            },
            null,
            {
              cancel: currentAbortController.signal,
            }
          );

          counter = result.$$meta?.count;

          if (result.$$meta.next != null) {
            setNextLink(result.$$meta.next.replace(/limit=\d+/, `limit=${SEARCH_LIMIT}`));
            next = result.$$meta?.next;
          }
        }

        const vmResults =
          transformResultFunc == null ? result.results : result.results.map(transformResultFunc);

        setSearchResults(vmResults);
        setCount(counter);
        setIsLoading(false);

        return {
          results: vmResults,
          count: counter,
          next,
        };
      } catch (searchApiError) {
        if (searchApiError.name && searchApiError.name === 'AbortError') {
          console.log(
            '[use search api hook] A new request was send before the response of another request was received. The previous request is canceled'
          );
          return undefined;
        }
        console.error(searchApiError);
        setError(searchApiError);
        setIsLoading(false);

        return undefined;
      }
    },
    [previewDataToUse]
  );

  const loadMoreSearchResults = useCallback(async () => {
    setIsLoading(true);
    try {
      if (currentAbortController) currentAbortController.abort();
      currentAbortController = new AbortController();
      const result = await searchClient.post(
        '/search/payload',
        {
          href: nextLink,
        },
        {
          cancel: currentAbortController.signal,
        }
      );
      const vmResults =
        transformResultFunction == null
          ? result.results
          : result.results.map(transformResultFunction);

      setSearchResults([...searchResults, ...vmResults]);
      setNextLink(result.$$meta.next);
      setIsLoading(false);

      return {
        results: vmResults,
        count: result.$$meta.count,
        next: result.$$meta.next,
      };
    } catch (searchApiError) {
      if (searchApiError.name && searchApiError.name === 'AbortError') {
        console.log(
          '[use search api hook] A new request was send before the response of another request was received. The previous request is canceled'
        );
        return undefined;
      }
      console.error(searchApiError);
      setError(searchApiError);
      setIsLoading(false);

      return undefined;
    }
  }, [nextLink, transformResultFunction, searchResults]);

  return {
    searchResults,
    error,
    isLoading,
    searchCount: count,
    getSearchResults,
    loadMoreSearchResults,
    setIsLoading,
  };
};

export default useSearchApi;
