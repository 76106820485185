import { useState, useEffect } from 'react';
import { selectUserViewOptions } from '@src/reduxStore/user/userSelectors';
import { useSelector } from 'react-redux';
import { getLocationContacts } from '@src/helpers/personsHelper';
import { selectFlatItems } from '@src/reduxStore/navigation/navigationSelectors';
import { getAncestors } from '@src/reduxStore/navigation/navigationHelpers';

// wie-is-wie cluster
export const FORCE_SHOW_CONTACTS_HREF = [
  '/content/2ad77bf5-68b5-44bb-b547-445c98744478', // wie-is-wie cluster
];

/**
 * This method gets a list of contacts (persons hrefs) and check their "responsibilities" using "search-api". Then it returns only the ones who have responsibilites whithin KathOndVlaa at this very moment. This is used basically to avoid showing contacts that are not working anymore in KathOndVlaa
 * @param {Array<*>} locationContacts
 * @param {boolean} shouldContactsBeFiltered
 * @param {*} viewOptions
 */
const getValidContacts = async (locationContacts, shouldContactsBeFiltered, viewOptions) => {
  const locContacts = await getLocationContacts(
    locationContacts,
    shouldContactsBeFiltered,
    viewOptions
  );
  return locContacts;
};

/**
 * This method returns a boolean indicating if the contacts should be filtered according to the view-options or not
 * @param {*} page
 * @param {*} theme
 * @param {*} flattedNavigationTree
 * @returns
 */
const shouldContactsBeFiltered = (page, theme, flattedNavigationTree) => {
  if (theme) {
    /**
     * In wie-is-wie cluster there is a functional need to show always the contacts, even if they are out of the view-options.
     * That is done with the following code
     */

    const themeNavigationItem = flattedNavigationTree.find((a) =>
      a.references?.includes(theme.href)
    );

    if (themeNavigationItem) {
      const ancestors = getAncestors(themeNavigationItem);
      return (
        !ancestors?.some((a) => FORCE_SHOW_CONTACTS_HREF.includes(a.href)) && Boolean(page?.href)
      );
    }
  }
  return Boolean(page?.href);
};

const useLocationContacts = (page, theme = false) => {
  const viewOptions = useSelector(selectUserViewOptions);
  const flattedNavigationTree = useSelector(selectFlatItems);
  const [contacts, setContacts] = useState([]);
  useEffect(() => {
    let isCancelled = false;

    const showContacts =
      page &&
      [
        'TEMPORARY_PAGE',
        'THEME_DETAIL',
        'THEME_DETAIL_ROOT',
        'CURRICULUM_PAGE',
        'BLOG_ITEM',
        'THEME_HOME',
      ].includes(page.pageType);

    if (showContacts) {
      const locationContacts = [...new Set([...(page.contacts || []), ...(theme?.contacts || [])])];

      if (locationContacts.length && flattedNavigationTree.length) {
        getValidContacts(
          locationContacts,
          shouldContactsBeFiltered(page, theme, flattedNavigationTree),
          viewOptions
        ).then((validContacts) => {
          if (!isCancelled) {
            // When a page has contact persons configured and they are valid, those contacts overrule the ones configured on a higher level (parent).
            const specificPageContacts = validContacts
              .filter((e) => e.show && page.contacts.includes(e.href))
              .sort((a, b) => a.lastName.localeCompare(b.lastName));

            if (specificPageContacts.length) {
              setContacts(specificPageContacts);
            } else {
              setContacts(
                validContacts
                  .filter((e) => e.show)
                  .sort((a, b) => a.lastName.localeCompare(b.lastName))
              );
            }
          }
        });
      }
    }

    return () => {
      isCancelled = true;
    };
  }, [page, viewOptions, flattedNavigationTree]);

  return {
    contacts,
  };
};

export default useLocationContacts;
