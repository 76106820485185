import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { generateHashForScrollTo } from '@helpers/utils';
import Icon from '../../../../UI/icon/icon';

import QuickNavigationMobileSubMenu from '../quickNavigationMobileSubMenu/quickNavigationMobileSubMenu';

require('./quickNavigationMobileItem.scss');

const QuickNavigationMobileItem = ({ item, selected, onSelect, closeMenu }) => {
  const [subSelect, setSubSelect] = useState(null);
  const [subMenuSelect, setSubMenuSelect] = useState(null);

  const keyHash = generateHashForScrollTo(item.key);

  const styleTag = `#${keyHash} a:hover, #${keyHash} a.active {border-color: ${item.color} !important;}`;

  return (
    <li className="mobile-menu__service accent-1">
      <a onClick={() => onSelect(item)} className="">
        {item.icon && <Icon icon={item.icon} style={{ width: '22px', fill: item.color }} />}
        {item.title}
      </a>
      {!item.multipleAlternativeMenus ? (
        <>
          <div className={`accordion mobile-menu__cluster ${!selected ? 'collapse' : ''}`}>
            <div className="accordion-group">
              {item.children
                .filter((childItem) => childItem.visible)
                .map((childItem, index) => (
                  <QuickNavigationMobileSubMenu
                    key={index}
                    item={childItem}
                    selected={subMenuSelect === childItem.key}
                    onSelect={setSubMenuSelect}
                    closeMenu={closeMenu}
                  />
                ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={`accordion mobile-menu__cluster ${!selected ? 'collapse' : ''}`}>
            {item.children
              .filter((childItem) => childItem.visible)
              .map((childItem, index) => (
                <div className="accordion-group" key={index}>
                  <div className="accordion-heading mobile-menu__niveau-wrapper">
                    <a
                      onClick={() => setSubSelect(childItem.key)}
                      className={`mobile-menu__niveau-header accordion-toggle ${
                        subSelect === childItem.key ? 'collapsed' : ''
                      }`}
                      data-toggle="collapse"
                      data-parent="#accordion1"
                      aria-expanded="true"
                    >
                      {childItem.title}
                    </a>
                  </div>

                  <div
                    id="collapseOne"
                    className={`accordion-body collapse ${
                      subSelect === childItem.key ? 'show' : ''
                    }`}
                  >
                    <div className="accordion-inner">
                      <div className="accordion" id="accordion2">
                        <div className="accordion-group">
                          {childItem.children
                            .filter((childChildItem) => childChildItem.visible)
                            .map((childChildItem, childIndex) => (
                              <QuickNavigationMobileSubMenu
                                key={childIndex}
                                item={childChildItem}
                                selected={subMenuSelect === childChildItem.key}
                                onSelect={setSubMenuSelect}
                                closeMenu={closeMenu}
                              />
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
      <style dangerouslySetInnerHTML={{ __html: styleTag }} />
    </li>
  );
};

QuickNavigationMobileItem.propTypes = {
  item: PropTypes.object,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  closeMenu: PropTypes.func,
};

export default QuickNavigationMobileItem;
