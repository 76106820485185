import React, { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import { CurriculaSelector } from '@kathondvla/curricula-selector';
import { cachedApi, api } from '@src/app/apiConfig';
import { getLoginUrl } from '@src/helpers/userHelper';
import { getUserKey } from '@src/reduxStore/user/userSelectors';
import { useSelector } from 'react-redux';

const CurriculaSelectorButton = ({
  preSelection,
  onSubmit,
  isBlock = false,
  fullWidthButton = true,
}) => {
  const [showCurriculaSelector, setShowCurriculaSelector] = useState(false);
  const userKey = useSelector(getUserKey);

  const handleCurriculaSelectorSubmit = useCallback(
    (data) => {
      onSubmit(data);
      setShowCurriculaSelector(false);
    },
    [onSubmit]
  );

  const handleCurriculaSelectorClose = useCallback(() => setShowCurriculaSelector(false), []);

  return (
    <>
      <button
        className={`kov-pro-btn kov-pro-btn--sm kov-pro-btn--dark kov-pro-btn--inverted kov-pro-btn__icon--right ${
          isBlock ? 'justify-content-between' : ''
        } ${fullWidthButton ? 'kov-pro-btn--block' : ''}`}
        onClick={() => setShowCurriculaSelector(true)}
      >
        Pas deze leerplanselectie aan <i className="icon-kov-pro-plus" />
      </button>
      {showCurriculaSelector &&
        ReactDOM.createPortal(
          <CurriculaSelector
            initialSelection={preSelection}
            apiConfig={{
              sriClient: api,
              sriClientCached: cachedApi,
            }}
            userHref={`/persons/${userKey}`}
            handleSubmit={handleCurriculaSelectorSubmit}
            handleClose={handleCurriculaSelectorClose}
            loginUrl={getLoginUrl()}
          />,
          document.body
        )}
    </>
  );
};

export default CurriculaSelectorButton;
