import React from 'react';
import PropTypes from 'prop-types';

const ThemeAbout = ({ title, description }) => (
  <>
    {description && (
      <section id="about" className="light kov-about no-print" style={{ marginBottom: '40px' }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8 offset-md-2">
              <div className="kov-pro-section-title">
                <h1>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>{title}</font>
                  </font>
                </h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-8 offset-md-2">
              <p>
                <font style={{ verticalAlign: 'inherit' }}>
                  <font
                    style={{ verticalAlign: 'inherit' }}
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </font>
              </p>
            </div>
          </div>
        </div>
      </section>
    )}
  </>
);

ThemeAbout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default ThemeAbout;
