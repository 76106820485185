import React from 'react';
import PropTypes from 'prop-types';

import { generateHashForScrollTo } from '@helpers/utils';
import { stripHtml } from '@src/app/utils';
import Icon from '../../../UI/icon/icon';

require('./quickNavigationItem.scss');

const QuickNavigationItem = ({ item, selected, onSelect }) => {
  const keyHash = generateHashForScrollTo(item.key);

  const styleTag = `#${keyHash} a:hover, #${keyHash} a.active {border-color: ${item.color} !important;}`;

  return (
    <li className="desktop-menu__service" id={keyHash} style={{ fill: item.color }}>
      <a
        className={`nav-link ${selected ? 'active' : ''}`}
        id="kov-theme-didactiek-tab"
        data-toggle="tab"
        role="tab"
        aria-controls="kov-theme-didactiek"
        aria-selected="true"
        onClick={() => onSelect(item)}
      >
        {item.icon && <Icon icon={item.icon} />}
        <span dangerouslySetInnerHTML={{ __html: stripHtml(item.title) }} />
      </a>
      <style dangerouslySetInnerHTML={{ __html: styleTag }} />
    </li>
  );
};

QuickNavigationItem.propTypes = {
  item: PropTypes.object,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default QuickNavigationItem;
