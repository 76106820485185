import { flattenTree, filterItems } from '@src/app/utils';
import { BLOG } from '@src/components/constants';
import { getFacetCategories } from '@src/reduxStore/content/contentHelpers';

export const sortBlogItems = (blogItems) => {
  blogItems.sort(
    (blogItem1, blogItem2) =>
      new Date(blogItem2.issued || blogItem2.created) -
      new Date(blogItem1.issued || blogItem1.created)
  );
};

export const getBlogItems = (blog) => {
  const blogItems = blog.children
    .filter((item) => item.visible)
    .filter((item) => item.pageType === 'BLOG_ITEM');
  sortBlogItems(blogItems);
  return blogItems;
};

export const getFilteredBlogItems = (blog, items, q, category) => {
  const filters = [];
  if (q) {
    filters.push({ type: 'FULL_TEXT', searchIndex: blog.searchIndex, q });
  }

  if (category && category.href && category.href !== BLOG.ALL_CATEGORY_HREF) {
    filters.push({ type: 'REQUIREMENTS', array: [category.href] });
  }

  return filterItems(items, filters);
};

export const getBlogExcerpt = (blogItem) => {
  const flatItems = flattenTree(blogItem);
  const firstParagraph = flatItems.find((item) => item.type === 'PARAGRAPH');

  return firstParagraph ? firstParagraph.$$html : '';
};

export const getBlogCategories = async (blog) => {
  const rawCategories = await getFacetCategories(
    blog.facets.find((o) => o.component === 'SELECT_FROM_REFERENCE_FRAME').source.href
  );

  let categories = [];

  if (rawCategories.length) {
    categories = rawCategories.map((category) => ({
      href: category.$$meta.permalink,
      title: category.title,
      children: [],
    }));

    categories.unshift({
      href: BLOG.ALL_CATEGORY_HREF,
      title: 'Alle',
      children: [],
    });
  }

  return categories;
};

export const addCategoriesToItems = (blogItems, categories) => {
  blogItems.forEach((item) => {
    item.categories = item?.requirements
      ?.map((requirementHref) => categories.find((category) => category.href === requirementHref))
      .filter(Boolean);
  });
};
