import React, { useCallback, useEffect, useState } from 'react';
import ModalProvider from '@UI/modal/modalProvider';
import Modal from '@UI/modal/modal';
import useModal from '@hooks/useModal';
import { getHrefDescendantsByType } from '@src/reduxStore/content/contentDataAccess';
import {
  KovContentBlockProvider,
  KovContentBlock,
} from '@kathondvla/react-shared-components/src/components/contentBlock';
import getCompilerOptions from '@src/reactComponents/content/helpers/sanitizeOptions';
import * as settings from '@src/config/settings';

const getTermModalItemOrder = (termHref, item) =>
  item.$$relationsFrom.find((relation) => relation.$$expanded.to.href === termHref)?.$$expanded
    .readorder;

const ThemeModal = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const { open: openModal, ...personModalProps } = useModal();

  const retrieveData = useCallback(
    async (key) => {
      const termHref = `/content/${key}`;
      const [rawContent, ...rawChildren] = await getHrefDescendantsByType(termHref);
      rawChildren.sort(
        (a, b) => getTermModalItemOrder(termHref, a) - getTermModalItemOrder(termHref, b)
      );

      setTitle(rawContent.title);
      const htmlContent = [
        <div key={rawContent.key} dangerouslySetInnerHTML={{ __html: rawContent.description }} />,
        rawChildren.map((child) => <KovContentBlock key={child.key} item={child} />),
      ];
      setContent(htmlContent);
      openModal();
    },
    [openModal]
  );

  useEffect(() => {
    window.addEventListener('openTermModal', (event) => {
      retrieveData(event.detail);
    });
    return () => {
      window.removeEventListener('openTermModal', () => {});
    };
  }, []);
  return (
    <ModalProvider {...personModalProps}>
      <Modal className="modal modal-term-center">
        <Modal.Header>
          <i className="icon-kov-pro-begrip"></i>
          <span>
            <h2 style={{ display: 'inline' }}>{`Begrip: ${title}`}</h2>
          </span>
        </Modal.Header>
        <Modal.Body>
          <KovContentBlockProvider
            baseUrl={settings.apisAndUrls.cachedContentApi}
            compilerOptions={getCompilerOptions()}
            allTitlesH1={false}
          >
            {content}
          </KovContentBlockProvider>
        </Modal.Body>
      </Modal>
    </ModalProvider>
  );
};

export default ThemeModal;
