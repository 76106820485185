import React from 'react';
import contentUtils from '@src/app/contentUtils';
import VideoItem from '@UI/video/VideoItem';

const FaqVideo = ({ content }) => (
  <VideoItem
    item={{
      visible: true,
      videoPlayback: contentUtils.calcVideoPlayback({
        type: 'VIDEO',
        attachments: [
          {
            type: 'VIDEO',
            playbackUrl: content?.video?.playbackUrl,
          },
        ],
      }),
    }}
  />
);

export default FaqVideo;
