import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import trackPiwikEvent from '@src/reduxStore/analytics/analyticsThunks';

require('./_themeContactCard.scss');

const ThemeContactCard = ({ person }) => {
  const [showCopy, setShowCopy] = useState(false);
  const dispatch = useDispatch();

  const openMail = () => {
    dispatch(
      trackPiwikEvent('contact_staff', {
        contact_method: 'send_email',
        contact_role: person?.position || undefined,
      })
    );
    window.location.href = `mailto:${person.email}`;
    setShowCopy(false);
  };

  const copy = () => {
    dispatch(
      trackPiwikEvent('contact_staff', {
        contact_method: 'copy_email',
        contact_role: person?.position || undefined,
      })
    );
    navigator.clipboard.writeText(person.email);
    setShowCopy(false);
  };

  return (
    <div className={`kov-pro-contact theme-contact-card`}>
      {person.pictureHref ? (
        <div
          className="kov-pro-contact__img"
          style={{ background: `url('${person.pictureHref}')` }}
        />
      ) : (
        <div className="kov-pro-contact__img" />
      )}

      <div className="kov-pro-contact__info equal-height">
        <div>
          <div className="kov-pro-contact__name">
            <span className="kov-pro-contact__name-first">{person.firstName}</span>
            <span className="kov-pro-contact__name-last"> {person.lastName}</span>
          </div>
          <div className="kov-pro-contact__role">{person.position}</div>
          <ul className="kov-pro-contact__education-level">
            {person.educationLevels &&
              person.educationLevels.map((educationLevel, index) => (
                <li key={index}>{educationLevel}</li>
              ))}
          </ul>
          <ul className="kov-pro-contact__regions">
            {person.regions && person.regions.map((region, index) => <li key={index}>{region}</li>)}
          </ul>
        </div>
        <div>
          <div className="kov-pro-contact__phone">
            {person.phones &&
              person.phones.map((phone, index) => (
                <div key={index} className="kov-pro-contact__phone-primary">
                  {phone}
                </div>
              ))}
          </div>
          <div className="contact-buttons d-flex">
            {person.email ? (
              <div className="dropdown">
                <button
                  className="kov-pro-btn kov-pro-btn--dark kov-pro-btn--inverted kov-pro-btn__icon--right
                                kov-pro-btn--dropdown dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => setShowCopy(!showCopy)}
                >
                  Mail <i className="icon-kov-pro-down-arrow" />
                </button>
                <div
                  className={`dropdown-menu ${showCopy ? 'show' : ''}`}
                  aria-labelledby="dropdownMenuButton"
                >
                  <button
                    type="button"
                    className="kov-pro-btn kov-pro-btn--block kov-pro-btn--light kov-pro-btn--inverted
                                        kov-pro-btn__icon--right"
                    onClick={openMail}
                  >
                    <span>stuur mail</span>
                    <i className="icon-kov-pro-newsletter" />
                  </button>
                  <button
                    type="button"
                    className="kov-pro-btn kov-pro-btn--block kov-pro-btn--light kov-pro-btn--inverted
                                        kov-pro-btn__icon--right"
                    onClick={copy}
                  >
                    <span>kopieer e-mailadres</span>
                    <i className="icon-kov-pro-copy" />
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

ThemeContactCard.propTypes = {
  person: PropTypes.object,
  className: PropTypes.string,
};

export default ThemeContactCard;
