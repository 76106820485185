/**
 * @typedef {Object} Position
 * @property {string} latitude
 * @property {string} longitude
 */

/**
 * @typedef {Object} APILocation
 * @property {string} key
 * @property {string} city
 * @property {Position} position
 * @property {string} postcode
 */

import { cachedApi } from '@src/app/apiConfig';
import { cleanUpObjectEmptyValues } from '@src/helpers/utils';
import { buildTrainingsAPIParams } from '@src/helpers/trainingsHelper';
import { transformTrainingResults } from '@src/reduxStore/trainings/trainingsResultViewModel';

import { getAPISearchResults } from '../content/contentDataAccess';
import { getAPIAllSearchResults } from '../content/contentHelpers';

/**
 * This method returns the trainings results for a given search-params.
 * It also allows the possibility to return a full list of trainings using the "getFullTrainingsList" flag
 * @param {*} filters
 * @param {Array<string>} theme
 * @param {Boolean} getFullTrainingsList
 * @returns {Promise<{trainingsTotalCount: string, trainingsList: Array<*>}>}
 */
export const getTrainings = async (filters, theme, getFullTrainingsList = false) => {
  const searchAPIParams = await buildTrainingsAPIParams(
    cleanUpObjectEmptyValues(filters),
    theme,
    getFullTrainingsList
  );

  const searchResults = getFullTrainingsList
    ? await getAPIAllSearchResults(searchAPIParams)
    : await getAPISearchResults(searchAPIParams);

  return {
    trainingsTotalCount: searchResults.$$meta.count,
    trainingsList: transformTrainingResults(searchResults.results),
  };
};

/**
 * This function get the postal codes of all the cities we have in the API and returns it in a specific format
 * @returns {Array.<(Promise.<APILocation>)>}
 */
export const getAPILocations = async () =>
  Promise.all([
    cachedApi.getAll('/sam/commons/subcities', {
      limit: 3000,
    }),
    cachedApi.getAll('/sam/commons/cities', {
      limit: 100,
      startDateAfter: '2019-01-01',
    }),
  ]).then(([subCities, cities]) =>
    [...subCities, ...cities].map((city) => ({
      key: city.key,
      city: city.name,
      position: {
        latitude: city.position?.latitude?.toString() || '',
        longitude: city.position?.longitude?.toString() || '',
      },
      postcode: city.zipCodes?.length ? city.zipCodes[0] : '',
    }))
  );

/**
 * This function returns a location for a given latitude and longitude. If the location is not part of the available locations it will return the result consulting the "openStreetMapApi"
 * @param {Array<APILocation>} availableLocations
 * @param {Position} position
 * @returns
 */
export const getLocation = async (availableLocations, position) => {
  const { latitude, longitude } = position;
  let location = availableLocations.find(
    (l) => l.position.latitude === latitude && l.position.longitude === longitude
  );
  if (location) return location;

  // if we don't find it by lat / lon in our available locations we check on OpenStreetMap service
  const openStreetMapLocation = await fetch(
    `https://nominatim.openstreetmap.org/reverse?${new URLSearchParams({
      format: 'jsonv2',
      lat: latitude,
      lon: longitude,
    })}`
  ).then((resp) => resp.json());

  if (openStreetMapLocation.error) {
    console.error('openStreetMapLocation', openStreetMapLocation);
    return {};
  }

  // when we get the response we try to find it again in the available locations but with the postal code this time
  location = availableLocations.find((l) => l.postcode === openStreetMapLocation.address.postcode);
  if (location) return location;

  // Otherwise we return a "custom formatted" object from OpenStreetMap service
  const { city, town, village, postcode } = openStreetMapLocation.address;
  return {
    city: city || town || village || '',
    postcode: postcode || '',
    position: {
      latitude,
      longitude,
    },
  };
};
