import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

const SearchInput = ({
  value,
  placeholder = 'Kernwoord',
  onChange,
  onSearch,
  inputClassName = '',
  showSearchIcon = false,
  showSearchIconAfter = false,
  showClear = true,
  onClear = () => {
    onChange('');
  },
}) => {
  const onChangeHandler = useCallback((event) => onChange(event.target.value), [onChange]);
  const onEnterPressed = useCallback(
    (event) => (event.key === 'Enter' ? onSearch() : null),
    [onSearch]
  );

  return (
    <>
      {showSearchIcon && (
        <span className="search">
          <i className="icon-kov-pro-search" />
        </span>
      )}
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(event) => onChangeHandler(event)}
        onKeyPress={onEnterPressed}
        className={inputClassName}
      />
      {showClear && (
        <span className="close" onClick={onClear}>
          <i className="icon-kov-pro-close" />
        </span>
      )}
      {showSearchIconAfter && (
        <span className="input-group-btn">
          <button className="btn btn-default" onClick={onSearch} type="button">
            <i className="icon-kov-pro-search" />
          </button>
        </span>
      )}
    </>
  );
};

SearchInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  inputClassName: PropTypes.string,
  showSearchIcon: PropTypes.bool,
  showClear: PropTypes.bool,
  placeholder: PropTypes.string,
  showSearchIconAfter: PropTypes.bool,
  onClear: PropTypes.func,
};
export default SearchInput;
