import { createPortal } from 'react-dom';

const createAndAppendWrapperElement = (portalId) => {
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute('id', portalId);
  document.body.appendChild(wrapperElement);
  return wrapperElement;
};

const ReactPortal = ({ portalId, children }) => {
  let element = document.getElementById(portalId);

  // if element is not found with wrapperId,
  // create and append to body
  if (!element) {
    element = createAndAppendWrapperElement(portalId);
  }

  return createPortal(children, element);
};

export default ReactPortal;
