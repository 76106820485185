import { NAVIGATION } from '@src/components/constants';
import { getImage } from '@src/app/contentUtils';
import { getStudyProgrammes, getProThemes, getHrefDescendantsByType } from './contentDataAccess';
import contentState from './contentState';

const retrieveStudyProgrammes = () => async (dispatch, getState) => {
  const proThemes = getState().content?.proThemes || [];
  if (proThemes.length) {
    const studyProgrammes = await getStudyProgrammes(proThemes);
    dispatch(contentState.actions.setStudyProgrammes(studyProgrammes));
  }
};

export const retrieveProThemes = () => async (dispatch) => {
  const proThemes = await getProThemes();
  dispatch(contentState.actions.setProThemes(proThemes));
  dispatch(retrieveStudyProgrammes());
};

export const retrieveReferenceFrames = () => async (dispatch) => {
  // The primary reference frame is used at this moment for:
  // 1) getting menuItems in the selector
  // 2) finding the theme in the primary reference item
  //  2.1) use it for knowing if a theme is a channel (subscribe)
  const primaryRefFrame = await getHrefDescendantsByType(NAVIGATION.PRIMARY_REFERENCE_FRAME).then(
    (referenceFrameResp) =>
      referenceFrameResp.map((item) => ({
        ...item,
        href: item.$$meta.permalink,
        title: item?.title || item.name,
        icon: getImage(item, 'ICON'),
      }))
  );

  dispatch(contentState.actions.setPrimaryRefFrame(primaryRefFrame));

  // Global Mini Databases Reference Frame
  // This reference frame is used to match the "theme" ("global database type" on these cases) of the items linked to a databankType (global databases)
  const globaDatabasesRefFrame = await getHrefDescendantsByType(
    '/content/ac1047fe-8a92-4cd0-9975-b40f2bae4ed1'
  ).then((referenceFrameResp) =>
    referenceFrameResp.map((item) => ({
      ...item,
      href: item.$$meta.permalink,
      title: item?.title || item.name,
    }))
  );
  dispatch(contentState.actions.setGlobaDatabasesRefFrame(globaDatabasesRefFrame));
};

export const init = () => async (dispatch) => {
  console.log('initialising content thunks');
  dispatch(retrieveReferenceFrames());
  dispatch(retrieveProThemes());
};
