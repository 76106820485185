const customLogger =
  ({ getState }) =>
  (next) =>
  (action) => {
    // Call the next dispatch method in the middleware chain.
    const returnValue = next(action);

    if (!action.payload) {
      console.debug(
        `new state after ${action.type}:
  state:`,
        getState()
      );
    } else {
      console.debug(
        `new state after ${action.type}
  payload${
    Array.isArray(action.payload) && action.payload.length > 10
      ? ` (${action.payload.length} items, showing only the first 10)`
      : ''
  }:`,
        Array.isArray(action.payload) && action.payload.length > 10
          ? action.payload.slice(0, 10)
          : action.payload,
        `
  state:`,
        getState()
      );
    }

    return returnValue;
  };

export default customLogger;
