import React, { useEffect, useState } from 'react';
import { getLoginProviderIcon } from '@src/helpers/userHelper';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUser,
  selectShouldViewOptionsModalBeOpenSelector,
} from '@src/reduxStore/user/userSelectors';
import { updateAllViewOptions } from '@src/reduxStore/user/userThunks';
import { brieflyDisplaySidebar } from '@src/reduxStore/ui/uiState';
import { getCookie, setCookie } from '@src/helpers/cookieHelper';
import KovModal from '../kovModal/kovModal';
import LoggedInFlow from '../loggedInFlow/loggedInFlow';
import AnonymousEntryFlow from '../anonymousEntryFlow/anonymousEntryFlow';

const dayjs = require('dayjs');

const EntryFlows = () => {
  const dispatch = useDispatch();
  const skipInitViewOptions = getCookie('cookies__skipInitViewOptions');

  const urlParams = new URLSearchParams(window.location.search);
  const urlSkipViewOptions = urlParams.get('skipinitviewoptions');

  const [isShown, setIsShown] = useState(false);
  const user = useSelector(selectUser);

  const params = {
    skipInitViewOptions,
    urlSkipViewOptions,
  };

  const shouldBeShown = useSelector((state) =>
    selectShouldViewOptionsModalBeOpenSelector(state, params)
  );

  useEffect(() => {
    setIsShown(shouldBeShown);
  }, [shouldBeShown]);

  const onComplete = (viewOptions, forceClose = false) => {
    dispatch(updateAllViewOptions(viewOptions));
    dispatch(brieflyDisplaySidebar());
    if (forceClose) {
      setIsShown(false);
    }
  };

  useEffect(() => {
    if (urlSkipViewOptions && !skipInitViewOptions) {
      console.log('skipping view options for 1 day');
      setCookie('cookies__skipInitViewOptions', true, dayjs().add(1, 'days').toDate());
    }
    if (urlSkipViewOptions || skipInitViewOptions === 'true') {
      console.log('skipping initialisation of view options');
    }
  }, [skipInitViewOptions, urlSkipViewOptions]);

  return (
    isShown && (
      <KovModal title="Welkom OnderwijsPro" logo={getLoginProviderIcon('KATHONDVLA')}>
        {user ? (
          <LoggedInFlow user={user} onComplete={(payload) => onComplete(payload)}></LoggedInFlow>
        ) : (
          <AnonymousEntryFlow
            onComplete={(payload, forceClose) => onComplete(payload, forceClose)}
          ></AnonymousEntryFlow>
        )}
      </KovModal>
    )
  );
};

export default EntryFlows;
