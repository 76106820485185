import React from 'react';
import themeLogoKoVl from '@src/assets/images/theme_logo_ko_vl.png';
import smartschoolOriginal from '@src/assets/images/smartschool_original.png';
import schoolwareLogo from '@src/assets/images/schoolware_logo.png';
import settings from '@src/config/settings';
import { redirectToLoginProvider } from '@src/helpers/userHelper';

const LoginBlock = () => (
  <div className="col-12 col-md-4 offset-md-4">
    <div className="kov-pro-login">
      <div className="kov-pro-login__title">
        <i className="icon-kov-pro-profile" /> Meld aan of registreer
      </div>
      <div className="kov-pro-login__wrapper">
        <button
          className="kov-pro-btn kov-pro-btn--block kov-pro-btn--split kov-pro-btn--green"
          type="button"
          onClick={() => redirectToLoginProvider('KATHONDVLA')}
        >
          <div className="kov-pro-btn__img">
            <img src={themeLogoKoVl} />
          </div>
          <span>Aanmelden</span>
        </button>
        <button
          className="kov-pro-btn kov-pro-btn--block kov-pro-btn--split kov-pro-btn--orange"
          type="button"
          onClick={() => redirectToLoginProvider('SMART_SCHOOL')}
        >
          <div className="kov-pro-btn__img">
            <img src={smartschoolOriginal} />
          </div>
          <span>Aanmelden</span>
        </button>
        <button
          className="kov-pro-btn kov-pro-btn--block kov-pro-btn--split kov-pro-btn--red"
          type="button"
          onClick={() => redirectToLoginProvider('SCHOOL_WARE')}
        >
          <div className="kov-pro-btn__img">
            <img src={schoolwareLogo} />
          </div>
          <span>Aanmelden</span>
        </button>
        <div className="kov-pro-login__links">
          <a href={`${settings.apisAndUrls.mijn}/#!/registreren`}>Nog geen account?</a>
        </div>
      </div>
    </div>
  </div>
);

export default LoginBlock;
