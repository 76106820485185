import React from 'react';
import PropTypes from 'prop-types';
import { stripHtml } from '@src/app/utils';
import { getAngularService } from '@src/module/reactMigrationUtils/angular-react-helper';
import ApplicabilityLabels from '@UI/applicabilityLabels/ApplicabilityLabels';
import { useSelector } from 'react-redux';
import themeSelectors from '@src/reduxStore/theme/themeSelectors';
import ThemeCardButtons from '@components/theme/themeCard/themeCardButtons';

const ThemeCard2 = ({ item }) => {
  const navigationService = getAngularService('navigationService');
  const subCurriculaHrefs = useSelector(themeSelectors.getCurricula);
  const parentHasCurriculumTheme = item.parent?.referenceFrameItem?.find(
    (i) => i.type === 'CURRICULUM_THEME'
  );

  return (
    <a
      className="col-12 col-sm-12 col-md-6 col-lg-4"
      href={navigationService.getPathWithSubCurricula(item, subCurriculaHrefs)}
      target={item.isPathExternal ? '_blank' : undefined}
      rel="noreferrer"
    >
      <div
        className={`kov-pro-hometile kov-pro-hometile--text ${
          item.isPathExternal ? 'kov-pro-hometile--external' : ''
        }`}
      >
        <div className="kov-pro-hometile__body equal-height">
          <h4 className="kov-pro-hometile__title">{stripHtml(item.title)}</h4>
          <p className="kov-pro-hometile__excerpt">{stripHtml(item.shortDescription)}</p>
          <ApplicabilityLabels labels={item.tags} />
          {item.pageType === 'CURRICULUM_PAGE' && parentHasCurriculumTheme && (
            <ThemeCardButtons item={item} />
          )}
        </div>
      </div>
    </a>
  );
};

ThemeCard2.propTypes = {
  item: PropTypes.object,
};

export default ThemeCard2;
