require('./wsNavigationFirstLevel.scss');

const { notifyRedactiePageLoaded } = require('@src/helpers/postMessageHelper');

class wsNavigationFirstLevel {
  constructor($scope, $window, $location) {
    'ngInject';

    this.$scope = $scope;
    // used in the template
    this.$location = $location;
    this.$window = $window;
  }

  async $onInit() {
    // this is used to listen to the postMessageService (if needed)
    // we only do this here, and not in the other navigation levels, because from redactie you can only preview the homepage which include all levels of navigation
    notifyRedactiePageLoaded();

    this.$scope.$emit('dynamicMetaDataUpdated', {
      title: 'PRO. voor de onderwijsprofessional',
      description: null,
    });
  }

  emitShowOutOfViewOptions = (newValue) => {
    this.$scope.$emit('showOutOfViewOptions', newValue);
  };

  $onDestroy() {
    this.$scope.$emit('showOutOfViewOptions', false);
  }
}

module.exports = {
  template: require('./wsNavigationFirstLevel.html'),
  controllerAs: 'ctrl',
  controller: wsNavigationFirstLevel,
  bindings: {},
};
