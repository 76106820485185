import { useState } from 'react';

const useModal = (onConfirm, onClose) => {
  const [isOpen, setIsOpen] = useState(false);

  const onConfirmHandler = (ev) => {
    if (onConfirm) onConfirm(ev);
    setIsOpen(false);
    document.body.classList.remove('modal-open');
  };

  const onCloseHandler = (ev) => {
    if (onClose) onClose(ev);
    setIsOpen(false);
    document.body.classList.remove('modal-open');
  };

  const open = () => {
    setIsOpen(true);
    document.body.classList.add('modal-open');
  };

  return { isOpen, open, onConfirm: onConfirmHandler, onClose: onCloseHandler };
};

export default useModal;
