import React from 'react';
import SearchInput from '@UI/searchInput/searchInput';
import { databaseActions } from '@src/reduxStore/database/databaseSlice';

const DatabaseSearchFilter = ({ searchTerm, search, dispatch }) => (
  <div className="filter-block">
    <a className={`title`} role="button">
      {search.label}
    </a>
    <div className={`filter-items collapse show`} style={{ cursor: 'ew-resize' }} id={search.label}>
      <SearchInput
        value={searchTerm}
        onChange={(term) => dispatch(databaseActions.setFilter({ fieldName: 'q', payload: term }))}
        onSearch={() => dispatch(databaseActions.applyTempFilter())}
        onClear={() => {
          dispatch(databaseActions.setFilter({ fieldName: 'q', payload: '' }));
          dispatch(databaseActions.applyTempFilter());
        }}
        inputClassName="form-control"
        placeholder="Zoek"
        showClear
      />
    </div>
  </div>
);

export default DatabaseSearchFilter;
