import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import CtaBanner from '@UI/ctaBanner/ctaBanner';
import { getAngularService } from '@src/module/reactMigrationUtils/angular-react-helper';
import useLocationContacts from '@src/hooks/useLocationContacts';
import NewsSection from '../newsSection/newsSection';
import ThemeContacts from '../themeContacts/themeContacts';
import Banner from '../../sections/banner/banner';

dayjs.extend(isBetween);

const ThemeHome = ({ sTheme, locationPath, children }) => {
  const routerService = getAngularService('routerService');

  const [callToActionBanner, setCallToActionBanner] = useState();

  const getBanner = useCallback(async () => {
    const { banner } = await routerService.getWebpageInfoFromHref(sTheme.href);
    if (banner && dayjs(dayjs()).isBetween(banner.from, banner.to, 'days', '[]')) {
      setCallToActionBanner(banner);
    }
  }, [routerService, sTheme.href]);

  const { contacts } = useLocationContacts(sTheme);
  const showContactsSection = contacts.length > 0;

  useEffect(() => {
    getBanner();
  });

  return (
    <>
      <Banner theme={sTheme} menuItem={sTheme} />
      <section id="theme-home">
        <div className="container">
          <div className="row">
            {sTheme.description && (
              <div className="kov-pro-theme-lead">
                <div
                  className="kov-pro-theme-parts-excerpt col-12"
                  dangerouslySetInnerHTML={{ __html: sTheme.description }}
                />
              </div>
            )}
            {children}
          </div>
          {callToActionBanner && <CtaBanner banner={callToActionBanner} />}
        </div>
      </section>

      {showContactsSection && <ThemeContacts contacts={contacts} />}

      {sTheme.referenceFrameItem && (
        <NewsSection
          sReferenceFrame={sTheme.referenceFrameItem}
          sThemePath={sTheme.pagePath}
          locationPath={locationPath}
        />
      )}
    </>
  );
};

export default ThemeHome;
