import store from '@src/reduxStore/store';
import { selectPrimaryRefFrame } from '@src/reduxStore/content/contentSelectors';
import { stateMatch } from 'redux-awaitable-state';

/* eslint-disable no-use-before-define */
const contentUtils = require('../app/contentUtils');

const service = () => {
  'ngInject';
  'use strict';

  async function getReferencedItem(item) {
    await stateMatch((state) => selectPrimaryRefFrame(state)?.length > 0);
    const referenceFrame = selectPrimaryRefFrame(store.getState());

    if (item.themes && item.themes.length > 0) {
      /**
       * The goal of the following code is to find the themes tagged into a PRO-theme.
       * In the most of the cases the PRO-themes will be tagged with one theme, but we have some CURRICULUM_THEME that have "sub curriculum theme" (sub-atomic themes) (edge case). Because of that what we do is that the find the tagged theme for the current PRO-theme and then we search for potential "sub curriculum themes" of that one.
       * In that scenario (themes with sub-curriculum themes) the "parent themes" became useless, so we don't use them to get content from the API, etc.., we basically use his "sub curriculum themes". So if the user tag a content the a "parent" CURRICULUM_THEME we call the search-api (for instance) with the "sub curriculum themes" of that CURRICULUM_THEME. Even though business decided to not add any rule to prevent items to be tagged with the "parent" CURRICULUM_THEME (instead of all the "sub curriculum themes") in redactie, and we have been told to not take that scenario into account because "the webmasters are told not to do it and they will never do it".
       * So if, for instance, a PRO-theme is tagged some day with a "parent" CURRICULUM_THEME and any of his "sub curriculum themes" (or all) this will show funny data but that won't be our problem
       */
      const itemThemes = item.themes
        .map((theme) => referenceFrame.find((a) => a.href === theme.href || a.href === theme))
        .filter((theme) => Boolean(theme)); // cleaning up the undefined values when, for instance, the theme has been removed so it is not available anymore on the referenceFrame

      const subCurriculumThemes = itemThemes
        .flatMap((itemTheme) => {
          return itemTheme.$$relationsTo
            ?.filter((rel) => rel.$$expanded.relationtype === 'IS_PART_OF')
            .map((rel) => {
              const item = referenceFrame.find((a) => a.href === rel.$$expanded.from.href);
              return (
                item.type === 'CURRICULUM_THEME' && {
                  ...item,
                  isSubCurriculumTheme: true,
                }
              );
            });
        })
        .filter(Boolean);

      return [...itemThemes, ...subCurriculumThemes];
    }

    const references = item.$$relationsFrom.filter(
      (o) => o.$$expanded.relationtype === 'REFERENCES'
    );

    if (references.length > 0) {
      return references.map((ref) => referenceFrame.find((a) => a.href === ref.$$expanded.to.href));
    }

    return null;
  }

  function getReferenceFrameItem(href) {
    const referenceFrame = selectPrimaryRefFrame(store.getState());
    return referenceFrame.find((refFrameItem) => refFrameItem.href === href);
  }

  async function getReferenceFrameTree() {
    await stateMatch((state) => selectPrimaryRefFrame(state)?.length > 0);
    const referenceFrame = selectPrimaryRefFrame(store.getState());
    return contentUtils.buildStructuredDocument(referenceFrame);
  }

  async function getReferenceFrameRecursiveChildren() {
    const referenceFrameBuiltTree = await getReferenceFrameTree();
    const flattenTree = flattenReferenceFrame(referenceFrameBuiltTree);

    return new Map(
      flattenTree.map((item) => [
        item.$$meta.permalink,
        item.$$recursiveChildren.map((recChld) => ({
          href: recChld.$$meta.permalink,
          title: recChld.title,
        })),
      ])
    );
  }

  const flattenReferenceFrame = (treeRoot) => {
    const flat = [];

    function addChildren(node) {
      if (node) {
        if (node.children && node.children.length > 0) {
          node.$$recursiveChildren = [...node.children];

          node.children
            .sort((a, b) => a.readorder - b.readorder)
            .map((child) => {
              const childVM = { ...child };
              childVM.$$parent = node;

              // if (!childVM.$$isHidden && childVM.$$typeConfig.type) {
              flat.push(childVM);
              // }
              addChildren(childVM);

              childVM.$$recursiveChildren.forEach((e) => node.$$recursiveChildren.push(e));
            });
        } else {
          node.$$recursiveChildren = [];
        }
      }
      return node;
    }

    const rootVM = { ...treeRoot };

    addChildren(rootVM);

    return flat;
  };

  return {
    getReferenceFrameRecursiveChildren,
    getReferencedItem,
    getReferenceFrameItem,
  };
};

angular.module('services').factory('referenceFrameService', service);
