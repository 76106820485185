// MOVE ME TO A GENERIC FOLDER SOON PLEASe

import React, { useState } from 'react';

const KovToolTip = ({ contents }) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div className="kov-pro-nas-filter__tooltip">
      <object>
        <a
          data-toggle="collapse"
          href="#"
          role="button"
          aria-expanded="true"
          onClick={() => {
            setCollapsed(!collapsed);
          }}
          aria-controls="tooltip-1"
          className={`${collapsed ? 'collapsed' : ''}`}
        >
          <i className="icon-kov-pro-begrip"></i>
        </a>
      </object>
      <div className={`kov-pro-tooltip collapse ${collapsed ? '' : 'show'}`} id="tooltip-1">
        <p>{contents}</p>
      </div>
    </div>
  );
};

export default KovToolTip;
