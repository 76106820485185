import { createSelector } from '@reduxjs/toolkit';

export const selectProThemes = (state) => state.content.proThemes;

export const selectStudyProgrammes = (state) => state.content.studyProgrammes;

export const selectPrimaryRefFrame = (state) => state.content.primaryReferenceFrame;

export const selectGlobaDatabasesRefFrame = (state) => state.content.globaDatabasesRefFrame;

export const selectGlobalDatabasesHrefs = createSelector(
  [selectGlobaDatabasesRefFrame],
  (globaDatabasesRefFrame) =>
    globaDatabasesRefFrame
      .filter((globalDbRefFrameItem) => globalDbRefFrameItem.type === 'THEME')
      .map((globalDbRefFrameItem) => globalDbRefFrameItem.href)
);
