import React, { useCallback, useContext } from 'react';
import ReactDOM from 'react-dom';
import useBackdropClick from '@hooks/useBackdropClick';
import { ModalContext } from './modalProvider';

import './modal.scss';

const SIZES = ['xs', 'sm', 'md', 'lg', 'xl'];

const Modal = ({ children, size, className, closeOnClickBackdrop = false }) => {
  const { onClose } = useContext(ModalContext);
  const onClick = useCallback(
    (ev) => closeOnClickBackdrop && onClose?.(ev, 'backdropClick'),
    [closeOnClickBackdrop, onClose]
  );

  const { backdropClick, mouseDown } = useBackdropClick(onClick);

  const modalSize = SIZES.find((s) => s === size) || 'md';

  return ReactDOM.createPortal(
    <React.Fragment>
      <div className={` ${className}`} tabIndex="-1" role="dialog" aria-hidden="true">
        <div className={`modal-dialog modal-${modalSize}`} role="document" onMouseDown={mouseDown}>
          <div className="modal-content">{children}</div>
        </div>
      </div>
      <div
        onClick={backdropClick}
        className="modal-backdrop show"
        style={{ zIndex: 1040 }}
        data-bootstrap-modal-aria-hidden-count="1"
        aria-hidden="true"
      />
    </React.Fragment>,
    document.body
  );
};

const Header = ({ icon, subtitle, showCloseButton = true, children }) => {
  const { onClose } = useContext(ModalContext);
  return (
    <header className="kov-modal-content__header modal-header" style={{ height: 'unset' }}>
      {icon && (
        <div className="modal-header-icon">
          <img src={`/img/${icon}`} alt="Title icon" />
        </div>
      )}
      <div>
        <div className="title">{children}</div>
        <div className="subtitle">{subtitle}</div>
      </div>
      {showCloseButton && (
        <div
          style={{
            position: 'absolute',
            right: '20px',
          }}
        >
          <i onClick={onClose} className="icon-kov-pro-close" style={{ cursor: 'pointer' }}></i>
        </div>
      )}
    </header>
  );
};

const Body = ({ html, children, style }) => (
  <main className="modal-body" style={style}>
    {html != null && <div dangerouslySetInnerHTML={{ __html: html }} />}
    {children}
  </main>
);

const Actions = ({ children }) => <footer className="modal-actions">{children}</footer>;

Modal.Header = Header;
Modal.Body = Body;
Modal.Actions = Actions;

export default Modal;
