import React from 'react';
import { generateHashForScrollTo, removeHtmlTags } from '@helpers/utils';
import PropTypes from 'prop-types';
import kovProTopBg from '@src/assets/images/kov-pro_topbg00.png';
import { useDispatch } from 'react-redux';
import trackPiwikEvent from '@src/reduxStore/analytics/analyticsThunks';

const NavigationThirdLevelItem = ({ menuItem }) => {
  const keyHash = generateHashForScrollTo(menuItem.key);
  const styleTag = `#${keyHash} a:hover {color: ${menuItem.color};}`;
  const dispatch = useDispatch();

  return (
    <li
      id={keyHash}
      className={
        menuItem.isPathExternal ? 'theme-menu-tile theme-menu-tile--external' : 'theme-menu-tile'
      }
    >
      <a
        onClick={
          menuItem.path.includes('/#!/nieuwsbrieven')
            ? () => dispatch(trackPiwikEvent('start_newsletter'))
            : undefined
        }
        href={menuItem.path}
        rel="noreferrer"
        target={menuItem.isPathExternal ? '_blank' : undefined}
      >
        <div className="theme-menu-tile__img-holder">
          <div
            className="theme-menu-tile__img-holder-bg"
            style={{
              backgroundImage: `url(${menuItem?.theme?.thumbImageS?.href || kovProTopBg})`,
            }}
          />
        </div>
        <div className="theme-menu-tile__title" style={{ color: 'inherit' }}>
          {removeHtmlTags(menuItem.title)}
        </div>
      </a>
      <style dangerouslySetInnerHTML={{ __html: styleTag }} />
    </li>
  );
};

NavigationThirdLevelItem.propTypes = {
  menuItem: PropTypes.object,
};

export default NavigationThirdLevelItem;
