import React from 'react';
import placeholder from '@src/assets/images/domains-of-study-placeholder.svg';
import { getSitemapPathFromSource } from '@src/services/sitemapService';

export const finalityLabel = {
  Doorstroom: 'D',
  Arbeidsmarkt: 'A',
  'Dubbele finaliteit': 'D/A',
};

const educationTypes = {
  generalEducation: '/sam/commons/secondaryeducationtypes/8c6bb8e9-5cd6-4964-937c-34a19fe68328',
  specificEducation: '/sam/commons/secondaryeducationtypes/1eca5e34-c672-487f-89ba-1d4710818450',
  complementaryEducation:
    '/sam/commons/secondaryeducationtypes/08a736de-9984-46ce-be78-b65ceaf37d49',
};

/**
 * This method is used to show a placeholder for the "Freinetpedagogie" studyprogramme in second and third grade (this is a business requirement described here https://katholiek-onderwijs-vlaanderen.monday.com/boards/5059664922/pulses/5928559267)
 * @param {*} studyProgramme
 * @param {*} eduTypeBlock
 * @param {string} tabId
 * @returns
 */
const shouldAddFreinetpedagogiePlaceholder = (studyProgramme, eduTypeBlock, tabId) => {
  const freinetpedagogieHrefPerGrade = {
    'second-grade': '/sam/commons/studyprogrammes/d2d9e0c3-d41e-4224-87ce-1fd8b2dc53ae',
    'thirth-grade': '/sam/commons/studyprogrammes/c887a378-6412-4600-ac1e-fb78a62f588a',
  };

  return (
    eduTypeBlock.label === 'Specifieke vorming' &&
    freinetpedagogieHrefPerGrade[tabId] === studyProgramme.href
  );
};

const getCurriculumThemesProThemes = (studyProgramme, typeOfEducationId) =>
  studyProgramme.educationTypes.find(
    (eduType) => eduType.href === educationTypes[typeOfEducationId]
  )?.leerplanPages || [];

const getStudyProgrammeGroupLabel = (studyProgramme, tabId) => {
  if (tabId === 'first-grade') {
    return studyProgramme.studyProgrammeGroups.find(
      (spg) => spg.studyProgrammeGroupType === 'stream'
    ).title;
  }
  if (['second-grade', 'thirth-grade'].includes(tabId)) {
    return `finaliteit ${
      finalityLabel[
        studyProgramme.studyProgrammeGroups.find(
          (spg) => spg.studyProgrammeGroupType === 'finality'
        ).title
      ]
    }`;
  }
  return '';
};

const StudyProgrammeAccordion = ({
  studyProgramme,
  updateActiveSections,
  activeSections,
  tabId,
  index,
}) => {
  const handleClick = () => {
    updateActiveSections();
  };

  const educationTypeBlocks = [
    {
      dataElements: getCurriculumThemesProThemes(studyProgramme, 'generalEducation'),
      label: 'Algemene vorming',
    },
    {
      dataElements: getCurriculumThemesProThemes(studyProgramme, 'specificEducation'),
      label: 'Specifieke vorming',
    },
    {
      dataElements: getCurriculumThemesProThemes(studyProgramme, 'complementaryEducation'),
      label: 'Complementair gedeelte',
    },
  ];

  const shouldRender =
    educationTypeBlocks.map((eduTypeBlock) => eduTypeBlock.dataElements).flat().length > 0;

  const isCollapsed = !activeSections.includes(index);

  return shouldRender ? (
    <div className="card kov-pro-curriculum-accordion__wrapper">
      <div className="card-header">
        <div className="kov-pro-curriculum-accordion__header">
          <button
            className={`btn btn-link${isCollapsed ? ' collapsed' : ''}`}
            onClick={handleClick}
          >
            {studyProgramme.title}
            <div className="kov-pro-curriculum-accordion__header-finality">
              {getStudyProgrammeGroupLabel(studyProgramme, tabId)}
            </div>
            <i className="icon-kov-pro-down-arrow" />
          </button>
        </div>
      </div>

      <div className={isCollapsed ? 'collapse' : ''}>
        <div className="kov-pro-curriculum-accordion__body card-body">
          <div className="row">
            {educationTypeBlocks
              .filter((eduTypeBlock) => eduTypeBlock.dataElements.length > 0)
              .map((eduTypeBlock) => (
                <React.Fragment key={eduTypeBlock.label}>
                  <div className="col-12 col-md">
                    <ul className="kov-curriculum__list">
                      <div className="kov-curriculum__list-title">{eduTypeBlock.label}</div>
                      {eduTypeBlock.dataElements.map((curricula) => (
                        <li key={curricula.$$meta.permalink}>
                          <a
                            className="kov-curriculum__list-item"
                            href={getSitemapPathFromSource(curricula.$$meta.permalink)}
                          >
                            <div className="kov-curriculum__img-wrapper">
                              <div
                                className="kov-curriculum__img"
                                style={{
                                  background: `url(${placeholder})`,
                                }}
                              />
                            </div>
                            <div className="kov-curriculum__name">{curricula.title}</div>
                          </a>
                        </li>
                      ))}
                      {shouldAddFreinetpedagogiePlaceholder(
                        studyProgramme,
                        eduTypeBlock,
                        tabId
                      ) && (
                        <div className="kov-curriculum__list-item">
                          <div className="kov-curriculum__img-wrapper">
                            <div
                              className="kov-curriculum__img"
                              style={{
                                background: `url(${placeholder})`,
                              }}
                            />
                          </div>
                          <div className="kov-curriculum__name" style={{ color: '#69829a' }}>
                            Freinetpedagogie (schoolleerplan)
                          </div>
                        </div>
                      )}
                    </ul>
                  </div>
                </React.Fragment>
              ))}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default StudyProgrammeAccordion;
