import React from 'react';
import { generateHashForScrollTo } from '@helpers/utils';
import PropTypes from 'prop-types';

const NavigationSecondLevelItem = ({ menuItem }) => {
  const keyHash = generateHashForScrollTo(menuItem.key);

  const lightGrey = '#F6F6F6';
  let styleTag;
  if (menuItem.importance === 'HIGH') {
    styleTag = `
    #${keyHash} a {background: ${menuItem.color}; color: ${lightGrey};}
    #${keyHash} a:before {background: ${menuItem.color};}
  `;
  } else {
    styleTag = `
    #${keyHash} a:before {background: ${menuItem.color};}
    #${keyHash} a:hover:before {width: 7px; background: ${menuItem.color};}
    #${keyHash} a:hover {color: ${menuItem.color};}
  `;
  }

  return (
    <li className="cluster-menu-tile" id={keyHash}>
      <a href={menuItem.path}>
        <div
          className="cluster-menu-tile__title"
          style={{ color: 'inherit' }}
          dangerouslySetInnerHTML={{ __html: menuItem.title }}
        />
      </a>
      <style dangerouslySetInnerHTML={{ __html: styleTag }} />
    </li>
  );
};

NavigationSecondLevelItem.propTypes = {
  menuItem: PropTypes.object,
};

export default NavigationSecondLevelItem;
