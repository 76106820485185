import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useUrlSearchParams from '@src/hooks/useUrlSearchParams';
import { themeActions } from '@src/reduxStore/theme/themeSlice';
import themeSelectors from '@src/reduxStore/theme/themeSelectors';

const SubAtomicThemeSelector = ({
  subatomicThemes,
  onUpdateSubCurricula,
  subAtomicSelectorDisabled = false,
}) => {
  // when selectedSubCurriculaHrefs is empty, visually this is represented as 'select all', however from a data point of view,
  // that equates to 'select none' i.e. don't filter on anything
  const selectedSubCurriculaHrefs = useSelector(themeSelectors.getCurricula);
  const dispatch = useDispatch();

  const { updateUrlSearchParams, urlParamsProcessed } = useUrlSearchParams([
    {
      key: 'curricula',
      state: selectedSubCurriculaHrefs,
      updateState: (curricula) => {
        dispatch(themeActions.setCurricula(curricula));
      },
    },
  ]);

  /*   this block takes the subatomicThemes from the theme reference frame item and converts them into selectable items for the dropdown. This only runs once.
  primarily, it shortens the titles of the sub curricula to eliminate repeated information
    for example;
    Natuurwetenschappen A - onderdeel Biologie (I-Nat-ad),
    Natuurwetenschappen A - onderdeel Chemie (I-Nat-ad)

    become

    Biologie,
    Chemie

    it does this by splitting each title into substrings, then eliminating repeated substrings from each title string, to leave only the unique information.
 */
  const subCurriculaOptions = useMemo(() => {
    const substringArrays = [];
    subatomicThemes.forEach((cur) => {
      const words = cur.title.split(/\s/);
      substringArrays.push({ key: cur.key, words });
    });
    return subatomicThemes.map((cur) => {
      const subStringSet = new Set(
        substringArrays
          .filter((subStringArray) => subStringArray.key !== cur.key)
          .flatMap((subStringArray) => subStringArray.words)
      );
      const shortTitle = cur.title
        .split(/\s/)
        .filter((word) => !subStringSet.has(word))
        .join(' ');

      return {
        shortTitle,
        title: cur.title,
        href: `/content/${cur.key}`,
      };
    });
  }, [subatomicThemes]);

  // for non database pages which don't filter things on curricula,
  // we still need to update the URL anyway for deeplinking
  useEffect(() => {
    if (!onUpdateSubCurricula && urlParamsProcessed) {
      updateUrlSearchParams();
    }
  }, [onUpdateSubCurricula, updateUrlSearchParams, urlParamsProcessed]);

  /*    update actions. depending on the position in a theme, extra actions may be needed when the sub theme is changed
  i.e. changing filter on a database item.
  this can be done by passing the action in through the onUpdateSubCurricula prop */
  const updateActions = (newSelectedSubCurriculaHrefs) => {
    dispatch(themeActions.setCurricula(newSelectedSubCurriculaHrefs));
    if (onUpdateSubCurricula) {
      onUpdateSubCurricula(newSelectedSubCurriculaHrefs);
    }
  };

  const updateChecked = (href) => {
    let newSelectedCurriculaHrefs = selectedSubCurriculaHrefs;

    // if there is none selected, fill it with all hrefs to remove later the one just clicked
    if (newSelectedCurriculaHrefs.length === 0) {
      newSelectedCurriculaHrefs = subCurriculaOptions.map((cur) => cur.href);
    }

    // toggle the href just clicked
    newSelectedCurriculaHrefs = newSelectedCurriculaHrefs.includes(href)
      ? newSelectedCurriculaHrefs.filter((el) => el !== href)
      : [...newSelectedCurriculaHrefs, href];

    // if once added the href they are all selected, it's represented as the empty array
    if (newSelectedCurriculaHrefs.length === subCurriculaOptions.length) {
      newSelectedCurriculaHrefs = [];
    }

    updateActions(newSelectedCurriculaHrefs);
  };

  return (
    <div id="kov-pro-selection">
      <div className="container">
        <div className="kov-pro-selection__wrapper">
          <ul
            className={`kov-pro-selection__list ${
              subAtomicSelectorDisabled && 'kov-pro-selection__list--disabled'
            }`}
          >
            <li className="kov-pro-selection__list-item">
              <label className="filter-item">
                alle onderdelen
                <input
                  type="checkbox"
                  onChange={() => updateActions([])}
                  checked={selectedSubCurriculaHrefs.length === 0}
                />
                <span className="checkmark" />
              </label>
            </li>
            {subCurriculaOptions.length > 0 &&
              subCurriculaOptions.map((theme, index) => (
                <li key={index} className="kov-pro-selection__list-item">
                  <label className="filter-item">
                    {theme.shortTitle || theme.title}
                    <input
                      type="checkbox"
                      onChange={() => updateChecked(theme.href)}
                      checked={
                        selectedSubCurriculaHrefs.includes(theme.href) ||
                        selectedSubCurriculaHrefs.length === 0
                      }
                    />
                    <span className="checkmark" />
                  </label>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SubAtomicThemeSelector;
