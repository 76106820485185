import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { flattenTree, stripHtml } from '@src/app/utils';

import TextTocItem from './textTocItem';

import UseThemeTextToc from './hooks/useThemeTextToc';

require('./themeTextToc.scss');

const ThemeToc = ({ page, theme, background, showDownloadSection, showContactsSection }) => {
  const [collapsed, setCollapsed] = useState(false);

  const { tocItems, itemSelected } = UseThemeTextToc(page, theme);

  const allSelections = useMemo(
    () =>
      flattenTree(page)
        .filter((o) => ['SUMMARY', 'SECTION'].includes(o.type) && o.importance === 'MEDIUM')
        .filter((o) => o.key !== page.key),
    [page]
  );

  return (
    <div className="kov-pro-theme-side-content col-12 col-md-4 order-md-12">
      <div
        className="kov-pro-theme-side-content__featured-image"
        style={{
          background: `url(${background == null ? '/images/placeholder-picture.png' : background})`,
        }}
      />
      <div className="hl-sticky sticky-top sticky-before">
        <ul>
          {allSelections && (
            <li
              className="kov-pro-theme-side-content-toc"
              style={{ borderTopColor: theme?.root?.color }}
            >
              {page && (
                <span
                  className="d-none d-md-block"
                  dangerouslySetInnerHTML={{ __html: stripHtml(page.title) }}
                />
              )}{' '}
              <a
                className={`mobile d-block d-md-none${collapsed ? ' collapsed' : ''}`}
                data-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="toc"
                onClick={() => setCollapsed(!collapsed)}
              >
                Inhoudstafel
              </a>
              <nav id="toc" className={`collapse ${!collapsed && 'show'}`}>
                <ul className="nav-toc">
                  {tocItems.map((tocItem, index) => (
                    <TextTocItem
                      key={index}
                      item={tocItem}
                      color={theme?.root?.color}
                      level={1}
                      select={itemSelected}
                    />
                  ))}
                </ul>
              </nav>
            </li>
          )}
          {(showDownloadSection || showContactsSection) && (
            <li
              className="kov-pro-theme-side-content-jumper"
              style={{ borderTopColor: theme?.root?.color }}
            >
              <ul className="kov-pro-jumper">
                {showDownloadSection && (
                  <li>
                    <a className="downloads" onClick={() => itemSelected('downloads')}>
                      Downloads
                    </a>
                  </li>
                )}
                {showContactsSection && (
                  <li>
                    <a onClick={() => itemSelected('contacts')} className="contact">
                      Contact
                    </a>
                  </li>
                )}
              </ul>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

ThemeToc.propTypes = {
  theme: PropTypes.object,
  page: PropTypes.object,
  background: PropTypes.string,
  showDownloadSection: PropTypes.bool,
  showContactsSection: PropTypes.bool,
};
export default ThemeToc;
