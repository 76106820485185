import React, { useEffect } from 'react';

require('./kovModal.scss');

const KovModal = ({
  title,
  logo,
  children,
  closeButton = false,
  close,
  extraClasses,
  showFooter,
}) => {
  useEffect(() => {
    document.body.classList.add('modal-open');
    return () => {
      document.body.classList.remove('modal-open');
    };
  });
  return (
    <>
      <div className={`modal cookies-modal ${extraClasses} fade show`}>
        <div className="modal-dialog modal-md ">
          <div className="kov-modal-content modal-content">
            <div className="kov-modal-dialog modal-dialog-centered" role="document">
              <div className="kov-modal-content modal-content">
                <div className="kov-modal-content__header modal-header">
                  {logo && <img className="kov-modal-content__header-logo" src={logo} alt="logo" />}
                  <h2 className="kov-modal-content__header-title">{title}</h2>
                  {closeButton && (
                    <button type="button" className="close" onClick={close}>
                      <i className="icon-kov-pro-close" />
                    </button>
                  )}
                </div>
                <div className="kov-modal-content__body modal-body">{children}</div>
                {showFooter && (
                  <div className="modal-footer">
                    <div className="controls">
                      <button
                        type="button"
                        onClick={close}
                        className="kov-pro-btn kov-pro-btn--inverted kov-pro-btn--light kov-pro-btn--block kov-pro-btn__icon--right"
                      >
                        GO BACK
                        <i className="icon-kov-pro-arrow-left" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`modal-backdrop cookies-backdrop fade show`} />
    </>
  );
};

export default KovModal;
