import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { generateHashForScrollTo } from '@src/helpers/utils';
import { stripHtml } from '@src/app/utils';
import { KovContentBlockContext } from '@kathondvla/react-shared-components/src/components/contentBlock/KovContentBlockProvider';
import ContentBlockTitleTooltip from '../contentBlockTitleTooltip/contentBlockTitleTooltip';

const ContentBlockDeeplink = ({ item, isCollapsableBlock, titleLevel }) => {
  const { color } = useContext(KovContentBlockContext);
  const [tooltipVisibility, setTooltipVisibility] = useState(false);

  const anchor = generateHashForScrollTo(item.key);
  const titleWithDeepLink = () => (
    <>
      <div>{stripHtml(item.title)}</div>
      <ContentBlockTitleTooltip sKey={item.key} sColor={color} visible={tooltipVisibility} />
    </>
  );

  const Section = (level) => {
    const TitleTag = `h${level > 6 ? 6 : level}`;
    if (level === 4 || isCollapsableBlock) {
      return (
        <h4 style={{ color: isCollapsableBlock ? color : 'inherit' }}>{titleWithDeepLink()}</h4>
      );
    }
    if (level === 2) {
      return <TitleTag style={{ color }}>{titleWithDeepLink()}</TitleTag>;
    }
    return <TitleTag>{titleWithDeepLink()}</TitleTag>;
  };

  return (
    <div
      id={anchor}
      onMouseEnter={() => setTooltipVisibility(true)}
      onMouseLeave={() => setTooltipVisibility(false)}
    >
      {Section(titleLevel)}
    </div>
  );
};

ContentBlockDeeplink.propTypes = {
  item: PropTypes.object,
  isCollapsableBlock: PropTypes.bool,
  titleLevel: PropTypes.number,
};

export default ContentBlockDeeplink;
