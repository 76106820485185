import {
  findForbiddenAttachmentItem,
  getVisibleItems,
  isItemForbidden,
  isItemVisible,
} from '@src/helpers/accessHelper';
import { flattenTree } from '@src/app/utils';

export const getLinks = (linkGroups) => {
  let sortedLinks = [];

  if (linkGroups.length > 0) {
    linkGroups.forEach((linkGroup) => {
      if (linkGroup.children) {
        sortedLinks = sortedLinks.concat(linkGroup.children);
      }
    });
  }

  sortedLinks = sortedLinks.map((link) => {
    if (link.references?.length > 0) {
      const [externalReference] = link.references;
      return { ...link, externalReference };
    }
    return link;
  });

  return sortedLinks;
};

export const getAttachments = (attachmentsGroups) => {
  const highAtt = [];
  const mediumAtt = [];
  let sortedAtt = [];

  if (attachmentsGroups.length) {
    attachmentsGroups
      .sort((a, b) => a.parent.readOrder - b.parent.readOrder)
      .filter((att) => isItemVisible(att) && !isItemForbidden(att))
      .forEach((att) => {
        if (att.importance === 'HIGH') {
          highAtt.push(att);
        } else if (att.importance === 'MEDIUM') {
          mediumAtt.push(att);
        }
      });

    sortedAtt = [...highAtt, ...mediumAtt];
  }

  return sortedAtt.filter((s) => s.visible);
};

export const getForbiddenAttachmentItem = (attachmentsGroups) =>
  findForbiddenAttachmentItem(getVisibleItems(attachmentsGroups));

export const shouldShowDownloadsSection = (page) => {
  const pageTypesWithSection = [
    'SHARED_MINI_DATABASE_ITEM',
    'THEME_DETAIL',
    'THEME_DETAIL_ROOT',
    'MINI_DATABASE_ITEM',
    'TEMPORARY_PAGE',
    'CURRICULUM_PAGE',
  ];
  if (!page || !pageTypesWithSection.includes(page.pageType)) return false;

  const items = flattenTree(page);
  const links = getLinks(items.filter((o) => o.type === 'LINK_GROUP'));
  const attachmentsGroups = items.filter((o) => o.type === 'ATTACHMENTS_GROUP');

  const accessibleAttachments = getAttachments(attachmentsGroups)
    .flatMap((group) => group.children || [])
    .filter((child) => child && child.visible && child.attachment);

  return links.length > 0 || accessibleAttachments.length > 0;
};
