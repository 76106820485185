import React, { useEffect, useState } from 'react';
import SidebarPortal from '@components/sections/sidebar/SidebarPortal';
import BlogSearchInput from '@components/theme/themeBlog/BlogSearchInput';
import PropTypes from 'prop-types';
import { stripHtml } from '@src/app/utils';
import Banner from '@components/sections/banner/banner';
import ThemeAbout from '@src/reactComponents/theme/themeAbout/themeAbout';
import ProgressBar from '@UI/progressBar/progressBar';
import LoadMore from '@UI/loadMore/loadMore';
import NoResultsPlaceholder from '@UI/noResultsPlaceholder/noResultsPlaceholder';
import { useMediaQuery } from '@react-hook/media-query';
import { ThemeMenu } from '@components/database/components';
import { getThemeTabs } from '@src/components/theme/templates/helpers/helpers';
import { useSelector } from 'react-redux';
import {
  selectItemInCurrentThemeTreeWithSearchIndex,
  selectCurrentThemeTreeWithVisibilityExceptions,
} from '@src/reduxStore/theme/themeSelectors';
import NewsSection from '../newsSection/newsSection';
import ThemeBlogFilters from './themeBlogFilters/themeBlogFilters';
import ThemeBlogItems from './themeBlogItems/themeBlogItems';
import useThemeBlogs from './hooks/useThemeBlogs';

const ThemeBlog = ({ blogHref, dynamicMetaDataUpdated }) => {
  const isTablet = useMediaQuery('screen and (max-width: 992px)');
  const [filtersSidebarOpen, setFiltersSidebarOpen] = useState(false);

  const theme = useSelector((_) => selectCurrentThemeTreeWithVisibilityExceptions(_, blogHref));

  // only blogs and blogItems have searchIndex (legacy..)
  const blog = useSelector((_) => selectItemInCurrentThemeTreeWithSearchIndex(_, blogHref));

  useEffect(() => {
    if (blog) dynamicMetaDataUpdated(blog);
  }, [blog]);

  const isStandalonePage = theme?.pageType === 'BLOG';
  const pageTabs = theme && getThemeTabs(theme, isStandalonePage);
  const showTabs = pageTabs?.length > 0;
  const showPageTitle = blog?.href !== theme?.href;
  const pageTitle = blog?.title;

  const {
    blogItems,
    blogItemCount,
    categories,
    loadingCategories,
    searchTerm,
    textSearch,
    onLoadMore,
    onSearchTermChange,
    onSearchTermClear,
    onSearch,
    onCategorySelected,
    selectedCategory,
    limit,
    numFiltersApplied,
  } = useThemeBlogs(blog);

  return blog ? (
    <>
      <Banner menuItem={blog} theme={theme} />
      <section id="theme-page">
        <div id="kov-accent-1">
          {showTabs && <ThemeMenu theme={theme} items={pageTabs} current={blog.href} />}
          <div className="container">
            <div className="kov-pro-theme-blog kov-pro-blog-blog col-12">
              <div className="row">
                {showPageTitle && (
                  <div className="kov-pro-theme-main-content-title d-none d-md-block">
                    <h1>{stripHtml(pageTitle)}</h1>
                  </div>
                )}
                <div
                  className="kov-pro-theme-main-content row"
                  id="blog"
                  style={{ paddingTop: isTablet ? 0 : '30px', width: '100%' }}
                >
                  <div
                    className={`no-print kov-pro-filter ${isTablet ? 'col-12' : 'col-3'}`}
                    style={{ margin: 0, paddingTop: 0 }}
                  >
                    {textSearch && (
                      <BlogSearchInput
                        searchTerm={searchTerm}
                        onSearch={onSearch}
                        onSearchTermChange={onSearchTermChange}
                        onSearchTermClear={onSearchTermClear}
                      />
                    )}
                    {!isTablet && (
                      <ThemeBlogFilters
                        isLoadingCategories={loadingCategories}
                        categories={categories}
                        color={theme.root.color}
                        onCategorySelected={onCategorySelected}
                        selectedCategory={selectedCategory}
                      />
                    )}
                  </div>
                  <div className="kov-pro-content col-12 col-md-12 col-lg-9">
                    {isTablet && (
                      <div className="d-flex">
                        <button
                          onClick={() => setFiltersSidebarOpen((prev) => !prev)}
                          className="kov-pro-btn kov-pro-btn--dark kov-pro-btn__icon--left kov-pro-btn--inverted kov-pro-btn--filter kov-pro-btn--block"
                        >
                          <i className="icon-kov-pro-filter" />
                          Filter
                          {numFiltersApplied > 0 && (
                            <span className="filter-counter">{numFiltersApplied}</span>
                          )}
                        </button>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-12 kov-pro-blog-roll">
                        {blogItems?.length > 0 ? (
                          <ThemeBlogItems
                            blogItems={blogItems}
                            color={theme.root.color}
                            onCategorySelected={onCategorySelected}
                            filters={{
                              q: searchTerm,
                              requirements: selectedCategory,
                              limit,
                            }}
                          />
                        ) : (
                          !loadingCategories && <NoResultsPlaceholder />
                        )}
                        {blogItems?.length < blogItemCount && (
                          <div className="kov-pro-load-more">
                            <ProgressBar length={blogItems.length} count={blogItemCount} />
                            <LoadMore onLoadMore={onLoadMore} loadingBarText={'Laad meer nieuws'} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {isTablet && (
                    <SidebarPortal
                      open={filtersSidebarOpen}
                      onClose={() => setFiltersSidebarOpen(false)}
                    >
                      {textSearch && (
                        <BlogSearchInput
                          searchTerm={searchTerm}
                          onSearch={() => {
                            onSearch();
                            setFiltersSidebarOpen(false);
                          }}
                          onSearchTermChange={onSearchTermChange}
                          onSearchTermClear={onSearchTermClear}
                        />
                      )}
                      <ThemeBlogFilters
                        isLoadingCategories={loadingCategories}
                        categories={categories}
                        color={theme.root.color}
                        onCategorySelected={onCategorySelected}
                        selectedCategory={selectedCategory}
                      />
                    </SidebarPortal>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {theme.referenceFrameItem && (
        <NewsSection
          sReferenceFrame={theme.referenceFrameItem}
          sThemePath={theme.pagePath}
          locationPath={window.location.path}
        />
      )}
      <ThemeAbout title="OVER DEZE BLOG" description={blog.description} />
    </>
  ) : null;
};

ThemeBlog.propTypes = {
  blogHref: PropTypes.string,
  dynamicMetaDataUpdated: PropTypes.func,
};

export default ThemeBlog;
