import React, { useCallback } from 'react';
import { databaseActions } from '@src/reduxStore/database/databaseSlice';
import NoResultsPlaceholder from '@UI/noResultsPlaceholder/noResultsPlaceholder';
import LoadingResultsPlaceholder from '@UI/loadingResultsPlaceholder/loadingResultsPlaceholder';
import { getOrderMethod } from '@components/database/helpers/helpers';
import DatabaseResultsItem from './DatabaseResultsItem';
import DatabaseOrder from './DatabaseOrder';

const DatabaseResults = ({
  dispatch,
  onUpdateReferences,
  results,
  isGlobalDatabase,
  filters,
  zillCurricula,
  references,
  loading,
  orderTypes,
  searchCount,
  dbKey,
}) => {
  const handleLoadMore = useCallback(() => {
    dispatch(databaseActions.setLimit({ type: 'next' }));
  }, [dispatch]);
  return (
    <div className="kov-pro-bib-list">
      {isGlobalDatabase && filters.curricula.length === 0 ? (
        <div className="kov-pro-infobox">Selecteer één of meerdere leerplannen via de filter</div>
      ) : (
        <>
          <div className="kov-pro-sorter">
            <span className="kov-label">{searchCount}</span>
            <DatabaseOrder
              order={getOrderMethod(filters.order, filters.q)}
              onSetOrder={(orderType) => {
                dispatch(databaseActions.setFilter({ fieldName: 'order', payload: orderType }));
                dispatch(databaseActions.revertTempFiltersToLastApplied());
              }}
              orderTypes={orderTypes}
            />
          </div>
          {
            <>
              {results.length === 0 && !loading && <NoResultsPlaceholder />}
              {results.length === 0 && loading && <LoadingResultsPlaceholder />}
              {
                <>
                  {results.map((child) => (
                    <DatabaseResultsItem
                      key={child.key}
                      item={child}
                      dbKey={dbKey}
                      onUpdateZill={(item) => {
                        dispatch(
                          databaseActions.setFilter({
                            fieldName: 'zillItem',
                            payload: item,
                          })
                        );
                      }}
                      onUpdate={onUpdateReferences}
                      zillCurricula={zillCurricula}
                      references={references}
                      isGlobalDatabase={isGlobalDatabase}
                    />
                  ))}
                  {results.length < searchCount && (
                    <div className="kov-pro-load-more">
                      <p className="text-center">{`${results.length} van ${searchCount}`}</p>
                      <div className="progress-tracker">
                        <div className="progress">
                          <div
                            data-percentage="0%"
                            style={{ width: `${(results.length / searchCount) * 100}%` }}
                            className="progress-bar"
                            role="progressbar"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          />
                        </div>
                      </div>
                      <button
                        type="button"
                        className="kov-pro-btn kov-pro-btn--light kov-pro-btn--inverted kov-pro-btn--block kov-pro-btn__icon--right"
                        onClick={handleLoadMore}
                      >
                        Laad meer voorbeelden
                        <i className="icon-kov-pro-right-arrow" />
                      </button>
                    </div>
                  )}
                </>
              }
            </>
          }
        </>
      )}
    </div>
  );
};
export default DatabaseResults;
