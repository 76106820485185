import React from 'react';
import PropTypes from 'prop-types';
import { getSitemapPathFromSource } from '@src/services/sitemapService';

const QuickNavigationMobileQuicklinks = ({ quickLinks, closeMenu }) =>
  quickLinks.map((link) => (
    <div key={link.key} className="mobile-menu__cluster">
      <a
        href={getSitemapPathFromSource(link.references[0])}
        onClick={closeMenu}
        style={{ color: 'inherit' }}
      >
        {link.title}
      </a>
    </div>
  ));

QuickNavigationMobileQuicklinks.propTypes = {
  quickLinks: PropTypes.array,
  closeMenu: PropTypes.func,
};

export default QuickNavigationMobileQuicklinks;
