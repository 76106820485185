import settings from '@src/config/settings';
import store from '@src/reduxStore/store';
import { suggestionsStateActions } from '@src/reduxStore/suggestions/suggestionsSlice';
import addSuggestions from '@src/reduxStore/suggestions/suggestionsThunks';

let listenerAlreadyAttached = false;

const getTrustedOrigins = () => settings.trustedOrigins.join(',');

/**
 * This method analyses the message received via POSTMESSAGE and if it is correct it adds the suggestions from REDACTIE to PRO
 * @param {*} event
 */
const checkMessage = async (event) => {
  if (!event.data) {
    throw new Error('There is no value in data property :: [POST_MESSAGE]', event);
  }

  if (!(event.data instanceof Object && Object.keys(event.data).length)) {
    throw new Error('Data must be an object and it must not be empty :: [POST_MESSAGE]', event);
  }

  if (event.data.type === 'REDACTIE_SUGGESTIONS') {
    console.log('[POST_MESSAGE]', event);
    const { suggestions } = event.data;
    await store.dispatch(addSuggestions(suggestions));
  }
};

const getOriginWindow = () => {
  let originWindow = window.opener;

  if (!originWindow) {
    if (window !== window.parent) {
      originWindow = window.parent;
    }
  }
  return originWindow;
};

const addPostMessageListener = (originWindow) => {
  window.addEventListener('message', checkMessage, false);

  if (originWindow) {
    originWindow.postMessage(
      { state: 'CORRECT', event: 'LOADED', source: 'PRO' },
      getTrustedOrigins()
    );
  }
  listenerAlreadyAttached = true;
};

const addListener = () => {
  const originWindow = getOriginWindow();
  if (originWindow) {
    addPostMessageListener(originWindow);
    store.dispatch(suggestionsStateActions.updateShowWrongPostMessageOriginAlert(false));
  } else {
    store.dispatch(suggestionsStateActions.updateShowWrongPostMessageOriginAlert(true));
  }
};

export const removePostMessageListener = () => {
  window.removeEventListener('message', checkMessage, false);
  store.dispatch(suggestionsStateActions.updateShowWrongPostMessageOriginAlert(false));
};

export const notifyRedactiePageLoaded = () => {
  // we avoid duplicated listeners for the same functionality
  if (listenerAlreadyAttached) return;

  setTimeout(() => {
    const redactieParam = window.location.search.includes('redactie');
    if (redactieParam) {
      addListener();
    }
  }, 500);
};
