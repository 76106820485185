import React, { useState, useEffect, useRef } from 'react';

const DatabaseOrder = ({ onSetOrder, order, orderTypes }) => {
  const [open, setOpened] = useState(false);
  const wrapperRef = useRef(null);
  /**
   * Alert if clicked on outside of element
   */
  useEffect(() => {
    let isCancelled = false;

    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (!isCancelled) setOpened(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside, { passive: true });
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside, { passive: true });
      isCancelled = true;
    };
  }, [wrapperRef]);

  return (
    <div className={`btn-group bootstrap-select ${open ? 'show' : null}`}>
      <button
        type="button"
        className="btn dropdown-toggle btn-default"
        data-toggle="dropdown"
        role="button"
        onClick={() => setOpened(!open)}
      >
        <span className="filter-option pull-left">{order}</span>
        &nbsp;
        <span className="bs-caret">
          <span className="caret" />
        </span>
      </button>
      <div
        ref={wrapperRef}
        className={`dropdown-menu open ${open ? 'show' : null}`}
        role="combobox"
      >
        <ul className="dropdown-menu inner" role="listbox" aria-expanded="false">
          {orderTypes.map((type, index) => (
            <li
              key={type}
              data-original-index={index}
              className={`database-order-item ${type === order ? 'selected' : null}`}
              onClick={() => {
                setOpened(false);
                onSetOrder(type);
              }}
            >
              <a
                tabIndex="0"
                className=""
                data-tokens="null"
                role="option"
                aria-disabled="false"
                aria-selected="true"
              >
                <span className="text">{type}</span>
                <span className="glyphicon glyphicon-ok check-mark" />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DatabaseOrder;
