import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import NewsTeaser from '@components/theme/newsSection/newsTeaser';
import NewsLoadingAnimation from '@components/theme/newsSection/newsLoadingAnimation';
import { NEWS_SECTION } from '@src/components/constants';
import { getAngularService } from '@src/module/reactMigrationUtils/angular-react-helper';
import { useDispatch, useSelector } from 'react-redux';
import themeSelectors from '@src/reduxStore/theme/themeSelectors';
import { curriculumSitesLogic } from '@kathondvla/shared-logic';
import { selectIsLoading, selectCurrentThemeTeasers } from '@src/reduxStore/news/newsSelectors';
import { newsActions, initTeasers } from '@src/reduxStore/news/newsSlice';
import AgendaLievenBoeve from '../../UI/newsSection/agendaLievenBoeve/agendaLievenBoeve';
import BlogParlementaireActiviteiten from '../../UI/newsSection/blogParlementaireActiviteiten/blogParlementaireActiviteiten';

const NewsSection = ({ sReferenceFrame, sThemePath, locationPath, isHomepage = false }) => {
  const dispatch = useDispatch();
  const navigationService = getAngularService('navigationService');
  const [databankHref, setDatabankHref] = useState();
  const [blogLink, setBlogLink] = useState();
  const [agendaLink, setAgendaLink] = useState();
  const selectedSubCurriculaHrefs = useSelector(themeSelectors.getCurricula);
  const loading = useSelector(selectIsLoading);
  const teasers = useSelector(selectCurrentThemeTeasers);

  const getDatabankHref = useCallback(async () => {
    const themeTree = await navigationService.getCurrentNavigationItemTo(locationPath);
    if (!themeTree.some((node) => node.level)) {
      return '/nieuwsoverzicht';
    }

    return `/nieuwsoverzicht?menuItem=${themeTree.pop()?.href}`;
  }, [locationPath, navigationService]);

  useEffect(() => {
    let isCancelled = false;

    (async () => {
      dispatch(initTeasers());
      dispatch(newsActions.setCurrentThemes({ sReferenceFrame, selectedSubCurriculaHrefs }));

      if (sThemePath) {
        if (!isCancelled) {
          setDatabankHref(
            `${sThemePath}/nieuwsoverzicht${
              selectedSubCurriculaHrefs && selectedSubCurriculaHrefs.length > 0
                ? `?curricula=${curriculumSitesLogic.encodeThemes(selectedSubCurriculaHrefs)}`
                : ''
            }`
          );
        }
      } else if (sReferenceFrame?.length > 0) {
        setDatabankHref(`/nieuwsoverzicht?menuItem=${sReferenceFrame[0].href}`);
      } else {
        const dbHref = await getDatabankHref();
        if (!isCancelled) setDatabankHref(dbHref);
      }

      const [blog, agenda] = await Promise.all([
        navigationService.getBlog(),
        navigationService.getAgenda(),
      ]);

      if (!isCancelled) {
        setBlogLink(blog?.references[0]);
        setAgendaLink(agenda?.references[0]);
      }
    })();

    return () => {
      isCancelled = true;
    };
  }, [getDatabankHref, sReferenceFrame, navigationService, sThemePath, selectedSubCurriculaHrefs]);

  if (!teasers && !loading) return null;

  return (
    <>
      {(loading || (teasers && teasers.length > 0)) && (
        <section id="news" className="light">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="kov-pro-section-title">
                  <h1 className="mr-auto">
                    <i className="icon-kov-pro-bookmark" />
                    Nieuws
                  </h1>
                  <h4>
                    <a href={databankHref}>ALLE NIEUWS</a>
                  </h4>
                </div>
              </div>
            </div>

            {!loading ? (
              <div className="row">
                <div
                  className={`col-12 col-md-12 kov-pro-prominent-teasers${
                    teasers.length > NEWS_SECTION.MAIN_TEASERS ? ' col-lg-8' : ' col-lg-12'
                  }`}
                >
                  <ul className="kov-pro-news-teaser-list">
                    {teasers.slice(0, NEWS_SECTION.MAIN_TEASERS).map((teaser) => (
                      <NewsTeaser key={teaser.key} teaser={teaser} sThemePath={sThemePath} />
                    ))}
                  </ul>
                </div>

                {teasers.length > NEWS_SECTION.MAIN_TEASERS && (
                  <div className="col-12 col-md-12 col-lg-4 kov-pro-side-teasers">
                    <div className="kov-pro-news-teaser-list__title">Meer nieuws</div>
                    <ul className="kov-pro-news-teaser-list kov-pro-news-teaser-list--minified">
                      {teasers
                        .slice(
                          NEWS_SECTION.MAIN_TEASERS,
                          NEWS_SECTION.MAIN_TEASERS + NEWS_SECTION.SIDE_TEASERS
                        )
                        .map((teaser) => (
                          <NewsTeaser
                            key={teaser.key}
                            teaser={teaser}
                            sThemePath={sThemePath}
                            minified
                          />
                        ))}
                    </ul>
                    <div className="divider-line" />
                    {agendaLink && <AgendaLievenBoeve />}
                    {blogLink && isHomepage && <BlogParlementaireActiviteiten />}
                  </div>
                )}
              </div>
            ) : (
              <NewsLoadingAnimation />
            )}
          </div>
        </section>
      )}
    </>
  );
};

NewsSection.propTypes = {
  sReferenceFrame: PropTypes.array,
  sThemePath: PropTypes.string,
  locationPath: PropTypes.string,
  isHomepage: PropTypes.bool,
};

export default NewsSection;
