import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ThemeMenu } from '@components/database/components';
import databaseSelectors from '@src/reduxStore/database/databaseSelectors';
import ThemePagination from '@UI/themeComponents/themePagination/ThemePagination';
import Banner from '@components/sections/banner/banner';
import ThemeToc from '@UI/themeComponents/themeTextToc/ThemeToc';
import { getAngularService } from '@src/module/reactMigrationUtils/angular-react-helper';
import { offsetFromTop } from '@src/app/utils';
import contentUtils from '@src/app/contentUtils';
import { curriculumSitesLogic } from '@kathondvla/shared-logic';
import { shouldShowDownloadsSection } from '@components/theme/themeDownloadSection/helpers/downloadSectionHelper';
import kovSentry from '@src/app/kovSentry';
import ThemeText from '../theme/themeText/themeText';
import ThemeDownloadSection from '../theme/themeDownloadSection/themeDownloadSection';
import ThemeAbout from '../theme/themeAbout/themeAbout';

const DatabaseItem = ({ theme, databaseItem }) => {
  const searchResults = useSelector(databaseSelectors.getResults);
  const routerService = getAngularService('routerService');
  const [scrollToPos, setScrollToPos] = useState(
    new URL(document.location).searchParams.get('scrollTo')
  );
  const refDB = new URLSearchParams(window.location.search).get('refDB');

  const filters = useSelector(databaseSelectors.getFilters);

  useEffect(() => {
    if (databaseItem) {
      kovSentry.endLog('load Pro');
    }
  }, [databaseItem]);

  useEffect(() => {
    if (scrollToPos) {
      setTimeout(() => {
        routerService.scrollTo(scrollToPos, offsetFromTop);
      }, 500);
      setScrollToPos(null);
      const url = new URL(document.location);
      url.searchParams.delete('scrollTo');
      window.history.replaceState({}, '', url);
    }
  }, [scrollToPos, routerService]);

  const returnUrlParams = useMemo(() => {
    const newReturnUrlParams = new URLSearchParams();
    if (filters.q !== '') newReturnUrlParams.set('q', filters.q);
    if (filters.ageRangeFrom !== 2) newReturnUrlParams.set('ageRangeFrom', filters.ageRangeFrom);
    if (filters.ageRangeTo !== 18) newReturnUrlParams.set('ageRangeTo', filters.ageRangeTo);
    if (filters.order) newReturnUrlParams.set('order', filters.order);
    if (filters.zillItem) newReturnUrlParams.set('zillItem', filters.zillItem);
    if (filters.curricula.length > 0)
      newReturnUrlParams.set('curricula', curriculumSitesLogic.encodeThemes(filters.curricula));

    const requirements = filters.requirements.flatMap((requirement) =>
      requirement.values.filter((value) => value.checked).map((value) => value.key)
    );
    if (requirements.length > 0) newReturnUrlParams.set('requirements', requirements.toString());
    const urlString = newReturnUrlParams.toString();

    return decodeURIComponent(urlString);
  }, [filters]);

  const collection = useMemo(
    () => (searchResults.length === 0 ? databaseItem.parent.children : searchResults),
    [searchResults, databaseItem.parent.children]
  );

  const paginationIndex = useMemo(
    () => collection.findIndex((item) => item.key === databaseItem.key),
    [collection, databaseItem.key]
  );

  const paginationPrevious = useMemo(() => {
    if (paginationIndex > 0) {
      return refDB !== null
        ? `${collection[paginationIndex - 1].pagePath}?refDB=${refDB}`
        : collection[paginationIndex - 1].pagePath;
    }
    return undefined;
  }, [collection, paginationIndex, refDB]);

  const paginationNext = useMemo(() => {
    if (paginationIndex < collection.length - 1) {
      return refDB !== null
        ? `${collection[paginationIndex + 1].pagePath}?refDB=${refDB}`
        : collection[paginationIndex + 1].pagePath;
    }
    return undefined;
  }, [collection, paginationIndex, refDB]);

  const isStandalonePage = theme?.pageType !== 'THEME_HOME';
  const themeHasTrainings = theme?.hasTrainings;
  const isStandalonePageWithoutTrainings = isStandalonePage && !themeHasTrainings;

  const showDownloadSection = useMemo(
    () => shouldShowDownloadsSection(databaseItem),
    [databaseItem]
  );

  // otherwise it will initialize the banner multiple times
  const menuItem = useMemo(() => ({ websiteType: 'database', extra: databaseItem }), []);

  return (
    <>
      <Banner menuItem={menuItem} theme={theme} subAtomicSelectorDisabled={true} />
      <section id="database-item-page" style={{ padding: '0 0 50px 0' }}>
        {theme && (!isStandalonePage || isStandalonePageWithoutTrainings) && (
          <ThemeMenu theme={theme} current={databaseItem?.parent?.href} items={theme.children} />
        )}

        <div className="container">
          <div className="row">
            <div className="kov-pro-theme-page kov-pro-theme-bib-detail col-12">
              <div className="row">
                <div className="kov-pro-bib-item-title col-12">
                  {databaseItem.title}
                  <ThemePagination
                    prev={paginationPrevious}
                    overview={`${databaseItem.parent.pagePath}${
                      returnUrlParams.length > 0 ? `?${returnUrlParams}` : ''
                    }`}
                    next={paginationNext}
                    minimal
                  />
                  <div className="divider-line divider-line--accent" />
                </div>

                <ThemeToc
                  page={databaseItem}
                  theme={theme}
                  showDownloadSection={showDownloadSection}
                  showContactsSection={false}
                  background={
                    // Property thumbImageM is being set in themeServive.js.
                    // Shared mini database items don't use this service so we use contentUtils to get the thumbnail from the attachements.
                    databaseItem.thumbImageM?.href ||
                    contentUtils.getImageWithSize(databaseItem, 'THUMBNAIL', {
                      w: 500,
                      h: 360,
                    })?.href
                  }
                />

                <div className="kov-pro-theme-main-content kov-pro-bib-item-content col-12 col-md-8 order-md-1">
                  <ThemeText item={databaseItem} color={theme?.root.color} />
                  {showDownloadSection && <ThemeDownloadSection item={databaseItem} />}
                </div>
              </div>
            </div>

            <ThemePagination
              prev={paginationPrevious}
              overview={`${databaseItem.parent.pagePath}${
                returnUrlParams.length > 0 ? `?${returnUrlParams}` : ''
              }`}
              next={paginationNext}
            />
          </div>
        </div>
        <ThemeAbout
          title={'Over deze databank'}
          description={databaseItem.parent.description || ''}
        />
      </section>
    </>
  );
};

DatabaseItem.propTypes = {
  theme: PropTypes.object,
  databaseItem: PropTypes.object,
};

export default DatabaseItem;
