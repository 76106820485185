import { createSelector } from '@reduxjs/toolkit';
import { selectUserViewOptions } from '@src/reduxStore/user/userSelectors';
import { filterTeasers } from '@src/helpers/contextFilterHelper';
import { isVisibleWithViewOptions } from '@src/helpers/viewOptionsHelper';
import { getApplicabilityLabels, sortTeaserByDateAndPriority } from '@src/helpers/newsHelper';
import { getImageWithSize, getImage } from '@src/app/contentUtils';
import { buildTreeFromItemsList } from '@src/helpers/utils';
import { getRedactieItems } from '../suggestions/suggestionsSelectors';

export const selectAllTeasers = (state) => state.news.teasers;
export const selectCurrentThemes = (state) => state.news.currentThemes;
export const selectStatus = (state) => state.news.status;
export const selectLastTimeTeasersLoaded = (state) => state.news.lastTimeTeasersLoaded;
export const selectCurrentThemePaths = (state) => state.news.currentThemePaths;
export const selectIsLoading = (state) => selectStatus(state) === 'loading';
export const selectExtraNews = (state) => state.news.extraNews;
export const selectCurrentTeaserOrNewsHref = (state) => state.news.currentTeaserOrNewsHref;

export const selectCurrentTeaserOrNews = createSelector(
  [selectAllTeasers, selectCurrentTeaserOrNewsHref, selectExtraNews, getRedactieItems],
  (allTeasers, currentTeaserOrNewsHref, extraNews, redactieItems) => {
    let teaser = allTeasers.find((e) => e.href === currentTeaserOrNewsHref);
    if (!teaser) {
      teaser = extraNews[currentTeaserOrNewsHref];
    }
    if (redactieItems) {
      return buildTreeFromItemsList(teaser.href, redactieItems);
    }
    if (teaser?.attachments && teaser?.attachments.length > 0) {
      return {
        ...teaser,
        coverImage: getImageWithSize(teaser, 'COVER_IMAGE', {
          w: 2000,
          h: 1600,
        }),
        thumbImage: getImage(teaser, 'THUMBNAIL'),
      };
    }
    return teaser;
  }
);

export const selectCurrentThemeTeasers = createSelector(
  [selectAllTeasers, selectCurrentThemes, selectUserViewOptions],
  (teasers, currentThemes, userViewOptions) => {
    if (currentThemes?.length === 0) return [];
    if (currentThemes && currentThemes.length > 0) {
      teasers = teasers.filter(
        (teaser) =>
          teaser.themes !== undefined && teaser.themes.some((th) => currentThemes.includes(th))
      );
    }

    teasers = filterTeasers(teasers, userViewOptions);
    teasers = getApplicabilityLabels(teasers);
    return sortTeaserByDateAndPriority(teasers, false);
  }
);

export const selectCurrentRelevantTeaser = createSelector(
  [selectAllTeasers, selectCurrentTeaserOrNews],
  (teasers, currentTeaserOrNews) => {
    if (!currentTeaserOrNews || currentTeaserOrNews.type === 'TEASER') return currentTeaserOrNews;

    const relations = currentTeaserOrNews.$$relationsTo;
    return teasers.find((teaser) =>
      teaser.$$relationsTo.some(
        (relation) =>
          relation.$$expanded.from.href ===
          relations.find((relationFrom) => relationFrom.$$expanded.relationtype === 'REFERENCES')
            ?.$$expanded.from.href
      )
    );
  }
);

export const selectCurrentRelevantTeaserWithApplicabiltyLabels = createSelector(
  [selectCurrentRelevantTeaser],
  (teaser) => teaser && getApplicabilityLabels([teaser])[0]
);

export const selectRelatedNewsTeasers = createSelector(
  [selectAllTeasers, selectCurrentRelevantTeaser, selectUserViewOptions],
  (allTeasers, currentTeaser, userViewOptions) => {
    if (currentTeaser?.themes?.length > 0) {
      const relatedTeasersMap = new Map();
      allTeasers.forEach((teaser) => {
        if (
          teaser.href !== currentTeaser.href &&
          teaser.themes !== undefined &&
          teaser.themes.some((th) => currentTeaser.themes.includes(th))
        ) {
          relatedTeasersMap.set(teaser.href, teaser);
        }
      });

      let relatedTeasers = filterTeasers([...relatedTeasersMap.values()], userViewOptions);
      relatedTeasers = getApplicabilityLabels(relatedTeasers);
      return sortTeaserByDateAndPriority(relatedTeasers, false).slice(0, 5);
    }
    return null;
  }
);

export const selectIsCurrentTeaserOutOfViewOptions = createSelector(
  [selectCurrentRelevantTeaser, selectUserViewOptions, getRedactieItems],
  (currentTeaser, userViewOptions, redactieItems) =>
    !redactieItems && !isVisibleWithViewOptions(currentTeaser, userViewOptions)
);
