import React, { useCallback, useEffect, useState } from 'react';
import CurriculaSelectorButton from '@UI/curriculaSelector/CurriculaSelectorButton';
import { capitalizeFirstLetter, removeHtmlTags, sortAlphabetically } from '@helpers/utils';
import KovToolTip from '@UI/kovToolTip/kovToolTip';
import { useSelector } from 'react-redux';
import { selectCurriculumThemes } from '@src/reduxStore/theme/themeSelectors';

const CurriculaSelectorFilter = ({
  appliedCurriculaFilterHrefs,
  onUpdate,
  isBlock = false,
  fullWidthButton = true,
}) => {
  const curriculaThemes = useSelector(selectCurriculumThemes);
  const [collapsed, setCollapsed] = useState(false);
  const [curriculaOptions, setCurriculaOptions] = useState([]);

  // update curriculaOptions when search filters change
  useEffect(() => {
    const appliedCurriculaThemes = curriculaThemes
      .filter((currTheme) => appliedCurriculaFilterHrefs?.includes(currTheme.href))
      .map((currTheme) => ({
        ...currTheme,
        selected: true,
      }));

    const appliedCurriculaThemesHrefs = new Set(
      appliedCurriculaThemes.map((appliedCurrTheme) => appliedCurrTheme.href)
    );

    setCurriculaOptions((prevState) =>
      [
        ...appliedCurriculaThemes,
        ...prevState
          .filter((curriculaOption) => !appliedCurriculaThemesHrefs.has(curriculaOption.href))
          .map((curriculaOption) => ({ ...curriculaOption, selected: false })),
      ].sort((a, b) => (a.title > b.title ? 1 : -1))
    );
  }, [curriculaThemes, appliedCurriculaFilterHrefs]);

  // populate checkbox list with results from curricula selector
  const handleCurriculaSelectorSubmit = useCallback(
    (curriculaSelectorData) => {
      const selectedCurriculaThemesHrefs = curriculaSelectorData.map(
        (curriculTheme) => curriculTheme.href
      );

      const newAppliedCurriculaThemes = curriculaThemes.filter((curriculTheme) =>
        selectedCurriculaThemesHrefs.includes(curriculTheme.href)
      );
      onUpdate(newAppliedCurriculaThemes);
    },
    [curriculaThemes, onUpdate]
  );

  // dispatch updated list of curriculum themes objects for filtering
  const handleSelection = useCallback(
    (selectedCurriculaThemeHref) => {
      const newCurriculaOptions = curriculaOptions.map((currOption) => ({
        ...currOption,
        selected:
          currOption.href === selectedCurriculaThemeHref
            ? !currOption.selected
            : currOption.selected,
      }));

      setCurriculaOptions(newCurriculaOptions);

      const newAppliedCurriculaThemes = newCurriculaOptions.filter(
        (currOption) => currOption.selected
      );

      onUpdate(newAppliedCurriculaThemes);
    },
    [onUpdate, curriculaOptions]
  );

  return (
    <>
      <div className="filter-block">
        {!isBlock && (
          <a
            className={`title${collapsed ? ' collapsed' : ''}`}
            data-toggle="collapse"
            onClick={() => setCollapsed(!collapsed)}
            role="button"
            aria-expanded={!collapsed}
            aria-controls="curricula-selection"
            style={{ width: '100%' }}
          >
            LEERPLANNEN <i className="icon-kov-pro-down-arrow" />
          </a>
        )}
        <ul
          className={`filter-items collapse${!collapsed ? ' show' : ''}`}
          id="curricula-selection"
        >
          {curriculaOptions.sort(sortAlphabetically).map((item, index) => (
            <li key={index}>
              <label className="filter-item" key={index}>
                {capitalizeFirstLetter(removeHtmlTags(item.title))}
                <input
                  onChange={() => handleSelection(item.href)}
                  type="checkbox"
                  checked={item.selected}
                />
                <span className="checkmark" />
              </label>
              {item.description && <KovToolTip contents={item.description} />}
            </li>
          ))}
          <CurriculaSelectorButton
            preSelection={curriculaOptions
              .filter((curriculaOption) => curriculaOption.selected)
              .map((currTheme) => currTheme.href)}
            isBlock={isBlock}
            onSubmit={handleCurriculaSelectorSubmit}
            fullWidthButton={fullWidthButton}
          />
        </ul>
      </div>
    </>
  );
};

export default CurriculaSelectorFilter;
