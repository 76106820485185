import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import QuickLinks from '@UI/homepage/quicklinks/quicklinks';
import SpotlightThemes from '@UI/homepage/spotlightThemes/spotlightThemes';
import NewsSection from '@components/theme/newsSection/newsSection';
import { useDispatch, useSelector } from 'react-redux';
import analyticsState from '@src/reduxStore/analytics/analyticsSlice';
import { selectUserViewOptions } from '@src/reduxStore/user/userSelectors';
import { checkIfOutOfViewOptions } from '@src/helpers/contextFilterHelper';
import { selectNavigationFirstLevel } from '@src/reduxStore/navigation/navigationSelectors';
import { loaderEnd } from '@src/helpers/loaderHelper';
import kovSentry from '@src/app/kovSentry';
import NavigationFirstLevelItem from './navigationFirstLevelItem/navigationFirstLevelItem';

const NavigationFirstLevel = ({ locationPath, emitShowOutOfViewOptions }) => {
  let subItems = [];
  const dispatch = useDispatch();
  const menuItem = useSelector(selectNavigationFirstLevel);
  const userViewOptions = useSelector(selectUserViewOptions);

  useEffect(() => {
    if (menuItem && userViewOptions)
      emitShowOutOfViewOptions(checkIfOutOfViewOptions(menuItem, userViewOptions));
  }, [menuItem, userViewOptions]);

  if (menuItem?.children) {
    const anyVisible = menuItem.children.some((e) => e.visible);
    subItems = anyVisible ? menuItem.children.filter((item) => item.visible) : menuItem.children;
  }

  useEffect(() => {
    if (menuItem) {
      dispatch(analyticsState.actions.updateBreadcrumbReadyStatus(true));
      kovSentry.endLog('load Pro');
      kovSentry.endLog('load Pro Homepage');
      loaderEnd();
    }
  }, [menuItem]);

  return menuItem ? (
    <>
      <section id="landing">
        <div className="kov-pro-landing">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="kov-pro-landing__wrapper">
                  <ul className="kov-pro-breadcrumb-list">
                    <li className="kov-pro-breadcrumb-list__item">
                      <a href="/">home</a>
                    </li>
                  </ul>
                  <h1 className="kov-pro-landing__title">Katholiek Onderwijs Vlaanderen</h1>
                  <h2 className="kov-pro-landing__subtitle">voor de onderwijsprofessional</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="service-tiles">
        <div className="container">
          <h1>{menuItem.title}</h1>
          <ul className="service-menu">
            <div className="row">
              {subItems.map((item) => (
                <div key={item.href} className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                  <NavigationFirstLevelItem menuItem={item} />
                </div>
              ))}
            </div>
          </ul>
        </div>
      </section>
      <section id="shortcuts">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="kov-pro-shortcuts">
                <QuickLinks />
                <SpotlightThemes />
              </div>
            </div>
          </div>
        </div>
      </section>
      <NewsSection
        locationPath={locationPath}
        isHomepage={menuItem.parent.websiteType === 'HOME_PAGE'}
      />
    </>
  ) : null;
};

NavigationFirstLevel.propTypes = {
  locationPath: PropTypes.string,
  emitShowOutOfViewOptions: PropTypes.func,
};

export default NavigationFirstLevel;
