import React, { useCallback, useEffect, useState } from 'react';
import {
  constructViewOptionsFromUser,
  getLoginProviderIcon,
  redirectToLoginProvider,
} from '@src/helpers/userHelper';
import PropTypes from 'prop-types';

import { useMediaQuery } from '@react-hook/media-query';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUser,
  selectUserViewOptions,
  getUserKey,
  getIsUserKOV,
} from '@src/reduxStore/user/userSelectors';
import { resetViewOptions, updateAllViewOptions } from '@src/reduxStore/user/userThunks';
import uiState, { isSideBarOpen } from '@src/reduxStore/ui/uiState';
import trackPiwikEvent from '@src/reduxStore/analytics/analyticsThunks';
import { transformViewOptionsToAnalyticsObject } from '@src/helpers/viewOptionsHelper';

const settings = require('../../../config/settings');

const Sidebar = ({ reloadViewOptions }) => {
  const isTablet = useMediaQuery('screen and (max-width: 992px)');

  const dispatch = useDispatch();

  const [validEducationLevel, setValidEducationLevel] = useState(false);
  const [validRegions, setValidRegions] = useState(false);

  const [contentFilterState, setContentFilterState] = useState();

  const user = useSelector(selectUser);
  const userKey = useSelector(getUserKey);
  const isUserKOV = useSelector(getIsUserKOV);
  const viewOptions = useSelector(selectUserViewOptions);
  const isShown = useSelector(isSideBarOpen);

  const validateChange = useCallback(() => {
    setValidEducationLevel(contentFilterState.EDUCATION_LEVELS.filter((f) => f.checked).length > 0);
    setValidRegions(contentFilterState.REGIONS.filter((f) => f.checked).length > 0);
  }, [contentFilterState]);

  const sendFilters = () => {
    dispatch(
      trackPiwikEvent(
        'update_view_filter',
        transformViewOptionsToAnalyticsObject(contentFilterState)
      )
    );

    dispatch(updateAllViewOptions(contentFilterState));
    dispatch(uiState.actions.setIsSideBarOpen(false));
    reloadViewOptions();
  };

  const closeSidebar = () => {
    dispatch(uiState.actions.setIsSideBarOpen(false));
  };

  useEffect(() => {
    if (viewOptions) {
      setContentFilterState({
        EDUCATION_LEVELS: viewOptions.EDUCATION_LEVELS.map((item) => ({
          ...item,
          checked: !!item.checked,
        })),
        REGIONS: viewOptions.REGIONS.map((item) => ({ ...item, checked: !!item.checked })),
      });
      setValidEducationLevel(viewOptions.EDUCATION_LEVELS.filter((f) => f.checked).length > 0);
      setValidRegions(viewOptions.REGIONS.filter((f) => f.checked).length > 0);
    }
  }, [viewOptions]);

  const resetPreferences = async () => {
    const originalViewOptions = await constructViewOptionsFromUser(userKey, isUserKOV);
    if (
      originalViewOptions.REGIONS.some((reg) => reg.checked) &&
      originalViewOptions.EDUCATION_LEVELS.some((lvl) => lvl.checked)
    ) {
      dispatch(
        trackPiwikEvent(
          'update_view_filter',
          transformViewOptionsToAnalyticsObject(originalViewOptions)
        )
      );
    }
    dispatch(resetViewOptions(originalViewOptions));
  };

  return (
    <section
      id="kov-sidenav"
      className="kov-sidebar"
      style={{ transform: `translateX(${isShown ? '0' : '420px'}` }}
    >
      <a className="closebtn" onClick={closeSidebar}>
        ×
      </a>
      <div
        className="kov-sidebar__wrapper"
        style={{ paddingTop: isTablet ? '70px' : '0', transitionProperty: 'transform' }}
      >
        {user && (
          <>
            <div className="kov-pro-login">
              <div className="kov-pro-login__title">
                <i className="icon-kov-pro-profile" /> Aangemeld als...
              </div>
              <div className="kov-profile">
                <a
                  href={`${settings.apisAndUrls.mijn}#!/profiel?wijzig-profiel-foto`}
                  target="_blank"
                  className="kov-profile__image"
                  style={{
                    background: user.$$profilePicture
                      ? `url('${settings.apisAndUrls.api}${user.$$profilePicture.$$expanded.href}') center center no-repeat`
                      : '',
                  }}
                  rel="noreferrer"
                />
                <div className="kov-profile__data">
                  <div className="kov-profile__department">{user.title}</div>
                  <div className="kov-profile__name">
                    {user.firstName} {user.lastName}
                  </div>
                </div>
              </div>
            </div>
            <div className="divider-line" />
          </>
        )}

        <div className="kov-pro-viewer-options">
          <div className="kov-pro-viewer-options__title">
            <i className="icon-kov-pro-filter" /> Kijkt als...
          </div>
          <div className="kov-pro-viewer-options">
            <div>
              <div className="kov-pro-viewer-options__subtitle">Niveau</div>
              <ul className="kov-pro-viewer-options__filter-items" id="filter-niveau">
                {contentFilterState &&
                  contentFilterState.EDUCATION_LEVELS &&
                  contentFilterState.EDUCATION_LEVELS.map((level, index) => (
                    <li key={index}>
                      <label className="filter-item">
                        {level.title}
                        <input
                          type="checkbox"
                          checked={level.checked}
                          onChange={() => {
                            const newEducationLevels = [...contentFilterState.EDUCATION_LEVELS];
                            newEducationLevels[index].checked =
                              !contentFilterState.EDUCATION_LEVELS[index].checked;

                            setContentFilterState({
                              EDUCATION_LEVELS: newEducationLevels,
                              REGIONS: contentFilterState.REGIONS,
                            });

                            validateChange();
                          }}
                        />
                        <span className="checkmark" />
                      </label>
                    </li>
                  ))}
              </ul>
            </div>

            <div>
              <div className="kov-pro-viewer-options__subtitle">Regio</div>
              <ul className="kov-pro-viewer-options__filter-items" id="filter-regio">
                {contentFilterState &&
                  contentFilterState.REGIONS &&
                  contentFilterState.REGIONS.map((level, index) => (
                    <li key={index}>
                      <label className="filter-item">
                        {level.title}
                        <input
                          type="checkbox"
                          checked={level.checked}
                          onChange={() => {
                            const newRegions = [...contentFilterState.REGIONS];
                            newRegions[index].checked = !contentFilterState.REGIONS[index].checked;

                            setContentFilterState({
                              REGIONS: newRegions,
                              EDUCATION_LEVELS: contentFilterState.EDUCATION_LEVELS,
                            });

                            validateChange();
                          }}
                        />
                        <span className="checkmark" />
                      </label>
                    </li>
                  ))}
              </ul>
            </div>

            {user && (
              <a href="#" className="reset" onClick={resetPreferences}>
                zet terug op basisprofiel
              </a>
            )}
          </div>
          <div className="kov-sidebar__confirm">
            <button
              className={`kov-pro-btn kov-pro-btn--block kov-pro-btn--green ${
                !validEducationLevel || !validRegions ? 'kov-pro-btn--disabled' : ''
              }`}
              disabled={!validEducationLevel || !validRegions}
              onClick={sendFilters}
            >
              <span>Bevestig</span>
            </button>
            <button
              className="kov-pro-btn kov-pro-btn--block kov-pro-btn--dark kov-pro-btn--inverted"
              onClick={closeSidebar}
            >
              <span>Annuleer</span>
            </button>
          </div>
        </div>

        {!user && (
          <>
            <div className="divider-line" />
            <div className="kov-pro-login">
              <div className="kov-pro-login__title">
                <i className="icon-kov-pro-profile" /> Meld aan
              </div>
              <div className="kov-pro-login__wrapper">
                <p>
                  Meld aan met je account. <br />
                  Pas daarna eventueel je filters aan.
                </p>
                <button
                  className="kov-pro-btn kov-pro-btn--block kov-pro-btn--split kov-pro-btn--green"
                  type="button"
                  onClick={() => redirectToLoginProvider('KATHONDVLA')}
                >
                  <div className="kov-pro-btn__img">
                    <img src={getLoginProviderIcon('KATHONDVLA')} />
                  </div>
                  <span>Aanmelden</span>
                </button>
                <button
                  className="kov-pro-btn kov-pro-btn--block kov-pro-btn--split kov-pro-btn--orange"
                  type="button"
                  onClick={() => redirectToLoginProvider('SMART_SCHOOL')}
                >
                  <div className="kov-pro-btn__img">
                    <img src={getLoginProviderIcon('SMART_SCHOOL')} />
                  </div>
                  <span>Aanmelden</span>
                </button>
                <button
                  className="kov-pro-btn kov-pro-btn--block kov-pro-btn--split kov-pro-btn--red"
                  type="button"
                  onClick={() => redirectToLoginProvider('SCHOOL_WARE')}
                >
                  <div className="kov-pro-btn__img">
                    <img src={getLoginProviderIcon('SCHOOL_WARE')} />
                  </div>
                  <span>Aanmelden</span>
                </button>
                <button
                  onClick={() => redirectToLoginProvider('OFFICE_365')}
                  type="button"
                  className="kov-pro-btn kov-pro-btn--block kov-pro-btn--split kov-pro-btn--365"
                >
                  <div className="kov-pro-btn__img">
                    <img src={getLoginProviderIcon('OFFICE_365')} />
                  </div>
                  <span>Aanmelden</span>
                </button>
                <div className="kov-pro-login__links">
                  <a
                    href={`${settings.apisAndUrls.mijn}/#!/registreren`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Nog geen account?
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

Sidebar.propTypes = {
  reloadViewOptions: PropTypes.func,
};

export default Sidebar;
