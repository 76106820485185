import { getAngularService } from '@src/module/reactMigrationUtils/angular-react-helper';
import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { curriculumSitesLogic } from '@kathondvla/shared-logic';

const isArrayEqual = (a, b) => {
  if (a.length !== b.length) {
    return false;
  }
  return !a.some((elem) => !b.includes(elem));
};
const isStateValueEqual = (param, a, b) =>
  (param.isArray && isArrayEqual(a, b)) || (!param.isArray && a === b);

const updateUrlSearchParamsWithState = (params) => {
  // The "params" attribute in this function only includes the ones that changed due to a user
  // action
  if (params.length === 0) {
    return;
  }
  const href = new URL(window.location.href);
  const previousSearch = href.toString();
  params.forEach(([param, value]) => {
    let newValue = value;

    if (param.isArray) {
      if (param.key === 'requirements') {
        newValue = value
          .flatMap((item) =>
            item.values.flatMap((itemValue) => (itemValue.checked ? itemValue.key : []))
          )
          .join(',');
      } else if (param.key === 'curricula') {
        newValue = curriculumSitesLogic.encodeThemes(value);
      } else {
        newValue = value.join(',');
      }
    }
    newValue = newValue === '' ? null : newValue;
    if (newValue) {
      href.searchParams.set(param.key, encodeURIComponent(newValue));
    } else {
      href.searchParams.delete(param.key);
    }
  });

  if (href.toString() !== previousSearch) {
    window.history.pushState({}, '', href);
  }
};

const transformUrlSearchParamsToState = (paramsConfig, urlSearchParams) => {
  const paramsStates = [];

  paramsConfig.forEach((param) => {
    const urlSearchParamsValue = urlSearchParams.has(param.key)
      ? decodeURIComponent(urlSearchParams.get(param.key))
      : null;
    if (urlSearchParamsValue === '' && param.isArray) {
      paramsStates.push([param, []]);
    } else if (urlSearchParamsValue !== null) {
      if (param.key === 'curricula') {
        paramsStates.push([param, curriculumSitesLogic.decodeThemes(urlSearchParamsValue)]);
      } else {
        const newState = param.isArray ? urlSearchParamsValue.split(',') : urlSearchParamsValue;
        paramsStates.push([param, newState]);
      }
    } else {
      paramsStates.push([param, param.defaultValue]);
    }
  });

  return paramsStates;
};

/**
 * - makes sure the url search parameters are in sync with the necessary state in a component on calling updateUrlSearchParams.
 *   The url parameters are only updated when updateUrlSearchParams, not immediately when the state changes.
 * - The hook initially updates the state with the url when the component is initialised
 * - handles popstate events (go back and forward in browser) to update the state with the url when they happen
 * (- manual updates of the url parameters triggers a reload so this is not handled by this hook)
 * @param {*} paramsStateObj an array with a configuration object for each parameter:
 *  - key: the name of the url parameter
 *  - state: the current state of the object
 *  - updateState: a state update function (newState) => setState(newState).
 *      IMPORTANT!: Take into account that multiple updateState functions can be called in 1 cycle.
 *        So make sure they don't depend on the same useState param:
 *        (newState) => { setter({...previousState, key: newState})} if you have 2 of those updateState functions
 *           in the second one previousState will still be the old value
 *           and it will overwrite the work done by the first updateState function
 * @returns
 * - updateUrlSearchParams: a function the component calls whenever the url parameters need to be updated
 * - DEPRECATEDisStateChangedByUrl: boolean, true if the state is changed because the url changed.
 * - urlParamsProcessed: boolen: is set to true once the hook loads the url params into state, it's set only once on first load
 */
const useUrlSearchParams = (paramsStateObj, filtersInitialized = true) => {
  const [stateChangedByUrl, setStateChangedByUrl] = useState(false);
  const [urlParamsProcessed, setUrlParamsProcessed] = useState(false);
  const initialised = useRef(false);
  const paramsConfig = useRef(
    paramsStateObj.map((param) => ({
      key: param.key,
      isArray: Array.isArray(param.state),
      defaultValue: param.state,
    }))
  );
  const paramsState = useMemo(
    () =>
      paramsStateObj.reduce((map, param) => {
        map[param.key] = { state: param.state, updateState: param.updateState };
        return map;
      }, {}),
    [paramsStateObj]
  );

  const syncUrlSearchParamsToState = useCallback(
    (urlSearchParams, initialized = false) => {
      if (!initialized) return;

      setUrlParamsProcessed(true);

      if (Array.from(urlSearchParams).length === 0) return;

      const urlParamsStates = transformUrlSearchParamsToState(
        paramsConfig.current,
        urlSearchParams
      );
      const mutatedStates = urlParamsStates.filter(
        ([param, value]) =>
          urlSearchParams.has(param.key) &&
          !isStateValueEqual(param, paramsState[param.key].state, value)
      );

      mutatedStates.forEach(([param, value]) => paramsState[param.key].updateState(value));

      if (mutatedStates.length > 0) {
        console.log('[url params hook] adapt state to url:', mutatedStates);
        setStateChangedByUrl(true);
      }
    },
    [paramsState]
  );

  const syncStateToUrlSearchParams = useCallback(() => {
    const urlParamsStates = transformUrlSearchParamsToState(
      paramsConfig.current,
      new URLSearchParams(window.location.search)
    );

    const urlParamsToBeUpdated = urlParamsStates
      .filter(([param, value]) => !isStateValueEqual(param, paramsState[param.key].state, value))
      .map(([param]) => [param, paramsState[param.key].state]);

    if (urlParamsToBeUpdated.length > 0) {
      console.log('[url params hook] url updated because of state changes:', urlParamsToBeUpdated);
    }

    updateUrlSearchParamsWithState(urlParamsToBeUpdated);
  }, [paramsState]);

  useEffect(() => {
    if (stateChangedByUrl) {
      setStateChangedByUrl(false);
    }
  }, [stateChangedByUrl]);

  useEffect(() => {
    if (!initialised.current && filtersInitialized) {
      // on initialsation the state needs to be updated to the initial url
      // BEWARE: because this is in a useEffect the state is only updated in a second run.
      initialised.current = true;
      const urlSearch = window.location.search;

      syncUrlSearchParamsToState(new URLSearchParams(urlSearch), true);
    }
  }, [filtersInitialized, syncUrlSearchParamsToState]);

  // handle popstate event (go back and forward in browser)
  useEffect(() => {
    const handleChange = (event) => {
      console.log('[url params hook] pop state event to', event.currentTarget.location.search);
      window.setTimeout(() => {
        getAngularService('$state').reload();
      }, 0);
    };
    window.addEventListener('popstate', handleChange);
    return () => {
      window.removeEventListener('popstate', handleChange);
    };
  }, []);

  return {
    updateUrlSearchParams: syncStateToUrlSearchParams,
    DEPRECATEDisStateChangedByUrl: stateChangedByUrl,
    /* replaced by urlParamsProcessed, we keep it at this moment because 
    behaviour is not exactly the same */
    urlParamsProcessed,
  };
};

export default useUrlSearchParams;
