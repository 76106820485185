import { useState, useEffect } from 'react';
import { getAngularService } from 'NgMigrationUtils/angular-react-helper';
import { isExternal } from '@helpers/utils.js';
import { useSelector } from 'react-redux';
import {
  selectUserViewOptions,
  selectSelectedRegionTags,
  selectSelectedEducationLevelTags,
} from '@src/reduxStore/user/userSelectors';
import { setVisibilityByViewOptions } from '@helpers/viewOptionsHelper';
import { getCachedContents } from '@src/reduxStore/content/contentDataAccess';

const useMainPageTheme = () => {
  const navigationService = getAngularService('navigationService');
  const viewOptions = useSelector(selectUserViewOptions);

  const selectedRegionLabels = useSelector(selectSelectedRegionTags);
  const selectedEducationLevelLabels = useSelector(selectSelectedEducationLevelTags);
  const [quickLinks, setQuickLinks] = useState([]);
  const [spotLight, setSpotlight] = useState([]);

  useEffect(() => {
    let isMounted = true;
    if (viewOptions) {
      (async () => {
        try {
          const [snellinks, spotlightThemes] = await Promise.all([
            navigationService.getQuickLinks(),
            navigationService.getSpotLightThemes(),
          ]);
          const references = [snellinks, spotlightThemes].flatMap((item) =>
            item.filter((link) => !isExternal(link)).flatMap((links) => links.references)
          );
          const externalReferences = [snellinks, spotlightThemes].flatMap((item) =>
            item.filter((link) => isExternal(link))
          );

          const contents = await getCachedContents(references);

          setVisibilityByViewOptions(contents, viewOptions);
          setVisibilityByViewOptions(externalReferences, viewOptions);

          const filteredReferences = contents
            .filter((content) => content.visible)
            .map((content) => content.$$meta.permalink);

          const filteredExternalReferences = externalReferences
            .filter((reference) => reference.visible)
            .map((reference) => reference.path);

          const filteredLinks = snellinks.filter((link) =>
            isExternal(link)
              ? filteredExternalReferences.some((ref) => link.references.includes(ref))
              : filteredReferences.some((ref) => link.references.includes(ref))
          );

          const filteredSpotlight = spotlightThemes.filter((link) =>
            isExternal(link)
              ? filteredExternalReferences.some((ref) => link.references.includes(ref))
              : filteredReferences.some((ref) => link.references.includes(ref))
          );

          if (isMounted) {
            setSpotlight(filteredSpotlight);
            setQuickLinks(filteredLinks);
          }
        } catch (e) {
          console.log('error in quicklinks and/or spotlightThemes');
        }
      })();
    }
    return () => {
      isMounted = false;
    };
  }, [navigationService, viewOptions, selectedEducationLevelLabels, selectedRegionLabels]);

  return {
    quickLinks,
    spotLight,
  };
};
export default useMainPageTheme;
