import React from 'react';

const LoadMore = ({ onLoadMore, actionButtonText = 'Laad meer' }) => (
  <button
    type="button"
    onClick={onLoadMore}
    className="kov-pro-btn kov-pro-btn--light kov-pro-btn--inverted kov-pro-btn--block kov-pro-btn__icon--right"
  >
    {actionButtonText}
    <i className="icon-kov-pro-right-arrow" style={{ transform: 'rotate(90deg)' }} />
  </button>
);
export default LoadMore;
