import { createSlice } from '@reduxjs/toolkit';

// state
const initialState = {
  isSideBarOpen: false,
  isUserNavOpen: false,
};

const uiState = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setIsSideBarOpen: (state, action) => {
      state.isSideBarOpen = action.payload;
      /* Close userNav if sidebar is open */
      if (action.payload) {
        state.isUserNavOpen = false;
      }
    },
    setIsUserNavOpen: (state, action) => {
      state.isUserNavOpen = action.payload;
    },
  },
});

// Thunks
export const brieflyDisplaySidebar = () => (dispatch) => {
  dispatch(uiState.actions.setIsSideBarOpen(true));
  setTimeout(() => {
    dispatch(uiState.actions.setIsSideBarOpen(false));
  }, 3000);
};

// selectors
export const isSideBarOpen = (state) => state.ui.isSideBarOpen;
export const isUserNavOpen = (state) => state.ui.isUserNavOpen;

export const uistateActions = uiState.actions;
export default uiState;
