import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import logo from '@src/assets/images/logo_zill.png';
import Loader from '@UI/loader/loader';
import { stripHtml } from '@src/app/utils';
import Icon from '@UI/icon/icon';
import { getByHrefNoPromise, prepareCurriculum } from '../helpers/themeTextHelpers';
import ThemeDatabaseZillGoalDetail from './themeDatabaseZillGoalDetail/themeDatabaseZillGoalDetail';

const ThemeDatabaseZillGoals = ({ item }) => {
  const [zillGoals, setZillGoals] = useState([]);
  const [detailedZillGoals, setDetailedZillGoals] = useState({});
  const [collapsed, setCollapsed] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getGoals = async () => {
      setLoading(true);
      await prepareCurriculum();

      const goals = [];
      const detailedGoals = {};
      const goalKeysFound = new Set();
      item.references.forEach((reference) => {
        const goal = getByHrefNoPromise(reference);
        if (goal) {
          const goalKey = goal.$$goal ? goal.$$goal.key : goal.key;
          if (!goalKeysFound.has(goalKey)) {
            goalKeysFound.add(goalKey);
            goals.push(getByHrefNoPromise(goalKey));
          }
          if (goal.$$goal) {
            detailedGoals[goal.$$goal.key] = detailedGoals[goal.$$goal.key] || [];
            detailedGoals[goal.$$goal.key].push(goal);
          }
        }
      });

      setZillGoals(goals.sort((goalA, goalB) => goalA.sort - goalB.sort));
      setDetailedZillGoals(detailedGoals);
      setLoading(false);
    };

    (async () => {
      if (item) getGoals();
    })();
  }, [item]);

  return zillGoals && zillGoals.length > 0 ? (
    <div className="fold-out-box">
      <div
        className={`fold-out-box__header ${collapsed ? 'collapsed' : ''}`}
        data-toggle="collapse"
        data-target="#fold-out-box"
        aria-expanded={collapsed}
        onClick={() => setCollapsed(!collapsed)}
      >
        <div className="fold-out-box__logo">
          <img src={logo} />
        </div>
        <div className="fold-out-box__title">Zill doelen</div>
        <div className="fold-out-box__collapse">
          <i className="icon-kov-pro-down-arrow" />
        </div>
      </div>

      <div
        className={`fold-out-box__collapse collapse ${collapsed ? '' : 'show'}`}
        id="fold-out-box"
        aria-expanded="false"
      >
        {loading && (
          <div style={{ margin: '20px 0', textAlign: 'center' }}>
            <Loader />
          </div>
        )}
        {!loading && (
          <ul className="ref-goals-list icon">
            {zillGoals.map((goal, index) => (
              <li key={index}>
                <a>
                  <Icon
                    icon={{ href: goal.theme.field.iconUrl, contentType: 'image/svg+xml' }}
                    style={{
                      fill: goal.theme.field.color,
                      marginTop: '-2px',
                      width: '18px',
                      height: '18px',
                    }}
                    className="mr10 pull-left"
                  />
                  <span
                    className="ref-goals-list__prefix"
                    style={{ color: goal.theme.field.color }}
                  >
                    {goal.code}
                  </span>
                  <span className="ref-goals-list__goal">{stripHtml(goal.title)}</span>
                </a>

                {detailedZillGoals[goal.key]?.length > 0 && (
                  <ThemeDatabaseZillGoalDetail
                    goal={goal}
                    detailedGoal={detailedZillGoals[goal.key]}
                  />
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  ) : null;
};

ThemeDatabaseZillGoals.propTypes = {
  item: PropTypes.object,
};

export default ThemeDatabaseZillGoals;
