import React, { useState, useRef, useEffect } from 'react';
import { sendMail, copyMail } from '@src/helpers/accessHelper';

const MailDropDown = ({ user, green = false }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    let isCancelled = false;

    function handleClickOutside(event) {
      if (elementRef.current && !elementRef.current.contains(event.target)) {
        if (!isCancelled) setDropdownOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside, { passive: true });
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside, { passive: true });
      isCancelled = true;
    };
  }, [elementRef]);

  return (
    <div id="mailDropDownGroup" className="dropdown">
      <button
        className={`kov-pro-btn ${
          green ? 'kov-pro-btn--green' : 'kov-pro-btn--dark kov-pro-btn--inverted'
        } kov-pro-btn__icon--right kov-pro-btn--dropdown dropdown-toggle`}
        type="button"
        id="dropdownMenuButton"
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        Mail <i className="icon-kov-pro-down-arrow"></i>
      </button>
      <div
        id="mailDropDown"
        ref={elementRef}
        className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`}
        aria-labelledby="dropdownMenuButton"
      >
        <button
          type="button"
          className="kov-pro-btn kov-pro-btn--block kov-pro-btn--light kov-pro-btn--inverted kov-pro-btn__icon--right"
          onClick={() => sendMail(user)}
        >
          <span>stuur mail</span>
          <i className="icon-kov-pro-newsletter"></i>
        </button>
        <button
          type="button"
          className="kov-pro-btn kov-pro-btn--block kov-pro-btn--light kov-pro-btn--inverted kov-pro-btn__icon--right"
          onClick={() => copyMail()}
        >
          <span>kopieer e-mailadres</span>
          <i className="icon-kov-pro-copy"></i>
        </button>
      </div>
    </div>
  );
};

export default MailDropDown;
