import React from 'react';
import PropTypes from 'prop-types';
import { getSitemapPathFromSource } from '@src/services/sitemapService';

const QuickNavigationQuicklinks = ({ quickLinks, closeMenu }) => {
  const onClickHandler = (link) => {
    if (!link.isPathExternal) {
      closeMenu();
    }
  };
  return (
    <div
      className="tab-pane active"
      id="kov-theme-snellinks"
      role="tabpanel"
      aria-labelledby="kov-theme-snellinks-tab"
      aria-expanded="true"
    >
      <a href="#" className="desktop-menu__service-title">
        <i className="icon-kov-pro-mm-snellinks"></i> Snellinks
      </a>
      <div className="cluster-menu">
        <div className="card-columns card-columns--2">
          <div className="card">
            <ul className="desktop-menu__cluster">
              {quickLinks.map((link) => (
                <li
                  key={link.key}
                  className={`desktop-menu__cluster-item ${
                    link.isPathExternal && 'desktop-menu__cluster-item--external'
                  }`}
                >
                  <a
                    href={getSitemapPathFromSource(link.references[0])}
                    onClick={() => onClickHandler(link)}
                    target={link.isPathExternal ? '_blank' : ''}
                    rel="noreferrer"
                  >
                    {link.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

QuickNavigationQuicklinks.propTypes = {
  quickLinks: PropTypes.array,
  closeMenu: PropTypes.func,
};

export default QuickNavigationQuicklinks;
