import React from 'react';
import useMainPageTheme from '@hooks/useMainPageTheme';
import { getSitemapPathFromSource } from '@src/services/sitemapService';
import { isRealExternalLink } from '@helpers/utils.js';
import { useSelector } from 'react-redux';
import { selectSectionTitle } from '@src/reduxStore/navigation/navigationSelectors';

const chunkArray = (myArray) => {
  let index = 0;
  const arrayLength = myArray.length;
  const tempArray = [];

  for (index = 0; index < arrayLength; index += 5) {
    const myChunk = myArray.slice(index, index + 5);
    // Do something if you want with the group
    tempArray.push(myChunk);
  }

  return tempArray;
};

const QuickLinks = () => {
  const { quickLinks } = useMainPageTheme();
  const sectionTitle = useSelector((_) => selectSectionTitle(_, quickLinks[0]?.parentHref));

  const chunks = chunkArray(quickLinks);

  return (
    <>
      {chunks && chunks.length > 0 && chunks[0].length > 0 && (
        <div className="kov-pro-shortcuts__wrapper">
          <div className="kov-pro-shortcuts__title">{sectionTitle}</div>

          <div className="card-columns card-columns--2">
            {chunks.map((linkGroup, index) => (
              <div key={index} className="card">
                <ul className="kov-pro-shortcuts__list">
                  {linkGroup.map((link) => (
                    <li
                      key={link.key}
                      className={`kov-pro-shortcuts__list-item ${
                        isRealExternalLink(link) && 'kov-pro-shortcuts__list-item--external'
                      }`}
                    >
                      <a
                        href={getSitemapPathFromSource(link.references[0])}
                        target={isRealExternalLink(link) ? '_blank' : ''}
                        rel="noreferrer"
                      >
                        {link.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default QuickLinks;
