import { useCallback, useEffect } from 'react';

const useClickOutside = (ref, callback, excludedRefs = undefined) => {
  const targetIsExcluded = useCallback(
    (target) => {
      if (!excludedRefs) return false;
      let excludedTargetFound = false;

      excludedRefs.forEach((exclude) => {
        if (exclude.current && exclude.current.contains(target)) excludedTargetFound = true;
      });

      return excludedTargetFound;
    },
    [excludedRefs]
  );

  const handleClick = useCallback(
    (e) => {
      if (excludedRefs && targetIsExcluded(e.target)) return;
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    },
    [excludedRefs]
  );

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export default useClickOutside;
