import React, { useCallback, useEffect, useState } from 'react';
import { Range, getTrackBackground } from 'react-range';
import { databaseActions } from '@src/reduxStore/database/databaseSlice';

const DatabaseAgeFilter = ({ age, from, to, dispatch }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [localFrom, setLocalFrom] = useState(parseInt(from, 10));
  const [localTo, setLocalTo] = useState(parseInt(to, 10));

  const setNewRange = useCallback(
    ([newFrom, newTo]) => {
      if (newFrom !== from) {
        dispatch(databaseActions.setFilter({ fieldName: 'ageRangeFrom', payload: newFrom }));
      }
      if (newTo !== to) {
        dispatch(databaseActions.setFilter({ fieldName: 'ageRangeTo', payload: newTo }));
      }
    },
    [from, dispatch, to]
  );

  useEffect(() => {
    if (isDragging) return;
    if (localFrom !== from) setLocalFrom(from);
    if (localTo !== to) setLocalTo(to);
  }, [from, isDragging, localFrom, localTo, to]);

  return (
    <div className="filter-block">
      <a
        className={`title${collapsed ? ' collapsed' : ''}`}
        data-toggle="collapse"
        onClick={() => setCollapsed(!collapsed)}
        role="button"
        aria-expanded={!collapsed}
        aria-controls={age.label}
      >
        {age.label} <i className="icon-kov-pro-down-arrow" />
      </a>
      <div
        className={`filter-items collapse${!collapsed ? ' show' : ''}`}
        style={{ cursor: 'ew-resize', padding: '20px 0px 10px 0' }}
        id={age.label}
      >
        <Range
          min={2}
          max={18}
          step={1}
          values={[localFrom, localTo]}
          onChange={(newValues) => {
            setIsDragging(true);
            setLocalFrom(newValues[0]);
            setLocalTo(newValues[1]);
          }}
          onFinalChange={(newValues) => {
            setIsDragging(false);
            setNewRange(newValues);
          }}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: '36px',
                display: 'flex',
                width: '100%',
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: '12px',
                  width: '100%',
                  borderRadius: '4px',
                  background: getTrackBackground({
                    values: [localFrom, localTo],
                    colors: ['#fff', '#72a854', '#fff'],
                    min: 2,
                    max: 18,
                  }),
                  alignSelf: 'center',
                }}
              >
                <div
                  style={{
                    visibility: localFrom === 2 ? 'hidden' : 'visible',
                    position: 'absolute',
                    top: '-10px',
                    left: '0px',
                    width: '25px',
                    fontSize: '10px',
                    lineHeight: ' 1.333',
                    textShadow: 'none',
                    borderRadius: '4px',
                    padding: '1px 5px',
                    background: '#e1e4e9',
                    color: '#999',
                    textAlign: 'center',
                    height: '15px',
                  }}
                >
                  2
                </div>
                {children}
                <div
                  style={{
                    visibility: localTo === 18 ? 'hidden' : 'visible',
                    position: 'absolute',
                    right: '0px',
                    top: '-10px',
                    width: '25px',
                    fontSize: '10px',
                    lineHeight: ' 1.333',
                    textShadow: 'none',
                    borderRadius: '4px',
                    padding: '1px 5px',
                    background: '#e1e4e9',
                    color: '#999',
                    textAlign: 'center',
                    height: '15px',
                  }}
                >
                  18
                </div>
              </div>
            </div>
          )}
          renderThumb={({ index, props }) => (
            <div
              {...props}
              style={{
                ...props.style,
                width: '0px',
              }}
            >
              <div
                style={{
                  textAlign: 'center',
                  position: 'absolute',
                  top: '-28px',
                  width: '25px',
                  color: '#fff',
                  zIndex: '10',
                  fontSize: '10px',
                  lineHeight: ' 1.333',
                  textShadow: 'none',
                  borderRadius: '4px',
                  padding: '1px 5px',
                  background: '#72a854',
                  left: '-12.5px',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    display: 'block',
                    content: '',
                    bottom: '-6px',
                    left: '50%',
                    width: '0',
                    height: '0',
                    marginLeft: '-3px',
                    overflow: 'hidden',
                    border: '3px solid transparent',
                    borderTopColor: '#72a854',
                  }}
                />
                {[localFrom, localTo][index]}
              </div>
              <div />
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default DatabaseAgeFilter;
