import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import LinkedCurriculaThemes from '@components/theme/themeText/curricula/LinkedCurriculaThemes';
import { Compiler } from '@kathondvla/react-shared-components/src';
import ContentBlock from '@src/reactComponents/content/contentBlock/contentBlock';
import getCompilerOptions from '@src/reactComponents/content/helpers/sanitizeOptions';
import ThemeDatabaseZillGoals from './themeDatabaseZillGoals/themeDatabaseZillGoals';
import ThemeModal from '../themeModal/themeModal';
import useLinks from '../hooks/useLinks';

const ThemeText = ({
  item,
  color,
  hideAttachments = false,
  openCookieModal,
  hideZillGoals = false,
}) => {
  let hasZillGoal = false;
  if (!hideZillGoals && item?.parent?.facets) {
    hasZillGoal = item.parent.facets.filter((o) => o.component === 'ZILL_GOAL_SELECTOR').length > 0;
  }

  const domContainerRef = useRef(null);
  useLinks(item, domContainerRef);
  const filterImportantDownloads = (child) =>
    child.importance !== 'HIGH' && child.type !== 'ATTACHMENT_GROUP';

  return (
    <div className="kov-pro-theme-content" ref={domContainerRef}>
      {item.description && (
        <p className="leadtext leadtext--longread">
          <Compiler text={item.description} options={getCompilerOptions()} />
        </p>
      )}
      {['SHARED_MINI_DATABASE_ITEM', 'MINI_DATABASE_ITEM'].includes(item.pageType) && (
        <LinkedCurriculaThemes color={color} item={item} />
      )}
      {!hideZillGoals && hasZillGoal && <ThemeDatabaseZillGoals item={item} color={color} />}
      {item.children?.map(
        (child) =>
          child.visible &&
          filterImportantDownloads(child) && (
            <ContentBlock
              key={child.key}
              item={child}
              color={color}
              hideAttachments={hideAttachments}
              openCookieModal={openCookieModal}
            />
          )
      )}
      <ThemeModal />
    </div>
  );
};

ThemeText.propTypes = {
  item: PropTypes.object,
  color: PropTypes.string,
  hideAttachments: PropTypes.bool,
  hideZillGoals: PropTypes.bool,
  openCookieModal: PropTypes.func,
};

export default ThemeText;
