import React, { useContext, useEffect, useState, useCallback } from 'react';
import { ModalContext } from '@UI/modal/modalProvider';
import Modal from '@UI/modal/modal';
import ThemeContactCard from '@UI/themeComponents/themeContactCard/themeContactCard';
import * as settings from '@src/config/settings';
import { getContactEmail, getContactPhone } from '@src/helpers/personsHelper';

const api = require('@kathondvla/sri-client/fetch-sri-client')({
  baseUrl: settings.apisAndUrls.api,
});

const SearchPersonModal = ({ person }) => {
  const { onClose } = useContext(ModalContext);

  const [contact, setContact] = useState({
    fistName: person.firstName,
    lastName: person.lastName,
    phones: null,
    email: null,
    pictureHref: person.profilePictureHref,
    responsibilities: person.mainResponsibility,
  });

  const createContact = useCallback(async () => {
    try {
      const contacts = await api.getAll('/contactdetails', {
        persons: person.personHref,
        types: 'PHONE_OFFICE,PHONE_MOBILE_OFFICE,EMAILADDRESS_PRIMARY',
      });
      setContact({
        ...person,
        phones: getContactPhone(contacts),
        email: getContactEmail(contacts),
        pictureHref: person.profilePictureHref,
        responsibilities: person.mainResponsibility,
      });
    } catch (searchApiError) {
      console.error(searchApiError);
    }
  }, [person]);

  useEffect(() => {
    createContact();
  }, [createContact]);

  return (
    <Modal className="modal person-contact-modal search-person-modal search-person-center">
      <Modal.Body style={{ overflowY: 'visible' }}>
        <div className="kov-modal-dialog modal-dialog modal-dialog-centered">
          <div className="kov-modal-content modal-content">
            <div
              className="kov-modal-content__body"
              style={{ padding: '0px 20px', border: 'none' }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
                style={{ position: 'absolute', right: '15px', top: '0px' }}
              >
                <i className="icon-kov-pro-close"></i>
              </button>
              <ThemeContactCard contact={contact} disableHover={true}></ThemeContactCard>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SearchPersonModal;
