import React from 'react';
import KovToolTip from '@UI/kovToolTip/kovToolTip';
import { removeHtmlTags } from '@helpers/utils';

const CheckboxOption = ({ option, onChange }) => {
  const disabledStyle = option.disabled ? { opacity: 0.5, cursor: 'not-allowed' } : null;
  return (
    <li className="kov-pro-nas-filter__list-item">
      <label className="filter-item" style={disabledStyle}>
        {removeHtmlTags(option.title)}
        <input
          key={option.key}
          checked={option.checked}
          onChange={(event) => onChange(option.key, event.target.checked)}
          type="checkbox"
          disabled={option.disabled}
        />
        <span className="checkmark" />
      </label>
      {option.description && <KovToolTip contents={option.description} />}
    </li>
  );
};
export default CheckboxOption;
