import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import NewsSection from '@src/reactComponents/theme/newsSection/newsSection';
import { useDispatch, useSelector } from 'react-redux';
import uiState from '@src/reduxStore/ui/uiState';
import { selectNavigationLevel } from '@src/reduxStore/navigation/navigationSelectors';
import { selectUserViewOptions } from '@src/reduxStore/user/userSelectors';
import Banner from '@src/reactComponents/sections/banner/banner';
import { checkIfOutOfViewOptions } from '@src/helpers/contextFilterHelper';
import { loaderEnd } from '@src/helpers/loaderHelper';
import kovSentry from '@src/app/kovSentry';
import NavigationThirdLevelItem from './navigationThirdLevelItem/navigationThirdLevelItem';

const NavigationThirdLevel = ({
  locationPath,
  menuItemHref,
  emitUpdatePageMetadata,
  emitShowOutOfViewOptions,
}) => {
  const dispatch = useDispatch();
  const menuItem = useSelector((_) => selectNavigationLevel(_, menuItemHref));
  const userViewOptions = useSelector(selectUserViewOptions);

  const openSidebar = () => dispatch(uiState.actions.setIsSideBarOpen(true));

  useEffect(() => {
    if (menuItem && userViewOptions)
      emitShowOutOfViewOptions(checkIfOutOfViewOptions(menuItem, userViewOptions));
  }, [menuItem, userViewOptions]);

  useEffect(() => {
    emitUpdatePageMetadata(menuItem);
    kovSentry.endLog('load Pro');
    loaderEnd();
  }, [menuItem]);

  const items = menuItem?.children.filter((item) => item.issued != null && item.visible);

  return menuItem ? (
    <>
      <Banner menuItem={menuItem} />

      <section id="theme-home" style={{ marginBottom: '160px' }}>
        <div className="container" style={{ position: 'relative' }}>
          <ul className="theme-menu">
            <div className="row">
              {items.length === 0 && (
                <div className="kov-pro-theme-content m-auto">
                  <div className="theme-content-synthese theme-content-synthese--importance-medium">
                    <p>
                      Er werden geen thema's gevonden doe overeenkomen met je kijkfilter.{' '}
                      <a style={{ textDecoration: 'underline' }} onClick={openSidebar}>
                        Pas je kijkfilter aan.
                      </a>
                    </p>
                  </div>
                </div>
              )}
              {items.map((item) => (
                <div key={item.key} className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                  <NavigationThirdLevelItem menuItem={item} />
                </div>
              ))}
            </div>
          </ul>
        </div>
      </section>
      {menuItem && (
        <NewsSection sReferenceFrame={[menuItem]} locationPath={locationPath}></NewsSection>
      )}
    </>
  ) : null;
};

NavigationThirdLevel.propTypes = {
  locationPath: PropTypes.string,
  menuItemHref: PropTypes.string,
  emitUpdatePageMetadata: PropTypes.func,
  emitShowOutOfViewOptions: PropTypes.func,
};

export default NavigationThirdLevel;
