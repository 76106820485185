import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import getAllTeasers from '@src/reduxStore/news/newsDataAccess';
import { newsCacheTime } from '@src/config/settings';
import { getSitemapItemFromSourceOrPath } from '@src/services/sitemapService';
import { buildTreeFromHref } from '@src/helpers/utils';
import { getUserAccess } from '@src/reduxStore/user/userSelectors';
import { stateMatch } from 'redux-awaitable-state';
import { getAngularService } from '@src/module/reactMigrationUtils/angular-react-helper';
import {
  selectStatus,
  selectLastTimeTeasersLoaded,
  selectAllTeasers,
  selectCurrentRelevantTeaserWithApplicabiltyLabels,
} from './newsSelectors';
import { selectProThemes } from '../content/contentSelectors';
import { getProThemesPaths } from '../content/contentHelpers';

export const initTeasers = createAsyncThunk('news/initTeasers', async () => getAllTeasers(), {
  condition: (param, { getState }) =>
    ['idle', 'failed'].includes(selectStatus(getState())) || // teasers not loaded
    new Date() - newsCacheTime * 60 * 1000 > selectLastTimeTeasersLoaded(getState()), // cache expired
});

const initialState = {
  teasers: [],
  status: 'idle',
  lastTimeTeasersLoaded: null,
  currentThemes: null /* this is used to filter the teasers related to those themes. 
   Null means we want all teasers, Empty array means we don't want any teaser */,
  currentTeaserOrNewsHref: null, // the page item we are showing
  currentThemePaths: [],
  extraNews: {}, // news items to be loaded when not found in teasers
};

const newsState = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setCurrentThemes: (state, action) => {
      const { selectedSubCurriculaHrefs, sReferenceFrame } = action.payload;
      if (Array.isArray(sReferenceFrame)) {
        // filter by subcurricula selector if present
        const filteredNewsThemes =
          selectedSubCurriculaHrefs?.length > 0
            ? sReferenceFrame?.filter((r) => selectedSubCurriculaHrefs.includes(r.$$meta.permalink))
            : sReferenceFrame;

        const navigationService = getAngularService('navigationService');
        state.currentThemes = filteredNewsThemes.flatMap((theme) =>
          theme.type === 'SECTION'
            ? navigationService.getRecursiveThemes(theme)
            : navigationService.getThemesAndRecursiveChildren([theme.$$meta.permalink])
        );
      } else {
        state.currentThemes = null;
      }
    },
    setCurrentTeaserOrNewsHref: (state, action) => {
      state.currentTeaserOrNewsHref = action.payload;
    },
    setCurrentThemePaths: (state, action) => {
      state.currentThemePaths = action.payload;
    },
    addExtraNews: (state, action) => {
      state.extraNews[action.payload.href] = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(initTeasers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(initTeasers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.teasers = action.payload;
        state.lastTimeTeasersLoaded = new Date();
      })
      .addCase(initTeasers.rejected, (state, action) => {
        state.status = 'failed';
        console.error(action.error.message);
      });
  },
});

export const loadThemePaths = () => async (dispatch, getState) => {
  await stateMatch(
    (state) =>
      selectCurrentRelevantTeaserWithApplicabiltyLabels(state) && selectProThemes(state).length
  );

  const rootTeaser = selectCurrentRelevantTeaserWithApplicabiltyLabels(getState());
  if (rootTeaser?.themes?.length) {
    // we find the proThemes related to the rootTeaser themes
    const proThemeMatches = selectProThemes(getState()).filter((proTheme) =>
      proTheme.themes?.some((themeHref) => rootTeaser?.themes.includes(themeHref))
    );
    // then we get the paths of those using the sitemap
    const themePaths = getProThemesPaths(proThemeMatches);
    dispatch(newsState.actions.setCurrentThemePaths(themePaths));
  }
};

export const loadCurrentTeaserOrNews = (hrefOrPath) => async (dispatch, getState) => {
  dispatch(initTeasers());
  await stateMatch(
    (state) => getUserAccess(state)?.length > 0 && selectStatus(state) === 'succeeded'
  );
  let href = hrefOrPath;

  if (!hrefOrPath.startsWith('/content')) {
    // if this is used we should call the sitemap to find the path provided and
    // return the source of that sitemap item
    const sitemapElement = getSitemapItemFromSourceOrPath(hrefOrPath);
    href = sitemapElement.source;
    console.error('CODE REMOVED BECAUSE TOUGHT DEPRECATED');
  }

  const allTeasers = selectAllTeasers(getState());
  const userAccess = getUserAccess(getState());

  const teaser = allTeasers.find((e) => e.href === href);
  if (!teaser) {
    const newsToAdd = await buildTreeFromHref(href, userAccess);
    dispatch(newsState.actions.addExtraNews(newsToAdd));
  }
  dispatch(newsState.actions.setCurrentTeaserOrNewsHref(href));
};

export const newsActions = newsState.actions;
export default newsState;
