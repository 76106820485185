import * as settings from '@src/config/settings';
import { getAngularService } from '@src/module/reactMigrationUtils/angular-react-helper';
import { getAPISearchResults, getHrefDescendantsByType } from './contentDataAccess';

/**
 * This function returns the elements needed to render the PRO homepage
 * @returns {Promise<{Array<*>}>}
 */
export const getHomePageItems = async () =>
  getHrefDescendantsByType(settings.proHomepageHref, [
    'STRUCTURED_DOCUMENT',
    'SECTION',
    'ATTACHMENT',
  ]);

/**
 * This function returns the paths from the sitemap for a given proThemes
 * @param {Array.<string>} proThemes
 * @returns {Array.<{title: string, path: string}>}
 */
export const getProThemesPaths = (proThemes) => {
  const routerService = getAngularService('routerService');

  return proThemes
    .map((proTheme) => {
      const webconfig = routerService.getWebpageInfoFromHref(proTheme.$$meta.permalink);
      if (!webconfig || (webconfig.isPublished && webconfig.isPublished !== true)) {
        return null;
      }
      return {
        title: proTheme.title,
        path: (webconfig && webconfig.path) || proTheme.$$meta.permalink,
      };
    })
    .filter(Boolean);
};

/**
 * This function returns the facets for different types of pages sorted by "readorder". The facets, at the end of the day, are "categories" that the user can click on them to filter the page.
 * @param {string} contentHref
 * @returns {Promise<{Array<*>}>}
 */
export const getFacetCategories = async (contentHref) => {
  const referenceFrameDescendants = await getHrefDescendantsByType(contentHref, [
    'REFERENCE_FRAME',
    'THEME',
    'THEME_GROUP',
  ]);

  const referenceFrame = referenceFrameDescendants.find(
    (descendant) => descendant.type === 'REFERENCE_FRAME'
  );

  return referenceFrame.$$relationsTo
    .sort((a, b) => a.$$expanded.readorder - b.$$expanded.readorder)
    .reduce((categories, relTo) => {
      const category = referenceFrameDescendants.find(
        (descendant) => descendant.$$meta.permalink === relTo.$$expanded.from.href
      );
      if (category) categories.push(category);
      return categories;
    }, []);
};

/**
 * This function is used to get all the results available on the search-api for a given searchParams. It will start for the first page and call all the following ones returning a merge of all the responses.
 * @param {*} searchParameters
 * @param {string | undefined} nextLink
 * @returns {Promise<{results: Array.<*>, $$meta: {count: number}}}
 */
export const getAPIAllSearchResults = async (searchParameters, nextLink = undefined) => {
  const searchResults = await getAPISearchResults(searchParameters, nextLink);
  if (searchResults.$$meta.next) {
    const nextSearchResults = await getAPIAllSearchResults(null, searchResults.$$meta.next);
    return {
      ...nextSearchResults,
      results: [...searchResults.results, ...nextSearchResults.results],
    };
  }
  return searchResults;
};
