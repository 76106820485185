const settings = require('../config/settings.js');

module.exports = {
  init(app) {
    const fetchSriClient = require('@kathondvla/sri-client/fetch-sri-client');

    const api = fetchSriClient({
      baseUrl: settings.apisAndUrls.api,
    });

    const cachedApi = fetchSriClient({
      baseUrl: settings.apisAndUrls.cachedApi,
    });

    const contentApi = fetchSriClient({
      baseUrl: settings.apisAndUrls.contentApi,
    });

    const cachedContentApi = fetchSriClient({
      baseUrl: settings.apisAndUrls.cachedContentApi,
    });

    const cachedWebsitesApi = fetchSriClient({
      baseUrl: settings.apisAndUrls.cachedWebsitesApi,
    });

    const securityApi = fetchSriClient({
      baseUrl: settings.apisAndUrls.securityApi,
    });

    const websitesApi = fetchSriClient({
      baseUrl: settings.apisAndUrls.contentApi,
    });

    const oauthApi = fetchSriClient({
      baseUrl: settings.oauth.oauthURL,
    });

    const privateStatesApi = fetchSriClient({
      baseUrl: settings.apisAndUrls.privateStatesApi,
    });

    const searchApi = fetchSriClient({
      baseUrl: settings.apisAndUrls.searchApi,
    });

    app.constant('api', api);
    app.constant('cachedApi', cachedApi);
    app.constant('contentApi', contentApi);
    app.constant('cachedContentApi', cachedContentApi);
    app.constant('cachedWebsitesApi', cachedWebsitesApi);
    app.constant('websitesApi', websitesApi);
    app.constant('securityApi', securityApi);
    app.constant('oauthApi', oauthApi);
    app.constant('privateStatesApi', privateStatesApi);
    app.constant('searchApi', searchApi);
  },
};
