import React from 'react';
import { Compiler } from '@kathondvla/react-shared-components/src';
import getCompilerOptions from '@components/content/helpers/sanitizeOptions';

const FaqParagraph = ({ content }) => (
  <div style={{ lineHeight: '28px', marginBottom: '28px' }}>
    <Compiler text={content.text} options={getCompilerOptions()} />
  </div>
);

export default FaqParagraph;
