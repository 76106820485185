import { pickBy, isEmpty, isNumber } from 'lodash-es';
import { getAngularService } from 'NgMigrationUtils/angular-react-helper';
import * as settings from '@src/config/settings';
import * as contentUtils from '@src/app/contentUtils';
import { getHrefDescendantsByType } from '@src/reduxStore/content/contentDataAccess';

export const cleanUpObjectEmptyValues = (obj) =>
  pickBy(obj, (filter) => !isEmpty(filter) || isNumber(filter));

export const generateHashForScrollTo = (key) => {
  // TODO: Migrate hashing function directly to https://github.com/satazor/js-spark-md5 or similar
  const routerService = getAngularService('routerService');
  return routerService.generateHashForScrollTo(key);
};

export const clearUrlLocationHash = () => {
  /* Set the hash to a dummy hash which doesn't hit any id at your page
  Empty string causes the page to scroll to the top */
  const noHashURL = window.location.href.replace(/#.*$/, '#_');
  window.history.pushState(null, null, noHashURL);
};

export const goToPath = (path) => {
  window.location.href = path;
};

/** Convert a date into a string according to a given format  */
export const dateToString = (
  date,
  format = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'short',
  }
) => new Date(date).toLocaleDateString('nl-BE', format);

export const getPageFromHref = (href) => {
  const routerService = getAngularService('routerService');
  return routerService.getWebpageInfoFromHref(href);
};

export const removeHtmlTags = (stringWithHtml) =>
  stringWithHtml ? $('<div/>').html(stringWithHtml).text() : null;

export const getRootElement = (item) => (item.level !== 0 ? getRootElement(item.parent) : item);

// move these two functions somewhere more sensible after angular is removed
function addItems(item, items, $$relationsTo, level) {
  const isPartOfRelations = $$relationsTo.filter((o) =>
    ['IS_PART_OF', 'IS_INCLUDED_IN'].includes(o.$$expanded.relationtype)
  );
  if (isPartOfRelations.length > 0) {
    item.children = [];
    isPartOfRelations.forEach((relation) => {
      const foundItem = items.find((o) => o.$$meta.permalink === relation.$$expanded.from.href);
      if (foundItem) {
        const newsItem = {
          level,
          parent: { ...item, children: [] }, // avoid circular references
          href: foundItem.$$meta.permalink,
          $$html: foundItem.$$html ? foundItem.$$html : null,
          key: foundItem.key ? foundItem.key : null,
          readOrder: relation.$$expanded.readorder,
          type: foundItem.type ? foundItem.type : null,
          title: foundItem.title ? foundItem.title : null,
          shortDescription: foundItem.shortdescription ? foundItem.shortdescription : null,
          description: foundItem.description ? foundItem.description : null,
          identifier:
            foundItem.identifiers && foundItem.identifiers.length > 0
              ? foundItem.identifiers[0]
              : null,
          coverImage: contentUtils.getImageWithSize(
            foundItem,
            'COVER_IMAGE',
            { w: 2000, h: 1600 },
            item.coverImage
          ),
          theme: foundItem.themes && foundItem.themes.length > 0 ? foundItem.themes : null,
          thumbImage: contentUtils.getImage(foundItem, 'THUMBNAIL'),
          thumbImageM: contentUtils.getImageWithSize(foundItem, 'THUMBNAIL', { w: 500, h: 360 }),
          thumbImageS: contentUtils.getImageWithSize(foundItem, 'THUMBNAIL', { w: 100, h: 100 }),
          image: contentUtils.getImage(foundItem, 'ILLUSTRATION'),
          imageS: contentUtils.getImageWithSize(foundItem, 'ILLUSTRATION', { w: 300 }),
          imageM: contentUtils.getImageWithSize(foundItem, 'ILLUSTRATION', { w: 800, h: 1000 }),
          videoPlayback: contentUtils.calcVideoPlayback(foundItem),
          attachment: contentUtils.getAttachment(foundItem, ['ATTACHMENT', 'CONTENT']),
          references: contentUtils.addReferences(foundItem),
          requirements: contentUtils.addRequirements(foundItem),
          links: contentUtils.addReferences(foundItem),
          importance: foundItem.importance ? foundItem.importance : 'MEDIUM',
          created: foundItem.$$meta.created,
          issued: foundItem.issued,
          visible: true,
        };

        if (foundItem.$$relationsTo.length > 0) {
          addItems(newsItem, items, foundItem.$$relationsTo, level + 1);
        }
        item.children.push(newsItem);
      } else {
        // console.warn('Item not found: ' + relation.$$expanded.from.href);
      }
    });
    item.children.sort((a, b) => a.readOrder - b.readOrder);
  }
}

export const buildTreeFromItemsList = (href, allItems) => {
  let TREE;
  try {
    const root = allItems.find((o) => o.$$meta.permalink === href);
    TREE = {
      $$meta: root.$$meta,
      href: root.$$meta.permalink,
      key: root.key,
      title: root.title,
      tags: root.tags,
      // alert, redundant data.
      // I introduced this while working on leerplansites where for SHARED_MINI_DATABASE_ITEMS we don't have a theme as "referenceItem"
      // but we do for "MINI_DATABASE_ITEMS" and we need to re-use A LOT of components that now should work receiving a theme or another type of
      // item as referenceItem.
      // So, pageType is being used in a lot of places so I need to do this to avoid a bigger refactor
      pageType: root.type,
      themes: root.themes, // in the same "SHARED_MINI_DATABASE_ITEMS" changes context we need themes property to be at root level
      type: root.type,
      attachments: root.attachments,
      // issued: (root.issued && (new Date(root.issued) < new Date())),
      description: root.description,
      root: {
        // TODO: put real information
        // color: '#B72A8D',
        color: settings.defaultColor,
        icon: 'https://cached-api.katholiekonderwijs.vlaanderen/content/35f254cc-4437-4862-bdd7-f555172cfdec/veldicoontjes-02.svg?bf05a5b1-753d-3d6a-3df5-6f5cf1dc2aa4',
        title: root.title,
      },
      references: contentUtils.addReferences(root),
      requirements: contentUtils.addRequirements(root),
      issued: root.$$meta.created,
      children: [],
      coverImage: contentUtils.getImageWithSize(root, 'COVER_IMAGE', { w: 2000, h: 1600 }),
      thumbImage: contentUtils.getImage(root, 'THUMBNAIL'),
      coverage: root.coverage,
      mainstructures: root.mainstructures,
      outypes: root.outypes,
      visible: true,
      level: 0,
      $$relationsTo: root.$$relationsTo,
    };

    addItems(TREE, allItems, root.$$relationsTo, 1);
  } catch (e) {
    console.error(e);
  }
  return TREE;
};

export const buildTreeFromHref = async (href, userAccess) => {
  const items = await getHrefDescendantsByType(href, [], userAccess);
  return buildTreeFromItemsList(href, items);
};

let debounceTimer;
export const debounce =
  (func) =>
  (...args) => {
    const context = this;
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    debounceTimer = setTimeout(() => {
      debounceTimer = null;
      func.apply(context, args);
    }, parseInt(settings.debounceTime, 10));
  };

export const isExternal = (item) => {
  if (item.references && item.references[0]) {
    return !item.references[0].startsWith('/content');
  }
  return false;
};

/**
 * In PRO sometimes we have links to PRO that are marked as "external" in redactie (this is an abuse of the functionality in redactie), so this function will tell PRO if a item link is external for real or not.
 * This code is a patch!! It should be removed as soon as we stop abusing redactie
 * @param {*} item
 * @returns
 */
export const isRealExternalLink = (item) => {
  if (item.references && item.references[0]) {
    const includesPro = item.references[0].includes('pro.katholiekonderwijs.vlaanderen');
    return isExternal(item) && !includesPro;
  }
  return false;
};

export const capitalizeFirstLetter = (input) => input.charAt(0).toUpperCase() + input.slice(1);

export const sortAlphabetically = (a, b) => {
  // The sort-method places uppercase letters before lowercase letters. Therefore, we compare convert to lowercase before sorting
  const aTitle = a.title.toLowerCase();
  const bTitle = b.title.toLowerCase();

  if (aTitle < bTitle) {
    return -1;
  }
  if (aTitle > bTitle) {
    return 1;
  }
  return 0;
};
