import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { KovContentBlockContext } from '@kathondvla/react-shared-components/src/components/contentBlock/KovContentBlockProvider';
import VideoItem from '@UI/video/VideoItem';

const ContentBlockVideo = ({ item }) => {
  const { customFuncs } = useContext(KovContentBlockContext);

  return <VideoItem item={item} openCookieModal={customFuncs.openCookieModal} />;
};

ContentBlockVideo.propTypes = {
  item: PropTypes.object,
};

export default ContentBlockVideo;
