import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { buildThemeItemList } from '@src/reduxStore/theme/themeDataAccess';
import { stateMatch } from 'redux-awaitable-state';
import { getUserAccess } from '@src/reduxStore/user/userSelectors';
import {
  selectTreeLoadingStatus,
  selectItemInCurrentThemeTree,
} from '@src/reduxStore/theme/themeSelectors';
import { getSitemapItemFromSourceOrPath } from '@src/services/sitemapService';
import transformContent from './transformContent';

export const initCurrentTheme = createAsyncThunk(
  'theme/initCurrentTheme',
  async (href, { dispatch, getState }) => {
    const themeRootHref = getSitemapItemFromSourceOrPath(href)?.rootHref;
    // eslint-disable-next-line no-use-before-define
    dispatch(themeSlice.actions.setCurrentTheme(themeRootHref));
    await stateMatch((state) => getUserAccess(state)?.length > 0);
    const userAccess = getUserAccess(getState());
    return buildThemeItemList(href, userAccess);
  },
  {
    condition: (href, { getState }) =>
      ['idle', 'failed'].includes(selectTreeLoadingStatus(getState())) || // themes not loaded
      !selectItemInCurrentThemeTree(getState(), href), // theme not found in store
  }
);

const initialState = {
  currentThemeHref: null,
  curricula: [],
  content: {},
  relations: [], // used to get the ancestors (breadcrumbs mostly I guess..)
  rawCurrentThemeItems: {},
  treeLoadingStatus: 'idle',
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setCurricula: (state, action) => {
      state.curricula = action.payload;
    },
    setCurrentTheme: (state, action) => {
      state.currentThemeHref = action.payload;
    },

    // This action stores the PRO referenceFrame data (fetched from content-api), augmented with extra information.
    updateAugmentedProReferenceFrame: (state, action) => {
      const [newContent, newRelations] = transformContent(
        action.payload.content,
        state.relations,
        action.payload.isProTheme || false
      );

      state.content = {
        ...state.content,
        ...newContent.reduce((map, cur) => {
          map[cur.href] = cur;
          return map;
        }, {}),
      };
      state.relations = [...state.relations, ...newRelations];
    },

    clearThemeData: (state) => {
      state.rawCurrentThemeItems = initialState.rawCurrentThemeItems;
      state.treeLoadingStatus = initialState.treeLoadingStatus;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(initCurrentTheme.pending, (state) => {
        state.treeLoadingStatus = 'loading';
      })
      .addCase(initCurrentTheme.fulfilled, (state, action) => {
        state.treeLoadingStatus = 'succeeded';
        state.rawCurrentThemeItems = action.payload;
      })
      .addCase(initCurrentTheme.rejected, (state, action) => {
        state.treeLoadingStatus = 'failed';
        console.error(action.error.message);
      });
  },
});

export const themeActions = themeSlice.actions;
export default themeSlice;
