import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import trackPiwikEvent from '@src/reduxStore/analytics/analyticsThunks';

require('./quickNavigationMobileSubMenu.scss');

const QuickNavigationMobileSubMenu = ({ item, selected, onSelect, closeMenu }) => {
  const dispatch = useDispatch();

  return (
    <div className="accordion-heading mobile-menu__niveau-wrapper">
      <a
        className={`mobile-menu__cluster-header ${selected ? 'collapsed' : ''}`}
        id={`${item.key}_Title`}
        data-toggle="collapse"
        data-target={`#${item.key}_collapse`}
        aria-expanded="false"
        aria-controls={`${item.key}_collapse`}
        onClick={() => onSelect(!selected ? item.key : null)}
        ref={(el) => {
          if (el) {
            el.style.setProperty('color', item.color, 'important');
          }
        }}
      >
        {item.title}
      </a>
      <div
        id={`${item.key}_collapse`}
        className={`${!selected ? 'collapse' : ''}`}
        aria-labelledby={`${item.key}_Title`}
        data-parent="#accordion"
      >
        <ul className="mobile-menu__theme">
          {item.children
            .filter((child) => child.visible)
            .map((child, index) => (
              <li
                key={index}
                className={`mobile-menu__theme-item ${
                  child.path === window.location.pathname ? 'mobile-menu__theme-item--active' : ''
                } ${child.importance === 'VERY_HIGH' ? 'mobile-menu__theme-item--favorite' : ''}`}
                ref={(el) => {
                  if (el) {
                    el.style.setProperty('color', child.color, 'important');
                  }
                }}
              >
                <a
                  href={child.path}
                  onClick={() => {
                    if (child.path.includes('/#!/nieuwsbrieven')) {
                      dispatch(trackPiwikEvent('start_newsletter'));
                    }
                    closeMenu();
                  }}
                  rel="noreferrer"
                  target={child.isPathExternal ? '_blank' : undefined}
                >
                  {child.title}
                </a>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

QuickNavigationMobileSubMenu.propTypes = {
  item: PropTypes.object,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  closeMenu: PropTypes.func,
};

export default QuickNavigationMobileSubMenu;
