const svgStyle = {
  width: '16px',
  height: '16px',
  verticalAlign: 'text-top',
  marginRight: '10px',
};

const customStyle = {
  indicatorsContainer: (provided) => ({
    ...provided,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0 4px 0 0',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 'unset',
    height: '100%',
    position: 'static',
    svg: svgStyle,
  }),
  menu: (provided) => ({
    ...provided,
    transform: 'translate3d(0px, -30px, 0px)',
    top: '0px',
    left: '0px',
    willChange: 'transform',
    background: 'none',
    border: 'none',
    overflow: 'inherit !important',
    position: 'absolute',
    width: 'max-content',
    '@media only screen and (max-width: 992px)': {
      maxWidth: '100%',
    },
  }),
  menuList: (provided) => ({
    ...provided,
    background: '#FFF',
    border: 'none',
    display: 'inline-table',
    padding: '15px 10px !important',
    width: '100%',
    left: '1px',
    textAlign: 'left',
    boxShadow: '0 5px 17px 0 rgb(0 0 0 / 20%)',
    overflowY: 'auto',
    svg: svgStyle,
  }),
  option: (provided, { isSelected, isFocused }) => ({
    ...provided,
    padding: '10px 15px',
    background: isFocused || isSelected ? 'rgba(213, 222, 228, 0.2)' : '',
    borderBottom: '1px solid #E7E7E8',
    color: isSelected ? 'black' : '#34495E',
    fontFamily: 'Roboto Condensed',
    fontWeight: isSelected ? '400' : '300',
    fontSize: '14px',
    transition: 'all .2s',
    display: 'flex',
  }),
  container: (provided) => ({
    ...provided,
    minHeight: '35px',
    margin: '7px 0',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#34495E',
    fontSize: '14px',
    fontWeight: 400,
  }),
  control: (provided) => ({
    ...provided,
    paddingLeft: '10px',
    minHeight: '30px',
  }),
  singleValue: (provided) => ({
    ...provided,
    whiteSpace: 'wrap',
    position: 'static',
    top: 0,
    transform: 'none',
    padding: '4px 0',
    display: 'flex',
    letterSpacing: 'normal',
  }),
  input: (provided) => ({
    ...provided,
    position: 'absolute',
  }),
};

export default customStyle;
