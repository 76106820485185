const { default: analyticsState } = require('@src/reduxStore/analytics/analyticsSlice');

class ws500 {
  constructor($scope, kovSentry) {
    'ngInject';
    this.$scope = $scope;
    this.kovSentry = kovSentry;
  }

  $onInit() {
    this.$scope.$emit('dynamicMetaDataUpdated', {
      title: '500',
      description: null,
    });
    this.kovSentry.endLog('load Pro');
  }
}

module.exports = {
  template: require('./ws500.html'),
  controllerAs: 'ctrl',
  controller: ws500,
  bindings: {},
};
