import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import themeSelectors from '@src/reduxStore/theme/themeSelectors';
import { getAngularService } from '@src/module/reactMigrationUtils/angular-react-helper';

const ThemeDetailMenu = ({ sItems, sColor, sCurrent }) => {
  const navigationService = getAngularService('navigationService');
  const elementRef = useRef(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [maxItems, setMaxItems] = useState();
  const [hoveredItem, setHoveredItem] = useState('');
  const subCurriculaHrefs = useSelector(themeSelectors.getCurricula);

  const styleTag = `.kov-pro-theme-nav ul li:after {border-color: ${sColor} !important;}   .kov-pro-theme-nav .dropdown-item:hover {border-bottom-color: ${sColor}!important }`;
  const page = sItems && sItems.find((o) => o.href === sCurrent);

  const activeInMore = () => sItems.slice(maxItems, sItems.length).some((o) => o.href === sCurrent);

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    const calculateMaxItems = (width) => {
      if (sItems) {
        let totalWidth = 80; // padding + menu
        let i = 0;

        while (sItems.length > i) {
          if (sItems[i].title) {
            const titleChars = sItems[i].title.length;
            totalWidth += titleChars * 7; // max width of 35 characters
          }
          totalWidth += 48; // padding

          if (totalWidth > width) {
            break;
          }

          i += 1;
        }

        return i;
      }
      return 0;
    };

    const getWidthAndCalculate = () => {
      if (elementRef.current) {
        setMaxItems(calculateMaxItems(elementRef.current.clientWidth));
      }
    };
    window.addEventListener('resize', getWidthAndCalculate, { passive: true });
    if (!maxItems) {
      getWidthAndCalculate();
    }

    // clean up
    return () => {
      window.removeEventListener('resize', getWidthAndCalculate);
    };
  }, [sItems, maxItems]);

  if (!sItems || !sItems.length) {
    return null;
  }

  return (
    <>
      <div className="kov-pro-theme-nav-wrapper" style={{ border: 'none' }}>
        <div className="kov-pro-theme-nav" style={{ width: '100%' }} ref={elementRef}>
          <ul className="kov-pro-theme-nav-list">
            {(sItems.length > maxItems ? sItems.slice(0, maxItems) : sItems).map((item, index) => (
              <li
                key={index}
                className={`kov-pro-theme-nav-list-item${item.href === sCurrent ? ' active' : ''}${
                  item.isPathExternal ? ' external' : ''
                }`}
                style={{ borderBottomColor: sColor }}
              >
                <a
                  title={item.title}
                  style={{ color: item.href === sCurrent ? sColor : '' }}
                  href={navigationService.getPathWithSubCurricula(item, subCurriculaHrefs)}
                  target={item.isPathExternal ? '_blank' : undefined}
                  rel="noreferrer"
                >
                  {item.title}
                </a>
              </li>
            ))}
            {sItems.length > maxItems && (
              <li className={`kov-pro-theme-nav-list-item dropdown show kov-pro-menu-more`}>
                <a
                  role="button"
                  id="dropdownMenuLink"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => setOpenDropdown(!openDropdown)}
                >
                  <i
                    className="icon-kov-pro-popover-menu"
                    style={{ color: activeInMore() ? sColor : '' }}
                  />
                </a>
                <div
                  className={`dropdown-menu ${openDropdown ? 'show' : ''}`}
                  aria-labelledby="dropdownMenuLink"
                >
                  {sItems.slice(maxItems).map((item, index) => (
                    <a
                      key={index}
                      title={item.title}
                      href={item.pagePath}
                      target={item.isPathExternal ? '_blank' : undefined}
                      style={{
                        color: item.href === sCurrent ? sColor : '',
                        padding: '5px 10px',
                      }}
                      rel="noreferrer"
                      onMouseOver={() => setHoveredItem(item.key)}
                      onMouseOut={() => setHoveredItem('')}
                      onClick={() => setOpenDropdown(false)}
                    >
                      <span>{item.title}</span>
                      <div
                        style={{
                          display: 'block',
                          content: '',
                          borderBottom: `1px solid ${sColor}`,
                          transform: hoveredItem !== item.key ? 'scaleX(0)' : 'unset',
                          transition: 'transform 250ms ease-in-out',
                          position: 'relative',
                          bottom: '-3px',
                        }}
                      />
                    </a>
                  ))}
                </div>
              </li>
            )}
          </ul>
        </div>

        <div className="kov-pro-theme-nav-mobile d-block d-md-none">
          <div className={`btn-group bootstrap-select ${openDropdown ? 'show' : ''}`}>
            <button
              type="button"
              className="btn dropdown-toggle btn-default"
              role="button"
              aria-expanded="false"
              onClick={() => setOpenDropdown(!openDropdown)}
            >
              <span className="filter-option pull-left">{page && page.title}</span>&nbsp;
              <span className="bs-caret" />
              <span className="caret" />
            </button>
            <div
              className={`dropdown-menu open ${openDropdown ? 'show' : ''}`}
              role="combobox"
              style={{
                maxHeight: '365px',
                overflow: 'hidden',
                minHeight: '13px',
                position: 'absolute',
                transform: 'translate3d(0px, 38px, 0px)',
                top: 0,
                left: 0,
                willChange: 'transform',
              }}
            >
              <ul
                className="dropdown-menu inner"
                role="listbox"
                aria-expanded="false"
                style={{ maxHeight: '247px', overflowY: 'auto', minHeight: '1px' }}
              >
                {sItems.map((item, index) => (
                  <li
                    key={index}
                    className={`${item.href === sCurrent ? 'selected' : ''} ${
                      item.isPathExternal ? 'external' : ''
                    }`}
                  >
                    <a
                      href={item.pagePath}
                      target={item.isPathExternal ? '_blank' : undefined}
                      rel="noreferrer"
                      className={item.isPathExternal ? 'external' : ''}
                    >
                      {' '}
                      <span className="text">{item.title}</span>
                      <span className="glyphicon glyphicon-ok check-mark" />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <style dangerouslySetInnerHTML={{ __html: styleTag }} />
      </div>
    </>
  );
};

ThemeDetailMenu.propTypes = {
  sItems: PropTypes.array,
  sColor: PropTypes.string,
  sCurrent: PropTypes.string,
};

export default ThemeDetailMenu;
