import { HOMEPAGE_REFERENCES } from '@src/components/constants';
import store from '../store';
import { selectItemChannelTheme } from '../navigation/navigationSelectors';

/**
 * Method to get the ancestorpath of any menu item or pro theme
 * The hard part is that pro themes can be referenced by multiple menu items so in that case there are multiple ancestor paths
 * @returns
 * if contentHref is a menu item => an ancestor path (just an array) to the root of the pro home page
 * if contentHref is a pro theme => an array or ancestor paths
 */
const getAncestorPaths = (state, contentHref, ancestorArray = []) => {
  const content = state.theme.content[contentHref];
  if (content.type === 'PRO_THEME') {
    const menuRels = state.theme.relations.filter(
      (rel) => rel.to === contentHref && rel.type === 'REFERENCES'
    );
    return menuRels
      .filter((rel) => state.theme.content[rel.from]) // there could be references from outside the main navigation structure (for example themes in the picture witin the global training database page)
      .map((rel) => getAncestorPaths(state, rel.from));
  }
  const parentRel = state.theme.relations.find(
    (rel) => rel.from === contentHref && rel.type === 'IS_PART_OF'
  );
  if (!parentRel) return ancestorArray;
  return getAncestorPaths(state, parentRel.to, [parentRel.to, ...ancestorArray]);
};

/**
 * @returns an array of arrays, an array of ancestorpaths up to the root of the homepage
 */
const getAncestors = (contentHref) => {
  const ancestorPaths = getAncestorPaths(store.getState(), contentHref);
  if (ancestorPaths[0] && !Array.isArray(ancestorPaths[0])) return [ancestorPaths]; // make the result of getAncestors always an array of arrays
  return ancestorPaths;
};

/**
 * Returns all ancestor paths that are within the navigation of the pro menu and filters out the part above the channels
 * @returns an array of arrays, an unordered array of ancestorpaths up the channel ordered form channel to lower in de tree
 */
export const getAncestorPathsToChannel = (contentHref) =>
  getAncestors(contentHref)
    .filter((ancestorPath) => ancestorPath.includes(HOMEPAGE_REFERENCES.MENU_ITEMS))
    .map((ancestorPath) =>
      ancestorPath.slice(ancestorPath.indexOf(HOMEPAGE_REFERENCES.MENU_ITEMS) + 1)
    );

/**
 * method to get the icon of a cluster
 * they don't have icon's themselves, the icon can be found on the theme that is linked to the channel
 * @param {*} menuItemHref
 */
export const getIconForCluster = (menuItemHref) => {
  const menutItemTheme = selectItemChannelTheme(store.getState(), menuItemHref);
  if (!menutItemTheme) return null;

  const iconAttachment = menutItemTheme.attachments.find((att) => att.type === 'ICON');
  if (!iconAttachment) return null;
  return { href: iconAttachment.href, color: menutItemTheme.color };
};
