import { OrderMethods } from '@components/database/constants';
import * as settings from '@src/config/settings';

export const sortResults = (filters) => (a, b) => {
  if (filters.order === OrderMethods.OLDEST) {
    return new Date(a.created) - new Date(b.created);
  }
  if (filters.order === OrderMethods.NEWEST) {
    return new Date(b.created) - new Date(a.created);
  }
  return 0;
};

export const transformDatabaseSearchResults = (res, viewOptions) => {
  const thumbPhoto = res.thumbnails
    ? res.thumbnails.find(
        (thumb) => parseInt(thumb.width, 10) === 100 && parseInt(thumb.height, 10) === 100
      )
    : null;

  const REGIONTAGS = viewOptions.REGIONS.filter((reg) => res.coverage.includes(reg.href)).map(
    (reg) => reg.tag
  );

  const EDUCATIONLEVELTAGS = viewOptions.EDUCATION_LEVELS.filter((edu) =>
    res.mainstructuresOuTypeCombinations
      .map((MouTypes) => MouTypes.permalink)
      .includes(edu.mainstructuresOuTypeCombination)
  ).map((edu) => edu.tag);

  const requirements = [...new Set(res.facets.references.map((ref) => ref.href))];
  return {
    title: res.title,
    thumbImageS: thumbPhoto ? { href: settings.apisAndUrls.cachedApi + thumbPhoto.href } : null,
    pagePath: res.webInfo?.path || res.$$meta.permalink,
    description: res.description,
    regionTags: REGIONTAGS,
    requirements,
    isShared: res.contentType === 'SHARED_MINI_DATABASE_ITEM',
    levelTags: EDUCATIONLEVELTAGS,
    tags: REGIONTAGS.concat(EDUCATIONLEVELTAGS),
    key: res.key,
  };
};

/**
 * In the Database component the sorting is changed automatically (in a dynamic way; meaning we don't update the state or the url) when the user uses the search-query filter without applying any previous change to the order filter
 * @param {*} order
 * @param {*} searchTerm
 * @returns
 */
export const getOrderMethod = (order, searchTerm) => {
  if (!order) {
    // if the order is null (default value) we check if the user introduced a search-query
    if (searchTerm && searchTerm.length > 0) {
      return OrderMethods.RELEVANCE;
    }
    return OrderMethods.NEWEST;
  }
  // this means the user changed the sort filter at some point, we just return the same sort value we have
  return order;
};
