import React, { useEffect, useState, useCallback } from 'react';

const offset = 50;
const BackToTop = () => {
  const [isActive, setIsActive] = useState(false);
  const [y, setY] = useState(window.scrollY);
  const [totalLength, setTotalLength] = useState(0);
  const [currentOffset, setCurrentOffset] = useState(0);
  const pathStyles = {
    transition: 'stroke-dashoffset 10ms linear',
    WebkitTransition: 'stroke-dashoffset 10ms linear',
    strokeDasharray: `${totalLength} ${totalLength}`,
    strokeDashoffset: currentOffset,
  };

  const handleScroll = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > offset) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }

      const height = document.documentElement.scrollHeight - window.innerHeight;
      setCurrentOffset(`${totalLength - (window.scrollY * totalLength) / height}`);
      setY(window.scrollY);
    },
    [y, totalLength]
  );

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    const svg = document.querySelector('.progress-wrap path');
    setTotalLength(svg.getTotalLength());
    setCurrentOffset(svg.getTotalLength());
  }, []);
  return (
    <div onClick={scrollToTop} className={`progress-wrap ${isActive && 'active-progress'}`}>
      <svg
        className="progress-circle svg-content"
        width="100%"
        height="100%"
        viewBox="-1 -1 102 102"
      >
        <path style={pathStyles} d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
      </svg>
    </div>
  );
};
export default BackToTop;
