import React, { useState, useEffect, useRef } from 'react';
import * as settings from '@src/config/settings';
import trackPiwikEvent from '@src/reduxStore/analytics/analyticsThunks';
import { useDispatch } from 'react-redux';

const ThemeContactCard = ({ contact, disableHover = false }) => {
  const ref = useRef();
  const [openDropdown, setOpenDropdown] = useState(false);
  const dispatch = useDispatch();

  const openMail = () => {
    dispatch(
      trackPiwikEvent('contact_staff', {
        contact_method: 'send_email',
        contact_role: contact?.responsibilities || undefined,
      })
    );
    window.location.href = `mailto:${contact.email}`;
    setOpenDropdown(false);
  };

  const copy = () => {
    dispatch(
      trackPiwikEvent('contact_staff', {
        contact_method: 'copy_email',
        contact_role: contact?.responsibilities || undefined,
      })
    );
    navigator.clipboard.writeText(contact.email);
    setOpenDropdown(false);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (openDropdown && ref.current && !ref.current.contains(e.target)) {
        setOpenDropdown(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [openDropdown]);

  return (
    <a className={`kov-pro-contact ${disableHover && 'kov-pro-contact-no-hover'}`}>
      <div
        className="kov-pro-contact__img"
        style={{
          background:
            contact.pictureHref && `url('${settings.apisAndUrls.api + contact.pictureHref}')`,
        }}
      />
      <div className="kov-pro-contact__info">
        <div>
          <div className="kov-pro-contact__name">
            <span className="kov-pro-contact__name-first">{contact.firstName}</span>{' '}
            <span className="kov-pro-contact__name-last">{contact.lastName}</span>
          </div>
          <div className="kov-pro-contact__role">{contact.responsibilities}</div>
        </div>

        <div>
          <div className="kov-pro-contact__phone">
            {contact.phones &&
              contact.phones.map((phone) => (
                <div key={phone} className="kov-pro-contact__phone-primary">
                  {phone}
                </div>
              ))}
          </div>

          <div className="contact-buttons d-flex">
            {contact.email && (
              <div className="dropdown" ref={ref}>
                <button
                  className="kov-pro-btn kov-pro-btn--dark kov-pro-btn--inverted kov-pro-btn__icon--right kov-pro-btn--dropdown dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => setOpenDropdown(!openDropdown)}
                >
                  Mail <i className="icon-kov-pro-down-arrow" />
                </button>
                <div
                  className={`dropdown-menu ${openDropdown && 'show'}`}
                  aria-labelledby="dropdownMenuButton"
                >
                  <button
                    type="button"
                    onClick={openMail}
                    className="kov-pro-btn kov-pro-btn--block kov-pro-btn--light kov-pro-btn--inverted kov-pro-btn__icon--right"
                  >
                    <span>stuur mail</span>
                    <i className="icon-kov-pro-newsletter" />
                  </button>
                  <button
                    type="button"
                    onClick={copy}
                    className="kov-pro-btn kov-pro-btn--block kov-pro-btn--light kov-pro-btn--inverted kov-pro-btn__icon--right"
                  >
                    <span>kopieer e-mailadres</span>
                    <i className="icon-kov-pro-copy" />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </a>
  );
};

export default ThemeContactCard;
