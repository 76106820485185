import ReduxStoreProvider from '@src/reduxStore/StoreProvider';
import GlobalSearch from '@src/reactComponents/globalSearch/globalSearch';
import GlobalTrainings from '@src/reactComponents/trainings/globalTrainings/globalTrainings';
import LocalTrainings from '@src/reactComponents/trainings/localTrainings/localTrainings';
import QuickLinks from '@UI/homepage/quicklinks/quicklinks';
import SpotlightThemes from '@UI/homepage/spotlightThemes/spotlightThemes';

import Footer from '@src/reactComponents/sections/footer/footer';

import EntryFlows from '@src/reactComponents/entryFlows/entryFlows/entryFlow';
import CookiesEntryFlow from '@src/reactComponents/entryFlows/cookiesEntryFlow/cookiesEntryFlow';
import QuickNavigation from '@src/reactComponents/navigation/quickNavigation/quickNavigation';
import NavigationFirstLevel from '@src/reactComponents/navigation/slowNavigation/navigationFirstLevel/navigationFirstLevel';
import NavigationSecondLevel from '@src/reactComponents/navigation/slowNavigation/navigationSecondLevel/navigationSecondLevel';
import NavigationThirdLevel from '@src/reactComponents/navigation/slowNavigation/navigationThirdLevel/navigationThirdLevel';
import VakkenPagina from '@src/reactComponents/vakkenPagina/vakkenPagina';
import NotLoggedInPage from '@src/reactComponents/secureAccess/restrictedAccessPages/NotLoggedInPage';
import NotPermittedPage from '@src/reactComponents/secureAccess/restrictedAccessPages/NotPermittedPage';

import Sidebar from '@src/reactComponents/sections/sidebar/sidebar';

import Header from '@src/reactComponents/sections/header/header';

import Banner from '@src/reactComponents/sections/banner/banner';
import BackToTop from '@UI/backToTop/backToTop';
import Notification from '@UI/notification/notification';
import NewsSection from '@src/reactComponents/theme/newsSection/newsSection';
import ApplicabilityLabels from '@UI/applicabilityLabels/ApplicabilityLabels';

import ThemeCard from '@src/reactComponents/theme/themeCard/themeCard';
import ThemeHome1 from '@src/reactComponents/theme/themeHome/themeHome1';
import ThemeHome2 from '@src/reactComponents/theme/themeHome/themeHome2';
import ThemeHome3 from '@src/reactComponents/theme/themeHome/themeHome3';
import ThemeFaq from '@src/reactComponents/theme/themeFaq/themeFaq';
import DatabaseItem from '@components/database/DatabaseItem';

import NewsResults from '@components/news/newsResults/newsResults';
import NewsFilters from '@src/reactComponents/news/newsFilters/newsFilters';
import NewsDatabase from '@components/news/NewsDatabase';
import NewsItemDetail from '@components/news/newsItemDetail/newsItemDetail';

import ThemeText from '@src/reactComponents/theme/themeText/themeText';
import ThemeBlogItems from '@components/theme/themeBlog/themeBlogItems/themeBlogItems';
import ThemeBlog from '@components/theme/themeBlog/themeBlog';
import ThemeDetail from '@components/theme/themeDetail/themeDetail';
import ThemeTemp from '@components/theme/themeTemp/themeTemp';
import ThemeStatic from '@components/theme/themeStatic/themeStatic';

import NoResultsPlaceholder from '@UI/noResultsPlaceholder/noResultsPlaceholder';

import ThemeBlogCategories from '@src/reactComponents/theme/themeBlog/themeBlogFilters/themeBlogCategories/themeBlogCategories';
import ThemeBlogItemDetails from '@src/reactComponents/theme/themeBlog/themeBlogItemDetail/themeBlogItemDetails';
import ThemeAbout from '@src/reactComponents/theme/themeAbout/themeAbout';
import ThemeDownload from '@src/reactComponents/theme/themeDownload/themeDownload';
import Database from '@components/database/Database';
import { reactToAngularComponent } from './angular-react-helper';
import app from '../../app/app';

app.component('reactGlobalSearch', reactToAngularComponent(GlobalSearch, ReduxStoreProvider));
app.component('reactGlobalTrainings', reactToAngularComponent(GlobalTrainings, ReduxStoreProvider));
app.component('reactLocalTrainings', reactToAngularComponent(LocalTrainings, ReduxStoreProvider));

// homepage components
app.component('reactQuickLinks', reactToAngularComponent(QuickLinks, ReduxStoreProvider));
app.component('reactSpotlightThemes', reactToAngularComponent(SpotlightThemes, ReduxStoreProvider));

app.component('reactFooter', reactToAngularComponent(Footer, ReduxStoreProvider));
app.component('reactQuickNavigation', reactToAngularComponent(QuickNavigation));

app.component('reactEntryFlows', reactToAngularComponent(EntryFlows, ReduxStoreProvider));
app.component('reactCookiesFlow', reactToAngularComponent(CookiesEntryFlow));
app.component(
  'reactNavigationFirstLevel',
  reactToAngularComponent(NavigationFirstLevel, ReduxStoreProvider)
);
app.component(
  'reactNavigationSecondLevel',
  reactToAngularComponent(NavigationSecondLevel, ReduxStoreProvider)
);
app.component(
  'reactNavigationThirdLevel',
  reactToAngularComponent(NavigationThirdLevel, ReduxStoreProvider)
);
app.component('reactNotLoggedIn', reactToAngularComponent(NotLoggedInPage, ReduxStoreProvider));
app.component('reactNotPermitted', reactToAngularComponent(NotPermittedPage, ReduxStoreProvider));

app.component('reactVakkenPagina', reactToAngularComponent(VakkenPagina, ReduxStoreProvider));

app.component('reactSidebar', reactToAngularComponent(Sidebar, ReduxStoreProvider));

app.component('reactHeader', reactToAngularComponent(Header, ReduxStoreProvider));

app.component('reactBackToTop', reactToAngularComponent(BackToTop));
app.component('reactBanner', reactToAngularComponent(Banner, ReduxStoreProvider));
app.component('reactNotification', reactToAngularComponent(Notification));
app.component('reactNewsSection', reactToAngularComponent(NewsSection, ReduxStoreProvider));
app.component('reactApplicabilityLabels', reactToAngularComponent(ApplicabilityLabels));

/** news db pages */
app.component('reactNewsResults', reactToAngularComponent(NewsResults));
app.component('reactNewsFilters', reactToAngularComponent(NewsFilters));
app.component('reactNewsDatabank', reactToAngularComponent(NewsDatabase, ReduxStoreProvider));
app.component('reactNewsItemDetail', reactToAngularComponent(NewsItemDetail, ReduxStoreProvider));

app.component('reactThemeCard', reactToAngularComponent(ThemeCard));

app.component('reactThemeHome1', reactToAngularComponent(ThemeHome1, ReduxStoreProvider));
app.component('reactThemeHome2', reactToAngularComponent(ThemeHome2, ReduxStoreProvider));
app.component('reactThemeHome3', reactToAngularComponent(ThemeHome3, ReduxStoreProvider));
app.component('reactThemeFaq', reactToAngularComponent(ThemeFaq, ReduxStoreProvider));
app.component('reactThemeTemp', reactToAngularComponent(ThemeTemp, ReduxStoreProvider));
app.component('reactThemeStatic', reactToAngularComponent(ThemeStatic, ReduxStoreProvider));

app.component('reactDatabase', reactToAngularComponent(Database, ReduxStoreProvider));
app.component('reactDatabaseItem', reactToAngularComponent(DatabaseItem, ReduxStoreProvider));

/** blogs */
app.component('reactThemeBlog', reactToAngularComponent(ThemeBlog, ReduxStoreProvider));
app.component(
  'reactThemeBlogItemDetails',
  reactToAngularComponent(ThemeBlogItemDetails, ReduxStoreProvider)
);
app.component('reactThemeBlogItems', reactToAngularComponent(ThemeBlogItems));
app.component('reactThemeBlogCategories', reactToAngularComponent(ThemeBlogCategories));

app.component('reactThemeAbout', reactToAngularComponent(ThemeAbout));
app.component('reactThemeDetail', reactToAngularComponent(ThemeDetail, ReduxStoreProvider));

/** content */
app.component('reactThemeText', reactToAngularComponent(ThemeText));
app.component('reactThemeDownload', reactToAngularComponent(ThemeDownload, ReduxStoreProvider));

/** no results placeholder */
app.component('reactNoResultsPlaceholder', reactToAngularComponent(NoResultsPlaceholder));
