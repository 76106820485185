import { useEffect, useState, useRef, useCallback } from 'react';
import { BLOG } from '@src/components/constants';
import useUrlSearchParams from '@hooks/useUrlSearchParams';
import {
  sortBlogItems,
  getFilteredBlogItems,
  getBlogItems,
  getBlogCategories,
  addCategoriesToItems,
} from '@components/theme/themeBlog/helpers/themeBlogHelpers';

let allBlogItems = [];
let categories = [];

const useThemeBlogs = (blog) => {
  const [numFiltersApplied, setNumFiltersApplied] = useState(0);
  const [searchMode, setSearchMode] = useState(false);
  const [blogItems, setBlogItems] = useState([]);
  const [limit, setLimit] = useState(BLOG.ITEMS_PER_PAGES);
  const [q, setQ] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(BLOG.ALL_CATEGORY_HREF);
  const [categorySearch, setCategorySearch] = useState(false);
  const [textSearch, setTextSearch] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [shouldSearchRefresh, setShouldSearchRefresh] = useState(false);

  const isInitialising = useRef(true);

  const { updateUrlSearchParams, DEPRECATEDisStateChangedByUrl } = useUrlSearchParams([
    {
      key: 'q',
      state: q,
      updateState: setQ,
    },
    {
      key: 'requirements',
      state: selectedCategory,
      updateState: setSelectedCategory,
    },
    {
      key: 'limit',
      state: limit,
      updateState: setLimit,
    },
    true,
  ]);

  const onUpdateFilter = useCallback((filterName, value) => {
    switch (filterName) {
      case 'requirements':
        setSelectedCategory(value);
        break;
      case 'limit':
        setLimit(+value);
        break;
      case 'q':
        setQ(value);
        break;
      default:
        console.log(`Unknown search parameter '${filterName}' is ignored`);
    }
  }, []);

  const doSearch = useCallback(async () => {
    setSearchMode(true);
    let items = allBlogItems;

    if (categories) {
      categories = await Promise.all(
        categories.map(async (category) => ({
          ...category,
          children: getFilteredBlogItems(blog, items, q, category),
        }))
      );

      items = categories.find((cat) => cat.href === selectedCategory).children;
    } else if (textSearch) {
      items = getFilteredBlogItems(blog, items, q);
    }
    sortBlogItems(items);
    setBlogItems(items);
    let numFiltersAppliedResult = 0;

    numFiltersAppliedResult += q !== '' ? 1 : 0;
    numFiltersAppliedResult += selectedCategory === 'Alle' ? 0 : 1;

    setNumFiltersApplied(numFiltersAppliedResult);
  }, [blog, q, selectedCategory, textSearch]);

  useEffect(() => {
    const getCategories = async (items) => {
      setLoadingCategories(true);
      const categoryList = await getBlogCategories(blog);
      addCategoriesToItems(items, categoryList);
      allBlogItems = items;
      categories = categoryList;
      setLoadingCategories(false);
    };

    if (isInitialising.current || DEPRECATEDisStateChangedByUrl) {
      if (blog) {
        const items = getBlogItems(blog);
        const { facets } = blog;
        setTextSearch(facets.some((o) => o.component === 'FULL_TEXT_SEARCH'));
        const hasCategories = facets.some((o) => o.component === 'SELECT_FROM_REFERENCE_FRAME');
        setCategorySearch(hasCategories);

        if (hasCategories) {
          getCategories(items).then(() => {
            doSearch();
          });
        } else {
          allBlogItems = items;
          setBlogItems(items);
        }
      }
    }
    if (isInitialising) {
      isInitialising.current = false;
    }
  }, [blog, doSearch, DEPRECATEDisStateChangedByUrl, onUpdateFilter]);

  /** update url params only when search mode is active. */
  useEffect(() => {
    if (searchMode) {
      updateUrlSearchParams();
      setSearchMode(false);
    }
  }, [searchMode, updateUrlSearchParams]);

  const onLoadMore = useCallback(() => {
    setLimit(limit + BLOG.ITEMS_PER_PAGES);
    setSearchMode(true);
  }, [limit]);

  const onCategorySelected = useCallback((categoryHref) => {
    setSelectedCategory(categoryHref);
    setLimit(BLOG.ITEMS_PER_PAGES);
    const newSelectedCategory = categories.find((cat) => cat.href === categoryHref);
    const items = newSelectedCategory.children;
    setBlogItems(items);
    setSearchMode(true);
  }, []);

  const onSearchTermClear = useCallback(() => {
    setQ('');
    setShouldSearchRefresh(true);
  }, []);

  useEffect(() => {
    if (shouldSearchRefresh) {
      doSearch();
      setShouldSearchRefresh(false);
    }
  }, [shouldSearchRefresh, doSearch]);

  return {
    blogItems: blogItems.slice(0, limit),
    blogItemCount: blogItems.length,
    categorySearch,
    categories,
    loadingCategories,
    searchTerm: q,
    textSearch,
    onLoadMore,
    onSearchTermChange: setQ,
    onSearchTermClear,
    onSearch: doSearch,
    onCategorySelected,
    selectedCategory,
    limit,
    numFiltersApplied,
  };
};

export default useThemeBlogs;
