class wsThemeBlog {
  constructor($scope, $stateParams) {
    'ngInject';

    this.$scope = $scope;
    this.$stateParams = $stateParams;
  }

  $onInit() {}

  dynamicMetaDataUpdated = (blog) => {
    this.$scope.$emit('dynamicMetaDataUpdated', blog);
  };
}

module.exports = {
  template: require('./wsThemeBlog.html'),
  controllerAs: 'ctrl',
  controller: wsThemeBlog,
  bindings: {},
};
