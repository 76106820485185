import React from 'react';
import SearchInput from '@UI/searchInput/searchInput';

const MobileSearchBar = ({
  searchTerm,
  onUpdateSearchTerm,
  dispatchSearch,
  typesFilter,
  scopeFilter,
  openFiltersSidePanel,
}) => {
  let filterCount = 0;
  if (typesFilter.length > 0) {
    filterCount += 1;
  }
  if (scopeFilter) {
    filterCount += 1;
  }

  return (
    <div className="kov-pro-news__filter--mobile col-12 d-flex d-lg-none">
      <div className="kov-pro-mini-search input-group">
        <SearchInput
          value={searchTerm}
          onChange={onUpdateSearchTerm}
          onSearch={dispatchSearch}
          placeholder="Zoek"
          inputClassName="form-control"
        ></SearchInput>
        <span className="input-group-btn">
          <button className="btn btn-default" type="button" onClick={dispatchSearch}>
            <i className="icon-kov-pro-search"></i>
          </button>
        </span>
      </div>
      <button
        className="kov-pro-btn kov-pro-btn--block kov-pro-btn--dark kov-pro-btn__icon--left kov-pro-btn--inverted kov-pro-btn--filter"
        onClick={openFiltersSidePanel}
      >
        <i className="icon-kov-pro-filter"></i>Filter
        <span className="filter-counter">{filterCount}</span>
      </button>
    </div>
  );
};

export default MobileSearchBar;
