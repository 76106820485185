import React, { useState, useEffect } from 'react';
import { stripHtml } from '@src/app/utils';

require('./themeTextTocItem.scss');

const TextTocItem = ({ item, color, level, select }) => {
  const [tocItems, setTocItems] = useState([]);
  const [hovering, setHover] = useState(false);

  useEffect(
    () =>
      setTocItems(
        item.children
          ? item.children.filter(
              (o) => ['SUMMARY', 'SECTION'].includes(o.type) && o.importance === 'MEDIUM'
            )
          : []
      ),
    [item]
  );

  return (
    item.visible && (
      <li className={item.active ? 'active' : undefined}>
        <a
          onClick={() => select(item.key)}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          style={{
            WebkitLineClamp: 'initial',
            ...(hovering || item.active
              ? {
                  color,
                  borderLeftColor: color,
                }
              : {}),
          }}
        >
          <span dangerouslySetInnerHTML={{ __html: stripHtml(item.title) }} />
        </a>
        {tocItems.length > 0 && level < 3 && (
          <ul className="nav-toc">
            {tocItems.map((subItem, index) => (
              <TextTocItem
                key={index}
                item={subItem}
                color={color}
                level={level + 1}
                select={select}
              />
            ))}
          </ul>
        )}
      </li>
    )
  );
};
export default TextTocItem;
