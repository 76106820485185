import React from 'react';
import CheckboxOption from '../checkboxOption/checkboxOption';

const CheckboxGroup = ({
  groupArray,
  checkboxId,
  onChange,
  itemListClassName,
  itemListItemClassName,
}) => {
  const onChangeHandler = (checkboxKey, checkboxValue) => {
    onChange({ checkboxId, checkboxKey, checkboxValue });
  };

  const allSelected = () => groupArray.every((item) => item.checked);

  return (
    <ul className={itemListClassName}>
      <li key="alle" className={itemListItemClassName}>
        <label className="filter-item">
          Alle
          <input
            checked={allSelected()}
            onChange={(event) =>
              onChange({ checkboxId, checkboxKey: 'alle', checkboxValue: event.target.checked })
            }
            type="checkbox"
          />
          <span className="checkmark"></span>
        </label>
      </li>
      {groupArray.map((option) => (
        <CheckboxOption key={option.key} option={option} onChange={onChangeHandler} />
      ))}
    </ul>
  );
};

export default CheckboxGroup;
