import React from 'react';

const NoResultsPlaceholder = ({ children, styleOverride = undefined }) => (
  <div className="kov-pro-no-search-results" style={styleOverride}>
    <div className="kov-pro-no-search-results__icon">
      <i className="icon-kov-pro-search-results" />
    </div>
    <div className="kov-pro-no-search-results__title">Geen zoekresultaten</div>
    {children && <p className="kov-pro-no-search-results__text">{children}</p>}
    {!children && (
      <p className="kov-pro-no-search-results__text">
        Er komen geen items overeen met jouw zoekcriteria. <br />
        Probeer een andere zoekopdracht.
      </p>
    )}
  </div>
);

export default NoResultsPlaceholder;
