import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  proThemes: [],
  studyProgrammes: [],
  primaryReferenceFrame: [],
  globaDatabasesRefFrame: [],
};

const contentState = createSlice({
  name: 'content',
  initialState,
  reducers: {
    setProThemes: (state, action) => {
      state.proThemes = action.payload;
    },
    setStudyProgrammes: (state, action) => {
      state.studyProgrammes = action.payload;
    },
    setPrimaryRefFrame: (state, action) => {
      state.primaryReferenceFrame = action.payload;
    },
    setGlobaDatabasesRefFrame: (state, action) => {
      state.globaDatabasesRefFrame = action.payload;
    },
  },
});

export const contentStateActions = contentState.actions;
export default contentState;
