import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { getAngularService } from 'NgMigrationUtils/angular-react-helper';
import useMainPageTheme from '@src/hooks/useMainPageTheme';
import { getPathFromUrl } from '@src/routes/utilsRoutes';

import { getThemeAncestors } from '@src/helpers/themeHelper';
import { selectNavigationFirstLevel } from '@src/reduxStore/navigation/navigationSelectors';
import { useSelector } from 'react-redux';
import { HOMEPAGE_REFERENCES } from '@src/components/constants';
import QuickNavigationItem from './quickNavigationItem/quickNavigationItem';
import QuickSubNavigation from './quickSubNavigation/quickSubNavigation';
import QuickNavigationQuicklinks from './quickNavigationQuicklinks/quickNavigationQuicklinks';
import QuickNavigationMobile from './quickNavigationMobile/quickNavigationMobile';

const QuickNavigation = ({ theme, closeMenu, settings, urlTab }) => {
  const navigationService = getAngularService('navigationService');
  const navigationFirstLevel = useSelector(selectNavigationFirstLevel);

  const [selected, setSelected] = useState(null);
  const [activeItems, setActiveItems] = useState([]);
  const [subTree, setSubTree] = useState(null);

  const { quickLinks } = useMainPageTheme();

  useEffect(() => {
    (async () => {
      if (navigationFirstLevel) {
        let newActiveItems;
        const path = getPathFromUrl(window.location.href);

        if (theme) {
          newActiveItems = [
            ...getThemeAncestors(navigationFirstLevel, theme, HOMEPAGE_REFERENCES.MENU_ITEMS),
            theme,
          ];

          const alternativeRoute = navigationService.getMatchingLatestClusterChild(theme);

          if (alternativeRoute) {
            newActiveItems = [
              ...navigationService.buildRecursivePath(alternativeRoute, 1, false),
              theme,
            ];
          }
        } else if (path === '/vakken-en-leerplannen' && window.location.search) {
          const location =
            window.location.hostname === 'localhost'
              ? `https://testpro.katholiekonderwijs.vlaanderen/vakken-en-leerplannen${window.location.search}`
              : window.location.href;
          newActiveItems = await navigationService.getBreadCrumbItems(location);
        } else {
          newActiveItems = await navigationService.getCurrentNavigationItemTo(path);
        }

        const newSubTree =
          (newActiveItems.length > 0 &&
            navigationFirstLevel.children.find((item) => item.key === newActiveItems[0].key)) ||
          navigationFirstLevel.children[0];

        // when the user opens the quickNavigation in the Homepage the newSubTree will be the first children of the homepage, and that will be wrong.
        const isProHomepage = newActiveItems[0]?.href === settings.proHomepageHref;

        // If there is no info about a channel/cluster/theme -> the hamburger should show all channels.
        const isAHomepageItem = navigationService
          .getItems()
          .filter((item) => item?.parent?.href === settings.proHomepageHref)
          .find((item) => item?.references?.includes(newActiveItems[0]?.href));

        // So, in these cases, we setup the selected item to null.
        const itShouldShowAllChannels = isProHomepage || isAHomepageItem || !newActiveItems.length;

        setSubTree(newSubTree);
        setSelected(itShouldShowAllChannels ? null : newSubTree.key);
        setActiveItems(newActiveItems);
      }
    })();
  }, [navigationService, theme]);

  const onQuickNavigationMenuSelected = useCallback((item) => {
    setSubTree(item?.key === 'quicklinks' ? null : item);
    setSelected(item?.key);
  }, []);

  const clearSelection = useCallback(() => {
    setSelected(null);
  }, []);

  return (
    <section
      id="overlay-menu"
      className="overlay-menu collapse show"
      aria-label="overlay menu"
      style={{ overflow: 'auto' }}
    >
      <section id="mm">
        <div className="overlay-menu-container">
          <div className="overlay-menu-body">
            <div className="overlay-menu__menu" aria-label="kanalen" style={{ overflow: 'auto' }}>
              <ul
                className="desktop-menu nav flex-column nav-pills"
                id="kov-themes"
                role="tablist"
                aria-orientation="vertical"
              >
                {navigationFirstLevel &&
                  navigationFirstLevel.children
                    .filter((child) => child.visible)
                    .map((child, index) => (
                      <QuickNavigationItem
                        key={index}
                        item={child}
                        selected={selected === child.key || (selected === null && index === 0)}
                        onSelect={onQuickNavigationMenuSelected}
                      />
                    ))}
                <div className="divider-line" />
                <li className="desktop-menu__service accent-5">
                  <a
                    className={`nav-link ${selected === 'quicklinks' ? 'active' : ''}`}
                    onClick={() => onQuickNavigationMenuSelected({ key: 'quicklinks' })}
                  >
                    <i className="icon-kov-pro-mm-snellinks" />
                    Snellinks
                  </a>
                  <a className="nav-link" href="/nieuwsoverzicht" onClick={closeMenu}>
                    <i className="icon-kov-pro-mm-nieuws" />
                    Alle nieuws
                  </a>
                </li>
              </ul>
            </div>

            <div className="overlay-menu__service" aria-label="kanalen detail">
              {subTree ? (
                <QuickSubNavigation
                  subTree={subTree}
                  activeItems={activeItems}
                  closeMenu={closeMenu}
                  settings={settings}
                  urlTab={urlTab}
                />
              ) : null}

              {selected === 'quicklinks' && (
                <QuickNavigationQuicklinks quickLinks={quickLinks} closeMenu={closeMenu} />
              )}
            </div>
          </div>
        </div>
      </section>

      <QuickNavigationMobile
        selected={selected}
        menu={navigationFirstLevel}
        quickLinks={quickLinks}
        closeMenu={closeMenu}
        onQuickNavigationMenuSelected={onQuickNavigationMenuSelected}
        clearSelection={clearSelection}
      />
    </section>
  );
};

QuickNavigation.propTypes = {
  theme: PropTypes.object,
  closeMenu: PropTypes.func,
  settings: PropTypes.object,
  urlTab: PropTypes.string,
};

export default QuickNavigation;
