import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import databaseSelectors from '@src/reduxStore/database/databaseSelectors';
import { databaseActions } from '@src/reduxStore/database/databaseSlice';
import useUrlSearchParams from '@hooks/useUrlSearchParams';
import { cloneDeep } from 'lodash-es';
import { OrderMethods } from '@components/database/constants';
import {
  getOrderMethod,
  transformDatabaseSearchResults,
} from '@components/database/helpers/helpers';
import { transformViewOptionsToParams, useSearchApi } from '@components/searchApi';
import { selectUserViewOptions } from '@src/reduxStore/user/userSelectors';
import { isPreview } from '@src/helpers/previewHelper';
import { getFacetCategories } from '@src/reduxStore/content/contentHelpers';

const getCheckedRequirements = (requirements) =>
  requirements
    .map((req) => req.values.filter((v) => v.checked).map((v) => v.href))
    .filter((req) => req.length > 0);

const getFacetReferences = (requirements) => {
  const result = [];
  getCheckedRequirements(requirements).forEach((referenceFrame) =>
    result.push(referenceFrame.join(','))
  );
  return result.join(';');
};

const getFacetReferencesWithZillItem = (requirements, zillItem) => {
  const facetReferences = getFacetReferences(requirements);
  let result = facetReferences;
  if (zillItem != null) {
    result += facetReferences.length === 0 ? `/content/${zillItem}` : `;/content/${zillItem}`;
  }
  return result;
};

const getFiltersCount = (filters) => {
  let numFiltersAppliedResult = 0;
  numFiltersAppliedResult += getCheckedRequirements(filters.requirements).length;
  numFiltersAppliedResult += filters.q.length > 0 ? 1 : 0;
  numFiltersAppliedResult += filters.zillItem != null ? 1 : 0;
  numFiltersAppliedResult += filters.curricula != null ? 1 : 0;
  return numFiltersAppliedResult;
};

const useDatabase = (database) => {
  const [numFiltersApplied, setNumFiltersApplied] = useState(0);
  const [requirementsInitialized, setRequirementsInitialized] = useState(false);
  const dispatch = useDispatch();
  const filters = useSelector(databaseSelectors.getFilters);
  const limit = useSelector(databaseSelectors.getLimit);
  const viewOptions = useSelector(selectUserViewOptions);

  const previewData = useMemo(
    () =>
      database.children
        .filter((item) => item.children)
        .map((item) => ({
          ...item,
          facets: {
            references: item.requirements.map((ref) => ({ href: ref })),
          },
          ...(item.thumbImage && {
            thumbnails: [
              {
                width: 100,
                height: 100,
                href: new URL(item.thumbImage.href).pathname,
              },
            ],
          }),
          mainstructuresOuTypeCombinations: item.mainstructuresOuTypeCombinations.map((m) => ({
            permalink: m,
          })),
          webInfo: {
            path: item.pagePath,
          },
        })),
    [database.children]
  );

  const { getSearchResults, searchResults, searchCount, isLoading } = useSearchApi({
    previewDataToUse: isPreview() ? previewData : null,
  });

  const filterObjectIntoParamStateObj = useMemo(
    () =>
      Object.entries({ ...filters, limit }).map((param) => ({
        key: param[0],
        state: param[1],
        updateState: (value) => {
          if (param[0] === 'requirements') {
            const newRequirements = cloneDeep(filters.requirements);
            newRequirements.forEach((req) =>
              req.values.forEach((val) => {
                val.checked = value.includes(val.key);
              })
            );
            dispatch(
              databaseActions.setFilter({ fieldName: 'requirements', payload: newRequirements })
            );
          } else if (param[0] === 'limit') {
            dispatch(databaseActions.setLimit({ type: 'custom', value }));
          } else {
            dispatch(databaseActions.setFilter({ fieldName: param[0], payload: value }));
          }
          dispatch(databaseActions.applyTempFilter());
        },
      })),
    [dispatch, filters, limit]
  );

  const { updateUrlSearchParams, urlParamsProcessed } = useUrlSearchParams(
    filterObjectIntoParamStateObj,
    requirementsInitialized
  );

  const doSearch = useCallback(
    (db, searchFilters) => {
      if (!viewOptions) {
        return;
      }
      const searchTerm = searchFilters.q.toLowerCase();
      const orderMethod = getOrderMethod(searchFilters.order, searchTerm);
      const isThemeDatabase = db.theme;
      const facetReferencesWithZillItem = getFacetReferencesWithZillItem(
        searchFilters.requirements,
        searchFilters.zillItem
      );

      const searchApiParams = {
        types: 'pro_web_page',
        proWebPageTypes: 'MINI_DATABASE_ITEM',
        limit,
        expand: 'FULL',
        ...(isThemeDatabase
          ? { noThemeOrThemeRoots: searchFilters.curricula.join(',') }
          : { themeRoots: searchFilters.curricula.join(',') }),
        includedIn: db?.href,
        ...transformViewOptionsToParams(database, viewOptions),
        // Search-API orders results by relevance by default. For ordering by date, the 'orderby' property should be added with a descending value, indicating newest or oldest.
        ...(orderMethod !== OrderMethods.RELEVANCE && {
          orderby: 'issued.startDate,title',
          descending: `${orderMethod === OrderMethods.NEWEST}`,
        }),
        // Only add 'q' to the search-API params when there is a FULL_TEXT_SEARCH facet
        ...(db.facets.find((facet) => facet.component === 'FULL_TEXT_SEARCH') && {
          q: searchTerm,
        }),
        // Only add references to the search-API params if there is at least one checked requirement
        ...(facetReferencesWithZillItem !== '' && {
          'facets.references': facetReferencesWithZillItem,
        }),
        // Only add age-range facets to the search-API params when there is a AGE_RANGE_SLIDER facet
        ...(db.facets.find((facet) => facet.component === 'AGE_RANGE_SLIDER') && {
          'facets.minAge': searchFilters.ageRangeFrom,
          'facets.maxAge': searchFilters.ageRangeTo,
        }),
      };

      getSearchResults(searchApiParams, (res) => transformDatabaseSearchResults(res, viewOptions));
    },
    [getSearchResults, limit, viewOptions]
  );

  /**
   * USE EFFECTS
   */

  /**
   * This used effect is called only once to initialize the requirements.
   */
  useEffect(() => {
    let isCancelled = false;

    if (database) {
      const initializeRequirements = async () => {
        const requirementPromises = database.facets
          .filter((facet) => facet.component === 'SELECT_FROM_REFERENCE_FRAME')
          .flatMap(async (facet) => {
            const categories = await getFacetCategories(facet.source.href);

            return {
              label: facet.label,
              source: facet.source.href,
              values: categories.map((opts) => ({
                title: opts.title,
                key: opts.key,
                href: opts.$$meta.permalink,
                checked: false,
              })),
            };
          });

        const requirements = await Promise.all(requirementPromises);

        if (!isCancelled) {
          dispatch(databaseActions.setInitialDatabaseRequirements(requirements));
        }
        setRequirementsInitialized(true);
      };

      initializeRequirements();
    } else {
      setRequirementsInitialized(true);
    }

    /**
     * This is used to clean the filters everytime this hook is initialized (meaning when the user navigates to a database page)
     * It is needed to clean the previous values that the database slice filters could have
     */
    dispatch(databaseActions.resetFilters());

    return () => {
      isCancelled = true;
    };
  }, [database, dispatch]);

  /**
   * This useEffect is triggered every time the filters change, meaning the user clicked the search button
   */
  useEffect(() => {
    if (!database || !requirementsInitialized) return;

    if (urlParamsProcessed) updateUrlSearchParams();

    // In Global Database it is mandatory to select at least one curricula in order to perform a search
    const shouldCallSearchApi =
      !database.isGlobalDatabase || (database.isGlobalDatabase && filters.curricula.length);
    if (shouldCallSearchApi) {
      doSearch(database, filters);
    } else {
      // this is done in order to clean-up previous results the state could have
      dispatch(databaseActions.setResults([]));
    }
    setNumFiltersApplied(getFiltersCount(filters));
  }, [
    filters,
    requirementsInitialized,
    updateUrlSearchParams,
    doSearch,
    database,
    dispatch,
    urlParamsProcessed,
  ]);

  /**
   * This useEffect is triggered when the searchResults from the useSearchApi hook changes. Meaning the user made a new search and the API returned the new results
   */
  useEffect(() => {
    // Updating results
    dispatch(databaseActions.setResults(searchResults));
  }, [searchResults, dispatch]);

  /**
   * ENDOF USE EFFECTS
   */

  return {
    updateUrlSearchParams,
    numFiltersApplied,
    searchResults,
    searchCount,
    isLoading,
  };
};

export default useDatabase;
