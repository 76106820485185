import { createSelector } from '@reduxjs/toolkit';
import { getAngularService } from '@src/module/reactMigrationUtils/angular-react-helper';
import { flattenTree } from '@src/app/utils';
import * as settings from '@src/config/settings';
import { HOMEPAGE_REFERENCES } from '@src/components/constants';
import { checkIfOutOfViewOptions } from '@src/helpers/contextFilterHelper';
import { mapTreeRecursive } from '@src/components/theme/templates/helpers/helpers';
import { selectUserViewOptions } from '../user/userSelectors';
import { getRedactieItems } from '../suggestions/suggestionsSelectors';

export const selectLatestClusterChildren = (state) => state.navigation.latestClusterChildren;
export const selectCurrentSubTabHref = (state) => state.navigation.currentSubTabHref;

export const selectNavigationItems = createSelector(
  [getRedactieItems, (state) => state.navigation.navigationItems],
  (redactieItems, navigationItems) => {
    // we only add the recactie items to the navigationItems selector when the user is trying to preview the homepage
    if (
      redactieItems &&
      redactieItems.find((a) => a.$$meta.permalink === settings.proHomepageHref)
    ) {
      return redactieItems.filter((redactieItem) =>
        ['STRUCTURED_DOCUMENT', 'SECTION', 'ATTACHMENT'].includes(redactieItem.type)
      );
    }

    return navigationItems;
  }
);

export const selectNavTree = createSelector(
  [selectNavigationItems, selectUserViewOptions],
  (navigationItems, viewOptions) => {
    if (navigationItems.length === 0) return null;
    const proHomepage = navigationItems.find((e) => e.type === 'STRUCTURED_DOCUMENT');
    return getAngularService('navigationService').buildProTree(
      proHomepage.key,
      navigationItems,
      viewOptions
    );
  }
);

export const selectFlatItems = createSelector([selectNavTree], (navigationTree) =>
  flattenTree(navigationTree, false)
);

export const selectNavigationFirstLevel = createSelector([selectNavTree], (homepageDocTree) =>
  homepageDocTree?.children?.find((item) => item.href === HOMEPAGE_REFERENCES.MENU_ITEMS)
);

/**
 * This selector returns the current navigation level altering the visibility on those nodes that is needed, for instance, when all of elements in the navigationLevel are hidden then we show all of them
 */
export const selectNavigationLevel = createSelector(
  [selectFlatItems, (_, itemHref) => itemHref, selectUserViewOptions],
  (navigationFlatItems, itemHref, viewOptions) => {
    if (navigationFlatItems?.length > 0) {
      const navigationLevel = navigationFlatItems.find((item) => item.href === itemHref);
      // we find the element to (probably) modify from the original flatted themeTree
      if (checkIfOutOfViewOptions(navigationLevel, viewOptions)) {
        // if we find it and it is "fully" out of the viewOptions we create an array of the item and the children in order to force later the visibility to trye
        return mapTreeRecursive(navigationLevel, viewOptions, true);
      }
      // we return the modified currentThemeTree
      return mapTreeRecursive(navigationLevel, viewOptions, false);
    }

    return null;
  }
);

export const selectNavigationLevelSubTab = createSelector(
  [selectNavigationLevel, selectCurrentSubTabHref, selectUserViewOptions],
  (navigationSecondLevel, currentSubTabHref, viewOptions) => {
    if (navigationSecondLevel?.multipleAlternativeMenus && currentSubTabHref) {
      // we find the subtab
      const subtab = navigationSecondLevel.children.find((tab) => tab.href === currentSubTabHref);

      // then we check if the whole content of the subtab is out of viewOptions
      if (checkIfOutOfViewOptions(subtab, viewOptions)) {
        // if we find it and it is "fully" out of the viewOptions we create an array of the item and the children in order to force later the visibility to true
        return {
          ...mapTreeRecursive(subtab, viewOptions, true),
          websiteType: 'tab',
        };
      }

      // we return the modified currentThemeTree
      return {
        ...mapTreeRecursive(subtab, viewOptions, false),
        websiteType: 'tab',
      };
    }

    return null;
  }
);

export const selectSectionTitle = createSelector(
  [selectFlatItems, (_, itemHolderHref) => itemHolderHref],
  (navigationFlatItems, itemHolderHref) =>
    navigationFlatItems.find((item) => item.href === itemHolderHref)?.title
);

export const selectItemChannelTheme = createSelector(
  [selectFlatItems, (_, itemHref) => itemHref],
  (navigationFlatItems, itemHref) => {
    const navigationItem = navigationFlatItems.find((item) => item.href === itemHref);
    const navigationItemTheme = navigationItem.theme || navigationItem.parent?.theme;
    if (navigationItemTheme?.isChannel) {
      return navigationItemTheme;
    }
    return false;
  }
);
