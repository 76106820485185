import { COOKIE_TYPE } from '@src/components/constants';
import dayjs from 'dayjs';
import { getAngularService } from 'NgMigrationUtils/angular-react-helper';
import { isACrawler } from '@src/app/utils';

export function setCookie(name, value, expires = dayjs().add(1, 'y').toDate()) {
  document.cookie = `${name}=${value}; path=/; expires=${expires}`;
}

export function deleteCookie(name, domain = false) {
  // to ensure the cookie is deleted before the next within the current "cicle"
  setTimeout(() => {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; ${
      domain ? `domain=${domain}` : ''
    }`;
  }, 0);
}
export function getCookie(name) {
  const cookie = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  if (cookie) return cookie[2];
  return null;
}

export function cookiesAccepted() {
  return getCookie(COOKIE_TYPE.CONSENT) === 'true';
}

export function analyticCookiesAccepted() {
  return getCookie(COOKIE_TYPE.ANALYTIC) === 'true';
}
export function socialCookiesAccepted() {
  return getCookie(COOKIE_TYPE.SOCIAL) === 'true';
}

export function acceptCookies() {
  return setCookie(COOKIE_TYPE.CONSENT, true);
}

export function showCookieModal({ force = false } = {}) {
  const areCookiesAccepted = cookiesAccepted();
  const isCookiesRoute = window.location.pathname === '/cookies';

  return !isACrawler() && ((!areCookiesAccepted && !isCookiesRoute) || force);
}

export function openCookieModal() {
  const $rootScope = getAngularService('$rootScope');
  $rootScope.$broadcast('appHome:cookies');
  $rootScope.$apply();
}
