import React from 'react';
import PropTypes from 'prop-types';
import { stripHtml } from '@src/app/utils';

const ThemeBlogCategories = ({
  categories,
  onCategorySelected,
  className = 'kov-pro-blogitem__categories',
}) => (
  <ul className={className}>
    {categories.map((category) => {
      if (category == null) {
        console.error('undefined category found, rendering null');
        return null;
      }

      return (
        <li key={category.href}>
          <a href="#" className="category-tag" onClick={() => onCategorySelected(category.href)}>
            {stripHtml(category.title)}
          </a>
        </li>
      );
    })}
  </ul>
);

ThemeBlogCategories.propTypes = {
  categories: PropTypes.array,
  onCategorySelected: PropTypes.func,
  className: PropTypes.string,
};

export default ThemeBlogCategories;
