import React from 'react';
import useMenuItemFilters from '@hooks/useMenuItemFilters';

require('./globalTrainingSelectedTheme.scss');

const GlobalTrainingSelectedTheme = ({ viewModel, collapseFiltersBoxHandler, themeFiltersRef }) => {
  const { selectedMenuItems } = useMenuItemFilters(viewModel);
  const isCurriculaFilterSelected = viewModel.curricula.length > 0;

  /**
   * This method is used when we have "menuItems" present in our filters, or some curricula has been selected.
   * It is triggered when the user click on the menuItem/curricula link, and it opens the filters box and scrolls to the point where the menuItems are defined
   */
  const onMenuItemNameClickHandler = () => {
    // we only need to open the filters box when we are clicking the link from outside of the filters box
    if (collapseFiltersBoxHandler) collapseFiltersBoxHandler(false);

    // Set timeout to pass along the main thread to process the show filters state
    setTimeout(() => {
      themeFiltersRef.current.scrollIntoView({ block: 'center' });
    });
  };

  return selectedMenuItems?.length || isCurriculaFilterSelected ? (
    <span className="kov-pro-nas__theme-filter-title">
      <br />
      binnen {selectedMenuItems?.length ? 'thema' : 'je'}{' '}
      <a onClick={onMenuItemNameClickHandler}>
        {selectedMenuItems?.length
          ? selectedMenuItems[selectedMenuItems.length - 1].title
          : 'geselecteerde leerplannen'}
      </a>
    </span>
  ) : (
    ''
  );
};

export default GlobalTrainingSelectedTheme;
