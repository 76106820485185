import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { nlBE } from 'date-fns/esm/locale';
import './dateRangePicker.scss';

const DateRangePicker = ({ onDaySelected, startDate, endDate, minDate, maxDate }) => {
  registerLocale('nlBE', nlBE);

  const DatePickerInput = forwardRef(({ value, onChange, onClick, placeholder }, ref) => (
    <>
      <i className="icon-kov-pro-calendar"></i>
      <input
        ref={ref}
        value={value}
        onChange={onChange}
        onClick={onClick}
        placeholder={placeholder}
      />
    </>
  ));
  DatePickerInput.displayName = 'DatePickerInput';

  const commonProps = {
    locale: 'nlBE',
    todayButton: 'Vandaag',
    dateFormat: 'dd/MM/yyyy',
    onChangeRaw: (e) => e.preventDefault(),
    closeOnScroll: true,
    isClearable: true,
    showMonthDropdown: true,
    showYearDropdown: true,
    dropdownMode: 'select',
  };

  const startDateRef = React.createRef();
  const endDateRef = React.createRef();

  return (
    <div className="kov-datepicker-box">
      <div className="kov-datepicker-box__date">
        <DatePicker
          {...commonProps}
          selected={startDate}
          onChange={(date) => onDaySelected('dateFrom', date)}
          minDate={minDate}
          maxDate={endDate || maxDate}
          placeholderText="van __ /__ /__"
          customInput={<DatePickerInput value={startDate} ref={startDateRef} />}
        />
      </div>
      <div className="kov-datepicker-box__date">
        <DatePicker
          {...commonProps}
          selected={endDate}
          onChange={(date) => onDaySelected('dateTo', date)}
          minDate={startDate || minDate}
          maxDate={maxDate}
          placeholderText="tot __ /__ /__"
          customInput={<DatePickerInput value={endDate} ref={endDateRef} />}
        />
      </div>
    </div>
  );
};

DateRangePicker.propTypes = {
  onDaySelected: PropTypes.func,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
};

export default DateRangePicker;
