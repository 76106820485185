import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { stripHtml } from '@src/app/utils';

const ThemeDatabaseZillGoalDetail = ({ goal, detailedGoal }) => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <>
      <span
        className={`ref-goals-list__detail ${collapsed ? 'collapsed' : ''}`}
        data-toggle="collapse"
        data-target="#detail-1"
        onClick={() => setCollapsed(!collapsed)}
        aria-expanded="false"
      >
        detail
      </span>
      <div id="detail-1" className={`${collapsed ? 'collapse' : 'show'}`}>
        <ul className="ref-goals-list-steps">
          {detailedGoal.map((item) => (
            <li key={item.key}>
              {item.type === 'CURRICULUM_ZILL_DEVELOPMENT_CONTENT' && item.content1 && (
                <div>
                  <span style={{ color: goal.theme.field.color }} className="bullet"></span>
                  <span className="ref-goals-list-steps__content1">
                    {stripHtml(item.content1.title)}
                  </span>
                  <i
                    className="icon-kov-pro-arrow-right"
                    style={{ color: goal.theme.field.color, margin: '0 5px' }}
                  ></i>
                  <span className="ref-goals-list-steps__content2">{stripHtml(item.title)}</span>
                </div>
              )}
              {item.type === 'CURRICULUM_ZILL_DEVELOPMENT_CONTENT' && !item.content1 && (
                <div>
                  <span style={{ color: goal.theme.field.color }} className="bullet"></span>
                  <span className="ref-goals-list-steps__content2">{stripHtml(item.title)}</span>
                </div>
              )}

              {item.type === 'CURRICULUM_ZILL_LEERLIJN_CLUSTER' && item.leerlijn.content2 && (
                <div>
                  <span style={{ color: goal.theme.field.color }} className="bullet"></span>
                  <span className="ref-goals-list-steps__content1">
                    {stripHtml(item.leerlijn.content2.content1.title)}
                  </span>
                  <i
                    className="icon-kov-pro-arrow-right"
                    style={{ color: goal.theme.field.color }}
                  ></i>
                  <span className="ref-goals-list-steps__content2">
                    {stripHtml(item.leerlijn.content2.title)}
                  </span>
                  <i
                    className="icon-kov-pro-arrow-right"
                    style={{ color: goal.theme.field.color }}
                  ></i>
                  <span className="ref-goals-list-steps__refage">
                    {item.startage - item.endage}
                  </span>
                  <span className="ref-goals-list-steps__step">{stripHtml(item.description)}</span>
                </div>
              )}
              {item.type === 'CURRICULUM_ZILL_LEERLIJN_CLUSTER' && item.leerlijn.content1 && (
                <div>
                  <span style={{ color: goal.theme.field.color }} className="bullet"></span>
                  <span className="ref-goals-list-steps__content2">
                    {stripHtml(item.leerlijn.content1.title)}
                  </span>
                  <i
                    className="icon-kov-pro-arrow-right"
                    style={{ color: goal.theme.field.color }}
                  ></i>
                  <span className="ref-goals-list-steps__refage">
                    {item.startage - item.endage}
                  </span>
                  <span className="ref-goals-list-steps__step">{stripHtml(item.description)}</span>
                </div>
              )}
              {item.type === 'CURRICULUM_ZILL_LEERLIJN_CLUSTER' && item.leerlijn.goal && (
                <div>
                  <span style={{ color: goal.theme.field.color }} className="bullet"></span>
                  <span className="ref-goals-list-steps__refage">
                    {item.startage - item.endage}
                  </span>
                  <span className="ref-goals-list-steps__step">{stripHtml(item.description)}</span>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

ThemeDatabaseZillGoalDetail.propTypes = {
  goal: PropTypes.object,
  detailedGoal: PropTypes.array,
};

export default ThemeDatabaseZillGoalDetail;
