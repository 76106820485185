require('./wsThemeBlogItem.scss');

class wsThemeBlogItem {
  constructor($scope, $stateParams) {
    'ngInject';
    this.$scope = $scope;
    this.$stateParams = $stateParams;
  }

  $onInit() {}

  dynamicMetaDataUpdated = (blogItem) => {
    this.$scope.$emit('dynamicMetaDataUpdated', blogItem);
  };
}

module.exports = {
  template: require('./wsThemeBlogItem.html'),
  controllerAs: 'ctrl',
  controller: wsThemeBlogItem,
  bindings: {},
};
