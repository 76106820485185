import { useCallback, useRef } from 'react';

const useBackdropClick = (onClick) => {
  const backdropClick = useRef();
  const handleMouseDown = useCallback((event) => {
    // We don't want to close the dialog when clicking the dialog content.
    backdropClick.current = event.target === event.currentTarget;
  }, []);
  const handleBackdropClick = useCallback(
    (event) => {
      // Ignore the events not coming from the "backdrop".
      if (!backdropClick.current) return;
      backdropClick.current = null;

      onClick?.(event, 'BackdropClick');
    },
    [onClick]
  );

  return { backdropClick: handleBackdropClick, mouseDown: handleMouseDown };
};

export default useBackdropClick;
