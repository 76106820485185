import { getRegions, getEducationLevels } from '@src/components/constants';

export const checkIfViewOptionsAreEmpty = (viewOptions) =>
  !viewOptions ||
  viewOptions.EDUCATION_LEVELS.every((educationLevel) => !educationLevel.checked) ||
  viewOptions.REGIONS.every((region) => !region.checked);

export const matchRegion = (item, region) => Boolean(item.coverage?.includes(region.href));

export const matchEducationLevel = (item, educationLevel) =>
  Boolean(
    item.mainstructuresOuTypeCombinations?.includes(educationLevel.mainstructuresOuTypeCombination)
  );

export const isVisibleWithViewOptions = (item, viewOptions) => {
  if (!item || checkIfViewOptionsAreEmpty(viewOptions)) {
    return true;
  }
  const matchingEducationLevel = viewOptions.EDUCATION_LEVELS.filter(
    (educationLevel) => educationLevel.checked && matchEducationLevel(item, educationLevel)
  );
  const matchingRegion = viewOptions.REGIONS.filter(
    (region) => region.checked && matchRegion(item, region)
  );

  return matchingEducationLevel.length > 0 && matchingRegion.length > 0;
};

export const setVisibilityByViewOptions = (items, viewOptions) => {
  items.forEach((item) => {
    item.visible = isVisibleWithViewOptions(item, viewOptions);
  });
};

export const getDefaultViewOptions = () => {
  const viewOpts = {
    REGIONS: getRegions(),
    EDUCATION_LEVELS: getEducationLevels(),
  };

  viewOpts.EDUCATION_LEVELS.sort((edl1, edl2) => edl1.trainingReadOrder - edl2.trainingReadOrder);
  viewOpts.REGIONS.sort((edl1, edl2) => edl1.readOrder - edl2.readOrder);

  return viewOpts;
};

/**
 * Gets education level filters in terms of the education levels
 * the user checked in their view options
 * @param {*} viewOptions - user's view options
 * @returns the list of matched education levels
 */
export const getMainstructuresOuTypeCombinationsFromViewOptions = (
  viewOptions,
  trainingFilter = true
) => {
  const educationLevelHrefs = [];
  const checkedEducationLevelHrefs = viewOptions.EDUCATION_LEVELS.filter(
    (educationLevel) => educationLevel.checked
  ).map((educationLevel) => educationLevel.mainstructuresOuTypeCombination);

  getEducationLevels(trainingFilter).forEach((mainstructuresOuTypeCombination) => {
    const key =
      mainstructuresOuTypeCombination.parentValue ||
      mainstructuresOuTypeCombination.mainstructuresOuTypeCombination;
    if (checkedEducationLevelHrefs.includes(key)) {
      educationLevelHrefs.push(mainstructuresOuTypeCombination.mainstructuresOuTypeCombination);
    }
  });

  return educationLevelHrefs;
};

/**
 * transforms the standard view options object into the form needed for piwik tag manager
 * @param {*} viewOptions user's view options
 * @returns an object containing the regions and mainstructures as a single dot seperated string
 */
export const transformViewOptionsToAnalyticsObject = (viewOptions) => ({
  user_edu_level:
    viewOptions.EDUCATION_LEVELS.filter((lvl) => lvl.checked).length > 0
      ? viewOptions.EDUCATION_LEVELS.filter((lvl) => lvl.checked)
          .map((lvl) => lvl.tag)
          .join('.')
      : undefined,
  user_region:
    viewOptions.REGIONS.filter((reg) => reg.checked).length > 0
      ? viewOptions.REGIONS.filter((reg) => reg.checked)
          .map((reg) => reg.tag)
          .join('.')
      : undefined,
});

/**
 * Gets coverage filters in terms of the regions
 * the user checked in their view options
 * @param {*} viewOptions user's view options
 * @returns the list of regions checked in the view model
 */
export const getCoverageFromViewOptions = (viewOptions) =>
  viewOptions.REGIONS.filter((region) => region.checked).map((region) => region.href);

export default setVisibilityByViewOptions;
