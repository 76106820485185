import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getAngularService } from 'NgMigrationUtils/angular-react-helper';
import logoKovl from '@src/assets/images/logo_ko_vl.svg';
import logoThemeKovL from '@src/assets/images/theme_logo_ko_vl.svg';
import QuickNavigation from '@components/navigation/quickNavigation/quickNavigation';
import { getUserKey, selectUser } from '@src/reduxStore/user/userSelectors';
import { useDispatch, useSelector } from 'react-redux';
import uiState, { isUserNavOpen } from '@src/reduxStore/ui/uiState';
import './_header.scss';

const Header = ({ sTheme, goToHome, goToSearch, locationPath, urlTab, dispatchEvent, loading }) => {
  const navigationService = getAngularService('navigationService');
  const settings = require('@src/config/settings');
  const dispatch = useDispatch();

  const [quickNavOpen, setQuickNavOpen] = useState(false);
  const [fixedHeader, setFixedHeader] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [tree, setTree] = useState();
  const [menuItems, setMenuItems] = useState();
  const userKey = useSelector(getUserKey);
  const userDetails = useSelector(selectUser);
  const userNavOpen = useSelector(isUserNavOpen);

  useEffect(() => {
    (async () => {
      setTree(await navigationService.getNavigationTreeAsync());
      setMenuItems(navigationService.getUserOptions(userKey));
    })();
  }, [locationPath, navigationService, sTheme, tree, userKey]);

  useEffect(() => {
    if (!quickNavOpen) document.getElementsByTagName('body')[0].classList.remove('no-scroll');
    else document.getElementsByTagName('body')[0].classList.add('no-scroll');
  }, [quickNavOpen]);

  useEffect(() => {
    const scrollListemer = () => {
      if (!fixedHeader && window.scrollY > 77) {
        setFixedHeader(true);
      } else if (fixedHeader && window.scrollY < 29) {
        setFixedHeader(false);
      }
    };

    window.addEventListener('scroll', scrollListemer, { passive: true });
    return () => {
      window.removeEventListener('scroll', scrollListemer);
    };
  }, [fixedHeader]);

  const doSearch = (e) => {
    e.preventDefault();
    setQuickNavOpen(false);
    if (!keyword || !keyword.length) {
      setMobileOpen(!mobileOpen);
      return;
    }

    goToSearch(keyword);
    setKeyword('');
    setQuickNavOpen(false);
    setMobileOpen(false);
  };

  const doHome = () => {
    setQuickNavOpen(false);
    dispatch(uiState.actions.setIsUserNavOpen(false));
    goToHome(true);
  };

  const dispatchReduxEvent = (kovEvent) => {
    dispatch(kovEvent);
  };

  return (
    <section id="menu" className={`nav-container ${fixedHeader ? 'fixed-header' : ''}`}>
      <div id="menu-v2">
        <div className="kov-menu-primary container-fluid">
          <nav className="kov-navbar navbar navbar-expand-md navbar-light">
            <div
              className={`kov-navbar__brand navbar-brand d-md-inline-flex ${
                mobileOpen ? 'd-none ' : ''
              }`}
              title="katholiekonderwijs.vlaanderen"
              rel="katholiekonderwijs.vlaanderen"
              id="logo"
            >
              <a href="/" onClick={doHome}>
                <img className="kov-navbar__brand-logo" src={logoKovl} alt="logo" />
                <img className="kov-navbar__brand-fixed-logo" src={logoThemeKovL} alt="logo" />
              </a>
              <h2 onClick={doHome} className="kov-navbar-title-page">
                voor de onderwijs<span>professional</span>
              </h2>
            </div>

            <div className={`kov-navbar__collapse`}>
              <ul className="kov-navbar__nav">
                <li className={`kov-navbar__nav-item kov-navbar__nav-item--search`}>
                  <form className={`searchbar ${mobileOpen ? 'searchbar-open' : ''}`}>
                    <input
                      type="search"
                      className={`searchbar-input ${keyword && keyword.length ? 'has-value' : ''}`}
                      placeholder="Zoek"
                      value={keyword}
                      onKeyDown={(e) => (e.key === 'Enter' ? doSearch(e) : null)}
                      onChange={(e) => setKeyword(e.currentTarget.value)}
                      onFocus={() => {
                        dispatch(uiState.actions.setIsUserNavOpen(false));
                      }}
                    />
                    <span
                      className="close"
                      style={{ display: keyword !== '' ? 'block' : 'none' }}
                      onClick={() => {
                        setMobileOpen(false);
                        setKeyword('');
                      }}
                    >
                      <i className="icon-kov-pro-close" />
                    </span>

                    <input
                      type="submit"
                      className="searchbar-submit"
                      value="Zoek"
                      onClick={doSearch}
                    />
                    <span
                      className="searchbar-icon"
                      onClick={() => {
                        setMobileOpen(!mobileOpen);
                      }}
                    >
                      <i className="icon-kov-pro-search" aria-hidden="true" />
                    </span>
                  </form>
                </li>
                <li
                  className={`kov-navbar__nav-item dropdown kov-pro-menu-more ${
                    userKey ? 'kov-navbar__nav-item--avatar' : ''
                  } ${userNavOpen ? 'show' : ''}`}
                  data-more-menu-item
                >
                  <a
                    className="kov-navbar__nav-link"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={() => {
                      dispatch(uiState.actions.setIsUserNavOpen(!userNavOpen));
                      setQuickNavOpen(false);
                    }}
                  >
                    {userDetails ? (
                      <>
                        {userDetails.$$profilePicture ? (
                          <div
                            className="kov-navbar-avatar"
                            style={{
                              background: `url('${settings.apisAndUrls.api}${userDetails.$$profilePicture.$$expanded.href}') center center no-repeat`,
                            }}
                          />
                        ) : (
                          <div className="kov-navbar-avatar kov-navbar-avatar--no-photo">
                            {userDetails.initials && (
                              <>
                                {userDetails.initials[0] && (
                                  <span className="kov-initials__prename">
                                    {userDetails.initials[0]}
                                  </span>
                                )}
                                {userDetails.initials[1] && (
                                  <span className="kov-initials__surname">
                                    {userDetails.initials[1]}
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      <i className="icon-kov-pro-profile" />
                    )}
                  </a>
                  <div
                    className={`kov-dropdown-menu__wrapper dropdown-menu ${
                      userNavOpen ? 'show' : ''
                    }`}
                    aria-labelledby="dropdownMenuLink"
                    style={{ right: '-30px' }}
                  >
                    <ul className="kov-dropdown-menu">
                      {menuItems &&
                        menuItems.map((item, index) =>
                          item.type !== 'divider' ? (
                            <li key={index} className="kov-dropdown-menu__item dropdown-item">
                              <a
                                className="dropdown-item"
                                onClick={() => {
                                  if (item.event) {
                                    dispatch(uiState.actions.setIsUserNavOpen(false));
                                    dispatchEvent(item.event, true);
                                  }
                                  if (item.dispatch) {
                                    dispatchReduxEvent(item.dispatch);
                                  }
                                }}
                              >
                                <i className={item.icon} />
                                <span>{item.title}</span>
                              </a>
                            </li>
                          ) : (
                            <div key={index} className="divider-line" />
                          )
                        )}
                    </ul>
                  </div>
                </li>
                <li
                  className="kov-navbar__nav-item kov-navbar__nav-item--menu"
                  onClick={() => {
                    setQuickNavOpen(!quickNavOpen);
                    dispatch(uiState.actions.setIsUserNavOpen(false));
                  }}
                >
                  <a
                    className={`kov-navbar__nav-link open-overlay c-hamburger c-hamburger--htx ${
                      quickNavOpen ? '' : 'collapsed'
                    }`}
                    aria-label="macromenu"
                  >
                    <span></span>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <div className="kov-pro-gradient-wrapper">
          <div className={`kov-pro-gradient marquee ${loading ? 'loading' : ''}`} />
        </div>
        <div className="progress-container">
          <div className="progress-bar" id="myBar" />
        </div>

        {tree && quickNavOpen && (
          <QuickNavigation
            theme={sTheme}
            closeMenu={() => setQuickNavOpen(false)}
            settings={settings}
            urlTab={urlTab}
          />
        )}
      </div>
    </section>
  );
};

Header.propTypes = {
  sTheme: PropTypes.object,
  goToHome: PropTypes.func,
  goToSearch: PropTypes.func,
  locationPath: PropTypes.string,
  urlTab: PropTypes.string,
  dispatchEvent: PropTypes.func,
  loading: PropTypes.bool,
};

export default Header;
