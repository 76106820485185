import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@components/UI/icon/icon';

import { stripHtml } from '@src/app/utils';
import { useSelector } from 'react-redux';
import { selectCurrentSubTabHref } from '@src/reduxStore/navigation/navigationSelectors';
import {
  getUserStoredSelectedTabHref,
  setUserStoredSelectedTabHref,
} from '@src/helpers/navigationHelper';
import QuickSubNavigationItems from '../quickSubNavigationItems/quickSubNavigationItems';

require('./quickSubNavigation.scss');

const QuickSubNavigation = ({ subTree, activeItems, closeMenu, settings, urlTab }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [tabSubTree, setTabSubTree] = useState(null);
  const availableTabs = subTree.children;
  const userStoredSelectedTabHref = getUserStoredSelectedTabHref(
    availableTabs.map((avalTab) => avalTab.href)
  );
  const currentSubTabHref = useSelector(selectCurrentSubTabHref) || userStoredSelectedTabHref;

  useEffect(() => {
    const setTab = (tree, subTabHref = null) => {
      if (tree.multipleAlternativeMenus) {
        const visibleTree = tree.children.filter((item) => item.visible);
        let newActiveTab;

        if (subTabHref) {
          newActiveTab = visibleTree.findIndex((item) => item.href === subTabHref);
        } else if (activeItems.length > 1) {
          newActiveTab = visibleTree.findIndex((item) => item.key === activeItems[1].key);
        } else if (activeItems.length === 1) {
          newActiveTab = urlTab ? visibleTree.findIndex((item) => item.title === urlTab) : 0;
        } else if (activeItems.length === 0) {
          newActiveTab = 0;
        }
        newActiveTab = newActiveTab === -1 ? 0 : newActiveTab;

        setActiveTab(newActiveTab);
        setTabSubTree(visibleTree[newActiveTab]);
      }
    };

    (() => {
      if (subTree.multipleAlternativeMenus && activeItems && activeItems.length === 1 && !urlTab) {
        setTab(subTree, currentSubTabHref);
      } else {
        setTab(subTree);
      }
    })();
  }, [activeItems, subTree, urlTab, currentSubTabHref]);

  const changeTab = (tab, index) => {
    setActiveTab(index);
    setTabSubTree(tab);
    setUserStoredSelectedTabHref(tab.href);
  };

  return (
    <div className="quick-sub-navigation tab-content">
      {subTree.multipleAlternativeMenus ? (
        <div
          className="tab-pane fade active show"
          id="kov-theme-didactiek"
          role="tabpanel"
          aria-labelledby="kov-theme-didactiek-tab"
        >
          <a href={subTree.path} className="desktop-menu__service-title" onClick={closeMenu}>
            {subTree.icon && <Icon icon={subTree.icon} style={{ fill: subTree.color }} />}
            <span dangerouslySetInnerHTML={{ __html: stripHtml(subTree.title) }} />
          </a>
          <div className="tabbable-line">
            <ul className="nav nav-tabs">
              {subTree.children
                .filter((tab) => tab.visible)
                .map((tab, index) => (
                  <li className="desktop-menu__niveau-item" key={index}>
                    <a
                      className={activeTab === index ? 'active' : ''}
                      data-toggle="tab"
                      onClick={() => changeTab(tab, index)}
                      style={{ borderBottomColor: tab.color }}
                    >
                      <span dangerouslySetInnerHTML={{ __html: stripHtml(tab.title) }} />
                    </a>
                  </li>
                ))}
            </ul>
            <div className="tab-content">
              <div className="tab-pane active">
                <QuickSubNavigationItems
                  subTree={tabSubTree}
                  active={activeItems[3] || activeItems[2]}
                  closeMenu={closeMenu}
                  settings={settings}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="tab-pane fade active show" role="tabpanel" aria-expanded="true">
          <a href={subTree.path} className="desktop-menu__service-title" onClick={closeMenu}>
            {subTree.icon && <Icon icon={subTree.icon} style={{ fill: subTree.color }} />}
            <span dangerouslySetInnerHTML={{ __html: stripHtml(subTree.title) }} />
          </a>
          <QuickSubNavigationItems
            subTree={subTree}
            active={activeItems[2] || activeItems[1]}
            closeMenu={closeMenu}
            settings={settings}
          />
        </div>
      )}
    </div>
  );
};

QuickSubNavigation.propTypes = {
  subTree: PropTypes.object,
  activeItems: PropTypes.array,
  closeMenu: PropTypes.func,
  settings: PropTypes.object,
  urlTab: PropTypes.string,
  getSelectedTab: PropTypes.func,
  persistSelectedTab: PropTypes.func,
};

export default QuickSubNavigation;
