const settings = require('../config/settings.js');
const constants = require('../components/constants.js');

module.exports = {
  getImage(item, type, ifNotFound) {
    if (item.attachments) {
      const image = item.attachments.find((o) => o.type === type);

      if (image) {
        return {
          href: image.href.startsWith('/content')
            ? settings.apisAndUrls.cachedContentApi +
              encodeURI(image.href) +
              (sessionStorage.getItem('preview') ? `?hash=${item.$$meta.hash}` : '')
            : image.href,
          description: image.description,
          rightsHolder: image.rightsHolder,
          sizes: item.attachments.filter((o) => o.type === type && o.resized),
          contentType: image.contentType,
        };
      }
    }
    return ifNotFound || null;
  },

  getImageWithSize(item, type, size, ifNotFound, cache = true) {
    const image = module.exports.getImage(item, type);

    if (image && image.sizes) {
      let sizedImage = null;
      if (size.w && size.h) {
        sizedImage = image.sizes.find((o) => o.width === size.w && o.height === size.h);
      } else {
        sizedImage = image.sizes.find((o) => o.width === size.w);
      }
      if (image.sizes && sizedImage) {
        const baseUrl = cache
          ? settings.apisAndUrls.cachedContentApi
          : settings.apisAndUrls.contentApi;
        const hash = sessionStorage.getItem('preview') && cache ? `?hash=${item.$$meta.hash}` : '';
        return {
          href: sizedImage.href.startsWith('/content')
            ? `${baseUrl}${encodeURI(sizedImage.href)}${hash}`
            : image.href,
          description: image.description ? image.description : null,
          rightsHolder: image.rightsHolder ? image.rightsHolder : null,
          dimension: size || {},
        };
      }
      return image;
    }
    return ifNotFound || null;
  },

  isBase64(input) {
    if (input == null) return false;
    const reducedString = input.substring(0, 100);
    const mimeRegex = /(data:\w+\/[a-zA-Z+\-.]+;base64,)/gm;

    return mimeRegex.test(reducedString);
  },

  getAttachmentHref(attachment) {
    if (!attachment?.href) return '';
    if (module.exports.isBase64(attachment.href) || attachment.href.startsWith('http')) {
      return attachment.href;
    } else {
      let apiEndPoint;
      // images need to point to the API and documents need to point to PRO itself
      if (/^image/.test(attachment?.contentType)) {
        apiEndPoint = settings.apisAndUrls.api;
      } else {
        apiEndPoint = settings.apisAndUrls.downloadUrl;
      }
      return `${apiEndPoint}${attachment.href}`;
    }
  },

  getAttachment(item, types) {
    if (!item.attachments) return null;
    const attachment = item.attachments.find((o) => types.includes(o.type));
    if (attachment && attachment.href) {
      return {
        href: module.exports.getAttachmentHref(attachment),
        description: attachment.description ? attachment.description : attachment.name,
        rightsHolder: attachment.rightsHolder ? attachment.rightsHolder : null,
        contentType: attachment.contentType,
        name: attachment.name,
        size: attachment.size ? module.exports.calculateSize(attachment.size) : '-',
      };
    }
    return null;
  },

  addRequirements(item) {
    if (item.$$relationsTo) {
      const requirements = item.$$relationsTo.filter(
        (o) => o.$$expanded.relationtype === 'REQUIRES'
      );
      return requirements.map((o) => o.$$expanded.from.href);
    }
    return [];
  },

  addReferences(item) {
    if (item.$$relationsFrom) {
      const references = item.$$relationsFrom.filter(
        // o => o.$$expanded.relationtype === 'REFERENCES' && o.$$expanded.to.href.startsWith('/content'),
        (o) => o.$$expanded.relationtype === 'REFERENCES'
      );
      return references.map((o) => o.$$expanded.to.href);
    }
    return [];
  },

  calcExternalLink(item) {
    if (item.type === 'REFERENCE') {
      const extRef = item.$$relationsFrom.find(
        (o) =>
          o.$$expanded.relationtype === 'REFERENCES' && !o.$$expanded.to.href.startsWith('/content')
      );
      if (extRef) {
        const { href } = extRef.$$expanded.to;
        return href.startsWith('http') ? href : `http://${href}`;
      }
    }
    return null;
  },

  calcVideoPlayback(item) {
    if (item.type === 'VIDEO') {
      const videoItem = item.attachments.find((o) => o.type === 'VIDEO');
      if (videoItem) {
        const url = videoItem.playbackUrl;
        if (url !== undefined && url !== '') {
          const regExpYoutube = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
          const regExpVimeo =
            /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i;
          const matchYoutube = url.match(regExpYoutube);
          const matchVimeo = url.match(regExpVimeo);

          if (matchYoutube && matchYoutube[2].length === 11) {
            return `https://youtube.com/embed/${matchYoutube[2]}`;
          }
          if (matchVimeo && matchVimeo[1].length === 9) {
            return `https://player.vimeo.com/video/${matchVimeo[1]}`;
          }
        }
      }
    }

    return null;
  },
  calculateSize(sizeInBytes) {
    if (sizeInBytes === undefined) return '-';

    // Check if sizeInBytes has been formatted already (contains KB, MB, ...)
    // If true, just return the formatted string.
    // For example: This will be true for attachement sizes in preview mode.
    var regExp = /[a-zA-Z]/g;
    if (regExp.test(sizeInBytes)) return sizeInBytes;

    // Make sure sizeInBytes is a number.
    if (typeof sizeInBytes === 'string') sizeInBytes = parseInt(sizeInBytes);

    if (sizeInBytes >= 1048576) {
      const sizeInMB = Number(sizeInBytes / 1024 / 1024).toFixed(0);
      return `${sizeInMB}MB`;
    }

    const sizeInKB = Number(sizeInBytes / 1024).toFixed(0);

    if (sizeInKB === '0') {
      return '1KB';
    } else {
      return `${sizeInKB}KB`;
    }
  },

  getAttachmentType(attachment) {
    return attachment && attachment.contentType ? constants.FILE_TYPES[attachment.contentType] : '';
  },

  buildStructuredDocument(contents) {
    // ALERT!! coming from selectPrimaryReferenceFrame items ONLY

    let root = contents.find((a) => a.href === constants.NAVIGATION.PRIMARY_REFERENCE_FRAME);
    root = addItems(root, contents, 1);

    return root;

    function addItems(item, items, level) {
      const newItem = { ...item };
      const isPartOfRelations = newItem.$$relationsTo
        .filter((o) => ['IS_PART_OF', 'IS_INCLUDED_IN'].includes(o.$$expanded.relationtype))
        .sort((a, b) => a.$$expanded.readorder - b.$$expanded.readorder);
      if (isPartOfRelations.length > 0) {
        newItem.children = [];
        isPartOfRelations.forEach((relation) => {
          let foundItem = items.find((a) => a.href === relation.$$expanded.from.href);
          if (foundItem) {
            foundItem = {
              ...foundItem,
              parent: newItem,
            };

            if (foundItem.$$relationsTo && foundItem.$$relationsTo.length > 0)
              foundItem = addItems(foundItem, items, level + 1);

            newItem.children.push({ ...foundItem, parent: null });
          }
        });
      }
      return newItem;
    }
  },

  getVisibleSectionsOfChildren(children) {
    return (
      children &&
      children
        .filter((i) => i.visible)
        .filter((o) => o.type === 'SECTION')
        .filter((o) => o.pageType !== null)
        .filter((o) => o.pageType !== 'BLOG_ITEM')
        .filter((o) => o.pageType !== 'MINI_DATABASE_ITEM')
    );
  },

  isShieldedContent(contentRights) {
    if (!contentRights) return false;
    return !contentRights.includes(constants.SECURITYLEVELS.PUBLIC);
  },

  getNameAndExtension(fileName) {
    if (!fileName) {
      return {
        file_extension: '',
        file_name: '',
      };
    }
    const nameParts = fileName.split('.');
    return {
      file_extension: nameParts.length === 1 ? '' : nameParts.pop().toLowerCase(),
      file_name: decodeURIComponent(nameParts.join('.')).toLowerCase(),
    };
  },
};
