import React from 'react';
import PropTypes from 'prop-types';
import ThemeHome from './themeHome';
import ThemeCard from '../themeCard/themeCard';

const ThemeHome1 = ({ sTheme, locationPath }) => {
  const cards = sTheme.children.filter((o) => o.type === 'SECTION' && o.visible);

  return (
    <ThemeHome sTheme={sTheme} locationPath={locationPath}>
      {cards.map((item, index) => (
        <div key={index} className="col-12 col-sm-12 col-md-6 col-lg-4">
          <ThemeCard item={item} color={sTheme.root.color} description={false} />
        </div>
      ))}
    </ThemeHome>
  );
};

ThemeHome1.propTypes = {
  sTheme: PropTypes.object,
  locationPath: PropTypes.string,
};

export default ThemeHome1;
