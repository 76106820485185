import React, { useMemo, useState } from 'react';
import SidebarPortal from '@components/sections/sidebar/SidebarPortal';
import { getVisibleSectionsOfChildren } from '@src/app/contentUtils';
import { ThemeMenu } from '@components/database/components';
import { useMediaQuery } from '@react-hook/media-query';
import Banner from '@src/reactComponents/sections/banner/banner';
import NewsFilters from '@src/reactComponents/news/newsFilters/newsFilters';
import NewsResults from '@components/news/newsResults/newsResults';
import PropTypes from 'prop-types';
import { getOrderMethod } from '@components/database/helpers/helpers';
import useNewsDatabase from './hooks/useNewsDatabase';

const initialUrlParams = new URLSearchParams(window.location.search);

const NewsDatabase = ({ proTheme }) => {
  const isTablet = useMediaQuery('screen and (max-width: 992px)');
  const [filtersSidebarOpen, setFiltersSidebarOpen] = useState(false);
  const [secondaryFilterValue, setSecondaryFilterValue] = useState(
    initialUrlParams.has('curricula') ? 'curricula' : 'theme'
  );
  const {
    doSearch,
    filterUpdate,
    updateOrder,
    clearFilters,
    clearSearchQuery,
    filters,
    searchResults,
    searchCount,
    isLoading,
    loadMoreSearchResults,
  } = useNewsDatabase(proTheme);

  const numFiltersApplied = useMemo(() => {
    let numFiltersAppliedResult = 0;
    numFiltersAppliedResult += filters.q.length > 0 ? 1 : 0;
    numFiltersAppliedResult += filters.van != null ? 1 : 0;
    numFiltersAppliedResult += filters.tot != null ? 1 : 0;
    numFiltersAppliedResult += filters.menuItem != null ? 1 : 0;
    numFiltersAppliedResult += filters.curricula.length > 0 ? 1 : 0;

    return numFiltersAppliedResult;
  }, [filters]);

  let detailMenuItems;
  const isProTheme = useMemo(() => proTheme != null, [proTheme]);

  if (isProTheme) {
    const filteredMenuItems = getVisibleSectionsOfChildren(proTheme.children);
    if (filteredMenuItems.length === 1) {
      detailMenuItems = filteredMenuItems.length
        ? [{ ...proTheme, parent: proTheme, type: 'SECTION' }, ...filteredMenuItems]
        : [];
    } else {
      detailMenuItems = proTheme.children;
    }
  }
  return (
    <>
      <Banner
        menuItem={{ websiteType: 'news', title: 'Nieuws' }}
        theme={proTheme}
        onUpdateSubCurricula={(curricula) => filterUpdate('curricula', curricula)}
      />
      <section id="theme-page" style={{ padding: '0 0 50px' }}>
        {isProTheme && (
          <ThemeMenu theme={proTheme} items={detailMenuItems} item={false} database={undefined} />
        )}

        <div className="container">
          {isProTheme && (
            <div className="kov-pro-theme-main-content-title">
              <h1>Nieuws</h1>
            </div>
          )}

          <div className="kov-pro-theme-main-content row">
            {!isTablet && (
              <>
                <div className="kov-sidebar__wrapper" id="kov-sidenav-filter">
                  <NewsFilters
                    proTheme={proTheme}
                    filters={filters}
                    onSearch={doSearch}
                    onClearFilters={clearFilters}
                    onClearSearchQuery={clearSearchQuery}
                    onUpdateFilter={(filterName, value) => filterUpdate(filterName, value)}
                    searchTerm={filters.q}
                    secondaryFilterValue={secondaryFilterValue}
                    setSecondaryFilterValue={setSecondaryFilterValue}
                  />
                </div>
                <div className="kov-pro-content col-12 col-md-12 col-lg-9">
                  <NewsResults
                    newsItems={searchResults}
                    newsItemCount={searchCount}
                    loading={isLoading}
                    loadMore={loadMoreSearchResults}
                    order={getOrderMethod(filters.order, filters.q)}
                    onOrderChange={(value) => {
                      updateOrder(value);
                    }}
                  />
                </div>
              </>
            )}
          </div>
          {isTablet && (
            <div className={'kov-pro-theme-main-content col-12 col-md-12 col-lg-9'}>
              <div className="kov-pro-filter--mobile d-flex d-lg-none">
                <button
                  onClick={() => setFiltersSidebarOpen((prev) => !prev)}
                  className="kov-pro-btn kov-pro-btn--dark kov-pro-btn__icon--left kov-pro-btn--inverted kov-pro-btn--filter kov-pro-btn--block"
                >
                  <i className="icon-kov-pro-filter" />
                  Filter
                  {numFiltersApplied > 0 && (
                    <span className="filter-counter">{numFiltersApplied}</span>
                  )}
                </button>
              </div>
              <NewsResults
                newsItems={searchResults}
                newsItemCount={searchCount}
                loading={isLoading}
                loadMore={loadMoreSearchResults}
                order={getOrderMethod(filters.order, filters.q)}
                onOrderChange={(value) => {
                  updateOrder(value);
                }}
              />
            </div>
          )}
        </div>
      </section>

      {isTablet && (
        <SidebarPortal open={filtersSidebarOpen} onClose={() => setFiltersSidebarOpen(false)}>
          <NewsFilters
            proTheme={proTheme}
            filters={filters}
            onSearch={() => {
              doSearch();
              setFiltersSidebarOpen(false);
            }}
            onClearFilters={clearFilters}
            onClearSearchQuery={clearSearchQuery}
            onUpdateFilter={(filterName, value) => filterUpdate(filterName, value)}
            fullWidth
            searchTerm={filters.q}
            secondaryFilterValue={secondaryFilterValue}
            setSecondaryFilterValue={setSecondaryFilterValue}
          />
        </SidebarPortal>
      )}
    </>
  );
};

NewsDatabase.propTypes = {
  proTheme: PropTypes.object,
};

export default NewsDatabase;
