import React from 'react';
import { getSitemapPathFromSource } from '@src/services/sitemapService';

const AccordionBlock = ({ accordionTitle, accordionId, isActive, updateActiveSections, items }) => {
  const handleClick = () => {
    updateActiveSections();
  };

  return (
    <div className="kov-pro-subjects-accordion">
      <div className="kov-pro-subjects-accordion__wrapper">
        <div className="kov-pro-subjects-accordion__header">
          <button
            className={`btn btn-link ${isActive ? '' : 'collapsed'}`}
            onClick={handleClick}
            aria-expanded="true"
          >
            {accordionTitle}
            <i className="icon-kov-pro-down-arrow" />
          </button>
        </div>
        <div
          id={accordionId}
          className={`kov-pro-subjects-accordion__body collapse ${isActive ? 'show' : ''}`}
        >
          <div>
            <ul
              className={`kov-subjects__list kov-subjects__list--temp kov-subjects__list--temp-${accordionId}`}
            >
              {items.map((item, innerIndex) => (
                <li key={innerIndex}>
                  <a className="kov-subjects__tile" href={getSitemapPathFromSource(item.href)}>
                    <div className="kov-subjects__img-wrapper">
                      <div className="kov-subjects__img" />
                    </div>
                    <div className="kov-subjects__info">
                      <div className="kov-subjects__title">{item.title}</div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccordionBlock;
