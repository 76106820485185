import React, { useEffect, useState } from 'react';
import LocalTrainingFilters from '@src/reactComponents/trainings/localTrainings/localTrainingFilters/localTrainingFilters';
import useTrainings from '@src/hooks/useTrainings';
import { ThemeMenu } from '@components/database/components';
import { getVisibleSectionsOfChildren } from '@src/app/contentUtils';
import PropTypes from 'prop-types';
import Banner from '@src/reactComponents/sections/banner/banner';
import NewsSection from '@src/reactComponents/theme/newsSection/newsSection';
import LocalTrainingResults from './localTrainingResults/localTrainingResults';

const LocalTrainings = ({ proTheme, locationPath }) => {
  const [mobileFilterShow, setMobileFilterShow] = useState(false);

  let detailMenuItems;
  if (proTheme != null) {
    const filteredMenuItems = getVisibleSectionsOfChildren(proTheme.children);
    if (filteredMenuItems.length === 1) {
      detailMenuItems = filteredMenuItems.length
        ? [{ ...proTheme, parent: proTheme, type: 'SECTION' }, ...filteredMenuItems]
        : [];
    } else {
      detailMenuItems = proTheme.children;
    }
  }

  const {
    updateFilter,
    updateCheckboxFilter,
    dispatchSearch,
    dispatchLoadMore,
    dispatchOrderUpdate,
    viewModel,
    trainingsFiltersCnt,
    resetFiltersToViewOptions,
  } = useTrainings();

  const doSearch = () => {
    setMobileFilterShow(false);
    dispatchSearch();
  };

  /**
   * Everytime the user lands in the local trainings we trigger a search.
   */
  useEffect(() => {
    doSearch();
  }, []);

  return (
    <>
      <Banner
        menuItem={{ websiteType: 'trainings' }}
        theme={proTheme}
        onUpdateSubCurricula={(curricula) => dispatchSearch({ curricula })}
      />
      <section id="theme-page">
        <div className="container">
          <div className="row">
            <ThemeMenu theme={proTheme} items={detailMenuItems} current="training" />
          </div>
        </div>
        <div className="divider-line" />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="kov-pro-theme-main-content-title">
                <h1>Professionalisering</h1>
              </div>
            </div>
            <LocalTrainingFilters
              doSearch={doSearch}
              cleanAllFilters={resetFiltersToViewOptions}
              viewModel={viewModel}
              updateFilter={updateFilter}
              updateCheckboxFilter={updateCheckboxFilter}
              mobileFilterShow={mobileFilterShow}
              setMobileFilterShow={() => setMobileFilterShow(!mobileFilterShow)}
            />
            <LocalTrainingResults
              onSetOrder={(filterKey, filterValue) => dispatchOrderUpdate(filterValue)}
              onLoadMore={dispatchLoadMore}
              viewModel={viewModel}
              setMobileFilterShow={() => setMobileFilterShow(!mobileFilterShow)}
              trainingsFiltersCnt={trainingsFiltersCnt}
            />
          </div>
        </div>
      </section>
      {proTheme && (
        <NewsSection
          locationPath={locationPath}
          sReferenceFrame={proTheme.referenceFrameItem}
          sThemePath={proTheme.pagePath}
        />
      )}
    </>
  );
};

LocalTrainings.propTypes = {
  proTheme: PropTypes.object,
  locationPath: PropTypes.string,
};

export default LocalTrainings;
