import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userDetails: null,
  userLoginMethod: undefined,
  userKey: undefined, // possible states: undefined (not fetched) || null (not logged) || string (logged)
  userAccess: [],
  userNewsletterPreferencesResponse: [],
  userPrivateState: {},
  curriculaSelections: [],
  userTargetGroup: undefined,
  viewOptions: undefined,
  isUserKOV: undefined,
};

const userState = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setUserLoginMethod: (state, action) => {
      state.userLoginMethod = action.payload;
    },
    setIsUserKOV: (state, action) => {
      state.isUserKOV = action.payload;
    },
    setUserKey: (state, action) => {
      state.userKey = action.payload;
    },
    setUserAccess: (state, action) => {
      state.userAccess = action.payload;
    },
    setUserTargetGroup: (state, action) => {
      state.userTargetGroup = action.payload;
    },
    updateUserNewsletterPreferencesState: (state, action) => {
      let newState;
      if (state.userNewsletterPreferencesResponse.length && !action.payload.refresh) {
        newState = state.userNewsletterPreferencesResponse.map((pref) =>
          pref.$$meta.permalink === action.payload.response.$$meta.permalink
            ? action.payload.response
            : pref
        );
      } else {
        newState = action.payload.response;
      }
      state.userNewsletterPreferencesResponse = newState;
    },
    setUserCurriculaSelection: (state, action) => {
      state.curriculaSelections = action.payload;
    },
    updateUserPrivateState: (state, action) => {
      const newState = { ...state.userPrivateState, ...action.payload };
      state.userPrivateState = newState;
    },
    setViewOptions: (state, action) => {
      state.viewOptions = action.payload;
    },
  },
});

export const userStateActions = userState.actions;
export default userState;
