const settings = require('@src/config/settings');
const { loaderStart, loaderEnd } = require('@src/helpers/loaderHelper.js');
const store = require('@src/reduxStore/store').default;
const { stateMatch } = require('redux-awaitable-state');
const {
  selectItemInCurrentThemeTree,
  selectCurrentThemeTree,
} = require('@src/reduxStore/theme/themeSelectors');

class wsDatabase {
  constructor($stateParams, $location, routerService, $scope, $ngRedux) {
    'ngInject';

    this.$stateParams = $stateParams;
    this.$location = $location;
    this.routerService = routerService;
    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  async $onInit() {
    if (this.$location.path() !== settings.proGlobalDatabase.url) {
      await stateMatch((state) => selectCurrentThemeTree(state));

      this.unsubscribe = this.$ngRedux.connect((state) => {
        // this is an exception becuase of this ticket => https://katholiek-onderwijs-vlaanderen.monday.com/boards/5059664922/pulses/5256281427
        // Everything is introduced here (main branch): https://bitbucket.org/vskovzw/pro/src/f9d00eefe21fcc608f25548d32ba6d765b2b4ba8/src/services/themeService.js#lines-456
        // Obviusly that is also present after the themeService refactor but in a different line.
        // We should try to detect the original problem and fix it or use below as a temporary fix
        // for those pages and fix it later (ASAP)
        const potentialPage = selectItemInCurrentThemeTree(state, this.$stateParams.source);
        let page =
          potentialPage.children.find((a) => a.href === potentialPage.href) || potentialPage;

        this.$scope.$emit('dynamicMetaDataUpdated', page);
        return {
          current: page,
        };
      })(this);
    } else {
      loaderStart();
      this.current = {
        ...this.routerService.getGlobalDatabase(),
        title: 'Inspirerend materiaal',
        children: [],
        isGlobalDatabase: true,
      };
      loaderEnd();
    }
    this.$scope.$emit('dynamicMetaDataUpdated', this.current);
  }

  $onDestroy() {
    if (this.unsubscribe) this.unsubscribe();
  }
}

module.exports = {
  template: require('./wsDatabase.html'),
  controllerAs: 'ctrl',
  controller: wsDatabase,
  bindings: {
    sTheme: '<',
  },
};
