const store = require('@src/reduxStore/store').default;
const { stateMatch } = require('redux-awaitable-state');
const {
  selectItemInCurrentThemeTree,
  selectCurrentThemeTree,
} = require('@src/reduxStore/theme/themeSelectors');

class wsThemeDownload {
  constructor($scope, $stateParams, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$stateParams = $stateParams;
    this.$ngRedux = $ngRedux;
  }

  async $onInit() {
    await stateMatch((state) => selectCurrentThemeTree(state));

    this.unsubscribe = this.$ngRedux.connect((state) => {
      // this is an exception becuase of this ticket => https://katholiek-onderwijs-vlaanderen.monday.com/boards/5059664922/pulses/5256281427
      // Everything is introduced here (main branch): https://bitbucket.org/vskovzw/pro/src/f9d00eefe21fcc608f25548d32ba6d765b2b4ba8/src/services/themeService.js#lines-456
      // Obviusly that is also present after the themeService refactor but in a different line.
      // We should try to detect the original problem and fix it or use below as a temporary fix
      // for those pages and fix it later (ASAP)
      const potentialPage = selectItemInCurrentThemeTree(state, this.$stateParams.source);
      const page =
        potentialPage.children.find((a) => a.href === potentialPage.href) || potentialPage;

      this.$scope.$emit('dynamicMetaDataUpdated', page);

      return {
        page,
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }
}

module.exports = {
  template: require('./wsThemeDownload.html'),
  controllerAs: 'ctrl',
  controller: wsThemeDownload,
  bindings: {
    sTheme: '<',
  },
};
