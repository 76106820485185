const constants = require('../components/constants');

export function sortTeaserByDateAndPriority(teasers, priority) {
  teasers = teasers.sort((a, b) => {
    const dateA = new Date(a.issued); // change .created to .issued
    const dateB = new Date(b.issued); // change .created to .issued
    return dateB - dateA;
  });

  if (priority) {
    const priorityTeasers = [];
    const normalTeasers = [];
    teasers.forEach((teaser) => {
      if (teaser.importance === 'HIGH') {
        priorityTeasers.push(teaser);
      } else {
        normalTeasers.push(teaser);
      }
    });

    teasers = priorityTeasers.concat(normalTeasers);
  }

  return teasers;
}

export function getApplicabilityLabels(items) {
  return items.map((item) => {
    if (!item.coverage) item.coverage = [];
    const mainstructuresOuTypeCombinations = item.mainstructuresOuTypeCombinations || [];

    const regions = item.coverage.map((d) => {
      const region = constants.getRegions().find((r) => r.href === d);
      return region.tag;
    });
    const levels = mainstructuresOuTypeCombinations.map((d) => {
      const level = constants
        .getEducationLevels()
        .find((r) => r.mainstructuresOuTypeCombination === d);
      return level.tag;
    });

    return {
      ...item,
      tags: [...new Set([...levels, ...regions].filter((tag) => tag))],
      mainstructuresOuTypeCombinations,
    };
  });
}
