import React from 'react';

const ProgressBar = ({ length, count }) => (
  <React.Fragment>
    <p className="text-center">
      <span>{length} </span>
      van
      <span> {count}</span>
    </p>
    <div className="progress-tracker">
      <div className="progress">
        <div
          data-percentage="0%"
          style={{ width: `${(length / count) * 100}%` }}
          className="progress-bar"
          role="progressbar"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
  </React.Fragment>
);

export default ProgressBar;
