import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { clearUrlLocationHash } from '@src/helpers/utils';
import LoadingResultsPlaceholder from '@UI/loadingResultsPlaceholder/loadingResultsPlaceholder';
import NoResultsPlaceholder from '@UI/noResultsPlaceholder/noResultsPlaceholder';
import { OrderMethods } from '@components/database/constants';
import ProgressBar from '@UI/progressBar/progressBar';
import LoadMore from '@UI/loadMore/loadMore';
import DatabaseOrder from '@components/database/components/DatabaseOrder';
import NewsResult from './newsResult/newsResult';

const orderTypes = [OrderMethods.RELEVANCE, OrderMethods.NEWEST, OrderMethods.OLDEST];

const NewsResults = ({ newsItems, newsItemCount, loading, loadMore, onOrderChange, order }) => {
  const newItemResultRef = useRef(null);
  if (!loading && newsItems?.length > 0) {
    setTimeout(() => {
      if (newItemResultRef?.current) {
        const itemTop = newItemResultRef.current.getBoundingClientRect().top;

        window.scrollTo({
          top: itemTop - 160,
          behavior: 'smooth',
        });

        clearUrlLocationHash();
      }
    }, 500);
  }

  return (
    <div className="kov-pro-news__roll">
      {newsItems.length > 0 ? (
        <>
          <div className="kov-pro-sorter">
            <span className="kov-label" style={{ padding: '0 5px' }}>
              {newsItemCount}
            </span>
            <DatabaseOrder
              order={order || OrderMethods.NEWEST}
              onSetOrder={onOrderChange}
              orderTypes={orderTypes}
            />
          </div>
          <ul className="kov-pro-news-teaser-list">
            {newsItems.map((newsItem, index) => (
              <NewsResult
                key={newsItem.key}
                newsItem={newsItem}
                childRef={`#${index}` === window.location.hash ? newItemResultRef : null}
              />
            ))}
            {loading && <LoadingResultsPlaceholder />}
          </ul>
        </>
      ) : (
        <>
          {loading ? (
            <>
              <div className="kov-pro-sorter">
                <span className="kov-label" style={{ padding: '0 5px' }}>
                  {newsItemCount}
                </span>
                <DatabaseOrder
                  order={order || OrderMethods.NEWEST}
                  onSetOrder={onOrderChange}
                  orderTypes={orderTypes}
                />
              </div>
              <ul className="kov-pro-news-teaser-list">
                <LoadingResultsPlaceholder />
              </ul>
            </>
          ) : (
            <NoResultsPlaceholder />
          )}
        </>
      )}

      {newsItems.length < newsItemCount && (
        <div className="kov-pro-load-more col-12">
          <ProgressBar length={newsItems.length} count={newsItemCount} />
          <LoadMore onLoadMore={loadMore} loadingBarText={'Laad meer nieuws'} />
        </div>
      )}
    </div>
  );
};

NewsResults.propTypes = {
  newsItems: PropTypes.array,
  newsItemCount: PropTypes.number,
  loading: PropTypes.bool,
  loadMore: PropTypes.func,
  onOrderChange: PropTypes.func,
  order: PropTypes.string,
};

export default NewsResults;
