export const getResults = (state) => state.database.results;
export const getFilters = (state) => state.database.filters;
export const getTempFilters = (state) => state.database.tempFilters;
export const getLimit = (state) => state.database.limit;
export const getSearchTerm = (state) => state.database.searchTerm;
export const getLoading = (state) => state.database.loading;

export default {
  getResults,
  getFilters,
  getTempFilters,
  getLimit,
  getSearchTerm,
  getLoading,
};
