import React, { useEffect, useState } from 'react';
import './icon.scss';

const cachedIcons = new Map();

const Icon = ({ icon, style, directUrl, className = 'stylish-icon' }) => {
  const { contentType, href } = icon;
  const [iconElement, setIconElement] = useState(cachedIcons.get(href));

  useEffect(() => {
    const abortController = new AbortController();

    if (!contentType || contentType === 'image/svg+xml') {
      const apexDomain = new URL(href).host.split('.').slice(-2).join('.');
      if (apexDomain && apexDomain === 'katholiekonderwijs.vlaanderen') {
        fetch(href, { signal: abortController.signal })
          .then((res) => res.text())
          .then((text) => {
            cachedIcons.set(href, text);
            setIconElement(text);
          })
          .catch((error) => console.log(error));
      }
    }

    return () => {
      abortController.abort();
    };
  }, [contentType, href]);

  return (
    <span className={className}>
      {(!contentType || contentType === 'image/svg+xml') && (
        <i dangerouslySetInnerHTML={{ __html: iconElement }} style={style} />
      )}
      {contentType && contentType !== 'image/svg+xml' && (
        <img src={icon.href || directUrl} style={style} />
      )}
    </span>
  );
};

export default Icon;
