import React from 'react';
import LoadingResultsPlaceholder from '@UI/loadingResultsPlaceholder/loadingResultsPlaceholder';
import NoResultsPlaceholderGlobalSearch from './noResultsPlaceholderGlobalSearch';
import GlobalSearchResult from './globalSearchResult';
import LoadMore from '../../UI/loadMore/loadMore';
import ProgressBar from '../../UI/progressBar/progressBar';

const GlobalSearchResults = ({
  results,
  error,
  isLoading,
  searchCount,
  loadMoreResults,
  typesFilter,
  scopeFilter,
  currentTheme,
  searchTerm,
}) => (
  <>
    {isLoading && !error && <LoadingResultsPlaceholder />}
    {!isLoading && !error && results.length === 0 && (
      <NoResultsPlaceholderGlobalSearch
        typesFilter={typesFilter}
        scopeFilter={scopeFilter}
        currentTheme={currentTheme}
      />
    )}
    {
      error && (
        <div className="kov-pro-no-search-results">
          <div className="kov-pro-no-search-results__icon">
            <i className="icon-kov-pro-search-results" />
          </div>
          <div className="kov-pro-no-search-results__title">Oeps!</div>
          <p className="kov-pro-no-search-results__text">
            Er is een technisch probleem met de zoekmachine. <br />
            Probeer het later nog eens.
          </p>
        </div>
      ) /* TODO PUT error placeholder here provided by Oetang! */
    }
    <ul className="kov-pro-result-list">
      {results.map((result, index) => (
        <GlobalSearchResult
          key={index}
          result={result}
          searchResultIndex={index}
          searchTerm={searchTerm}
        />
      ))}
      {!isLoading && !error && searchCount > results.length && (
        <div className="kov-pro-load-more col-12">
          <ProgressBar length={results.length} count={searchCount} />
          <LoadMore onLoadMore={loadMoreResults} actionButtonText={'Laad meer'} />
        </div>
      )}
    </ul>
  </>
);

export default GlobalSearchResults;
