import React from 'react';
import PropTypes from 'prop-types';
import ThemeCard2 from '@components/theme/themeCard/themeCard2';
import ThemeHome from './themeHome';

const ThemeHome2 = ({ sTheme, locationPath }) => {
  const cards = sTheme.children.filter((o) => o.type === 'SECTION' && o.visible);

  let styleTag = '';
  if (sTheme) {
    if (sTheme.root && sTheme.root.color) {
      styleTag = `.kov-pro-hometile--text .kov-pro-hometile__body:after { border-left: solid 4px ${sTheme.root.color}; !important; }`;
    }
  }

  return (
    <ThemeHome sTheme={sTheme} locationPath={locationPath}>
      {cards.map((item, index) => (
        <ThemeCard2 key={index} sTheme={sTheme} item={item} />
      ))}
      <style dangerouslySetInnerHTML={{ __html: styleTag }} />
    </ThemeHome>
  );
};

ThemeHome2.propTypes = {
  sTheme: PropTypes.object,
  locationPath: PropTypes.string,
};

export default ThemeHome2;
