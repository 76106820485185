import React from 'react';
import NoResultsPlaceholder from '@UI/noResultsPlaceholder/noResultsPlaceholder';
import { getAncestorPathsToChannel } from '@src/reduxStore/theme/themeStateHelper';
import { getLoginUrl } from '@src/helpers/userHelper';
import { useSelector } from 'react-redux';
import { getUserKey } from '@src/reduxStore/user/userSelectors';

const CURRICULUM_PAGE = '/content/4329fb23-9830-4a44-912c-bf8e0d1b2d8b';

const NoResultsPlaceholderGlobalSearch = ({ typesFilter, scopeFilter, currentTheme }) => {
  const userKey = useSelector(getUserKey);

  if (scopeFilter && typesFilter.length !== 0) {
    const themeAncestors = getAncestorPathsToChannel(scopeFilter);
    const emptyTypes = themeAncestors.some((path) => path.includes(CURRICULUM_PAGE))
      ? ['PRO_PAGES', 'JOB_OFFERS']
      : ['CURRICULA', 'JOB_OFFERS'];
    if (typesFilter.every((type) => emptyTypes.includes(type))) {
      return (
        <NoResultsPlaceholder>
          {typesFilter.includes('JOB_OFFERS') && (
            <>
              Vacatures zijn niet gekoppeld aan onze thema&apos;s.
              <br />
              Zoek in de volledige PRO.-website om naar vacatures te kunnen zoeken.
            </>
          )}
          {typesFilter.includes('JOB_OFFERS') &&
            (typesFilter.includes('PRO_PAGES') || typesFilter.includes('CURRICULA')) && (
              <>
                <br />
                <br />
              </>
            )}
          {typesFilter.includes('PRO_PAGES') && (
            <>
              Selecteer leerplanpagina&apos;s secundair om te zoeken naar thematische info binnen{' '}
              {currentTheme.title}
            </>
          )}
          {typesFilter.includes('CURRICULA') && (
            <>
              Er zijn geen leerplanpagina&apos;s binnen {currentTheme.title}.
              <br />
              Zoek in de volledige PRO.-website om naar leerplanpagina&apos;s te kunnen zoeken.
            </>
          )}
        </NoResultsPlaceholder>
      );
    }
  }
  return (
    <NoResultsPlaceholder>
      Er komen geen items overeen met jouw zoekcriteria. <br />
      Probeer een andere zoekopdracht.
      {!userKey && (
        <span>
          {' of '}
          <a href={getLoginUrl()} style={{ textDecoration: 'underline' }}>
            meld je aan
          </a>
          {' om ook binnen afgeschermde info te zoeken'}
        </span>
      )}
    </NoResultsPlaceholder>
  );
};

export default NoResultsPlaceholderGlobalSearch;
