import { getRegions, getEducationLevels } from '@src/components/constants';
import * as oauthInt from '@kathondvla/fetch-oauth-interceptor';
import { constructViewOptionsFromUser, getUserTargetGroup } from '@src/helpers/userHelper';
import userState from './userState';
import {
  getMe,
  getMoreUserDetails,
  getUserSecurityAccess,
  getUserNewsletterPreferences,
  getUserPrivateState,
  createUserPrivateState,
  savePrivateState,
  saveToLocalStorage,
  getFromLocalStorage,
  updateNewsletterPreferences,
  isPersonPartOfKathOndVla,
  getUserLoginMethod,
} from './userDataAccess';

const settings = require('../../config/settings');

oauthInt.interceptFactory({
  urlPatterns: [
    new RegExp(settings.apisAndUrls.api, 'g'),
    new RegExp(settings.apisAndUrls.contentApi, 'g'),
    new RegExp(settings.apisAndUrls.websitesApi, 'g'),
    new RegExp(settings.apisAndUrls.securityApi, 'g'),
    new RegExp(settings.apisAndUrls.privateStatesApi, 'g'),
    new RegExp(settings.apisAndUrls.searchApi, 'g'),
  ],
  oauth: settings.oauth,
  ignore: {
    get: [
      /\/positions/g,
      /\/sam\/organisationalunits/g,
      /\/contactdetails/g,
      /\/content\/.*\/hash/g,
      /\/sam\/commons/g,
      /\/vakken/g,
      /\/web\/pages/g,
    ],
    post: [/\/persons\/register/g, /\/persons\/generateusername/g, /\/persons\/contact-helpdesk/g],
    put: [/\/persons\/batch/g],
  },
  onUserChanged: () => {},
  onLoggedOut: () => null,
});

const retrieveIsKOVuser = (userkey) => async (dispatch) => {
  let isUserKOV = false;
  if (userkey) isUserKOV = await isPersonPartOfKathOndVla(`/persons/${userkey}`);

  dispatch(userState.actions.setIsUserKOV(isUserKOV));
};

export const retrieveUserDetails = (userKey) => async (dispatch) => {
  const userDetails = await getMoreUserDetails(userKey);
  dispatch(userState.actions.setUserDetails(userDetails));
};

export const saveUserPrivateState =
  (newState, saveBool = true) =>
  async (dispatch, getState) => {
    dispatch(userState.actions.updateUserPrivateState(newState));

    if (saveBool) {
      const state = getState();
      saveToLocalStorage('privateState', state.user.userPrivateState.state);
      if (state.user.userKey) {
        await savePrivateState(
          state.user.userPrivateState.$$meta.permalink,
          state.user.userPrivateState
        );
      }
    }
  };

export const updateAllViewOptions = (viewOptions) => async (dispatch, getState) => {
  const allEmpty = !(
    viewOptions.EDUCATION_LEVELS.find((e) => e.checked) ||
    viewOptions.REGIONS.find((e) => e.checked)
  );
  const state = getState();
  const newPrivateState = {
    ...state.user.userPrivateState,
    state: {
      ...state.user.userPrivateState?.state,
      viewOptions,
      entryFlowComplete: true,
    },
  };
  dispatch(saveUserPrivateState(newPrivateState, !allEmpty));
};

export const retrieveUserPrivateState = (userKey) => async (dispatch, getState) => {
  let userPrivateState = {};
  let save = false;
  if (userKey) {
    const privateState = await getUserPrivateState(userKey);
    if (privateState && privateState.length) {
      [userPrivateState] = privateState;
    } else {
      let options = getFromLocalStorage('privateState');

      if (!options) {
        save = true;
        options = {
          viewOptions: await constructViewOptionsFromUser(userKey, getState().user.isUserKOV),
        };
      }
      userPrivateState = createUserPrivateState(userKey, options);
    }
  } else {
    const localPrivateState = getFromLocalStorage('privateState');
    userPrivateState = localPrivateState
      ? { state: localPrivateState }
      : {
          state: {
            viewOptions: {
              REGIONS: getRegions(),
              EDUCATION_LEVELS: getEducationLevels(),
            },
          },
        };
  }
  dispatch(saveUserPrivateState(userPrivateState, save));
};

export const retrieveUserSecurityLevel = (userKey) => async (dispatch) => {
  const userSecuritLevel = await getUserSecurityAccess(userKey);
  dispatch(userState.actions.setUserAccess(userSecuritLevel));
};

export const retrieveUserTargetGroup = (userKey) => async (dispatch) => {
  // null indicates we resolved the value but we have no value
  let userTargetGroup = null;
  if (userKey) {
    userTargetGroup = await getUserTargetGroup(userKey).then(
      (userTargetGroupResp) => (userTargetGroupResp && userTargetGroupResp.name) || null // when "userTargetGroupResp" is undefined we need to set up null as the value
    );
  }
  dispatch(userState.actions.setUserTargetGroup(userTargetGroup));
};

export const retrieveUserNewsletterPreferences =
  (userKey, refresh = false) =>
  async (dispatch) => {
    const userNewsletterPreferencesResponse = await getUserNewsletterPreferences(userKey);
    dispatch(
      userState.actions.updateUserNewsletterPreferencesState({
        response: userNewsletterPreferencesResponse,
        refresh,
      })
    );
  };

export const updateUserNewsletterPreferences =
  (isSubscribing, preferences, hrefs) => async (dispatch) => {
    let newPreferences;

    if (isSubscribing) {
      // we collect the themes to which the user is subscribing and we add them to the already existing preferences.themes property
      const newThemesHrefs = [...new Set([...preferences.themes.map((t) => t.href), ...hrefs])];
      const newPreferencesThemes = newThemesHrefs.map((nT) => ({ href: nT }));

      newPreferences = {
        ...preferences,
        themes: newPreferencesThemes,
      };
    } else {
      // we filter out all the themes to which the user wants to unsuscribe
      newPreferences = {
        ...preferences,
        themes: preferences.themes.filter((item) => !hrefs.includes(item.href)),
      };
    }

    await updateNewsletterPreferences(newPreferences);
    dispatch(
      userState.actions.updateUserNewsletterPreferencesState({
        response: newPreferences,
        refresh: false,
      })
    );
  };

export const resetViewOptions = (originalViewOptions) => (dispatch) => {
  dispatch(updateAllViewOptions(originalViewOptions));
};

const retrieveUserLoginMethod = (userKey) => async (dispatch) => {
  let userLoginMethod = null;
  if (userKey) {
    userLoginMethod = await getUserLoginMethod(userKey);
  }
  dispatch(userState.actions.setUserLoginMethod(userLoginMethod));
};

// Run this command as soon as the app is loaded
// once the user key is loaded 99% of the DOM can be mounted

export const init = () => async (dispatch) => {
  console.log('initialising user');
  const basicUser = await getMe();
  // null means that we tried to fetch the user but the user is not logged
  const userKey = basicUser ? basicUser.uuid : null;
  dispatch(userState.actions.setUserKey(userKey));
  dispatch(retrieveIsKOVuser(userKey));

  // the following two thunks do not always need the user key,
  // because even anonymous users can have view options in the private state
  // and need a security clearance
  dispatch(retrieveUserPrivateState(userKey));
  dispatch(retrieveUserSecurityLevel(userKey));
  dispatch(retrieveUserTargetGroup(userKey));
  dispatch(retrieveUserLoginMethod(userKey));

  if (userKey) {
    // The Following three thunks are only needed for logged in users
    dispatch(retrieveUserDetails(userKey));
    dispatch(retrieveUserNewsletterPreferences(userKey));
  }
};
