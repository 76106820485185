import { getAngularService } from 'NgMigrationUtils/angular-react-helper';
import {
  getEndOfSchoolYear,
  getNextYear,
  getNow,
  getPreviousDay,
} from '@kathondvla/sri-client/date-utils/date-utils';
import {
  getEducationLevels,
  getRegions,
  TRAINING_TYPES,
  TRAINING_POSITIONS,
  TRAINING_SORTS,
  TRAINING_LOCATION_TYPES,
} from '@src/components/constants';
import { curriculumSitesLogic } from '@kathondvla/shared-logic';
import { allowFutureTrainings } from '@src/config/settings';
import {
  getCoverageFromViewOptions,
  getMainstructuresOuTypeCombinationsFromViewOptions,
} from '@src/helpers/viewOptionsHelper';
import { OrderMethods } from '@src/reactComponents/database/constants';
import { stateMatch } from 'redux-awaitable-state';
import { selectPrimaryRefFrame, selectProThemes } from '@src/reduxStore/content/contentSelectors';
import { flattenTree } from '../app/utils';
import { cleanUpObjectEmptyValues } from './utils';

/* Prevent circular dependencies error, we should not import store here 
https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files */
let store;
export const injectTrainingsStore = (_store) => {
  store = _store;
};

export const getSchoolYearEndDate = () => {
  const endYear = allowFutureTrainings ? getNextYear(getNow()) : getNextYear(getNow(), 2);
  return new Date(getPreviousDay(getEndOfSchoolYear(endYear)));
};

export const getDefaultFilters = () => ({
  limit: 30,
  q: '',
  distanceInKm: 10,
  lat: undefined,
  lon: undefined,
  menuItem: undefined,
  dateFrom: undefined,
  dateTo: undefined,
  mainstructuresOuTypeCombinations: [],
  curricula: [],
  coverage: [],
  trainingTypes: [],
  positions: [],
  attendanceType: [],
  orderby: null,
});

/**
 * This function return the filters in the trainings with the necessary data to be rendered (title, etc..) marking them as checked or not depending on the trainingFilters that it receives
 * @param {*} trainingsFilters
 * @returns {*}
 */
export const getTrainingsViewModel = (trainingsFilters) => {
  const mainstructuresOuTypeCombinations = getEducationLevels(true).map((educationLevel) => ({
    title: educationLevel.title,
    key: educationLevel.mainstructuresOuTypeCombination,
    checked: trainingsFilters.mainstructuresOuTypeCombinations.includes(
      educationLevel.mainstructuresOuTypeCombination
    ),
    typeOf: educationLevel.parentValue,
  }));

  const coverage = getRegions().map((region) => ({
    title: region.title,
    key: region.href,
    checked: trainingsFilters.coverage.includes(region.href),
  }));

  const trainingTypes = TRAINING_TYPES.map((type) => ({
    title: type.title,
    key: type.tag,
    checked: trainingsFilters.trainingTypes.includes(type.tag),
  }));

  const positions = [...TRAINING_POSITIONS]
    .sort((a, b) => a.readOrder - b.readOrder)
    .map((type) => ({
      title: type.title,
      key: type.href,
      description: type.description,
      checked: trainingsFilters.positions.includes(type.href),
    }));

  const attendanceType = TRAINING_LOCATION_TYPES.map((loc) => ({
    title: loc.title,
    key: loc.locationType,
    checked: trainingsFilters.attendanceType.includes(loc.locationType),
  }));

  const orderby = TRAINING_SORTS.map((order) => ({
    title: order.title,
    key: order.searchTag,
    checked: trainingsFilters.orderby === order.searchTag,
  }));

  return {
    ...trainingsFilters,
    mainstructuresOuTypeCombinations,
    coverage,
    trainingTypes,
    positions,
    attendanceType,
    orderby,
  };
};

/**
 * This function received a filter key and a values and returns a boolean telling you if the value for that filter is different from the default value of that filter
 * @param {string} key
 * @param {string|number} value
 * @returns {Boolean}
 */
export const isFilterValueDifferentFromDefault = (key, value) => getDefaultFilters()[key] !== value;

/**
 * This function gets the default filters and merge them with the filters the user has in the url
 */
export const getInitialFilters = () => {
  const defaultFilters = getDefaultFilters();
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.entries(defaultFilters).reduce((acc, [filterKey, filterValue]) => {
    if (!urlSearchParams.has(filterKey))
      return {
        ...acc,
        [filterKey]: filterValue,
      };

    const urlFilterValue = urlSearchParams.get(filterKey);
    return {
      ...acc,
      ...(filterKey === 'curricula'
        ? { curricula: curriculumSitesLogic.decodeThemes(urlFilterValue) }
        : {}),
      ...(filterKey === 'limit' ? { limit: +urlFilterValue } : {}),
      ...(!['curricula', 'limit'].includes(filterKey)
        ? {
            [filterKey]: Array.isArray(filterValue)
              ? decodeURIComponent(urlFilterValue).split(',')
              : decodeURIComponent(urlFilterValue),
          }
        : {}),
    };
  }, {});
};

/**
 * Get a serializable version of an item, containing only relevant info and removing
 * circular dependency (every item in pro navigation tree has a reference to its parent
 * and every parent contain the list of child nodes).
 * @param {*} item: an item the pro navigation tree
 * @returns a serializable version of the node
 */
const getSerializableProItem = (item) =>
  item
    ? {
        key: item.key,
        title: item.title,
        href: item.href,
        icon: item.icon,
        color: item.color,
        multipleAlternativeMenus: item.multipleAlternativeMenus,
        theme: item.theme,
        children: item.children.map((child) => getSerializableProItem(child)),
        visible: item.visible,
        mainstructuresOuTypeCombinations: item.mainstructuresOuTypeCombinations,
        coverage: item.coverage,
      }
    : null;

/**
 * Gets the list of menuItem ancestors plus the menuItem itself
 * @param {string} menuItemHref: a menuItem href
 * @param {*} navigationTree: the navigation menu tree where to find the item
 * @returns list of menuItems
 */
export const getMenuItemFamilyList = (menuItemHref, navigationTree) => {
  if (!menuItemHref) {
    return [];
  }
  const navigationService = getAngularService('navigationService');
  const flattedNavigationTree = flattenTree(navigationTree, false);
  const menuItem = flattedNavigationTree.find((item) => item.href === menuItemHref);

  if (menuItem) {
    const ancestors = navigationService.getMenuItemAncestors(menuItem, flattedNavigationTree);
    const items = [...ancestors, menuItem];
    const firstNoVisibleIndex = items.findIndex((item) => !item.visible);
    const visibleItems = firstNoVisibleIndex > 0 ? items.slice(0, firstNoVisibleIndex) : items;
    return visibleItems.map((item) => getSerializableProItem(item));
  }
  return [];
};

/**
 * This function will return true if all the education levels that the user selected on the filters are out of the view options he has defined.
 * @param {*} viewOptions
 * @param {*} viewModel
 * @returns {Boolean}
 */
export const checkIfFiltersAreOutsideOfViewOptions = (viewOptions = {}, viewModel = {}) => {
  if (Object.keys(viewOptions).length === 0 || Object.keys(viewModel).length === 0) return false;

  const viewOptsCheckedEduTypesHrefs = viewOptions.EDUCATION_LEVELS.filter((i) => i.checked).map(
    (i) => i.mainstructuresOuTypeCombination
  );

  const { mainstructuresOuTypeCombinations: viewModelEducationTypes } = viewModel;

  const viewModelCheckedEduTypes = viewModelEducationTypes.filter((i) => i.checked);

  // according to EARS we only show the "outside of view options" alert when none of the user
  // education levels is present on the user filters
  return (
    Boolean(viewModelCheckedEduTypes.length) &&
    !viewModelCheckedEduTypes.some(
      (g) =>
        viewOptsCheckedEduTypesHrefs.includes(g.key) ||
        viewOptsCheckedEduTypesHrefs.includes(g.typeOf)
    )
  );
};

export const getOrderBasedOnFilters = (filters) => {
  if (filters.q) return OrderMethods.RELEVANCE;
  if (filters.dateFrom || filters.dateTo) return OrderMethods.CHRONOLOGICAL;
  return OrderMethods.RECENT_PUBLISHED;
};

export const getRootThemesField = async (menuItemParam) => {
  // Possible cases here:
  //  1 Sections of a document that have a theme assigned in them (usually sections in the PRO-homepage)
  //  2 Sections that are part of a PRO-theme that is linked to a theme. You get the PRO-theme first and
  //    from there you get the theme (/content/e254d7a1-03a4-4a79-bfff-38680d1766de)
  //  3 Sections in the PRO-homepage that have children (usually other sections) that are linked to
  //    PRO-themes (which everyone of them have a linked theme). In this case we take the element
  //    and iterate over all his children getting the "themes" of them

  const navigationService = getAngularService('navigationService');

  await stateMatch(
    (state) => selectProThemes(state)?.length > 0 && selectPrimaryRefFrame(state)?.length > 0
  );

  const proThemes = selectProThemes(store.getState());
  const referenceFrame = selectPrimaryRefFrame(store.getState());

  // CASE 1
  let menuItemThemes = referenceFrame.find((a) => a.href === menuItemParam)?.themes || [];

  // CASE 2
  // CASE 2 Variant 1
  if (menuItemThemes.length === 0)
    menuItemThemes = proThemes.find((a) => a.$$meta.permalink === menuItemParam)?.themes || [];

  // CASE 2 Variant 2
  // if the item is not a proTheme itself we find for the proTheme related to the item
  if (menuItemThemes.length === 0)
    menuItemThemes =
      proThemes.find((proTheme) =>
        proTheme.$$relationsTo.some((relTo) => relTo.$$expanded.from.href === menuItemParam)
      )?.themes || [];

  // CASE 3
  if (menuItemThemes.length === 0) {
    const menuItem = await navigationService.getItemAsync(menuItemParam);
    menuItemThemes = navigationService.getRecursiveThemes(menuItem) || [];
  }

  // this should change when we refactor themeService.
  // the first menu items are linked to reference frame themes while
  // every menuItem at the last level are linked to pro theme,
  // which in turn are linked to reference frame theme. that is the href search-api needs
  return menuItemThemes.join(',');
};

export const buildTrainingsAPIParams = async (
  searchParameters = {},
  theme = null,
  getFullTrainingsList = false
) => {
  // When we call the API, if we have an offset, it means we are requesting the next page.
  // In that case we need to set up the limit to 30 because we always want to request the results
  // to the API in batches of 30 results
  // At the same time, if we have the "getFullTrainingsList" we want to do it in batches of 500
  // (to reduce the amount of call we need to do to get them all)
  let limit = searchParameters.limit || 30;
  if (getFullTrainingsList) {
    limit = 500;
  } else if (searchParameters.offset) {
    limit = 30;
  }

  let { orderby } = searchParameters;
  if (searchParameters.orderby === OrderMethods.RELEVANCE) {
    orderby = '';
  } else if (searchParameters.orderby === OrderMethods.CHRONOLOGICAL) {
    orderby = `${OrderMethods.CHRONOLOGICAL},${OrderMethods.RECENT_PUBLISHED}`;
  }

  // "menuItem" attribute will be only defined on globalTrainings
  let rootThemes = searchParameters.menuItem
    ? await getRootThemesField(searchParameters.menuItem)
    : theme;

  if (searchParameters.curricula) {
    rootThemes = rootThemes
      ? `${rootThemes},${searchParameters.curricula}`
      : searchParameters.curricula;
  }

  const distanceInKm =
    searchParameters.lon && searchParameters.lat ? searchParameters.distanceInKm : null;

  return cleanUpObjectEmptyValues({
    ...searchParameters,
    menuItem: null,
    curricula: null,
    rootThemes,
    types: 'TRAINING_MODULE',
    'enrollmentPeriod.startDateBefore': allowFutureTrainings
      ? ''
      : new Date().toISOString().split('T')[0],
    expand: 'full',
    limit,
    distanceInKm,
    orderby,
  });
};

export const extendFiltersWithUserViewOptions = (trainingsFilters, viewOptions) => ({
  ...trainingsFilters,
  mainstructuresOuTypeCombinations: getMainstructuresOuTypeCombinationsFromViewOptions(viewOptions),
  coverage: getCoverageFromViewOptions(viewOptions),
});
