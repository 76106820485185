require('./wsDatabaseItem.scss');
const settings = require('@src/config/settings');
const helpersUtils = require('@src/helpers/utils');
const { checkIfOutOfViewOptions } = require('@src/helpers/contextFilterHelper');
const { selectUserViewOptions } = require('@src/reduxStore/user/userSelectors');
const store = require('@src/reduxStore/store').default;
const { getRedactieItems } = require('@src/reduxStore/suggestions/suggestionsSelectors');
const { notifyRedactiePageLoaded } = require('@src/helpers/postMessageHelper');
const { stateMatch } = require('redux-awaitable-state');
const {
  selectItemInCurrentThemeTree,
  selectCurrentThemeTree,
} = require('@src/reduxStore/theme/themeSelectors');

class wsDatabaseItem {
  constructor($scope, $element, $stateParams, routerService, $window, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$element = $element;
    this.$stateParams = $stateParams;
    this.$window = $window;
    this.routerService = routerService;
    this.$ngRedux = $ngRedux;
  }

  async $onInit() {
    if (this.sCurrent && this.sCurrent.type === 'SHARED_MINI_DATABASE_ITEM') {
      this.$window.addEventListener('suggestionsloaded', async () => {
        // this was left behind for a future approximation. It is the last place where
        // we use "suggestionsloaded" trigger but it is a special case because in this case
        // we don't have a theme, so we can't use the current implemented selector in full,
        // we will need to find a way to make it happen.
        // I consider this outOfScope of themeService refactor
        // Example case: http://localhost:8081/doe-je-gezonde-goesting-gezond-gebeten-voor-homies
        const items = getRedactieItems(store.getState());
        const tree = helpersUtils.buildTreeFromItemsList(this.sCurrent.href, items);
        this.sCurrent = await this.buildShareDatabaseItem(tree);
      });
    } else {
      await stateMatch((state) => selectCurrentThemeTree(state));

      this.unsubscribe = this.$ngRedux.connect((state) => {
        const page = selectItemInCurrentThemeTree(state, this.$stateParams.source);

        const userViewOptions = selectUserViewOptions(state);
        const outOfViewOptions = checkIfOutOfViewOptions(page, userViewOptions);
        this.$scope.$emit('showOutOfViewOptions', outOfViewOptions);

        this.$scope.$emit('dynamicMetaDataUpdated', page);

        return {
          sCurrent: page,
        };
      })(this);

      this.$element.append(
        `<style>.kov-pro-theme-content .theme-content-link {background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff), linear-gradient(${this.sColor}, ${this.sColor}); background-size: .05em 1px,.05em 1px,1px 1px; background-repeat: no-repeat,no-repeat,repeat-x; background-position: 0 95%,100% 95%,0 95%;} .kov-pro-theme-content .theme-content-link:hover {background: none; color: ${this.sColor};}</style>`
      );
      this.$element.append(
        `<style>ws-term a:hover {color: ${this.sColor} !important;} ws-term .theme-content-begrip:hover:after {color: ${this.sColor} !important;}</style>`
      );
    }

    notifyRedactiePageLoaded();
  }

  async buildShareDatabaseItem(tree) {
    const url = new URL(window.location.href);
    const refUrl = url.searchParams.get('refDB');

    let parent = {
      title: '',
      pagePath: settings.proGlobalDatabase.url,
      children: [],
      facets: [],
    };

    if (refUrl) {
      const database = this.routerService.getWebpageInfoFromHref('/content/' + refUrl);

      if (database) {
        await stateMatch((state) => selectCurrentThemeTree(state));

        parent = {
          // user came from a standalone database
          title: selectItemInCurrentThemeTree(store.getState(), database.source).title,
          pagePath: database.path,
          children: [tree],
          facets: database.facets,
        };
      }
    }

    return {
      ...tree,
      parent,
    };
  }
}

module.exports = {
  template: require('./wsDatabaseItem.html'),
  controllerAs: 'ctrl',
  controller: wsDatabaseItem,
  bindings: {
    sTheme: '<',
    sCurrent: '<',
  },
};
