import React from 'react';
import ThemeDetailMenu from '@components/theme/themeDetailMenu/themeDetailMenu';
import { getVisibleSectionsOfChildren } from '@src/app/contentUtils';
import { stripHtml } from '@src/app/utils';

const ThemeMenu = ({ theme, items, current = undefined }) => {
  const menuItems =
    items &&
    getVisibleSectionsOfChildren(items)
      .map((item) => ({ ...item, title: item.title && stripHtml(item.title) }))
      .filter((item) =>
        // when the items have the following type the should be shown in the
        // themeDetailMenu component, meaning links in the themeDetailMenu.
        // Other type of nodes will be ignored (SECTION, etc..)
        [
          'MINI_DATABASE',
          'BLOG',
          'TEMPORARY_PAGE',
          'DOWNLOAD_PAGE',
          'THEME_DETAIL',
          'THEME_TRAINING',
          'THEME_DETAIL_ROOT',
          'CURRICULUM_PAGE',
        ].includes(item.pageType || item.type)
      );

  return theme != null && menuItems.length > 0 ? (
    <>
      <div className="container">
        <div className="row">
          <div className="kov-theme-title col-12">
            <ThemeDetailMenu
              sItems={menuItems}
              sColor={theme.root.color}
              sIcon={theme.root.icon}
              sCurrent={current}
            />
          </div>
        </div>
      </div>
      <div className="divider-line" />
    </>
  ) : null;
};
export default ThemeMenu;
