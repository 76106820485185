import React from 'react';
import PropTypes from 'prop-types';

require('./notification.scss');

const Notification = ({
  isShown,
  onDismiss,
  text,
  children,
  type = 'warning',
  dismissLabel = 'OK',
  extraClasses = { container: '', dismiss: '' },
}) =>
  isShown ? (
    <div className={`notification notification-${type} ${extraClasses.container}`}>
      <p>
        {children ? (
          <span>{children} </span>
        ) : (
          <span dangerouslySetInnerHTML={{ __html: text }}></span>
        )}
        {onDismiss && (
          <a className={`dismiss ${extraClasses.dismiss}`} onClick={onDismiss}>
            {dismissLabel}
          </a>
        )}
      </p>
    </div>
  ) : null;

Notification.propTypes = {
  isShown: PropTypes.bool,
  text: PropTypes.string,
  type: PropTypes.string,
  dismissLabel: PropTypes.string,
  onDismiss: PropTypes.func,
  extraClasses: PropTypes.object,
  children: PropTypes.node,
};
export default Notification;
