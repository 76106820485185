/* eslint-disable no-unused-expressions */
import CheckboxGroup from '@UI/checkboxGroup/checkboxGroup';
import RadioGroup from '@UI/radioGroup/radioGroup';
import SearchInput from '@UI/searchInput/searchInput';
import React, { useState } from 'react';
import { TYPE_FILTER_MAP } from '@components/searchApi';

const GlobalSearchFilters = ({
  searchTerm,
  typesFilter,
  scopeFilter,
  theme,
  onUpdateSearchTerm,
  onUpdateTypesFilter,
  onUpdateScopeFilter,
  dispatchSearch,
  closeFiltersSidePanel,
  clearAllFilters,
}) => {
  const [typesFilterCollapsed, setTypesFilterCollapsed] = useState(false);

  const filtersViewModel = {
    types: Object.entries(TYPE_FILTER_MAP).map(([key, value]) => ({
      key,
      title: value.label,
      checked: typesFilter.includes(key),
    })),
    scope: [
      {
        title: { __html: 'in de volledig PRO.-website' },
        key: 'GLOBAL',
        checked: !scopeFilter,
      },
    ],
  };
  if (theme) {
    filtersViewModel.scope.push({
      key: 'LOCAL',
      title: { __html: `enkel resultaten binnen <span><b>${theme.title || theme.href}</b></span>` },
      description: 'Selecteer deze optie als je enkel binnen dit thema wil zoeken',
      checked: !!scopeFilter,
    });
  }

  const onChangeScope = (key) => {
    onUpdateScopeFilter(key === 'LOCAL' ? theme.href : null);
  };

  const onChangeType = (checkboxData) => {
    const { checkboxKey, checkboxValue } = checkboxData;

    if (checkboxKey === 'alle') {
      onUpdateTypesFilter(checkboxValue ? Object.keys(TYPE_FILTER_MAP) : []);
    } else {
      const newTypes = checkboxValue
        ? Object.keys(TYPE_FILTER_MAP).filter(
            (typeKey) => typesFilter.includes(typeKey) || typeKey === checkboxKey
          ) // =~ [...filters.types, checkboxKey] but this way the order is always the same
        : typesFilter.filter((typeKey) => typeKey !== checkboxKey);
      onUpdateTypesFilter(newTypes);
    }
  };

  const onCloseFiltersSidePanel = (e) => {
    e.preventDefault();
    closeFiltersSidePanel();
  };

  return (
    <div className="kov-sidebar__wrapper" id="kov-sidenav-filter">
      <a className="closebtn" onClick={onCloseFiltersSidePanel}>
        <i className="icon-kov-pro-close" />
      </a>
      <div className="kov-pro-filter">
        <h2 className="kov-pro-filter__title">Filter zoekresultaten</h2>
        <div className="filter-block">
          <a className="title">Zoek</a>
          <div id="filter1" className="filter-items collapse show">
            <div className="kov-input-search">
              <SearchInput
                value={searchTerm}
                onChange={onUpdateSearchTerm}
                onSearch={dispatchSearch}
                placeholder="Zoek"
                inputClassName="form-control"
              />
            </div>
            <RadioGroup
              groupArray={filtersViewModel.scope}
              onChange={onChangeScope}
              itemListClassName={'kov-input-search__radio'}
            />
          </div>
        </div>

        <div className="filter-block">
          <a
            className={`title ${typesFilterCollapsed ? 'collapsed' : ''}`}
            role="button"
            onClick={() => setTypesFilterCollapsed(!typesFilterCollapsed)}
          >
            Types
            <i className="icon-kov-pro-down-arrow" />
          </a>
          <div id="filter2" className={`filter-items collapse ${!typesFilterCollapsed && 'show'}`}>
            <CheckboxGroup
              groupArray={filtersViewModel.types}
              onChange={onChangeType}
              name="types"
              itemListClassName={'filter-block__type'}
            />
          </div>
        </div>
        <div className="kov-sidebar__confirm">
          <button
            className="kov-pro-btn kov-pro-btn--block kov-pro-btn--darkgreen"
            onClick={dispatchSearch}
          >
            Zoeken
          </button>
          <button className="filter-tag" onClick={clearAllFilters}>
            wis alle filters en zoektermen <i className="icon-kov-pro-close" />
          </button>
        </div>
      </div>
    </div>
  );
};
export default GlobalSearchFilters;
