export const isPreview = () =>
  window.sessionStorage.getItem('preview') === 'true' ||
  new URLSearchParams(window.location.search).has('preview');

export const startPreview = () => {
  window.sessionStorage.setItem('preview', 'true');
};

export const stopPreview = () => {
  window.sessionStorage.removeItem('preview');
};
