import React from 'react';
import settings from '@src/config/settings';
import { getLoginUrl } from '@src/helpers/userHelper';
import { useSelector, useDispatch } from 'react-redux';
import { getUserKey } from '@src/reduxStore/user/userSelectors';
import trackPiwikEvent from '@src/reduxStore/analytics/analyticsThunks';

const getNotLoggedMessage = (
  itemIsLinkedToCurriculaTheme,
  itemIsLinkedToThemeLinkedToPrimaryReferenceFrame
) => {
  if (itemIsLinkedToCurriculaTheme)
    return 'Meld je aan om je te kunnen inschrijven op dit leerplan.';
  if (itemIsLinkedToThemeLinkedToPrimaryReferenceFrame)
    return 'Meld je aan om je te kunnen inschrijven op dit kanaal in de algemene nieuwsbrief.';
  return '';
};

const getAlreadySubscribedToNewsletterMessage = (
  itemIsLinkedToCurriculaTheme,
  itemIsLinkedToThemeLinkedToPrimaryReferenceFrame
) => {
  if (itemIsLinkedToCurriculaTheme)
    return 'Nieuws over dit leerplan ontvangen in je leerplannennieuwsbrief.';
  if (itemIsLinkedToThemeLinkedToPrimaryReferenceFrame)
    return 'Nieuws over dit kanaal ontvangen in je algemene nieuwsbrief.';
  return '';
};

const getLastSubscriptionToNewsletterMessage = (
  itemIsLinkedToCurriculaTheme,
  itemIsLinkedToThemeLinkedToPrimaryReferenceFrame
) => {
  if (itemIsLinkedToCurriculaTheme) {
    return 'Wil je uitschrijven voor de leerplannennieuwsbrief?';
  }
  if (itemIsLinkedToThemeLinkedToPrimaryReferenceFrame) {
    return 'Wil je uitschrijven voor de algemene nieuwsbrief?';
  }
  return '';
};

const getNotSubscribedToNewsletterMessage = (
  itemIsLinkedToCurriculaTheme,
  itemIsLinkedToThemeLinkedToPrimaryReferenceFrame
) => {
  if (itemIsLinkedToCurriculaTheme)
    return 'Schrijf je eerst in op de leerplannennieuwsbrief. Je hebt daarop momenteel nog geen abonnement.';
  if (itemIsLinkedToThemeLinkedToPrimaryReferenceFrame)
    return 'Schrijf je eerst in op de algemene nieuwsbrief. Je hebt daarop momenteel nog geen abonnement.';
  return '';
};

const BannerNewsletterDropdown = ({
  handleClose,
  handleSubscribe,
  subscribedToCurricula,
  subscribedToCurriculaNewsletter,
  itemIsLinkedToCurriculaTheme,
  itemIsLinkedToThemeLinkedToPrimaryReferenceFrame,
  subscribedToGeneralNewsletter,
  subscribedToThemeNewsletter,
  isLastSubscriptionToItem,
}) => {
  const isUserLogged = Boolean(useSelector(getUserKey));
  const dispatch = useDispatch();

  const handleNewsletters = () => {
    dispatch(trackPiwikEvent('start_newsletter'));
    window.open(`${settings.apisAndUrls.mijn}/#!/nieuwsbrieven`, '_blank');
  };

  return (
    <div className="kov-popup__wrapper show" id="kovPopupNewsletter">
      <i
        className="icon-kov-pro-close"
        data-testid="banner-dropdown-close-button"
        onClick={() => handleClose()}
      />
      <div className="kov-popup__title">Nieuwsbrief</div>
      {!isUserLogged ? (
        <>
          <p className="kov-popup__text">
            {getNotLoggedMessage(
              itemIsLinkedToCurriculaTheme,
              itemIsLinkedToThemeLinkedToPrimaryReferenceFrame
            )}
          </p>
          <button
            onClick={() => {
              window.location.href = getLoginUrl();
            }}
            className="kov-pro-btn kov-pro-btn--green kov-pro-btn--sm"
          >
            meld aan
          </button>
        </>
      ) : (
        <>
          {isUserLogged &&
          ((subscribedToCurriculaNewsletter && itemIsLinkedToCurriculaTheme) ||
            (subscribedToGeneralNewsletter && itemIsLinkedToThemeLinkedToPrimaryReferenceFrame)) ? (
            <>
              <p className="kov-popup__text">
                {isLastSubscriptionToItem
                  ? getLastSubscriptionToNewsletterMessage(
                      itemIsLinkedToCurriculaTheme,
                      itemIsLinkedToThemeLinkedToPrimaryReferenceFrame
                    )
                  : getAlreadySubscribedToNewsletterMessage(
                      itemIsLinkedToCurriculaTheme,
                      itemIsLinkedToThemeLinkedToPrimaryReferenceFrame
                    )}
              </p>
              {isLastSubscriptionToItem ? (
                <button
                  onClick={() => handleNewsletters()}
                  className="kov-pro-btn kov-pro-btn--green kov-pro-btn--sm"
                >
                  schrijf uit
                </button>
              ) : (
                <>
                  <div className="kov-pro-switch__switch">
                    <p className="kov-popup__text">nee</p>
                    <label className="switch">
                      <input
                        type="checkbox"
                        defaultChecked={subscribedToCurricula || subscribedToThemeNewsletter}
                        onChange={(event) => handleSubscribe(event.target.checked)}
                      />
                      <span className="slider round" />
                    </label>
                    <p className="kov-popup__text">ja</p>
                  </div>
                  <div className="divider-line" />
                  <span>
                    <a target={'_blank'} onClick={() => handleNewsletters()} rel="noreferrer">
                      Beheer je nieuwsbrieven
                    </a>
                  </span>
                </>
              )}
            </>
          ) : (
            <>
              <p className="kov-popup__text">
                {getNotSubscribedToNewsletterMessage(
                  itemIsLinkedToCurriculaTheme,
                  itemIsLinkedToThemeLinkedToPrimaryReferenceFrame
                )}
              </p>
              <button
                onClick={() => handleNewsletters()}
                className="kov-pro-btn kov-pro-btn--green kov-pro-btn--sm"
              >
                schrijf in
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default BannerNewsletterDropdown;
