import React from 'react';
import PropTypes from 'prop-types';
import TrainingEventInfo from './trainingEventInfo';

const TrainingResult = ({ training }) => (
  <li className="kov-pro-training">
    <a href={training.forwardLink} target={'_blank'} rel="noreferrer">
      <div className="kov-pro-training__info">
        <div className="kov-pro-training__info-wrapper">
          <div className="kov-pro-training__meta">
            {training.eventInfo.waitingList && (
              <div className="kov-pro-training__tag kov-pro-training__tag--waiting">wachtlijst</div>
            )}
            {training.eventInfo.full && (
              <div className="kov-pro-training__tag kov-pro-training__tag--full">volzet</div>
            )}
            {training.eventInfo.cancelled && (
              <div className="kov-pro-training__tag kov-pro-training__tag--full">afgelast</div>
            )}
            <ul className="kov-pro-training__type">
              {training.typeNames.map((type) => (
                <li key={type}>{type.toLowerCase()}</li>
              ))}
            </ul>
          </div>
          <div className="kov-pro-training__title">{training.title}</div>
          <div className="kov-pro-training__excerpt">{training.motivation}</div>
        </div>
        <TrainingEventInfo
          eventInfo={training.eventInfo}
          wrapperClass={'kov-pro-training__specs'}
        />
      </div>
    </a>
  </li>
);

TrainingResult.propTypes = {
  training: PropTypes.object,
};

export default TrainingResult;
