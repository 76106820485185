import { isPreview } from '@src/helpers/previewHelper';
import settings from '@src/config/settings';
import { contentApi, cachedContentApi } from '@src/app/apiConfig';
import { getAngularService } from '@src/module/reactMigrationUtils/angular-react-helper';

const kovSentry = require('../../app/kovSentry');
const contentUtils = require('../../app/contentUtils');

async function loadTeasers(rootIssuedBefore, rootIssuedAfter, hash, shortCache = true) {
  kovSentry.startLog('TEASER', { op: 'http', description: 'GET TEASER' });
  const headers = { 'VSKO-Resource-Hash': hash };
  if (shortCache === true) {
    headers['vsko-short-cache-duration'] = true;
  }
  const result = await cachedContentApi.getAll(
    '/content',
    {
      rootIssuedBefore,
      rootIssuedAfter,
      referencedNewsItemPublishedBefore: rootIssuedBefore,
      rootType: 'TEASER',
      limit: 5000,
    },
    { headers }
  );

  kovSentry.endLog('TEASER');
  return result;
}

async function loadTeasersPreview() {
  return contentApi.getAll('/content', {
    rootType: 'TEASER',
    limit: 5000,
  });
}

function parseForwardHrefs(finalTeasers, references) {
  const finalTeaserMap = new Map(finalTeasers.map((t) => [t.href, t]));

  const PROCESSEDTEASERS = [];

  references.forEach((ref) => {
    let teaserHref = ref.$$relationsFrom.find(
      (fromRef) => fromRef.$$expanded.relationtype === 'IS_PART_OF'
    );
    teaserHref = teaserHref.$$expanded.to.href;

    const forwardHref = ref.$$relationsFrom.find(
      (fromRef) => fromRef.$$expanded.relationtype === 'REFERENCES'
    );
    const relevantTeaser = finalTeaserMap.get(teaserHref);
    if (relevantTeaser && !PROCESSEDTEASERS.includes(teaserHref)) {
      // /could be an unpublished, in that case we don't care?
      PROCESSEDTEASERS.push(teaserHref);
      relevantTeaser.forwardHref = forwardHref
        ? forwardHref.$$expanded.to.href
        : relevantTeaser.href;
    }
  });

  const routerService = getAngularService('routerService');
  finalTeasers = finalTeasers.map((teaser) => {
    if (!teaser.forwardHref) {
      teaser.forwardHref = teaser.href;
      teaser.isDetailPage = false;
    }

    teaser.isExternal = !teaser.forwardHref.includes('/content/');

    if (teaser.forwardHref.includes('/training/modules/')) {
      teaser.globalForwardHref = `https://nascholing.be/redirectTo.aspx?redirectID=${teaser.forwardHref}`;
      teaser.localForwardHref = teaser.globalForwardHref;
    } else if (
      teaser.forwardHref.includes('/content/') &&
      (!teaser.localForwardHref || !teaser.globalForwardHref)
    ) {
      // in case of teaser pointing to itself, we already set the hrefs.
      // check for website API url for /content/href
      const webconf = routerService.getWebpageInfoFromHref(teaser.forwardHref);
      teaser.translatedHref = webconf && webconf.path;
      let forwardsToNews = false;
      if (teaser.forwardHref === teaser.href) {
        forwardsToNews = true;
      } else if (webconf && webconf.type === 'NEWS_ITEM_AND_TEASER_FALLBACK') {
        forwardsToNews = true;
      }
      if (forwardsToNews) {
        teaser.localForwardHref = `${teaser.translatedHref || teaser.forwardHref}`; // ${teaser.translatedHref || teaser.forwardHref}
        teaser.globalForwardHref = `${teaser.translatedHref || teaser.forwardHref}`;
        teaser.isDetailPage = true;
        teaser.visible = true;
      } else {
        teaser.globalForwardHref = teaser.translatedHref || teaser.forwardHref; // for other cases within content?
        teaser.localForwardHref = teaser.globalForwardHref;
      }
    } else {
      teaser.globalForwardHref = teaser.forwardHref; // for other cases
      teaser.localForwardHref = teaser.forwardHref;
    }
    return teaser;
  });

  return finalTeasers;
}

async function parseTeasers(teasersAndReferences) {
  let finalTeasers = teasersAndReferences
    .filter((e) => e.type === 'TEASER' && e.issued)
    .map((teaser) => ({
      id: teaser.$$meta.permalink,
      href: teaser.$$meta.permalink,
      key: teaser.key,
      type: teaser.type,
      title: teaser.title,
      shortDescription: teaser.shortdescription,
      description: teaser.description,
      thumbImage: contentUtils.getImage(teaser, 'THUMBNAIL'),
      thumbImageM: contentUtils.getImageWithSize(teaser, 'THUMBNAIL', { w: 500, h: 360 }),
      thumbImageS: contentUtils.getImageWithSize(teaser, 'THUMBNAIL', { w: 100, h: 100 }),
      created: teaser.created,
      issued: teaser.issued,
      themes: teaser.themes, // /teasers can have no themes... doesn't this need to be an empty array then?
      importance: teaser.importance,
      $$relationsFrom: teaser.$$relationsFrom,
      $$relationsTo: teaser.$$relationsTo,
      coverage: teaser.coverage,
      mainstructuresOuTypeCombinations: teaser.mainstructuresOuTypeCombinations,
      mainstructures: teaser.mainstructures,
      outypes: teaser.outypes,
      visible: true,
    }));

  finalTeasers = parseForwardHrefs(
    finalTeasers,
    teasersAndReferences.filter((e) => e.type === 'REFERENCE')
  );
  const now = new Date();
  return finalTeasers.filter((e) => e.visible && new Date(e.issued) <= now);
}

export default async function getAllTeasers() {
  kovSentry.startLog('news');
  const coeff = 1000 * 60 * settings.newsCacheTime;
  const date = new Date();
  const rounded = new Date(Math.floor(date.getTime() / coeff) * coeff).toISOString();
  const firstDayOfMonth = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1));
  const firstDayOfMonthLastyear = new Date(Date.UTC(date.getFullYear() - 1, date.getMonth(), 1));
  const lastDayOfPreviousMonth = new Date(firstDayOfMonth);
  lastDayOfPreviousMonth.setDate(firstDayOfMonth.getDate() - 1);

  // get hash for teasers and news items
  let proNewsTeasers;

  if (isPreview()) {
    proNewsTeasers = await loadTeasersPreview();
  } else {
    const teasersHashResource = await cachedContentApi.get(settings.newsHash);
    const teasersHash = teasersHashResource.$$calculatedHash || teasersHashResource.hash; // calculatedhash might or might not make it into the spec.
    const [teasersPromise, teasersPromiseOlder] = await Promise.all([
      loadTeasers(rounded, firstDayOfMonth.toISOString(), teasersHash),
      loadTeasers(
        lastDayOfPreviousMonth.toISOString(),
        firstDayOfMonthLastyear.toISOString(),
        teasersHash,
        false
      ),
    ]);
    proNewsTeasers = (await teasersPromise).concat(await teasersPromiseOlder);
  }
  const teaserList = await parseTeasers(proNewsTeasers);
  kovSentry.endLog('news');
  return teaserList;
}
