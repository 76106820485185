import { AN_VALUES_THEME_ROOT } from '@src/components/constants';
import { getAngularService } from 'NgMigrationUtils/angular-react-helper';

const settings = require('@src/config/settings');

export const prepareCurriculum = async () => {
  const curriculumManager = getAngularService('curriculumManager');
  return curriculumManager.prepareCurriculum(
    null,
    null,
    settings.apisAndUrls.cachedContentApi,
    AN_VALUES_THEME_ROOT
  );
};

export const getByHrefNoPromise = (o) => {
  const curriculumManager = getAngularService('curriculumManager');
  return curriculumManager.getByHrefNoPromise(o);
};
