import React from 'react';
import ReactPortal from '@components/sections/ReactPortal';

const SidebarPortal = ({ open, onClose, children }) => (
  <ReactPortal portalId="sidebar">
    <div
      className="kov-sidebar__wrapper"
      id="kov-sidenav-filter"
      style={{ transform: `translateX(${open ? '0' : '555px'})` }}
    >
      <a className="closebtn" onClick={onClose}>
        <i className="icon-kov-pro-close" />
      </a>
      {children}
    </div>
  </ReactPortal>
);

export default SidebarPortal;
