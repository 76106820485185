import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useMenuItemFilters from '@src/hooks/useMenuItemFilters';
import { MenuItemFilters } from '@UI/index';
import CurriculaSelectorFilter from '@UI/curriculaSelector/CurriculaSelectorFilter';
import { useSelector } from 'react-redux';
import { selectNavigationFirstLevel } from '@src/reduxStore/navigation/navigationSelectors';

const NewsMenuItemSelector = ({
  filters,
  updateThemeFilter,
  updateCurriculaFilter,
  secondaryFilterValue,
  setSecondaryFilterValue,
}) => {
  const navigationFirstLevel = useSelector(selectNavigationFirstLevel);

  const { selectedMenuItems, getLevelOptions, onMenuItemSelected } = useMenuItemFilters(
    filters,
    updateThemeFilter
  );

  const changeFilter = (value) => {
    if (value === 'curricula') {
      updateThemeFilter(null);
    }
    if (value === 'theme') {
      updateCurriculaFilter('curricula', []);
    }
    setSecondaryFilterValue(value);
  };

  const [filterBlockCollapsed, setFilterBlockCollapsed] = useState(false);

  return (
    <div className="filter-block">
      <a
        data-toggle="collapse"
        role="button"
        aria-expanded="false"
        aria-controls="filter1"
        className={filterBlockCollapsed ? 'title collapsed' : 'title'}
        onClick={() => setFilterBlockCollapsed(!filterBlockCollapsed)}
      >
        Verfijn verder <i className="icon-kov-pro-down-arrow" />
      </a>
      <div
        className={filterBlockCollapsed ? 'filter-items collapse' : 'filter-items collapse show'}
      >
        <ul className="kov-input-search__radio">
          <li>
            <label className="filter-item active">
              <a
                aria-controls="home"
                role="tab"
                data-toggle="tab"
                className={`${secondaryFilterValue === 'theme' ? 'active' : ''}`}
                aria-expanded={secondaryFilterValue === 'theme'}
                onClick={() => changeFilter('theme')}
              >
                op thema
                <input
                  readOnly
                  type="radio"
                  name="filter"
                  checked={secondaryFilterValue === 'theme'}
                />
                <span className="checkround"></span>
              </a>
            </label>
          </li>
          <li>
            <label className="filter-item active">
              <a
                aria-controls="profile"
                role="tab"
                data-toggle="tab"
                className={`${secondaryFilterValue === 'curricula' ? 'active' : ''}`}
                aria-expanded={secondaryFilterValue === 'curricula'}
                onClick={() => changeFilter('curricula')}
              >
                op leerplannen secundair
                <input
                  readOnly
                  type="radio"
                  name="filter"
                  checked={secondaryFilterValue === 'curricula'}
                />
                <span className="checkround"></span>
              </a>
            </label>
          </li>
        </ul>
        <div className="tab-content">
          <div
            role="tabpanel"
            className={`${secondaryFilterValue === 'theme' ? 'active' : ''} tab-pane`}
            id="home"
            aria-expanded={`${secondaryFilterValue === 'theme' ? 'active' : ''}`}
          >
            <MenuItemFilters
              firstLevel={navigationFirstLevel}
              selectedMenuItems={selectedMenuItems}
              onMenuItemSelected={onMenuItemSelected}
              getLevelOptions={getLevelOptions}
            />
          </div>
          <div
            role="tabpanel"
            className={`${secondaryFilterValue === 'curricula' ? 'active' : ''} tab-pane`}
            id="profile"
            aria-expanded={`${secondaryFilterValue === 'curricula' ? 'active' : ''}`}
          >
            <CurriculaSelectorFilter
              appliedCurriculaFilterHrefs={filters.curricula}
              isBlock={true}
              onUpdate={(curriculaThemes) =>
                updateCurriculaFilter(
                  'curricula',
                  curriculaThemes.map((ct) => ct.href)
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

NewsMenuItemSelector.propTypes = {
  filters: PropTypes.object,
  updateThemeFilter: PropTypes.func,
  updateCurriculaFilter: PropTypes.func,
  secondaryFilterValue: PropTypes.string,
  setSecondaryFilterValue: PropTypes.func,
};

export default NewsMenuItemSelector;
