import { findForbiddenItem, getSecureAccessPlaceholderContent } from '@src/helpers/accessHelper';
import { useSelector } from 'react-redux';
import { getUserKey } from '@src/reduxStore/user/userSelectors';

const useDownloadGroup = (downloadGroup) => {
  const [mainDownload, ...secondaryDownloads] = downloadGroup.children;
  const forbiddenSecondaryAttachment = findForbiddenItem(downloadGroup.children);
  let forbiddenMessage = {};

  const userKey = useSelector(getUserKey);

  if (forbiddenSecondaryAttachment) {
    const { text, link, callToAction } = getSecureAccessPlaceholderContent(
      userKey,
      forbiddenSecondaryAttachment.securityStatus
    );

    forbiddenMessage = {
      text,
      link,
      callToAction,
    };
  }

  return {
    userKey,
    mainDownload,
    secondaryDownloads,
    forbiddenSecondaryAttachment,
    forbiddenMessage,
  };
};

export default useDownloadGroup;
