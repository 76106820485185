import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { getAngularService } from 'NgMigrationUtils/angular-react-helper';
import { getSitemapPathFromSource } from '@src/services/sitemapService';

import logo from '@src/assets/images/logo_ko_vl.svg';
import { useDispatch } from 'react-redux';
import trackPiwikEvent from '@src/reduxStore/analytics/analyticsThunks';

const CONSTANTS = require('@src/components/constants');

const Footer = ({ openSidebar }) => {
  const navigationService = getAngularService('navigationService');
  const settings = getAngularService('settings');
  const dispatch = useDispatch();

  const year = new Date().getFullYear();
  const newsletterUrl = `${settings.apisAndUrls.mijn}/#!/nieuwsbrieven`;

  const [snelnaar, setSnelNaar] = useState(null);
  const [contact, setContact] = useState(null);

  const copyrightDisclaimer = CONSTANTS.FOOTER_LINKS_COPYRIGHT_DISCLAIMERS;
  const socials = CONSTANTS.FOOTER_LINKS_SOCIAL;

  const goToNewsletter = () => {
    dispatch(trackPiwikEvent('start_newsletter'));
    window.open(newsletterUrl);
  };

  useEffect(() => {
    (async () => {
      const footerMenu = await navigationService.getFooterMenu();

      if (footerMenu) {
        setSnelNaar(
          footerMenu.children.find((item) => item.href === CONSTANTS.FOOTER.SNELNAAR).children
        );
        setContact(
          footerMenu.children.find((item) => item.href === CONSTANTS.FOOTER.CONTACT).children
        );
      }
    })();
  }, [navigationService]);

  return (
    <section id="footer">
      <div className="kov-pro-gradient-wrapper">
        <div className="kov-pro-gradient" />
      </div>
      <div className="kov-pro-footer container-fluid">
        <div className="kov-pro-footer__content row">
          <div className="col-12 col-md-3">
            <a className="" href="#">
              <img className="logo" src={logo} alt="Logo" />
            </a>
          </div>
          <div className="col-12 col-md-3">
            <h3 className="kov-pro-footer__list-title">Snel naar</h3>
            <ul className="kov-pro-footer__list">
              {snelnaar &&
                snelnaar.map((item) => (
                  <li key={item.key}>
                    <a href={getSitemapPathFromSource(item.references[0])}>{item.title}</a>
                  </li>
                ))}
            </ul>
          </div>
          <div className="col-12 col-md-3">
            <h3 className="kov-pro-footer__list-title">Contact</h3>
            <ul className="kov-pro-footer__list">
              {contact &&
                contact.map((item) => (
                  <li key={item.key}>
                    <a href={getSitemapPathFromSource(item.references[0])}>{item.title}</a>
                  </li>
                ))}
            </ul>
          </div>
          <div className="col-12 col-md-3">
            <h3 className="kov-pro-footer__list-title">Nieuwsbrief</h3>
            <button
              onClick={goToNewsletter}
              className="kov-pro-btn kov-pro-btn--lg kov-pro-btn--green kov-pro-btn__icon--left"
            >
              <i className="icon-kov-pro-newsletter" />
              <span>Schrijf in</span>
            </button>

            <h3 className="kov-pro-footer__list-title" style={{ marginTop: '40px' }}>
              MIJN.
            </h3>
            <ul className="kov-pro-footer__list">
              <li>
                <a
                  href="https://mijn.katholiekonderwijs.vlaanderen"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="icon-kov-pro-profile" /> Beheer
                </a>
              </li>
              <li>
                <a href="#" onClick={openSidebar}>
                  <i className="icon-kov-pro-filter" /> Kijkfilter
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* footer bottom  */}
        <div className="kov-pro-footer__copyright row">
          <div className="kov-pro-footer__copyright-info">
            <div className="kov-pro-footer__copyright-disclaimer col-12 col-sm-12 col-md-8">
              <p>
                Katholiek Onderwijs Vlaanderen <span>- © {year}</span>
              </p>
              <ul className="kov-pro-footer__copyright-disclaimer-list">
                {copyrightDisclaimer &&
                  copyrightDisclaimer.map((item, index) => (
                    <li
                      key={index}
                      className="kov-pro-footer__copyright-disclaimer-list-item list-inline-item"
                    >
                      <a href={item.href}>{item.title}</a>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="kov-pro-footer__copyright-social col-12 col-sm-12 col-md-4">
              <ul className="kov-pro-footer__copyright-social-list">
                {socials &&
                  socials.map((item, index) => (
                    <li
                      key={index}
                      className="kov-pro-footer__copyright-social-list-item"
                      style={{ marginRight: '.5rem' }}
                    >
                      <a target="_blank" href={item.href} className={item.aClass} rel="noreferrer">
                        <i className={item.iClass} />
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Footer.propTypes = {
  openSidebar: PropTypes.func,
};

export default Footer;
