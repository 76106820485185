import React from 'react';

const CtaBanner = ({ banner }) => (
  <div className="row">
    <div className="col-12">
      <div className="kov-pro-cta">
        <div className="kov-pro-cta__wrapper">
          <div>
            <div className="kov-pro-cta__title">{banner.title}</div>
            <div className="kov-pro-cta__text">{banner.subtitle}</div>
          </div>
          <div>
            <button
              type="button"
              className="kov-pro-btn kov-pro-btn--green"
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  banner.url,
                  banner.url.includes('pro.katholiekonderwijs.vlaanderen') ? '_self' : '_blank'
                );
              }}
            >
              {banner.cta}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default CtaBanner;
