import React, { useContext } from 'react';
import { Compiler } from '@kathondvla/react-shared-components/src/components';
import PropTypes from 'prop-types';
import { KovContentBlockContext } from '@kathondvla/react-shared-components/src/components/contentBlock/KovContentBlockProvider';

const ContentBlockQuote = ({ item }) => {
  const { color, compilerOptions } = useContext(KovContentBlockContext);

  return (
    item.visible && (
      <blockquote className="kov-quote theme-content-blockquote" style={{ color }}>
        <span>
          <Compiler text={item.$$html} options={compilerOptions} />
        </span>
        {item?.creators?.length > 0 && <span className="credit">{item.creators[0]}</span>}
      </blockquote>
    )
  );
};

ContentBlockQuote.propTypes = {
  item: PropTypes.object,
};

export default ContentBlockQuote;
