import React, { useMemo } from 'react';
import { getSitemapPathFromSource } from '@src/services/sitemapService';
import ApplicabilityLabels from '@UI/applicabilityLabels/ApplicabilityLabels';
import { stripHtml } from '@src/app/utils';

const NewsTeaser = ({ teaser, sThemePath, minified = false }) => {
  const publicationDate = useMemo(
    () =>
      teaser.issued
        ? new Date(teaser.issued).toLocaleDateString('nl-BE', {
            weekday: 'long',
            month: 'long',
            day: '2-digit',
          })
        : '',
    [teaser.issued]
  );

  return (
    <>
      <li>
        <a
          href={getSitemapPathFromSource(
            sThemePath && teaser.isDetailPage ? teaser.localForwardHref : teaser.globalForwardHref
          )}
          target="_blank"
          className={`kov-pro-news-teaser ${
            teaser.isExternal ? 'kov-pro-news-teaser--external' : ''
          }`}
          style={minified ? undefined : { justifyContent: 'space-between' }}
          rel="noreferrer"
        >
          <div className="kov-pro-news-teaser__info">
            <span className="kov-pro-news-teaser__info-date">
              <i className="icon-kov-pro-bookmark-full" /> {publicationDate}
            </span>
            <div className="kov-pro-news-teaser__info-title">
              <span dangerouslySetInnerHTML={{ __html: stripHtml(teaser.title) }} />
              <i className="icon-kov-pro-right-arrow" />
            </div>
            {!minified && <div dangerouslySetInnerHTML={{ __html: teaser.description }} />}
            <ApplicabilityLabels labels={teaser.tags} />
          </div>
          {!minified && teaser.thumbImage && (
            <div className="kov-pro-news-teaser__thumb">
              <div
                className="kov-pro-news-teaser__thumb-img"
                style={{
                  minWidth: '250px',
                  background: `url('${teaser.thumbImage.href}') center center no-repeat`,
                }}
              />
            </div>
          )}
        </a>
      </li>
    </>
  );
};

export default NewsTeaser;
