const { checkPathSecurity } = require('@src/helpers/accessHelper');
const { cookiesAccepted } = require('@helpers/cookieHelper');
const { default: analyticsState } = require('@src/reduxStore/analytics/analyticsSlice');
const store = require('@src/reduxStore/store').default;
const { notifyRedactiePageLoaded } = require('@src/helpers/postMessageHelper');
const { isThemeValid } = require('@src/helpers/themeHelper.js');
const {
  selectCurrentThemeTreeWithVisibilityExceptions,
  selectFlatCurrentThemeTree,
  selectTreeLoadingStatus,
} = require('@src/reduxStore/theme/themeSelectors');

class wsTheme {
  constructor(
    $scope,
    $state,
    $stateParams,
    $location,
    $window,
    $anchorScroll,
    routerService,
    utils,
    kovSentry,
    $ngRedux
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$location = $location;
    this.$window = $window;
    this.$anchorScroll = $anchorScroll;
    this.routerService = routerService;
    this.utils = utils;
    this.kovSentry = kovSentry;
    this.$ngRedux = $ngRedux;
    this.kovAccentId = null;
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const theme = selectCurrentThemeTreeWithVisibilityExceptions(state, this.$stateParams.source);
      if (selectTreeLoadingStatus(state) !== 'succeeded') {
        return {
          sTheme: false,
        };
      }
      const isVisible = this.$stateParams.source
        ? selectFlatCurrentThemeTree(state).find((item) => item.href === this.$stateParams.source)
            ?.visible
        : theme.visible;

      this.$scope.$emit('showOutOfViewOptions', !isVisible);

      return {
        sTheme: theme,
      };
    })(this);

    this.$window.addEventListener(
      'changeThemeColor',
      (e) => {
        this.sTheme.root = { ...this.sTheme.root, color: e.detail };
        this.kovAccentId = this.utils.getKOVAccentIdByThemeColor(e.detail);
        this.$scope.$apply();
      },
      false
    );

    if (isThemeValid(this.sTheme)) {
      this.$scope.$emit('setPageSettings', this.sTheme);

      this.checkSecurity();

      this.$anchorScroll.yOffset = 70;

      notifyRedactiePageLoaded();
    } else {
      this.routerService.transitionTo404();
    }

    const currentLocation = this.$location.$$path;
    this.$scope.$on('$destroy', () => {
      if (currentLocation === '/cookies' && !cookiesAccepted())
        this.$scope.$emit('appHome:cookies');
    });

    this.kovAccentId = this.utils.getKOVAccentIdByThemeColor(this?.sTheme?.root?.color);
  }

  checkSecurity() {
    if (!cookiesAccepted()) return;

    const pathSecurity = checkPathSecurity(this.$stateParams.source, this.sTheme);

    if (!pathSecurity) return;

    store.dispatch(
      analyticsState.actions.updateCommonProperties({
        page_access: 'denied',
      })
    );

    switch (pathSecurity) {
      case 'afgeschermd1':
        this.$state.transitionTo('afgeschermd-1', { theme: this.sTheme }, { location: false });
        break;
      case 'afgeschermd2':
        this.$state.transitionTo('afgeschermd-2', { theme: this.sTheme }, { location: false });
        break;
      case 'afgeschermd3':
        this.$state.transitionTo('afgeschermd-3', { theme: this.sTheme }, { location: false });
        break;
      case 'afgeschermd4':
        this.$state.transitionTo('afgeschermd-4', { theme: this.sTheme }, { location: false });
        break;
      default:
        break;
    }
  }

  $onDestroy() {
    this.unsubscribe();
    this.$scope.$emit('setPageSettings');
    this.$scope.$emit('showOutOfViewOptions', false);
  }
}

module.exports = {
  template: require('./wsTheme.html'),
  controllerAs: 'ctrl',
  controller: wsTheme,
  bindings: {},
};
