import { getNonAbolishedResources } from '@kathondvla/sri-client/date-utils';
import {
  getDiocesesHrefsFromZipcodes,
  getSAMOusEducationalProgrammes,
  getSAMOusLocations,
  getVOSOusLocations,
} from '@src/reduxStore/content/organisationsDataAccess';

const getSAMLocationsZipCodes = async (SAMOus) =>
  SAMOus.length
    ? getSAMOusLocations(SAMOus.map((SAMOu) => SAMOu.$$meta.permalink)).then((locations) =>
        locations.map((location) => location.physicalLocation.$$expanded.address.zipCode)
      )
    : [];

const getCVOsZipCodes = (VOSOus) =>
  VOSOus.filter((organisation) => organisation.$$meta.type === 'CVO').reduce((cvoZipCodes, cvo) => {
    const cvoAddresses = getNonAbolishedResources(cvo.seatAddresses);
    if (cvoAddresses.length > 0) {
      cvoZipCodes.push(...cvoAddresses.map((cvoAddress) => cvoAddress.address.zipCode));
    }
    return cvoZipCodes;
  }, []);

const getVOSOusZipCodes = async (VOSOus) => {
  if (VOSOus.length === 0) return [];

  const cvosZipCodes = getCVOsZipCodes(VOSOus);

  const VOSLocationsHrefs = VOSOus.reduce((accVOSLocationsHrefs, VOSOu) => {
    getNonAbolishedResources(VOSOu.$$locations || []).forEach(
      (VOSOuLocation) =>
        !accVOSLocationsHrefs.includes(VOSOuLocation.href) &&
        accVOSLocationsHrefs.push(VOSOuLocation.href)
    );
    return accVOSLocationsHrefs;
  }, []);

  const VOSOusZipCodes = await getVOSOusLocations(VOSLocationsHrefs).then((VOSOusLocationsResp) =>
    VOSOusLocationsResp.map((location) => location.address.zipCode)
  );

  return [...new Set([...cvosZipCodes, ...VOSOusZipCodes])];
};

const getSAMOusMainstructuresHrefs = async (SAMOusHrefs) =>
  getSAMOusEducationalProgrammes(SAMOusHrefs).then((eduProgsResp) =>
    getNonAbolishedResources(eduProgsResp).reduce((mainstructuresHrefs, currEduProg) => {
      const mainstructureHref = (
        currEduProg.mainstructure || currEduProg.ag?.$$expanded?.mainstructure
      )?.href;

      if (mainstructureHref) mainstructuresHrefs.push(mainstructureHref.split('/sam/commons')[1]);

      return mainstructuresHrefs;
    }, [])
  );

const getVOSOusMainstructuresHrefs = (VOSOus) =>
  VOSOus.reduce((mainstructuresHrefs, VOSOu) => {
    if (VOSOu.mainStructures) {
      mainstructuresHrefs.push(
        ...getNonAbolishedResources(VOSOu.mainStructures).map((mainstructure) => mainstructure.href)
      );
    }
    return mainstructuresHrefs;
  }, []);

export const getUserDiocesesHrefs = async (SAMOus, VOSOus) => {
  const [SAMOusZipCodes, VOSOusZipCodes] = await Promise.all([
    getSAMLocationsZipCodes(SAMOus),
    getVOSOusZipCodes(VOSOus),
  ]);

  return getDiocesesHrefsFromZipcodes([...new Set([...SAMOusZipCodes, ...VOSOusZipCodes])]);
};

export const getUserMainstructuresHrefs = async (SAMOusHrefs, VOSOus) => {
  const SAMOusMainstructuresHrefs = await getSAMOusMainstructuresHrefs(SAMOusHrefs);

  const VOSOusMainstructuresHrefs = getVOSOusMainstructuresHrefs(VOSOus);

  return [...new Set([...SAMOusMainstructuresHrefs, ...VOSOusMainstructuresHrefs])];
};
