class wsThemeHome3 {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
  }

  $onInit() {
    if (this.sTheme) {
      this.$scope.$emit('dynamicMetaDataUpdated', this.sTheme);
    }
  }
}

module.exports = {
  template: require('./wsThemeHome3.html'),
  controllerAs: 'ctrl',
  controller: wsThemeHome3,
  bindings: {
    sTheme: '<',
  },
};
