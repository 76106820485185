import React, { useState } from 'react';
import CheckboxGroup from '@UI/checkboxGroup/checkboxGroup';
import TrainingLocation from '@UI/trainings/trainingLocation/trainingLocation';
import DateRangePicker from '@UI/dateRangePicker/dateRangePicker';
import SearchInput from '@components/UI/searchInput/searchInput';
import { getSchoolYearEndDate } from '@src/helpers/trainingsHelper';
import PropType from 'prop-types';
import { useMediaQuery } from '@react-hook/media-query';

const collapsibleFiltersData = [
  {
    filterId: 'educationLevel',
    filterLabel: 'Onderwijsniveau',
  },
  {
    filterId: 'functionFilter',
    filterLabel: 'Functie',
  },
  {
    filterId: 'attendanceType',
    filterLabel: 'Fysiek of online',
  },
  {
    filterId: 'trainingType',
    filterLabel: 'Type',
  },
  {
    filterId: 'locationAndDate',
    filterLabel: 'Locatie en datum',
  },
];

const CollapsibleFilterHolder = ({
  filterData,
  isFilterCollapsed,
  onCollapseHandler,
  children,
}) => {
  const { filterLabel, filterId } = filterData;
  return (
    <div className="filter-block">
      <a
        className={`title${isFilterCollapsed(filterId) ? ' collapsed' : ''}`}
        role="button"
        onClick={() => onCollapseHandler(filterId)}
      >
        {filterLabel} <i className="icon-kov-pro-down-arrow" />
      </a>
      <div
        id={filterId}
        className={`filter-items${
          filterData.filterId === 'locationAndDate' ? ' filter-items--distance' : ''
        } collapse${isFilterCollapsed(filterId) ? ' show' : ''}`}
      >
        {children}
      </div>
    </div>
  );
};

const LocalTrainingFilters = ({
  doSearch,
  cleanAllFilters,
  viewModel,
  updateFilter,
  updateCheckboxFilter,
  mobileFilterShow,
  setMobileFilterShow,
}) => {
  const isTablet = useMediaQuery('screen and (max-width: 992px)');

  const [collapsedFilters, setCollapsedFilters] = useState([]);

  const isFilterCollapsed = (filterId) => collapsedFilters.includes(filterId);
  const onCollapseHandler = (filterId) =>
    setCollapsedFilters((prevCollapsedFilters) =>
      prevCollapsedFilters.includes(filterId)
        ? prevCollapsedFilters.filter((val) => val !== filterId)
        : [...prevCollapsedFilters, filterId]
    );

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      doSearch();
    }
  };

  return (
    <div
      className="kov-sidebar__wrapper"
      id="kov-sidenav-filter"
      style={{
        transform: isTablet ? `translateX(${mobileFilterShow ? '0px' : '555px'})` : 'none',
      }}
    >
      <a className="closebtn" onClick={setMobileFilterShow}>
        <i className="icon-kov-pro-close" />
      </a>
      <div className="kov-pro-filter">
        <div className="row">
          <div className="kov-pro-news__filter--desktop col-12">
            <div onKeyDown={handleKeyDown}>
              <div className="kov-pro-filter__title-wrapper">
                <h2 className="kov-pro-filter__title">Filter</h2>
                <button className="filter-tag" onClick={cleanAllFilters}>
                  wis filter <i className="icon-kov-pro-close" />
                </button>
              </div>
              <div className="kov-pro-events__filter">
                <div className="filter-block">
                  <div className="title">Zoek</div>
                  <div className="filter-items">
                    <div className="kov-input-search">
                      <SearchInput
                        placeholder="Zoek"
                        value={viewModel.q}
                        onChange={(term) => updateFilter('q', term)}
                        onSearch={doSearch}
                        inputClassName="form-control"
                      />
                    </div>
                  </div>
                </div>
                <>
                  {collapsibleFiltersData.map((collapsibleFilterData, index) => (
                    <CollapsibleFilterHolder
                      filterData={collapsibleFilterData}
                      isFilterCollapsed={isFilterCollapsed}
                      onCollapseHandler={onCollapseHandler}
                      key={index}
                    >
                      {collapsibleFilterData.filterId === 'educationLevel' && (
                        <CheckboxGroup
                          customStyles={'filter-block__type'}
                          groupArray={viewModel.mainstructuresOuTypeCombinations}
                          checkboxId={'mainstructuresOuTypeCombinations'}
                          onChange={updateCheckboxFilter}
                          itemListClassName="filter-block__type"
                          itemListItemClassName="kov-pro-nas-filter__list-item"
                        />
                      )}
                      {collapsibleFilterData.filterId === 'functionFilter' && (
                        <CheckboxGroup
                          customStyles={'filter-block__type'}
                          groupArray={viewModel.positions}
                          checkboxId={'positions'}
                          onChange={updateCheckboxFilter}
                          itemListClassName="filter-block__type"
                          itemListItemClassName="kov-pro-nas-filter__list-item"
                        />
                      )}
                      {collapsibleFilterData.filterId === 'attendanceType' && (
                        <CheckboxGroup
                          customStyles={'filter-block__type'}
                          groupArray={viewModel.attendanceType}
                          checkboxId={'attendanceType'}
                          onChange={updateCheckboxFilter}
                          itemListClassName="filter-block__type"
                          itemListItemClassName="kov-pro-nas-filter__list-item"
                        />
                      )}
                      {collapsibleFilterData.filterId === 'trainingType' && (
                        <CheckboxGroup
                          customStyles={'filter-block__type'}
                          groupArray={viewModel.trainingTypes}
                          checkboxId={'trainingTypes'}
                          onChange={updateCheckboxFilter}
                          itemListClassName="filter-block__type"
                          itemListItemClassName="kov-pro-nas-filter__list-item"
                        />
                      )}
                      {collapsibleFilterData.filterId === 'locationAndDate' && (
                        <>
                          <TrainingLocation
                            latitude={viewModel.lat}
                            longitude={viewModel.lon}
                            distanceInKm={+viewModel.distanceInKm}
                            updateDistance={(filterValue) =>
                              updateFilter('distanceInKm', filterValue)
                            }
                            updateGPSPositionFilters={(latFilterValue, lonFilterValue) => {
                              updateFilter('lat', latFilterValue);
                              updateFilter('lon', lonFilterValue);
                            }}
                          />

                          <DateRangePicker
                            startDate={
                              viewModel.dateFrom ? new Date(+viewModel.dateFrom) : undefined
                            }
                            endDate={viewModel.dateTo ? new Date(+viewModel.dateTo) : undefined}
                            minDate={new Date()}
                            maxDate={getSchoolYearEndDate()}
                            onDaySelected={updateFilter}
                          />
                        </>
                      )}
                    </CollapsibleFilterHolder>
                  ))}
                </>

                <button
                  onClick={doSearch}
                  className="kov-pro-btn kov-pro-btn--block kov-pro-btn--darkgreen"
                >
                  Toon resultaten
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

LocalTrainingFilters.prototype = {
  doSearch: PropType.func,
  cleanAllFilters: PropType.fnc,
  viewModel: PropType.object,
  updateFilter: PropType.fnc,
  updateCheckboxFilter: PropType.fnc,
  mobileFilterShow: PropType.bool,
  setMobileFilterShow: PropType.func,
};

export default LocalTrainingFilters;
