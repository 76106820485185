import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ApplicabilityLabels from '@UI/applicabilityLabels/ApplicabilityLabels';
import { dateToString } from '@helpers/utils';
import * as settings from '@src/config/settings';

const NewsResult = ({ newsItem, childRef }) => {
  const isExternal = useMemo(
    () => newsItem.callToAction != null && newsItem.callToAction.type === 'EXTERNAL',
    [newsItem]
  );

  const thumbnail = useMemo(() => {
    if (newsItem.thumbnails == null) return undefined;

    const thumbs = newsItem.thumbnails
      .filter((thumb) => thumb.width != null && parseInt(thumb.width, 10) >= 250)
      .filter(Boolean)
      .sort((a, b) => parseInt(a.width, 10) < parseInt(b.width, 10));
    if (thumbs.length > 0) {
      return thumbs ? encodeURI(thumbs?.[0]?.href) : null;
    }
    return null;
  }, [newsItem]);

  const goTo = useMemo(() => {
    // Rules (according to Matthias):
    //  - no callToAction => we send the user to "newsItem.webInfo.path"
    //  - callToAction type `EXTERNAL` => we send the user to "callToAction.href"
    //  - callToAction type `INTERNAL`:
    //    - Trainings (aka "fake-internal") => we send the user to nascholing with
    //      "callToAction.link" attribute in the `redirectID` param
    //    - Other Internal (aka "real-internal") => we send the user to "callToAction.href"
    if (newsItem.callToAction && newsItem.callToAction.length > 0) {
      const callToAction = newsItem.callToAction[0];
      if (isExternal) {
        return callToAction.href;
      }
      if (/^\/training\/modules/.test(callToAction.link)) {
        const returnUrl = `${window.location.href}`;

        return `${settings.apisAndUrls.trainings.nascholing}redirectTo.aspx?redirectID=${
          callToAction.link
        }&returnUrl=${encodeURI(returnUrl)}`;
      }
      return `${callToAction.href}`;
    }
    return `${newsItem.webInfo.path}`;
  }, [newsItem, isExternal]);

  return (
    <li ref={childRef}>
      <a
        href={goTo}
        className={`kov-pro-news-teaser ${thumbnail ? '' : 'kov-pro-news-teaser--no-thumb'} ${
          isExternal ? 'kov-pro-news-teaser--external' : ''
        }`}
        target="_blank"
        rel="noreferrer"
      >
        <div className="kov-pro-news-teaser__info">
          {newsItem.issued?.startDate && (
            <p className="text-date-time">
              <span className="date">
                <i className="icon-kov-pro-bookmark-full" />{' '}
                {dateToString(newsItem.issued.startDate, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  weekday: 'long',
                })}
              </span>
              <span className="time" />
              <span className="author">
                <i className="icon-kov-pro-profile" />
              </span>
            </p>
          )}
          <div className="kov-pro-news-teaser__info-title">
            {newsItem.title && <span dangerouslySetInnerHTML={{ __html: newsItem.title }} />}
            <i className="icon-kov-pro-right-arrow" />
          </div>
          {newsItem.description && (
            <div
              className="kov-pro-news-teaser__info-excerpt"
              dangerouslySetInnerHTML={{ __html: newsItem.description }}
            />
          )}

          <ApplicabilityLabels labels={newsItem.tags} />
        </div>
        {thumbnail != null && (
          <div className="kov-pro-news-teaser__thumb">
            <div
              className="kov-pro-news-teaser__thumb-img"
              style={{
                background: `url("${settings.apisAndUrls.api}${thumbnail}") center center no-repeat`,
              }}
            />
          </div>
        )}
      </a>
    </li>
  );
};

NewsResult.propTypes = {
  newsItem: PropTypes.object,
  proTheme: PropTypes.object,
  childRef: PropTypes.object,
  index: PropTypes.number,
};

export default NewsResult;
