import { useState, useEffect } from 'react';
import { getAngularService } from 'NgMigrationUtils/angular-react-helper';
import { setVisibilityByViewOptions } from '@helpers/viewOptionsHelper.js';

import * as utils from '@src/app/utils';
import { TRAININGS_GLOBAL_DATABASE_SPOTLIGHTED_THEMES_REFERENCE_FRAME } from '@src/components/constants';
import { useSelector } from 'react-redux';
import { selectUserViewOptions } from '@src/reduxStore/user/userSelectors';
import { getHrefDescendantsByType } from '@src/reduxStore/content/contentDataAccess';

const retrieveThumbnail = (theme) => {
  if (!theme) {
    return null;
  }
  if (theme.thumbImageS) {
    return theme.thumbImageS.href;
  }
  if (
    theme.coverImage &&
    theme.coverImage.dimension?.w === 100 &&
    theme.coverImage.dimension?.h === 100
  ) {
    return theme.coverImage.href;
  }
  return null;
};

const useThemeSpotlight = () => {
  const [spotlightThemes, setSpotlightThemes] = useState([]);
  const viewOptions = useSelector(selectUserViewOptions);

  const retrieveSpotlightedThemes = async () => {
    const navigationService = getAngularService('navigationService');
    const routerService = getAngularService('routerService');
    const globalTrainingsDatabankHref = routerService.findWebConfigurationByType(
      'GLOBAL_TRAININGSDATABASE'
    );

    // we need:
    //  1. the users view options for filtering
    //  2. the entire reference frame for global trainings.
    //  3. the homepage document
    const [globalNewsThemeDescendants, themeTree] = await Promise.all([
      getHrefDescendantsByType(globalTrainingsDatabankHref?.source, ['SECTION']),
      navigationService.getNavigationTreeAsync(),
    ]);

    // THIS IS THE MAIN MENU REFERENCE FRAME.
    const themeTreeArray = utils.flattenTree(themeTree);

    // THIS IS THE SPOTLIGHTED TRAININGS THEME REFERENCE FRAME
    // We find the children of the "Thema's in de kijker" node.
    const spotlightedThemesArray = globalNewsThemeDescendants.filter((item) =>
      item.$$relationsFrom.find(
        (rel) =>
          rel.$$expanded.relationtype === 'IS_PART_OF' &&
          rel.$$expanded.to.href === TRAININGS_GLOBAL_DATABASE_SPOTLIGHTED_THEMES_REFERENCE_FRAME
      )
    );

    const referencedMenuItems = spotlightedThemesArray
      .map((documentLeaf) => {
        // get the referenced menu item from the spotlighted theme
        const menuItemHref = documentLeaf.$$relationsFrom.find(
          (relation) => relation.$$expanded.relationtype === 'REFERENCES'
        ).$$expanded.to.href;

        // find that menu item in the navigation tree
        const navigationTreeItem = themeTreeArray.find(
          (theme) => theme.references?.length > 0 && theme.references.includes(menuItemHref)
        );

        if (!navigationTreeItem) return undefined;

        // return an abridged version of the navigation menu item
        return {
          title: navigationTreeItem.title,
          color: navigationTreeItem.color,
          mainstructuresOuTypeCombinations: navigationTreeItem.mainstructuresOuTypeCombinations,
          coverage: navigationTreeItem.coverage,
          coverImage: retrieveThumbnail(navigationTreeItem.theme),
          menuItemHref: navigationTreeItem.href,
        };
      })
      .filter(Boolean);

    // filter by users view options
    setVisibilityByViewOptions(referencedMenuItems, viewOptions);
    /** Only visible themes according to the user's view options should be in the spotlight */
    setSpotlightThemes(referencedMenuItems.filter((theme) => theme.visible));
  };

  useEffect(() => {
    (async () => {
      retrieveSpotlightedThemes();
    })();
    // Valid react rule when you only want to execute this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    spotlightThemes,
  };
};

export default useThemeSpotlight;
