import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import settings from '@src/config/settings';

const CookiesEntryFlow = ({ completeCookies }) => {
  const modalRef = useRef(null);
  const handleCompleted = (ev) => completeCookies(ev.detail);
  useEffect(() => {
    const div = modalRef.current;
    div.addEventListener('onSubmitCookies', handleCompleted);
    return () => {
      div.removeEventListener('onSubmitCookies', handleCompleted);
    };
  }, []);
  return (
    <kov-cookie-modal
      ref={modalRef}
      essential-id={settings.cookies.essentialId}
      analytic-id={settings.cookies.analyticId}
      social-media-id={settings.cookies.socialMediaId}
      content-href={settings.cookies.contentHref}
    ></kov-cookie-modal>
  );
};

CookiesEntryFlow.propTypes = {
  isShown: PropTypes.bool,
  completeCookies: PropTypes.func,
  cookiesTheme: PropTypes.object,
  cookiesSource: PropTypes.string,
};
export default CookiesEntryFlow;
