const contentHrefPathRegexp =
  /\/content\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/s;
export const isContentHrefPath = (path) => contentHrefPathRegexp.test(path);
export const getContentHref = (path) => {
  const processedPath = path || '';
  return (
    processedPath.match(contentHrefPathRegexp) && processedPath.match(contentHrefPathRegexp)[0]
  );
};

export const getPathFromUrl = (urlStr) => {
  const processedUrlStr = urlStr || '';
  if (processedUrlStr.startsWith('http')) {
    const url = new URL(processedUrlStr);
    return url.pathname;
  }

  return processedUrlStr.split('?')[0];
};
