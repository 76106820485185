// This contains most code for supporting OAuth, translations, and remote logging.

module.exports = function (
  $provide,
  $uiRouterProvider,
  cfpLoadingBarProvider,
  ngMetaProvider,
  $locationProvider
) {
  'ngInject';

  require('./vskoconfig')($provide);
  require('../routes/routes').default($uiRouterProvider, $locationProvider, ngMetaProvider);

  cfpLoadingBarProvider.includeBar = false;
  cfpLoadingBarProvider.includeSpinner = false;
};
