import { apisAndUrls } from '@src/config/settings';

// Hardcoded routes
let localSitemap = [
  { path: '/niet-aangemeld', type: 'SECUREACCESS1' },
  { path: '/geen-toegang', type: 'SECUREACCESS3' },
  { path: '/geen-toegang-strikt', type: 'SECUREACCESS4' },
  { path: '/Informatie-voor-besturen', type: 'SECUREACCESS2' },
  { path: '/zoeken', type: 'GLOBAL_SEARCH' },
];

let sitemapPromise = null;

const getSitemapProHash = () => {
  return fetch(`${apisAndUrls.websitesApi}/web/sitemap/pro/hash`)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(response);
    })
    .catch((err) => {
      console.error(
        'Sitemap Service - PRO Sitemap error getting hash (/web/sitemap/pro/hash)',
        err
      );
    });
};

const getRemoteSitemap = (sitemapProHash) => {
  const endPoint = `${apisAndUrls.cachedWebsitesApi}/web/sitemap/pro`;
  const apiCallUrl = `${endPoint}${shouldFetchUnpublished() ? '?allowNotPublished=true' : ''}`;
  const headers = sitemapProHash?.hash
    ? { headers: { 'VSKO-Resource-Hash': sitemapProHash.hash } }
    : {};
  return fetch(apiCallUrl, headers)
    .then((response) => response.json())
    .catch((err) => {
      console.error('Sitemap Service - PRO Sitemap error getting sitemap (/web/sitemap/pro)', err);
    });
};

// Dynamic routes
const fetchSitemap = async () => {
  if (sitemapPromise) {
    return sitemapPromise;
  }

  sitemapPromise = new Promise(async (resolve) => {
    const sitemapProHash = await getSitemapProHash();
    const remoteSitemap = await getRemoteSitemap(sitemapProHash);
    localSitemap = localSitemap.concat(remoteSitemap);

    resolve();
  });
  return sitemapPromise;
};

const shouldFetchUnpublished = () => {
  const settings = require('../config/settings');

  return (
    settings.allowNotPublished ||
    window.sessionStorage.getItem('preview') == 'true' ||
    window.location.search.indexOf('preview') !== -1
  );
};

/** UTILS FUNCTIONS */
const getSitemapItemByPath = (path) => {
  return localSitemap.find((page) => page.path.toLowerCase() === path.toLowerCase());
};

const getAllSitemapItemsFromType = (type) => {
  return localSitemap.filter((page) => page.type === type);
};

const getSitemapItemFromSource = (source) => {
  const matches = localSitemap.filter((page) => page.source === source);
  if (matches.length === 1) {
    return matches[0];
  } else {
    return matches.find((page) => page.rootHref === source && !page.type.includes('LOCAL'));
  }
};

const getAllItemsBySource = (source) => {
  return localSitemap.filter((page) => page.source === source);
};

const getSitemapItemFromSourceOrPath = (sourceOrPath) => {
  let sitemapItem;
  if (sourceOrPath.startsWith('/content')) {
    sitemapItem = getSitemapItemFromSource(sourceOrPath);
  } else {
    sitemapItem = getSitemapItemByPath(sourceOrPath);
  }
  return sitemapItem;
};

const getSitemapPathFromSource = (source) => {
  const sitemap = getSitemapItemFromSource(source);
  return sitemap?.path || source;
};

const getValidUrl = (href) => {
  const sitemapUrl = getSitemapPathFromSource(href);
  if (sitemapUrl) {
    return sitemapUrl;
  }

  return href;
};

const extendSitemapRedirects = (redirectsToAdd, targetPath) => {
  redirectsToAdd.forEach((redToAdd) => {
    const alreadyExist = localSitemap.find((sitemapEl) => sitemapEl.path === redToAdd.path);
    if (!alreadyExist) {
      localSitemap.push({
        path: redToAdd.path,
        type: 'REDIRECT',
        targetPath: targetPath,
      });
    }
  });
};

const extendSitemapPreviewMode = (webpagesToAdd) => {
  webpagesToAdd.forEach((webpage) => {
    const alreadyExist = getSitemapItemFromSource(webpage.source.href);
    if (!alreadyExist) {
      localSitemap.push({
        rootHref: webpage.externalReferences && webpage.externalReferences[0],
        source: webpage.source.href,
        path: webpage.path.toLowerCase(),
        type: webpage.type,
      });
    } else {
      alreadyExist.type = webpage.type;
    }
  });
};

export {
  fetchSitemap,
  getAllSitemapItemsFromType,
  getAllItemsBySource,
  getSitemapItemFromSource,
  getSitemapPathFromSource,
  getSitemapItemFromSourceOrPath,
  extendSitemapRedirects,
  extendSitemapPreviewMode,
  getValidUrl,
};
