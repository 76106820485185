import React, { useState } from 'react';
import CheckboxOption from '@UI/checkboxOption/checkboxOption';

const DatabaseReferenceFilter = ({ requirement, onChange }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <>
      {requirement.values && requirement.values.length > 0 && (
        <div className="filter-block">
          <a
            className={`title${collapsed ? ' collapsed' : ''}`}
            data-toggle="collapse"
            onClick={() => setCollapsed(!collapsed)}
            role="button"
            aria-expanded={!collapsed}
            aria-controls={requirement.source}
            style={{ width: '100%' }}
          >
            {requirement.label} <i className="icon-kov-pro-down-arrow" />
          </a>
          <ul
            className={`filter-items collapse${!collapsed ? ' show' : ''}`}
            id={requirement.source}
          >
            {requirement.values.map((value) => (
              <CheckboxOption key={value.key} option={value} onChange={() => onChange(value.key)} />
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
export default DatabaseReferenceFilter;
