import React, { useState, useRef, useEffect } from 'react';

const ViewOptionDropdown = ({
  invalid = false,
  options,
  initialOptionsToSet,
  assignValues,
  placeholder,
  invalidFeedback,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [optionsToSet, setOptionsToSet] = useState([]);
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (initialOptionsToSet) {
      setOptionsToSet(
        initialOptionsToSet.map((item) => ({
          ...item,
          checked: !!item.checked,
        }))
      );
    }
  }, [initialOptionsToSet]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && isOpen && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
        setOptionsToSet(
          optionsToSet.map((opt) => {
            opt.checked = options.find((opt2) => opt2.readOrder === opt.readOrder).checked;
            return opt;
          })
        );
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // dispose
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, isOpen, options, optionsToSet]);

  return (
    <div className="kov-modal__dropdown">
      <button
        className={`kov-modal__dropdown-selection dropdown-toggle ${
          invalid && !options.some((reg) => reg.checked) && 'is-invalid'
        }`}
        type="button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="kov-modal__dropdown-placeholder">
          {options.some((reg) => reg.checked)
            ? options
                .map((reg) => (reg.checked ? reg.tag.toUpperCase() : ''))
                .filter(Boolean)
                .join(', ')
            : placeholder}
        </span>
      </button>
      {!options.some((reg) => reg.checked) && invalid && (
        <div className="invalid-feedback" style={{ margin: '-10px 0 10px 0' }}>
          {invalidFeedback}
        </div>
      )}
      <ul
        ref={wrapperRef}
        className={`dropdown-menu ${isOpen && 'show'}`}
        style={{
          transform: 'translate3d(15px, 86px, 0px)',
          position: 'absolute',
          top: '0px',
          left: '0px',
          willChange: 'transform',
        }}
      >
        {optionsToSet.map((opt) => (
          <li key={opt.readOrder} className="dropdown-item">
            <label className="filter-item">
              {opt.title}
              <input
                type="checkbox"
                checked={opt.checked}
                onChange={(event) =>
                  setOptionsToSet(
                    optionsToSet.map((option) => {
                      if (option.readOrder === opt.readOrder) {
                        option.checked = event.target.checked;
                      }
                      return option;
                    })
                  )
                }
              />
              <span className="checkmark" />
            </label>
          </li>
        ))}
        <button
          className="kov-pro-btn kov-pro-btn--inverted kov-pro-btn--light kov-pro-btn--block kov-modal__dropdown-submit"
          onClick={() => {
            assignValues(optionsToSet);
            setIsOpen(false);
          }}
        >
          Pas toe
        </button>
      </ul>
    </div>
  );
};

export default ViewOptionDropdown;
