import React, { useEffect, useState } from 'react';
import BannerNewsletterDropdown from '@components/sections/banner/bannerNewsletterDropdown';
import {
  retrieveUserNewsletterPreferences,
  updateUserNewsletterPreferences,
} from '@src/reduxStore/user/userThunks';
import { getUserKey, selectUserNewsletterPreferences } from '@src/reduxStore/user/userSelectors';
import { useSelector, useDispatch } from 'react-redux';

const BannerNewsletterSubscribe = ({ curriculumThemesHrefs, menuItemThemeHrefs }) => {
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);

  const itemIsLinkedToCurriculaTheme = Boolean(curriculumThemesHrefs?.length > 0); // The item is a curricula
  const itemIsLinkedToThemeLinkedToPrimaryReferenceFrame = Boolean(menuItemThemeHrefs?.length > 0); // known usually as Kanaal (the item is a Kanaal)

  const userKey = useSelector(getUserKey);
  const userNewsletterPreferences = useSelector(selectUserNewsletterPreferences);
  const currNewsPreferences = userNewsletterPreferences.get('curriculaNewsletter');
  const genNewsPreferences = userNewsletterPreferences.get('generalNewsletter');

  const isUserSubscribedTo = {
    theme: Boolean(
      genNewsPreferences?.themes.some((item) => menuItemThemeHrefs.includes(item.href))
    ), // subscription to the specific theme (kanaal) (in case it is a theme)
    generalNewsletter: Boolean(genNewsPreferences?.subscribed), // subscription to the "general" newsletter
    curricula: Boolean(
      currNewsPreferences?.themes?.some((item) => curriculumThemesHrefs.includes(item.href))
    ), // subscription to the specific curricula (in case it is a curricula)
    curriculaNewsletter: Boolean(currNewsPreferences?.subscribed), // subscription to the "curricula" newsletters
  };

  useEffect(() => {
    function refreshUserNewsletterPreferences() {
      if (userKey) dispatch(retrieveUserNewsletterPreferences(userKey, true));
    }

    window.addEventListener('focus', refreshUserNewsletterPreferences);

    return () => {
      window.removeEventListener('focus', refreshUserNewsletterPreferences);
    };
  }, []);

  const isUserSubscribedToItem =
    (isUserSubscribedTo.curriculaNewsletter && isUserSubscribedTo.curricula) ||
    (isUserSubscribedTo.generalNewsletter && isUserSubscribedTo.theme);

  const isLastSubscriptionToItem =
    isUserSubscribedToItem &&
    ((itemIsLinkedToCurriculaTheme && currNewsPreferences.themes.length <= 1) ||
      (itemIsLinkedToThemeLinkedToPrimaryReferenceFrame && genNewsPreferences.themes.length <= 1));

  const handleSubscribeChange = (isSubscribing) => {
    // User shouldn't be able to unsubscribe from last channel or curriculum subscription.
    if (isLastSubscriptionToItem && !isSubscribing) return;

    if (itemIsLinkedToCurriculaTheme) {
      dispatch(
        updateUserNewsletterPreferences(isSubscribing, currNewsPreferences, curriculumThemesHrefs)
      );
    }
    if (itemIsLinkedToThemeLinkedToPrimaryReferenceFrame) {
      dispatch(
        updateUserNewsletterPreferences(isSubscribing, genNewsPreferences, menuItemThemeHrefs)
      );
    }
  };

  return (
    <>
      <button
        className={`kov-pro-btn kov-popup ${
          isUserSubscribedToItem ? 'kov-pro-btn--round-active' : 'kov-pro-btn--round'
        }`}
        data-testid="banner-dropdown-button"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <i className="icon-kov-pro-newsletter" />
        <i className="icon-kov-pro-newsletter-fill" />
      </button>
      {showDropdown && (
        <BannerNewsletterDropdown
          handleClose={() => setShowDropdown(false)}
          handleSubscribe={(isSubscribing) => handleSubscribeChange(isSubscribing)}
          subscribedToCurriculaNewsletter={isUserSubscribedTo.curriculaNewsletter}
          subscribedToCurricula={isUserSubscribedTo.curricula}
          itemIsLinkedToCurriculaTheme={itemIsLinkedToCurriculaTheme}
          itemIsLinkedToThemeLinkedToPrimaryReferenceFrame={
            itemIsLinkedToThemeLinkedToPrimaryReferenceFrame
          }
          subscribedToGeneralNewsletter={isUserSubscribedTo.generalNewsletter}
          subscribedToThemeNewsletter={isUserSubscribedTo.theme}
          isLastSubscriptionToItem={isLastSubscriptionToItem}
        />
      )}
    </>
  );
};

export default BannerNewsletterSubscribe;
