import React from 'react';

const FaqGroups = ({ faqGroups, selectedFaqGroupHref, onUpdateSelectedFaqGroup, sTheme }) => (
  <ul className="kov-pro-faq-nav__list">
    {faqGroups.map((group, index) => (
      <li
        key={index}
        className={`kov-pro-faq-nav__list-item ${
          selectedFaqGroupHref === group.href ? 'active' : ''
        }`}
        onClick={() => {
          const pageWrapper = document.getElementById('theme-page');
          if (pageWrapper) pageWrapper.scrollIntoView();
          onUpdateSelectedFaqGroup(group.href);
        }}
      >
        <a
          style={{
            color: selectedFaqGroupHref === group.href ? sTheme.root.color : '',
          }}
        >
          {group.title}
          <span className="kov-pro-pill">{group.count}</span>
        </a>
      </li>
    ))}
  </ul>
);

export default FaqGroups;
