import { analyticCookiesAccepted } from '@helpers/cookieHelper';
import settings from '@src/config/settings';
import { init as initPiwik, disablePiwikAnalytics } from '@src/app/piwikManager';

let analyticsEnabled = false;

function initializePiwikAnalytics() {
  if (settings.piwikAnalytics.enabled) {
    console.log('Analytics::piwikAnalytics enabled');
    initPiwik();
  }
}

export async function enableAllAnalytics() {
  if (!analyticsEnabled && analyticCookiesAccepted()) {
    initializePiwikAnalytics();
    analyticsEnabled = true;
  }
}

export function disableAllAnalytics() {
  if (analyticsEnabled) {
    console.log('Analytics::disabling');
    // removing piwik PRO cookies
    disablePiwikAnalytics();
    analyticsEnabled = false;
    window.location.reload();
  }
}
