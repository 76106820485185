import React, { useEffect, useState, useRef, useMemo } from 'react';
import { getEducationLevels, getRegions } from '@src/components/constants';
import { isEqual } from 'lodash-es';
import useClickOutside from '@hooks/useClickOutside';
import {
  selectSelectedRegionTags,
  selectSelectedEducationLevelTags,
} from '@src/reduxStore/user/userSelectors';
import { useSelector } from 'react-redux';

require('./_applicabilityLabels.scss');

const ApplicabilityLabels = ({ labels }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [regionLabels, setRegionLabels] = useState([]);
  const [levelLabels, setLevelLabels] = useState([]);

  const elementRef = useRef(null);
  const [maxItems, setMaxItems] = useState(0);
  const selectedRegionLabels = useSelector(selectSelectedRegionTags);
  const selectedEducationLevelLabels = useSelector(selectSelectedEducationLevelTags);

  const shouldShowItemRegionLabels = useMemo(() => {
    if (selectedRegionLabels.length === 0) return true;
    if (regionLabels.length === 0) return true;

    return !isEqual(regionLabels, selectedRegionLabels);
  }, [regionLabels, selectedRegionLabels]);

  const shouldShowItemLevelLabels = useMemo(() => {
    if (selectedEducationLevelLabels.length === 0) return true;
    if (levelLabels.length === 0) return true;

    return !isEqual(levelLabels, selectedEducationLevelLabels);
  }, [levelLabels, selectedEducationLevelLabels]);

  const allLabels = useMemo(
    () => [
      ...(shouldShowItemLevelLabels ? levelLabels : []),
      ...(shouldShowItemRegionLabels ? regionLabels : []),
    ],
    [levelLabels, regionLabels, shouldShowItemLevelLabels, shouldShowItemRegionLabels]
  );

  useClickOutside(elementRef, () => setIsOpen(false));

  useEffect(() => {
    if (labels) {
      const newRegionTags = getRegions()
        .map((region) => region.tag)
        .filter(
          (regionTag) => labels.includes(regionTag) && selectedRegionLabels.includes(regionTag)
        );

      const newLevelTags = getEducationLevels()
        .map((level) => level.tag)
        .filter(
          (levelTag) => labels.includes(levelTag) && selectedEducationLevelLabels.includes(levelTag)
        );

      setRegionLabels(newRegionTags);
      setLevelLabels(newLevelTags);
    }
  }, [labels, selectedRegionLabels, selectedEducationLevelLabels]);

  useEffect(() => {
    const calculateMaxItems = (width) => {
      if (allLabels.length > 0) {
        let totalWidth = 30; // padding
        let newMaxItems = 0;
        let i = 0;
        while (totalWidth < width && allLabels.length > i) {
          if (i !== 0) {
            newMaxItems += 1;
          }
          if (allLabels[i]) {
            totalWidth += allLabels[i].length * 8;
          }
          totalWidth += 20; // padding
          i += 1;
        }
        if (allLabels.length >= i) {
          newMaxItems += 1;
        }
        return newMaxItems;
      }
      return 0;
    };

    const getWidthAndCalculate = () => {
      if (elementRef.current) {
        setMaxItems(calculateMaxItems(elementRef.current.parentElement.clientWidth));
      }
    };

    window.addEventListener('resize', getWidthAndCalculate, { passive: true });
    if (!maxItems) {
      getWidthAndCalculate();
    }

    // clean up
    return () => {
      window.removeEventListener('resize', getWidthAndCalculate);
    };
  }, [maxItems, allLabels]);

  useEffect(() => {
    if (allLabels.length <= maxItems && isOpen) {
      setIsOpen(false);
    }
  }, [allLabels, isOpen, maxItems]);

  return allLabels.length > 0 ? (
    <ul
      className="kov-pro-indicator"
      ref={elementRef}
      title={allLabels.map((label) => label.toUpperCase()).join(' • ')}
      onClick={
        allLabels.length > maxItems
          ? (e) => {
              e.preventDefault();
              setIsOpen(!isOpen);
            }
          : undefined
      }
    >
      {allLabels.slice(0, maxItems).map((tag) => (
        <li key={tag} className="kov-pro-indicator__label">
          {tag}
        </li>
      ))}
      {allLabels.length > maxItems ? (
        <li
          className="kov-pro-indicator__label kov-pro-indicator__label--overflow"
          style={{ position: 'relative' }}
        >
          <span className="dropdown-toggle" />
          {isOpen && (
            <ul className="dropdown-menu">
              {allLabels.slice(maxItems).map((label, index) => (
                <li key={index} className="kov-pro-indicator__label dropdown-item">
                  {label}
                </li>
              ))}
            </ul>
          )}
        </li>
      ) : null}
    </ul>
  ) : null;
};

export default ApplicabilityLabels;
