import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  /**
   * The following variable will be setup to true when, in previewMode, the origin window from where we triggered the preview does not match with the Pro env. In those case the preview don't work because we only accept messages coming from redactie that matches the current PRO environment
   */
  showWrongPostMessageOriginAlert: false,
  redactieItems: null,
};

const suggestionsState = createSlice({
  name: 'suggestions',
  initialState,
  reducers: {
    updateRedactieItems: (state, action) => {
      state.redactieItems = action.payload;
    },
    updateShowWrongPostMessageOriginAlert: (state, action) => {
      state.showWrongPostMessageOriginAlert = action.payload;
    },
  },
});

export const suggestionsStateActions = suggestionsState.actions;
export default suggestionsState;
