import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { KovContentBlockContext } from '@kathondvla/react-shared-components/src/components/contentBlock/KovContentBlockProvider';

import { KovContentBlock } from '@kathondvla/react-shared-components/src/components/contentBlock';
import ContentBlockDeeplink from '../contentBlockTitle/contentBlockDeeplink/contentBlockDeeplink';

const ContentBlockCollapsed = ({ item, titleLevel }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { color } = useContext(KovContentBlockContext);

  return (
    item.visible && (
      <div className="kov-pro-inline-accordion">
        <div className="kov-pro-inline-accordion__question">
          <div
            className="kov-pro-inline-accordion__question-header"
            onClick={() => setCollapsed(!collapsed)}
          >
            <button
              data-toggle="collapse"
              data-target="#question-1"
              aria-expanded="true"
              aria-controls="collapseOne"
              className={`btn btn-link ${collapsed ? 'collapsed' : ''}`}
              style={{ borderLeftColor: !collapsed ? color : 'transparant' }}
            >
              <ContentBlockDeeplink item={item} isCollapsableBlock={true} titleLevel={titleLevel} />
            </button>

            {collapsed && (
              <div id="question-1" className="collapse show">
                <div
                  className="kov-pro-inline-accordion__question-body"
                  style={{ borderLeftColor: color }}
                >
                  {item?.children &&
                    item.children.map((child) => <KovContentBlock key={child.key} item={child} />)}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};

ContentBlockCollapsed.propTypes = {
  item: PropTypes.object,
  titleLevel: PropTypes.number,
};

export default ContentBlockCollapsed;
