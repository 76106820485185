const settings = require('../config/settings.js');
const fetchSriClient = require('@kathondvla/sri-client/fetch-sri-client');

export const contentApi = fetchSriClient({
  baseUrl: settings.apisAndUrls.contentApi,
});

export const cachedContentApi = fetchSriClient({
  baseUrl: settings.apisAndUrls.cachedContentApi,
});

export const api = fetchSriClient({
  baseUrl: settings.apisAndUrls.api,
});

export const cachedApi = fetchSriClient({
  baseUrl: settings.apisAndUrls.cachedApi,
});

export const securityApi = fetchSriClient({
  baseUrl: settings.apisAndUrls.securityApi,
});

export const oauthApi = fetchSriClient({
  baseUrl: settings.oauth.oauthURL,
});
export const privateStatesApi = fetchSriClient({
  baseUrl: settings.apisAndUrls.privateStatesApi,
});

export const searchApi = fetchSriClient({
  baseUrl: settings.apisAndUrls.searchApi,
});

export const uncachedSriClient = fetchSriClient({
  baseUrl: settings.apisAndUrls.api,
  caching: {
    timeout: 400,
  },
});
