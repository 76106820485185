import React from 'react';
import SearchInput from '@UI/searchInput/searchInput';
import { useMediaQuery } from '@react-hook/media-query';
import FaqGroups from './FaqGroups';

const FaqFilters = ({
  searchTerm,
  onUpdateSearchTerm,
  onSearch,
  faqGroups,
  selectedFaqGroupHref,
  onUpdateSelectedFaqGroup,
  sTheme,
  filtersSidebarOpen,
  setFiltersSidebarOpen,
}) => {
  const isTablet = useMediaQuery('screen and (max-width: 992px)');

  return (
    <div
      className="kov-sidebar__wrapper"
      id={'kov-sidenav-filter'}
      style={{
        transform: isTablet ? `translateX(${filtersSidebarOpen ? '0px' : '555px'})` : 'none',
      }}
    >
      <a className="closebtn" onClick={() => setFiltersSidebarOpen(false)}>
        <i className="icon-kov-pro-close" />
      </a>
      <div className="kov-pro-filter">
        <div className="kov-pro-filter__title-wrapper">
          <h2 className="kov-pro-filter__title">Filter</h2>
        </div>

        <div className="kov-pro-faq-nav">
          <div
            className="kov-pro-mini-search kov-pro-mini-search--faq input-group"
            style={{ marginBottom: '15px' }}
          >
            <SearchInput
              placeholder="Zoek"
              value={searchTerm}
              onChange={onUpdateSearchTerm}
              onSearch={onSearch}
              inputClassName="form-control"
              showSearchIconAfter
            />
          </div>
          {faqGroups.length && (
            <FaqGroups
              faqGroups={faqGroups}
              onUpdateSelectedFaqGroup={onUpdateSelectedFaqGroup}
              selectedFaqGroupHref={selectedFaqGroupHref}
              sTheme={sTheme}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FaqFilters;
