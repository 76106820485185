import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import NavigationSecondLevelItem from '@src/reactComponents/navigation/slowNavigation/navigationSecondLevel/navigationSecondLevelItem/navigationSecondLevelItem';
import NewsSection from '@src/reactComponents/theme/newsSection/newsSection';
import {
  selectNavigationLevel,
  selectNavigationLevelSubTab,
} from '@src/reduxStore/navigation/navigationSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { navigationActions } from '@src/reduxStore/navigation/navigationSlice';
import Banner from '@src/reactComponents/sections/banner/banner';
import { selectUserViewOptions } from '@src/reduxStore/user/userSelectors';
import { checkIfOutOfViewOptions } from '@src/helpers/contextFilterHelper';
import { loaderEnd } from '@src/helpers/loaderHelper';
import kovSentry from '@src/app/kovSentry';
import { setUserStoredSelectedTabHref } from '@src/helpers/navigationHelper';

/**
 * The subtab should be shown if it is visible OR if it is selected (even if its not visible)
 * @param {*} subTab
 * @param {string} selectedSubTabHref
 * @returns
 */
const shouldShowSubTab = (subTab, selectedSubTabHref) =>
  subTab.visible || selectedSubTabHref === subTab.href;

const NavigationSecondLevel = ({
  itemHref,
  onSelectTab,
  locationPath,
  emitUpdatePageMetadata,
  emitShowOutOfViewOptions,
}) => {
  const dispatch = useDispatch();
  const navigationSecondLevel = useSelector((_) => selectNavigationLevel(_, itemHref));
  const navigationSecondLevelSubTab = useSelector((_) => selectNavigationLevelSubTab(_, itemHref));
  const userViewOptions = useSelector(selectUserViewOptions);

  const onSelectSubTab = useCallback((newTab) => {
    // updating the value on the store
    dispatch(navigationActions.setCurrentSubTabHref(newTab.href));
    // updating the value on the localStorage (for persistance when closing the browser)
    setUserStoredSelectedTabHref(newTab.href);
    onSelectTab(newTab, true);
  }, []);

  const menuItem = useMemo(
    () => navigationSecondLevelSubTab || navigationSecondLevel,
    [navigationSecondLevelSubTab, navigationSecondLevel]
  );

  useEffect(() => {
    if (menuItem && userViewOptions) {
      emitShowOutOfViewOptions(checkIfOutOfViewOptions(menuItem, userViewOptions));
    }
  }, [menuItem, userViewOptions]);

  useEffect(() => {
    emitUpdatePageMetadata(navigationSecondLevel);
    kovSentry.endLog('load Pro');
    loaderEnd();
  }, [navigationSecondLevel]);

  useEffect(() => {
    if (navigationSecondLevel?.multipleAlternativeMenus && !navigationSecondLevelSubTab) {
      const firstVisibleTab = navigationSecondLevel.children.find((tab) => tab.visible);
      onSelectSubTab(firstVisibleTab);
    }
  }, [navigationSecondLevel, navigationSecondLevelSubTab, onSelectSubTab]);

  return (
    <>
      <Banner menuItem={menuItem} />

      <section id="theme-home" style={{ marginBottom: '160px' }}>
        <div className="container" style={{ position: 'relative' }}>
          {navigationSecondLevelSubTab && (
            <div className="kov-pro-cluster-tabs">
              <div className="tabbable-line">
                <ul className="nav nav-tabs">
                  {navigationSecondLevel.children
                    .filter((tab) => shouldShowSubTab(tab, navigationSecondLevelSubTab?.href))
                    .map((tab) => (
                      <li key={tab.key}>
                        <a
                          className={navigationSecondLevelSubTab?.key === tab.key ? 'active' : ''}
                          data-toggle="tab"
                          onClick={() => onSelectSubTab(tab)}
                        >
                          {tab.title}
                        </a>
                      </li>
                    ))}
                </ul>
                <div className="tab-content">
                  <div className="tab-pane active">
                    <ul className="cluster-menu">
                      <div className="row">
                        {navigationSecondLevelSubTab.children
                          .filter((item) => item.visible)
                          .map((item) => (
                            <div
                              key={item.key}
                              className="cluster-menu-tile col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3"
                            >
                              <NavigationSecondLevelItem menuItem={item} />
                            </div>
                          ))}
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!navigationSecondLevelSubTab && (
            <ul className="cluster-menu">
              <div className="row">
                {navigationSecondLevel.children
                  .filter((item) => item.visible)
                  .map((item) => (
                    <div key={item.key} className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                      <NavigationSecondLevelItem menuItem={item} />
                    </div>
                  ))}
              </div>
            </ul>
          )}
        </div>
      </section>
      {menuItem && (
        <NewsSection sReferenceFrame={[menuItem]} locationPath={locationPath}></NewsSection>
      )}
    </>
  );
};

NavigationSecondLevel.propTypes = {
  itemHref: PropTypes.string,
  onSelectTab: PropTypes.func,
  locationPath: PropTypes.string,
  emitUpdatePageMetadata: PropTypes.func,
  emitShowOutOfViewOptions: PropTypes.func,
};

export default NavigationSecondLevel;
