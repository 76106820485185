import { loaderEnd } from '@src/helpers/loaderHelper';

class wsLocalNewsDatabank {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
  }

  async $onInit() {
    this.$scope.$emit('dynamicMetaDataUpdated', { title: 'Nieuws', description: null });
    loaderEnd();
  }
}

export default {
  template: require('./wsLocalNewsDatabank.html'),
  controllerAs: 'ctrl',
  controller: wsLocalNewsDatabank,
  bindings: {
    sTheme: '<',
  },
};
