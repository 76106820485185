import React from 'react';
import { KovImage } from '@kathondvla/react-shared-components/src';
import { getAttachmentHref } from '@src/app/contentUtils';

const FaqImage = ({ content }) => (
  <KovImage
    item={{
      ...content,
      imageM: {
        href: getAttachmentHref(content?.image),
      },
    }}
  />
);

export default FaqImage;
