import React from 'react';
import kovProTopBg from '@src/assets/images/kov-pro_topbg00.png';

const themeTile = ({ theme, onSelected }) => (
  <li className="theme-menu-tile theme-menu-tile--accent-4 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
    <a onClick={onSelected}>
      <div className="theme-menu-tile__img-holder">
        {theme.coverImage ? (
          <div
            className="theme-menu-tile__img-holder-bg"
            style={{ backgroundImage: `url(${theme.coverImage})` }}
          ></div>
        ) : (
          <div
            className="theme-menu-tile__img-holder-bg"
            style={{
              backgroundImage: `url(${kovProTopBg})`,
            }}
          ></div>
        )}
      </div>
      <div className="theme-menu-tile__title">{theme.title}</div>
    </a>
  </li>
);

export default themeTile;
