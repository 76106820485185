import { STATES_MAP } from '@src/routes/routes';
import { getKeyByValue } from './utils';
import settings from '@src/config/settings';
import store from '@src/reduxStore/store';
import analyticsState from '@src/reduxStore/analytics/analyticsSlice';
import { deleteCookie } from '@helpers/cookieHelper';
import { getAngularService } from 'NgMigrationUtils/angular-react-helper';

const { dispatch } = store;

const stringifiedPiwikScript = `(function(window, document, dataLayerName, id) {
  window[dataLayerName]=window[dataLayerName]||[],window[dataLayerName].push({start:(new Date).getTime(),event:"stg.start"});var scripts=document.getElementsByTagName('script')[0],tags=document.createElement('script');\
  function stgCreateCookie(a,b,c){var d="";if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d="; expires="+e.toUTCString();f="; SameSite=Strict"}document.cookie=a+"="+b+d+f+"; path=/"}
  var isStgDebug=(window.location.href.match("stg_debug")||document.cookie.match("stg_debug"))&&!window.location.href.match("stg_disable_debug");stgCreateCookie("stg_debug",isStgDebug?1:"",isStgDebug?14:-1);
  var qP=[];dataLayerName!=="dataLayer"&&qP.push("data_layer_name="+dataLayerName),isStgDebug&&qP.push("stg_debug");var qPString=qP.length>0?("?"+qP.join("&")):"";
  tags.async=!0,tags.src="https://kathondvla.containers.piwik.pro/"+id+".js"+qPString,scripts.parentNode.insertBefore(tags,scripts);
  !function(a,n,i){a[n]=a[n]||{};for(var c=0;c<i.length;c++)!function(i){a[n][i]=a[n][i]||{},a[n][i].api=a[n][i].api||function(){var a=[].slice.call(arguments,0);"string"==typeof a[0]&&window[dataLayerName].push({event:n+"."+i+":"+a[0],parameters:[].slice.call(arguments,1)})}}(i[c])}(window,"ppms",["tm","cm"]);
  })(window, document, 'dataLayer', '${settings.piwikAnalytics.token}');`
  .replace(/\n/g, '')
  .replace(/\s+/g, ' '); // to make it look better on the script tag..

const initializeScript = () => {
  const bodyFirstElement = document.body.children[0];
  const piwikAnalyticsScript = document.createElement('script');
  piwikAnalyticsScript.id = 'piwikAnalyticsTag';
  piwikAnalyticsScript.innerText = stringifiedPiwikScript;
  piwikAnalyticsScript.type = 'text/javascript';
  document.body.insertBefore(piwikAnalyticsScript, bodyFirstElement);
};

export const initializePiwikSubscribers = () => {
  const angularRootScope = getAngularService('$rootScope');
  const angularTransitions = getAngularService('$transitions');
  angularRootScope.$on('$locationChangeSuccess', function (event, current, previous) {
    const currentUrl = new URL(current);
    // the "previous" from angular will not catch a referrer from outside of PRO so,
    // if previous is equal to current we understand the user just landed in PRO and we get
    // the document.referrer
    const referrer = current === previous ? document.referrer : previous;
    dispatch(
      analyticsState.actions.updateCommonProperties({
        page_hostname: currentUrl.hostname?.toLowerCase(),
        page_pathname: currentUrl.pathname?.toLowerCase(),
        page_referrer: referrer.length > 0 ? referrer.toLowerCase() : undefined,
        page_access: 'granted',
      })
    );
  });

  angularTransitions.onBefore({}, (transition) => {
    let pageTemplate = getKeyByValue(STATES_MAP, transition.to().name);
    if (pageTemplate?.includes('SECUREACCESS')) {
      pageTemplate = getKeyByValue(STATES_MAP, transition.from().name);
    }

    dispatch(
      analyticsState.actions.updateCommonProperties({
        // if we are redirected to a secure access page we want the template of the page that is being masked,
        //otherwise the breadcrumbs and other page info don't match
        page_template: pageTemplate?.toLowerCase(),
        page_status_code: transition.to().name === '404' ? 404 : 200,
      })
    );
  });
};

export const init = async () => {
  initializeScript();
  dispatch(analyticsState.actions.updateIsPiwikTagReady(true));
};

export const disablePiwikAnalytics = () => {
  dispatch(analyticsState.actions.updateIsPiwikTagReady(false));
};
