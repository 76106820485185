import React from 'react';
import PropTypes from 'prop-types';

const ThemePagination = ({ prev, overview, next, minimal = false }) =>
  minimal ? (
    <div className="kov-pro-bib-navigator d-none d-md-block pull-right no-print">
      {prev && (
        <a href={prev}>
          <i className="icon-kov-pro-arrow-left" />
        </a>
      )}
      <a href={overview}>
        <i className="icon-kov-pro-blog-list" />
      </a>
      {next && (
        <a href={next}>
          <i className="icon-kov-pro-arrow-right" />
        </a>
      )}
    </div>
  ) : (
    <>
      <div className="col-12">
        <div className="divider-line mb0" />
      </div>

      <div className="kov-pro-pagination col-12 text-center no-print">
        <div className="btn-group kov-pro-btn-group" role="group" aria-label="Pagination">
          {prev && (
            <a href={prev}>
              <button
                type="button"
                className="kov-pro-btn kov-pro-btn--light kov-pro-btn--inverted kov-pro-btn__icon--left"
              >
                <i className="icon-kov-pro-arrow-left" />
                <span>vorige</span>
              </button>
            </a>
          )}
          <a href={overview}>
            <button type="button" className="kov-pro-btn kov-pro-btn--light kov-pro-btn--inverted">
              <i className="icon-kov-pro-blog-list" />
              <span>naar overzicht</span>
            </button>
          </a>
          {next && (
            <a href={next}>
              <button
                type="button"
                className="kov-pro-btn kov-pro-btn--light kov-pro-btn--inverted kov-pro-btn__icon--right"
              >
                <span>volgende</span>
                <i className="icon-kov-pro-arrow-right" />
              </button>
            </a>
          )}
        </div>
      </div>

      <div className="col-12">
        <div className="divider-line" />
      </div>
    </>
  );

ThemePagination.propTypes = {
  prev: PropTypes.string,
  overview: PropTypes.string,
  next: PropTypes.string,
  minimal: PropTypes.bool,
};

export default ThemePagination;
