import * as XLSX from 'xlsx-js-style';
import { decode } from 'html-entities';
import { isAfter, getNow } from '@kathondvla/sri-client/date-utils';
import dayjs from 'dayjs';

const TRAINING_TO_BE_DEFINED = 'Te bepalen';
const TRAINING_MULTIPLE_DATES = 'Meerdere data';

const cellSizes = {
  xsmall: 20,
  small: 30,
  medium: 50,
  xlarge: 100,
};
const cellStyle = { alignment: { vertical: 'top', horizontal: 'left', wrapText: '1' } };

const TRAINING_POSITIONS = {
  '/namedsets/451cca0f-5fd4-445a-ac56-e6b3de012e15': {
    href: '/namedsets/451cca0f-5fd4-445a-ac56-e6b3de012e15',
    title: 'Leraar',
    tags: ['doelgroepen'],
    readOrder: 5,
  },
  '/namedsets/c952becd-8e84-4eda-b0d7-967e7033d6da': {
    href: '/namedsets/c952becd-8e84-4eda-b0d7-967e7033d6da',
    title: 'Directeur',
    tags: ['doelgroepen'],
    readOrder: 3,
  },
  '/namedsets/2eac4ef8-f06f-41ac-a676-579b9eaa1b6a': {
    href: '/namedsets/2eac4ef8-f06f-41ac-a676-579b9eaa1b6a',
    title: 'Middenkader',
    description: 'Bijvoorbeeld zorgcoördinator, technisch adviseur(-coördinator), inkoper ...',
    tags: ['doelgroepen'],
    readOrder: 6,
  },
  '/namedsets/813197eb-a73b-4183-9072-d3b5d1041f38': {
    href: '/namedsets/813197eb-a73b-4183-9072-d3b5d1041f38',
    title: 'Bestuurder',
    tags: ['doelgroepen'],
    readOrder: 2,
  },
  '/namedsets/c29d408e-6107-4fd1-b719-e18b2628280a': {
    href: '/namedsets/c29d408e-6107-4fd1-b719-e18b2628280a',
    title: 'Opvoedend personeel',
    tags: ['doelgroepen'],
    readOrder: 7,
  },
  '/namedsets/dc8bc3ab-b675-48cf-90b0-21afd4d00b53': {
    href: '/namedsets/dc8bc3ab-b675-48cf-90b0-21afd4d00b53',
    title: 'Administratief personeel',
    tags: ['doelgroepen'],
    readOrder: 1,
  },
  '/namedsets/e56c4d81-8bf5-4f6c-a36b-0604f37f94ca': {
    href: '/namedsets/e56c4d81-8bf5-4f6c-a36b-0604f37f94ca',
    title: 'Preventieadviseur',
    tags: ['doelgroepen'],
    readOrder: 8,
  },
  '/namedsets/326fcbc1-0805-46dc-9c0d-5ed5e47a9fa2': {
    href: '/namedsets/326fcbc1-0805-46dc-9c0d-5ed5e47a9fa2',
    title: 'Andere',
    description: 'Zij die zich niet herkennen in één van de andere categorieën van functies',
    tags: ['doelgroepen'],
    readOrder: 9,
  },
  '/namedsets/bb4676fc-fa17-471b-88df-751f1ba69065': {
    href: '/namedsets/bb4676fc-fa17-471b-88df-751f1ba69065',
    title: 'ICT-coördinator',
    tags: ['doelgroepen'],
    readOrder: 4,
  },
};

const TRAINING_TYPES = {
  INDIVIDUGERICHT: 'Individugericht',
  TEAMGERICHT: 'Teamgericht',
  CONGRES: 'Congres',
  DAGENVAN: 'Inspiratiedag (dagen van...)',
  LEERPAD: 'Leerpad',
  LERENDNETWERK: 'Lerend netwerk',
  MEERDAAGSE: 'Meerdaagse',
  NETWERKDAG: 'Netwerkdag',
};

const TRAININGS_LIST_HEADER_ARRAY = [
  {
    headerTitle: 'Nascholing-link',
    columnSize: cellSizes.medium,
  },
  {
    headerTitle: 'Titel met link',
    columnSize: cellSizes.medium,
  },
  {
    headerTitle: 'Motivatie',
    columnSize: cellSizes.medium,
  },
  {
    headerTitle: 'Datum',
    columnSize: cellSizes.xsmall,
  },
  {
    headerTitle: 'Teamgericht datum',
    columnSize: cellSizes.xsmall,
  },
  {
    headerTitle: 'Locatie',
    columnSize: cellSizes.xsmall,
  },
  {
    headerTitle: 'Teamgericht locatie',
    columnSize: cellSizes.xsmall,
  },
  {
    headerTitle: 'Fysiek of online',
    columnSize: cellSizes.xsmall,
  },
  {
    headerTitle: 'Onderwijsniveau',
    columnSize: cellSizes.medium,
  },
  {
    headerTitle: 'Functies',
    columnSize: cellSizes.small,
  },
  {
    headerTitle: 'Type initiatief',
    columnSize: cellSizes.small,
  },
  {
    headerTitle: 'Reference frame themes',
    columnSize: cellSizes.xlarge,
  },
];

const settings = require('../../config/settings');

/** Transform raw training data into expected training */
const getTrainingsFromRawData = (trainings) =>
  trainings.map((training) => {
    let locationName = null;
    let themeNames;
    let eventDate = null;
    let isOnline = false;
    let locations = [];

    const onDemand = training.trainingTypes.includes('TEAMGERICHT');

    if (!onDemand) {
      locationName = TRAINING_TO_BE_DEFINED;
      eventDate = TRAINING_TO_BE_DEFINED;
      if (training.series) {
        locations = training.series
          .flatMap((serie) => {
            if (serie.locations) {
              return serie.locations.flatMap((location) => {
                if (location.address && location.address.city) return location.address.city;
                return undefined;
              });
            }
            return undefined;
          })
          .filter((val) => val !== undefined);
        locations = [...new Set(locations)];

        if (locations.length > 1) locationName = 'Meerdere locaties';
        else if (locations.length === 1 && locations[0] !== null) [locationName] = locations;
      }
      // Only keep eventDates of which the series hasn't started and convert to readable formatting
      const eventDates = training.series
        .map((serie) => serie.eventDates)
        .filter(
          (eventSerieDates) =>
            eventSerieDates && eventSerieDates[0] !== null && isAfter(eventSerieDates[0], getNow())
        )
        .flat()
        .filter((date) => date !== null)
        .filter(Boolean);

      if (eventDates.length === 1) {
        [eventDate] = eventDates;
      } else if (eventDates.length > 1) {
        eventDate = TRAINING_MULTIPLE_DATES;
      }

      isOnline = training.series.some(
        (serie) => serie.locations && serie.locations.every((location) => location.online)
      );

      locationName = `${locationName}${isOnline ? ', Online' : ''}`;
    }

    if (training.themes) {
      themeNames = training.themes.map((theme) => {
        const themes = [...(theme.ancestors || []), theme];
        themes.sort((t1, t2) => t1.level - t2.level);
        return themes.map((t) => t.title);
      });
    }

    let attendanceType = '';
    if (isOnline) {
      attendanceType = locationName ? 'MIX' : 'ONLINE';
    } else if (locationName) {
      attendanceType = 'FYSIEK';
    }

    return {
      ...training,
      locationName,
      isOnline,
      eventDate,
      typeNames: training.trainingTypes,
      attendanceType,
      onDemand,
      themeNames,
    };
  });

const getDate = (dateStr) => (dateStr ? dayjs(dateStr).format('DD/MM/YYYY') : null);

const stripHtml = (value) => (value ? decode(value.replace(/(<([^>]+)>)/gi, '')) : null);

/** Get trainings excel file rows */
export const getTrainingFileRows = (trainings) => {
  const rawData = getTrainingsFromRawData(trainings);
  return rawData
    .map((training) => [
      {
        t: 'l',
        v: `${settings.apisAndUrls.trainings.nascholing}redirectTo.aspx?redirectID=${training.$$meta.permalink}`,
        l: {
          Target: `${settings.apisAndUrls.trainings.nascholing}redirectTo.aspx?redirectID=${training.$$meta.permalink}`,
        },
      },
      {
        t: 's',
        v: training.title || '',
      },
      {
        t: 's',
        v: stripHtml(training.motivation) || '',
      },
      {
        t: 's',
        v: [TRAINING_MULTIPLE_DATES, TRAINING_TO_BE_DEFINED].includes(training.eventDate)
          ? training.eventDate
          : getDate(training.eventDate) || '',
      },
      {
        t: 's',
        v: training.onDemand ? 'Op aanvraag' : '',
      },
      {
        t: 's',
        v: training.locationName || '',
      },
      {
        t: 's',
        v: training.onDemand ? 'Eigen school' : '',
      },
      {
        t: 's',
        v: training.attendanceType || '',
      },
      {
        t: 's',
        v: (training.mainstructuresOuTypeCombinations || [])
          .map((educationLevel) => educationLevel.name)
          .join('\n'),
      },
      {
        t: 's',
        v: (training.positions || [])
          .map((position) =>
            TRAINING_POSITIONS[position] ? TRAINING_POSITIONS[position].title : null
          )
          .filter((positionTitle) => positionTitle)
          .join('\n'),
      },
      {
        t: 's',
        v: (training.typeNames || [])
          .map((trainingType) => TRAINING_TYPES[trainingType])
          .join('\n'),
      },
      {
        t: 's',
        v: (training.themeNames || []).join('\n'),
      },
    ])
    .map((training) =>
      training.map((trainingCol) => ({
        ...trainingCol,
        v: trainingCol.v.slice(0, 32000), // excel limitation row size (some training.themeNames exceeded that limit..)
        s: {
          ...trainingCol.s,
          ...cellStyle,
        },
      }))
    );
};

const addTrainingsSheet = (workbook, trainingResults) => {
  const trainingsFileRows = getTrainingFileRows(trainingResults);
  const headerRow = TRAININGS_LIST_HEADER_ARRAY.map((headerEl) => ({
    v: headerEl.headerTitle,
    t: 's',
    s: {
      font: { bold: true },
      ...cellStyle,
    },
  }));

  const trainingsListData = [headerRow, ...trainingsFileRows];

  const trainingsListWorksheet = XLSX.utils.aoa_to_sheet(trainingsListData);

  trainingsListWorksheet['!cols'] = TRAININGS_LIST_HEADER_ARRAY.map((headerEl) => ({
    wch: headerEl.columnSize,
  }));

  XLSX.utils.book_append_sheet(workbook, trainingsListWorksheet, 'Trainings List');
};

const addQueriesSheet = (workbook, searchApiRequest) => {
  const queriesData = [
    [
      {
        v: 'pro and search requests (clickable)',
        t: 's',
        s: {
          font: { bold: true },
          ...cellStyle,
        },
      },
    ],
    [
      {
        v: window.location.href,
        t: 'l',
        l: {
          Target: window.location.href,
        },
        s: cellStyle,
      },
    ],
    [
      {
        v: searchApiRequest,
        t: 'l',
        l: {
          Target: searchApiRequest,
        },
        s: cellStyle,
      },
    ],
  ];

  const queriesWorksheet = XLSX.utils.aoa_to_sheet(queriesData);
  queriesWorksheet['!cols'] = [{ wch: cellSizes.xlarge }];
  XLSX.utils.book_append_sheet(workbook, queriesWorksheet, 'Queries');
};

/**
 * This method generates, creates and downloads an "styled" excel file for a given trainings list. It also creates an extra sheet on it including the information about the query executed by the user
 * @param {*} trainingResults
 * @param {string} searchApiRequest
 */
export const downloadTrainingsExcel = (trainingResults, searchApiRequest) => {
  const workbook = XLSX.utils.book_new();

  // trainings list sheet
  addTrainingsSheet(workbook, trainingResults);
  // queries sheet
  addQueriesSheet(workbook, searchApiRequest);

  XLSX.writeFile(workbook, `trainings_${new Date().toISOString().split('T')[0]}.xlsx`);
};
