import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  trainingsList: [],
  trainingsCount: 0,
  loading: false,
};

const trainingState = createSlice({
  name: 'trainings',
  initialState,
  reducers: {
    setTrainingsList: (state, action) => {
      state.trainingsList = action.payload;
    },
    appendToTrainingsList: (state, action) => {
      state.trainingsList = state.trainingsList.concat(action.payload);
    },
    setTrainingsCount: (state, action) => {
      state.trainingsCount = action.payload;
    },
    setLoadingState: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const trainingsActions = trainingState.actions;
export default trainingState;
