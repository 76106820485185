import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { stripHtml } from '@src/app/utils';
import { useSelector } from 'react-redux';
import themeSelectors from '@src/reduxStore/theme/themeSelectors';
import { curriculumSitesLogic } from '@kathondvla/shared-logic';

const NavigationBreadcrumbs = ({ breadcrumbItems }) => {
  const [truncateLastItem, setTruncateLastItem] = useState(false);
  const subCurriculaHrefs = useSelector(themeSelectors.getCurricula);
  const listRef = useRef(null);

  /** Listen to any window resize and check if the last item
   * in the breadcrumb list should be truncated */
  useEffect(() => {
    let timeoutId = null;
    const checkBreadcrumbSize = () => {
      setTruncateLastItem(false);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        if (listRef.current != null) {
          setTruncateLastItem(listRef.current.offsetHeight < listRef.current.scrollHeight);
        }
      }, 150);
    };
    window.addEventListener('resize', checkBreadcrumbSize);

    if (breadcrumbItems.length) {
      checkBreadcrumbSize();
    }

    return () => {
      window.removeEventListener('resize', checkBreadcrumbSize);
    };
  }, [breadcrumbItems]);

  const getEncodedSubCurriculumThemes = (breadcrumb) => {
    if (subCurriculaHrefs.length > 0 && breadcrumb.hasThemesOrCurriculumThemes) {
      return curriculumSitesLogic.encodeThemes(subCurriculaHrefs);
    }
    return false;
  };

  return (
    <ul className="kov-pro-breadcrumb-list" ref={listRef}>
      <li className="kov-pro-breadcrumb-list__item">
        <a href="/">home</a>
      </li>
      {breadcrumbItems.map((breadcrumb, index) => (
        <li key={index} className="kov-pro-breadcrumb-list__item">
          <a
            href={`${breadcrumb.breadcrumbPath}${
              getEncodedSubCurriculumThemes(breadcrumb)
                ? `?curricula=${getEncodedSubCurriculumThemes(breadcrumb)}`
                : ''
            }`}
          >
            {stripHtml(
              index === breadcrumbItems.length - 1 &&
                truncateLastItem &&
                breadcrumb.breadcrumbTitle?.length > 10
                ? `${breadcrumb.breadcrumbTitle.slice(0, 10)} ...`
                : breadcrumb.breadcrumbTitle
            )}
          </a>
        </li>
      ))}
    </ul>
  );
};

NavigationBreadcrumbs.propTypes = {
  breadcrumbItems: PropTypes.array,
};

export default NavigationBreadcrumbs;
