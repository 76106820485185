import {
  getValidUrl,
  fetchSitemap,
  getSitemapItemFromSourceOrPath,
} from '@src/services/sitemapService';
import settings from '@src/config/settings';
import trackPiwikEvent from '@src/reduxStore/analytics/analyticsThunks';
import analyticsState from '@src/reduxStore/analytics/analyticsSlice';
import generalRoutes from './generalRoutes';
import themeRoutes from './themeRoutes';

import { isContentHrefPath, getContentHref } from './utilsRoutes';

export const STATES_MAP = {
  // Slow Nav
  HOME_PAGE: 'home',
  WWW_HOME_PAGE: 'home',
  FALLBACK_MENU_LEVEL_2: 'navigationSecondLevel',
  FALLBACK_MENU_LEVEL_3: 'navigationThirdLevel',

  // Secure Hardcoded routes
  SECUREACCESS1: 'afgeschermd-1',
  SECUREACCESS2: 'afgeschermd-2',
  SECUREACCESS3: 'afgeschermd-3',
  SECUREACCESS4: 'afgeschermd-4',

  // Theme states
  THEME_PAGE: 'theme.detail',
  THEME_HOME_PICTURE: 'theme.home1',
  THEME_HOME_TEXT: 'theme.home2',
  THEME_HOME_FULL: 'theme.home3',
  MINI_DATABASE: 'theme.database',
  MINI_DATABASE_ITEM: 'theme.databaseItem',
  FAQ_PAGE: 'theme.faq',
  DOWNLOAD_PAGE: 'theme.download',
  DOWNLOAD_PAGE_NO_THUMB: 'theme.downloadNoThumb',
  TEMPORARY_PAGE: 'theme.temp',
  BLOG: 'theme.blog',
  BLOG_ITEM: 'theme.blogItem',
  STATIC: 'theme.static',
  CURRICULUM_PAGE: 'theme.detail',

  // Global & Local types
  GLOBAL_NEWS: 'newsDatabank',
  GLOBAL_SEARCH: 'search',
  GLOBAL_TRAININGSDATABASE: 'trainings',
  GLOBAL_DATABASE: 'globalDatabank', // this is a dynamic type (no 'GLOBAL_DATABASE' DB), but we "send
  // the user to this state" when he goes to the inspirerend material url
  LOCAL_NEWS: 'theme.news',
  LOCAL_SEARCH: 'theme.search',
  LOCAL_TRAINING: 'theme.training',
  SHARED_MINI_DATABASE_ITEM: 'globalDatabaseItem',

  // Other
  NEWS_ITEM_AND_TEASER_FALLBACK: 'newsDetail',
  CURRICULA_NAVIGATION: 'vakkenpagina',
  404: '404',
};
export const getStateFromType = (type) => STATES_MAP[type];

const cleanPath = (urlPath) => {
  if (urlPath === '/') {
    return urlPath;
  }

  // Clear trailing slash for search
  if (urlPath[urlPath.length - 1] === '/') {
    return urlPath.substring(0, urlPath.length - 1);
  }

  return urlPath;
};

export default ($uiRouter, $locationProvider, ngMetaProvider) => {
  const $urlService = $uiRouter.urlService;
  const $urlRouter = $uiRouter.urlRouter;
  const $stateRegistry = $uiRouter.stateRegistry;
  let previousTrackedUrl;

  $locationProvider.html5Mode(true);

  // Register route states
  [...generalRoutes, ...themeRoutes].forEach((state) => {
    $stateRegistry.register({
      ...state,
      onEnter: ($ngRedux, $location) => {
        'ngInject';

        // this will send a view_page everytime a new "state" of angular is
        // entered and it won't trigger anything on "subStates" like applying a filter to a search

        // The second condition is a patch because, when listening the popstate browser event (when the user hits the back button) we need to call the angular state reload. That is making the onEnter code to be executed twice. With comparing the last tracked url with the current one we prevent duplicated track on Piwik on that case (this should be fixed when we update the router / get rid on angular)
        if (state.name !== 'theme' && $location.$$url !== previousTrackedUrl) {
          previousTrackedUrl = $location.$$url;
          // We needed this setup a timeout here because the pageTitle was not ready when tracking and was tracking sometimes the title of the previous page.
          // We know this does not ensure the pageTitle will be there but all the different approaches we tried were not working in all cases.
          // So, we decided that for now (until we use react-router that will make all of this be refactored) this was the simplest + more effective way of doing this
          setTimeout(() => {
            $ngRedux.dispatch(trackPiwikEvent('view_page'));
          }, 30);
        }
      },
      onExit: ($ngRedux) => {
        'ngInject';

        $ngRedux.dispatch(analyticsState.actions.updateBreadcrumbReadyStatus(false));
        $ngRedux.dispatch(analyticsState.actions.cleanUpBreadcrumbs());
      },
    });
  });

  // Init Sitemap
  $urlService.deferIntercept();
  fetchSitemap().then(() => {
    $urlService.listen();
    $urlService.sync();
  });

  $urlRouter.when('/nieuwsoverzicht', (_matchValue, url) => ({
    state: 'newsDatabank',
    params: { ...url.search },
  }));

  $urlRouter.when(settings.proGlobalDatabase.url, (_matchValue, url) => ({
    state: 'globalDatabank',
    params: { ...url.search },
  }));

  // Based on incoming url find out the multi url matching states
  $urlRouter.otherwise((_matchValue, url) => {
    const currentSearchParams = url.search;
    let currentLocationPath = cleanPath(url.path);
    const isPermalink =
      /^\/content\/[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(
        currentLocationPath
      );

    // sometimes we get here with a permalink instead of a path, so we transform
    // the permalink into a path checking the sitemap
    if (isPermalink) {
      currentLocationPath = getValidUrl(currentLocationPath);
    }
    const sitemapElement = getSitemapItemFromSourceOrPath(currentLocationPath);
    let sitemapPathType = sitemapElement?.type;

    if (
      sitemapPathType === 'MINI_DATABASE_ITEM' &&
      sitemapElement.rootHref === sitemapElement.source
    ) {
      sitemapPathType = 'SHARED_MINI_DATABASE_ITEM';
    }

    // Decision tree based on urls and helper functions to return
    switch (sitemapPathType) {
      case 'REDIRECT':
        window.location.href = sitemapElement.targetPath;
        return undefined;

      default: {
        const targetState = getStateFromType(sitemapPathType);

        if (targetState) {
          console.debug('sitemap page:', sitemapElement);
          return {
            state: targetState,
            params: { ...currentSearchParams, source: sitemapElement.source },
          };
        }

        if (isContentHrefPath(currentLocationPath)) {
          const contentHref = getContentHref(currentLocationPath);

          return {
            state: 'theme.detail',
            params: { ...currentSearchParams, source: contentHref },
          };
        }
      }
    }

    // Nothing direct found, could be an old url (Pre-routerservice refactor)
    if (currentLocationPath.indexOf('/newsDetail/') === 0) {
      window.location.replace(currentLocationPath.replace('/newsDetail', ''));
      return undefined;
    }

    if (currentLocationPath.indexOf('/localNews/') !== -1) {
      const pathArr = currentLocationPath.split('/localNews');
      window.location.replace(pathArr.at(-1));
      return undefined;
    }

    if (currentLocationPath.indexOf('/tegel') !== -1) {
      window.location.replace(currentLocationPath.replace('/tegel', ''));
      return undefined;
    }

    // Nothing matching found
    return { state: '404' };
  });

  ngMetaProvider.setDefaultTitle('PRO. - Katholiek Onderwijs Vlaanderen');
  ngMetaProvider.setDefaultTag(
    'image',
    `https://${window.location.host}/images/placeholder-banner.png`
  );
  ngMetaProvider.setDefaultTag(
    'description',
    'De pro. is de website voor de onderwijsprofessional'
  );
};
