import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Banner from '@src/reactComponents/sections/banner/banner';
import ThemeToc from '@UI/themeComponents/themeTextToc/ThemeToc';
import { ThemeMenu } from '@components/database/components';
import useLocationContacts from '@src/hooks/useLocationContacts';
import { getThemeTabs } from '@src/components/theme/templates/helpers/helpers';
import { selectItemInCurrentThemeTree } from '@src/reduxStore/theme/themeSelectors';
import { useSelector } from 'react-redux';
import { getAngularService } from '@src/module/reactMigrationUtils/angular-react-helper';
import { offsetFromTop } from '@src/app/utils';
import ThemeContacts from '../themeContacts/themeContacts';
import NewsSection from '../newsSection/newsSection';

import ThemeDownloadGroupList from '../themeDownload/themeDownloadGroupList/themeDownloadGroupList';
import ThemeText from '../themeText/themeText';
import useDownloads from '../themeDownload/hooks/useDownloads';

const ThemeTemp = ({ theme, source, updateMetaData, locationPath }) => {
  const routerService = getAngularService('routerService');
  const itemInCurrentThemeTree = useSelector((state) =>
    selectItemInCurrentThemeTree(state, source)
  );

  const page = useMemo(
    () =>
      // this is an exception becuase of this ticket => https://katholiek-onderwijs-vlaanderen.monday.com/boards/5059664922/pulses/5256281427
      // Everything is introduced here (main branch): https://bitbucket.org/vskovzw/pro/src/f9d00eefe21fcc608f25548d32ba6d765b2b4ba8/src/services/themeService.js#lines-456
      // Obviusly that is also present after the themeService refactor but in a different line.
      // We should try to detect the original problem and fix it or use below as a temporary fix
      // for those pages and fix it later (ASAP)
      itemInCurrentThemeTree.children.find((a) => a.href === itemInCurrentThemeTree.href) ||
      itemInCurrentThemeTree,
    [itemInCurrentThemeTree]
  );

  if (page) {
    updateMetaData(page);
  }

  const isStandalonePage = theme.href === source;
  const pageTabs = getThemeTabs(theme, isStandalonePage);
  const showTabs = pageTabs.length > 0;
  const showPageTitle = page.href !== theme.href;
  const pageTitle = page.title;

  const { state, showForbiddenPlaceholder } = useDownloads(page);

  const { contacts } = useLocationContacts(page, theme);
  const showContactsSection = contacts.length > 0;

  const [scrollToPos, setScrollToPos] = useState(
    new URL(document.location).searchParams.get('scrollTo')
  );

  useEffect(() => {
    if (scrollToPos) {
      setTimeout(() => {
        routerService.scrollTo(scrollToPos, offsetFromTop);
        setScrollToPos(null);
        const url = new URL(document.location);
        url.searchParams.delete('scrollTo');
        window.history.replaceState({}, '', url);
      }, 500);
    }
  }, [scrollToPos, routerService]);

  const showDownloadSection = state.results.length > 0;

  return (
    <>
      {page && (
        <div className="temporary-page">
          <Banner menuItem={page} theme={theme} />
          <section id="theme-page--temporary">
            {showTabs ? <ThemeMenu theme={theme} items={pageTabs} current={page.href} /> : ''}
            <div className="container">
              <div className="row">
                <div className="kov-pro-theme-page col-12">
                  <div className="row">
                    {showPageTitle ? (
                      <div className="kov-pro-theme-main-content-title col-12 d-none d-md-block">
                        <h1>{pageTitle}</h1>
                      </div>
                    ) : (
                      ''
                    )}

                    <ThemeToc
                      page={page}
                      theme={theme}
                      showDownloadSection={showDownloadSection}
                      showContactsSection={showContactsSection}
                    />

                    <div className="kov-pro-theme-main-content col-12 col-md-8 order-md-1">
                      <ThemeText
                        item={page}
                        color={theme.root.color}
                        hideAttachments
                        hideZillGoals
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {showDownloadSection && (
            <section id="downloads">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="kov-pro-section-title">
                      <h1>
                        <i className="icon-kov-pro-download" />
                        Downloads
                      </h1>
                    </div>
                  </div>
                  <div className="col-12 col-lg-8">
                    <div className="row">
                      <div className="col-12 kov-pro-xl-downloadlist">
                        <ThemeDownloadGroupList
                          downloadGroups={state.results}
                          showForbiddenPlaceholder={showForbiddenPlaceholder}
                          color={theme.root.color}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {showContactsSection && <ThemeContacts contacts={contacts} />}

          {theme.referenceFrameItem && (
            <NewsSection
              sReferenceFrame={theme.referenceFrameItem}
              sThemePath={theme.pagePath}
              locationPath={locationPath}
            />
          )}
        </div>
      )}
    </>
  );
};

ThemeTemp.propTypes = {
  theme: PropTypes.object,
  source: PropTypes.string,
  updateMetaData: PropTypes.func,
  locationPath: PropTypes.string,
};

export default ThemeTemp;
