import { getTrainings } from '@src/reduxStore/trainings/trainingsData';
import { trainingsActions } from './trainingsState';
import { getCurrentProThemeHref } from '../theme/themeSelectors';

export const searchTrainings = (payload) => async (dispatch, getState) => {
  dispatch(trainingsActions.setLoadingState(true));
  const { trainingsList, trainingsTotalCount } = await getTrainings(
    payload,
    getCurrentProThemeHref(getState())
  );
  dispatch(trainingsActions.setTrainingsList(trainingsList));
  dispatch(trainingsActions.setTrainingsCount(trainingsTotalCount));
  dispatch(trainingsActions.setLoadingState(false));
};

export const loadMoreTrainings = (payload) => async (dispatch, getState) => {
  const { trainingsList } = await getTrainings(payload, getCurrentProThemeHref(getState()));
  dispatch(trainingsActions.appendToTrainingsList(trainingsList));
};
