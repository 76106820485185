import React from 'react';
import PropTypes from 'prop-types';

import ContentBlockCollapsed from '../contentBlockCollapsed/contentBlockCollapsed';
import ContentBlockDeeplink from './contentBlockDeeplink/contentBlockDeeplink';

const ContentBlockDeepLink = ({ item, titleLevel }) =>
  item.visible && (
    <>
      {item.importance === 'LOW' && <ContentBlockCollapsed item={item} titleLevel={titleLevel} />}
      {item.importance !== 'LOW' && <ContentBlockDeeplink item={item} titleLevel={titleLevel} />}
    </>
  );

ContentBlockDeepLink.propTypes = {
  item: PropTypes.object,
  titleLevel: PropTypes.number,
};

export default ContentBlockDeepLink;
