import React, { useState, useEffect } from 'react';
import image from '@src/assets/images/kov-pro-agenda-cta.png';
import { getAngularService } from 'NgMigrationUtils/angular-react-helper';
import { getSitemapPathFromSource } from '@src/services/sitemapService';

const AgendaLievenBoeve = ({ forwardLink }) => {
  // TODO: move all this logic ro a react master compponent and use the link prop above
  const navigationService = getAngularService('navigationService');
  const [link, setLink] = useState(forwardLink);

  useEffect(() => {
    const fetchData = async () => {
      const item = await navigationService.getAgenda();
      if (item.references && item.references.length > 0) setLink(item.references[0]);
    };
    fetchData();
  }, [navigationService]);
  return (
    <div className="kov-link-tile kov-link-tile--photo">
      <div className="kov-link-tile__title">Agenda</div>
      <div className="kov-link-tile__author">Lieven Boeve</div>
      <div
        className="kov-link-tile__photo"
        style={{ background: `url(${image})`, maxWidth: '200px' }}
      ></div>

      <a href={getSitemapPathFromSource(link)} className="kov-pro-btn kov-pro-btn__icon--right">
        Lees <i className="icon-kov-pro-arrow-right"></i>
      </a>
    </div>
  );
};

export default AgendaLievenBoeve;
