export const fileTypesMap = {
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template': 'word',
  'application/msword': 'word',
  'application/pdf': 'pdf',
  'application/xml': 'xml',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template': 'excel',
  'application/vnd.ms-excel': 'excel',
  'video/mp4': 'video',
  'audio/mp3': 'audio',
  'audio/mpeg': 'audio',
  'application/vnd.ms-powerpoint': 'powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.template': 'powerpoint',
  'image/jpg': 'image',
  'image/png': 'image',
  'image/jpeg': 'image',
  'image/gif': 'image',
};

export const getAttachmentType = (attachment) =>
  attachment && attachment.contentType ? fileTypesMap[attachment.contentType] : 'Andere';

export const getReferenceExcerpt = (item) => {
  if (!item.pageConfig) {
    return null;
  }

  if (item.pageConfig.type === 'MINI_DATABASE_ITEM') {
    return item.description;
  }

  return null;
};

export const changeFontColor = (event, color) => {
  event.target.style.color = color;
};

export const resetFontColor = (event) => {
  event.target.style.color = '';
};
