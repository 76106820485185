import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ContentBlockTitleTooltip from '@components/content/contentBlockTitle/contentBlockTitleTooltip/contentBlockTitleTooltip';
import { generateHashForScrollTo } from '@helpers/utils';
import {
  FaqAttachmentsGroup,
  FaqImage,
  FaqImageGroup,
  FaqParagraph,
  FaqVideo,
} from '@components/theme/themeFaq/components/content';
import { getAngularService } from '@src/module/reactMigrationUtils/angular-react-helper';
import { offsetFromTop } from '@src/app/utils';

const FaqItem = ({ item, sColor, scrollToAnchor }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      const routerService = getAngularService('routerService');
      routerService.scrollTo(scrollToAnchor, offsetFromTop);
      const url = new URL(document.location);
      url.searchParams.delete('scrollTo');
      window.history.replaceState({}, '', url);
    }
  }, [ref]);

  const anchor = generateHashForScrollTo(item.key);
  const [collapsed, setCollapsed] = useState(anchor !== scrollToAnchor);
  const [allowTooltip, setAllowTooltip] = useState(false);

  const styleTag = `#${anchor} h4:hover{color: ${sColor};}`;

  return item.content == null ? null : (
    <>
      <div
        id={anchor}
        ref={anchor === scrollToAnchor ? ref : null}
        className="kov-pro-faq__question card"
        onMouseEnter={() => setAllowTooltip(true)}
        onMouseLeave={() => setAllowTooltip(false)}
      >
        <div className="kov-pro-faq__question-header">
          <button
            className={`btn btn-link ${collapsed ? 'collapsed' : ''}`}
            aria-expanded="true"
            onClick={() => setCollapsed(!collapsed)}
          >
            <h4>{item.title}</h4>
          </button>
          <ContentBlockTitleTooltip sKey={item.key} visible={allowTooltip} />
        </div>

        <div id="question-1--1--1" className={`collapse ${!collapsed && 'show'}`}>
          <div className="kov-pro-faq__question-body">
            {item.content.map((itemChild, index) => (
              <React.Fragment key={index}>
                {(() => {
                  switch (itemChild.type) {
                    case 'PARAGRAPH':
                      return <FaqParagraph content={itemChild} />;
                    case 'IMAGE':
                      return <FaqImage content={itemChild} />;
                    case 'IMAGE_GROUP':
                      return <FaqImageGroup content={itemChild} />;
                    case 'VIDEO':
                      return <FaqVideo content={itemChild} />;
                    case 'ATTACHMENTS_GROUP':
                      return <FaqAttachmentsGroup content={itemChild} />;
                    default:
                      return null;
                  }
                })()}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
      <style dangerouslySetInnerHTML={{ __html: styleTag }} />
    </>
  );
};

FaqItem.propTypes = {
  item: PropTypes.object,
  sColor: PropTypes.string,
  scrollToAnchor: PropTypes.string,
};

export default FaqItem;
