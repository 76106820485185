import React, { useState, useEffect } from 'react';
import image from '@src/assets/images/kov-pro-blog-cta.png';
import { getAngularService } from 'NgMigrationUtils/angular-react-helper';
import { getSitemapPathFromSource } from '@src/services/sitemapService';

const BlogParlementaireActiviteiten = ({ forwardLink }) => {
  // TODO: move all this logic ro a react master compponent and use the link prop above

  const navigationService = getAngularService('navigationService');
  const [link, setLink] = useState(forwardLink);

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      const item = await navigationService.getBlog();
      if (item.references && item.references.length > 0 && !isCancelled)
        setLink(item.references[0]);
    };
    fetchData();

    return () => {
      isCancelled = true;
    };
  }, [navigationService]);

  return (
    <div className="kov-link-tile kov-link-tile--photo">
      <div className="kov-link-tile__title">Blog</div>
      <div className="kov-link-tile__author">Parlementaire activiteiten</div>
      <div className="kov-link-tile__photo" style={{ background: `url(${image})` }} />
      <a
        href={getSitemapPathFromSource(link)}
        className="kov-pro-btn kov-pro-btn--green kov-pro-btn__icon--right"
      >
        Lees <i className="icon-kov-pro-arrow-right" />
      </a>
    </div>
  );
};

export default BlogParlementaireActiviteiten;
