import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getIsLoading, getTrainingsCount } from '@src/reduxStore/trainings/trainingsSelectors';

const TrainingOrder = ({ sorts, onSetOrder }) => {
  const loading = useSelector(getIsLoading);
  const count = useSelector(getTrainingsCount);
  const [open, setOpened] = useState(false);

  return (
    <div className="kov-pro-nas-filter-tags__results">
      {!loading && <span className="label">{count}</span>}
      <div className={`btn-group bootstrap-select ${open ? 'show' : ''}`}>
        <button
          type="button"
          className="btn dropdown-toggle btn-default"
          data-toggle="dropdown"
          onClick={() => setOpened(!open)}
          role="button"
          title="recent gepubliceerd"
        >
          <span className="filter-option pull-left">
            {sorts.find((sort) => sort.checked).title}
          </span>
          <span className="bs-caret">
            <span className="caret"></span>
          </span>
        </button>
        <div className={`dropdown-menu open ${open ? 'show' : ''}`} role="combobox">
          <ul className="dropdown-menu inner" role="listbox" aria-expanded="false">
            {sorts.map((sort) => (
              <li key={sort.key} data-original-index="0" className={sort.checked ? 'selected' : ''}>
                <a
                  tabIndex="0"
                  className=""
                  onClick={() => {
                    onSetOrder('orderby', sort.key);
                    setOpened(false);
                  }}
                  data-tokens="null"
                  role="option"
                  aria-disabled="false"
                  aria-selected="true"
                >
                  <span className="text">{sort.title}</span>
                  <span className="glyphicon glyphicon-ok check-mark"></span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TrainingOrder;
