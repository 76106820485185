import React from 'react';

const Loader = () => (
  <div className="loader loader--component">
    <div id="ld3">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

export default Loader;
