import React, { useCallback, useMemo } from 'react';
import { databaseActions } from '@src/reduxStore/database/databaseSlice';
import {
  DatabaseAgeFilter,
  DatabaseReferenceFilter,
  DatabaseSearchFilter,
  DatabaseZillFilter,
} from '@components/database/components/facets';
import CurriculaSelectorFilter from '@UI/curriculaSelector/CurriculaSelectorFilter';
import settings, { proGlobalDatabase } from '@src/config/settings';

const DatabaseFilters = ({
  database,
  filters,
  zillCurricula,
  hideFilters,
  dispatch,
  update,
  theme,
  onClearFilters,
  doSearch,
}) => {
  const handleClearFilters = useCallback(() => {
    dispatch(databaseActions.resetFilters(filters.requirements));
    onClearFilters();
  }, [dispatch, filters.requirements, onClearFilters]);

  const taggedCurriculumThemesHrefs = useMemo(() => {
    // when we get here from a database with subatomic views the curricula(s) to apply come in the url
    if (filters.curricula.length > 0) {
      return filters.curricula;
    }
    // when thats not the case we don't put them in the filters (becuse it will be only one), so we get them from the referenceFrameItem
    return theme?.referenceFrameItem
      ? theme.referenceFrameItem
          .filter((th) => th.type === 'CURRICULUM_THEME')
          .map((th) => th.$$meta.permalink)
      : [];
  }, [theme?.referenceFrameItem, filters.curricula]);

  return (
    <div className={'kov-pro-filter'}>
      <div className="kov-pro-filter__title-wrapper">
        <h2 className="kov-pro-filter__title">Filter {!hideFilters.toString()}</h2>
        <button onClick={handleClearFilters} className="filter-tag">
          wis filter <i className="icon-kov-pro-close" />
        </button>
      </div>
      {database.facets.find((facet) => facet.component === 'FULL_TEXT_SEARCH') && (
        <DatabaseSearchFilter
          searchTerm={filters.q}
          search={database.facets.find((facet) => facet.component === 'FULL_TEXT_SEARCH')}
          dispatch={dispatch}
        />
      )}
      {filters.requirements.length > 0 &&
        filters.requirements.map((requirement, index) => (
          <DatabaseReferenceFilter key={index} requirement={requirement} onChange={update} />
        ))}
      {database.facets.find((facet) => facet.component === 'AGE_RANGE_SLIDER') && (
        <DatabaseAgeFilter
          age={database.facets.find((facet) => facet.component === 'AGE_RANGE_SLIDER')}
          from={filters.ageRangeFrom}
          to={filters.ageRangeTo}
          dispatch={dispatch}
        />
      )}
      {database.facets.find((facet) => facet.component === 'ZILL_GOAL_SELECTOR') &&
        zillCurricula && (
          <DatabaseZillFilter
            facet={database.facets.find((facet) => facet.component === 'ZILL_GOAL_SELECTOR')}
            zillItem={zillCurricula.find((cur) => cur.href === filters.zillItem)}
            zillCurriculum={zillCurricula}
            dispatch={dispatch}
          />
        )}

      {database.isGlobalDatabase && (
        <CurriculaSelectorFilter
          appliedCurriculaFilterHrefs={filters.curricula}
          onUpdate={(items) =>
            dispatch(
              databaseActions.setFilter({
                fieldName: 'curricula',
                payload: items.map((item) => item.href),
              })
            )
          }
        />
      )}

      <div className="kov-sidebar__confirm">
        <button
          className="kov-pro-btn kov-pro-btn--block kov-pro-btn--darkgreen"
          onClick={doSearch}
        >
          Zoeken
        </button>
        {!database.isGlobalDatabase &&
          taggedCurriculumThemesHrefs.length > 0 &&
          // The "Meer Inspiratie.." button is only show when the "database type" is tagged with "Inspirerend materiaal".
          // That field is called "themes" in the database (content-api database) ("theme" in PRO) but the property "themes" is used in multiple types of documents. So, when the document is a "MINI_DATABASE" the property "themes" indicates the "type" of database
          // ref: monday #4113370878
          database.theme?.includes(proGlobalDatabase.databaseTypeThemehref) && (
            <button
              onClick={() => {
                window.location.href = `${
                  settings.proGlobalDatabase.url
                }?curricula=${taggedCurriculumThemesHrefs.join(',')}`;
              }}
              className="kov-pro-btn kov-pro-btn--block kov-pro-btn--light kov-pro-btn--inverted"
            >
              Meer inspiratie over leerplannen heen
            </button>
          )}
      </div>
    </div>
  );
};

export default DatabaseFilters;
