import React, { useState, useEffect } from 'react';
import { databaseActions } from '@src/reduxStore/database/databaseSlice';
import MenuItemSelector from '@UI/menuItemFilters/menuItemSelector/menuItemSelector';
import Icon from '@UI/icon/icon';

const PLACEHOLDERS = {
  0: 'Alle ontwikkelvelden',
  1: 'Alle ontwikkelthema’s',
  2: 'Alle generieke doelen',
};
const DatabaseZillFilter = ({ zillItem, facet, zillCurriculum, dispatch }) => {
  const [collapsed, setCollapsed] = useState(false);

  const [zillMenus, setZillMenus] = useState([]);

  useEffect(() => {
    const tempZillMenus = [];
    if (!zillItem) {
      tempZillMenus.push({
        selectedZillitem: null,
        selectedZillCurriculum: [
          { title: PLACEHOLDERS[0] },
          ...zillCurriculum.filter((cur) => cur.level === 0),
        ],
      });
    } else {
      tempZillMenus.push({
        selectedZillitem: zillItem,
        selectedZillCurriculum: [
          { title: PLACEHOLDERS[zillItem.level] },
          ...zillCurriculum.filter((cur) => cur.parent === zillItem.parent),
        ],
      });
      if (zillItem.children) {
        tempZillMenus.push({
          selectedZillitem: null,
          selectedZillCurriculum: [
            { title: PLACEHOLDERS[zillItem.level + 1] },
            ...zillItem.children,
          ],
        });
      }
      if (zillItem.parent !== null) {
        const parentItem = zillCurriculum.find((cur) => cur.href === zillItem.parent);
        tempZillMenus.unshift({
          selectedZillitem: parentItem,
          selectedZillCurriculum: [
            { title: PLACEHOLDERS[zillItem.level - 1] },
            ...zillCurriculum.filter((cur) => cur.parent === parentItem.parent),
          ],
        });
        if (parentItem.parent !== null) {
          const grandParentItem = zillCurriculum.find((cur) => cur.href === parentItem.parent);
          tempZillMenus.unshift({
            selectedZillitem: grandParentItem,
            selectedZillCurriculum: [
              { title: PLACEHOLDERS[zillItem.level - 2] },
              ...zillCurriculum.filter((cur) => cur.parent === grandParentItem.parent),
            ],
          });
        }
      }
    }
    setZillMenus(tempZillMenus);
  }, [zillItem, zillCurriculum]);

  const selectItem = (level, itemSelected) => {
    dispatch(
      databaseActions.setFilter({
        fieldName: 'zillItem',
        payload: itemSelected.href || zillMenus[level - 1]?.selectedZillitem.href,
      })
    );
  };

  const defaultTemplate = (option) => (
    <>
      {option.iconUrl && (
        <Icon
          icon={{ href: option.iconUrl, contentType: 'image/svg+xml' }}
          style={{ fill: option.color }}
        />
      )}
      <span>{option.title}</span>
    </>
  );

  const level2Template = (option) => (
    <>
      <span
        style={{
          color: option.color,
          alignSelf: 'center',
          marginRight: '12px',
          fontSize: '10px',
        }}
      >
        {option.code}
      </span>
      <span>{option.title}</span>
    </>
  );

  return (
    <div className="filter-block">
      <a
        className={`title${collapsed ? ' collapsed' : ''}`}
        data-toggle="collapse"
        onClick={() => setCollapsed(!collapsed)}
        role="button"
        aria-expanded={!collapsed}
        aria-controls={facet.label}
      >
        {facet.label} <i className="icon-kov-pro-down-arrow"></i>
      </a>
      {zillCurriculum && (
        <div
          className={`filter-items collapse${!collapsed ? ' show' : ''}`}
          style={{ cursor: 'ew-resize' }}
          id={facet.label}
        >
          <div style={{ padding: '5px' }}>
            {zillMenus.map((el, index) => (
              <MenuItemSelector
                key={index}
                level={index}
                options={el.selectedZillCurriculum}
                selected={el.selectedZillitem || el.selectedZillCurriculum[0]}
                onSelected={selectItem}
                optionTemplate={index === 2 ? level2Template : defaultTemplate}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DatabaseZillFilter;
export { PLACEHOLDERS };
