import React, { createContext } from 'react';

export const ModalContext = createContext({
  onConfirm: () => {},
  onClose: () => {},
});

const ModalProvider = ({ isOpen, onConfirm, onClose, children }) => (
  <ModalContext.Provider value={{ onConfirm, onClose }}>{isOpen && children}</ModalContext.Provider>
);

export default ModalProvider;
