import wordIcon from '@src/assets/images/kov-pro-file-word.svg';
import pdfIcon from '@src/assets/images/kov-pro-file-pdf.svg';
import excelIcon from '@src/assets/images/kov-pro-file-excel.svg';
import powerPointIcon from '@src/assets/images/kov-pro-file-ppt.svg';
import * as settings from '@src/config/settings';
import { getIconForCluster } from '@src/reduxStore/theme/themeStateHelper';
import { getEventInfoForTrainings } from '@src/reduxStore/trainings/trainingsResultViewModel';
import { generateHashForScrollTo } from '@helpers/utils';

const EXTENSION_ICON_MAP = [
  { extensions: ['pdf'], icon: pdfIcon },
  { extensions: ['doc', 'dtox', 'docx'], icon: wordIcon },
  { extensions: ['xltx', 'xlsx', 'xls'], icon: excelIcon },
  { extensions: ['ppt', 'pptx', 'ppsx', 'potx'], icon: powerPointIcon },
];

const getRelevantAliases = (q, result) => {
  if (!q || !(result.aliases || (result.tilesPage && result.tilesPage.aliases))) {
    return undefined;
  }
  const relevantAlias = result.aliases.find((alias) =>
    q.toLowerCase().includes(alias.key.toLowerCase())
  );
  if (relevantAlias) {
    return relevantAlias;
  }
  if (result.tilesPage && result.tilesPage.aliases) {
    return result.tilesPage.aliases.find((alias) =>
      q.toLowerCase().includes(alias.key.toLowerCase())
    );
  }
  return undefined;
};

const getPersonResult = (result) => {
  const mainResponsibility = result.responsibilities
    ? result.responsibilities[0]?.position?.name
    : undefined;
  return {
    contentHref: result.key,
    type: 'PERSON',
    title: result.fullName,
    extraKovProResultClass: 'kov-pro-result--contact',
    iconClassName: 'icon-kov-pro-profile',
    context: mainResponsibility,
    goTo: {},
    person: {
      personHref: result.$$meta.permalink,
      firstName: result.firstName,
      lastName: result.lastName,
      profilePictureHref: result.profilePicture?.small,
      mainResponsibility,
    },
  };
};

const findThemPagesInAncestors = (ancestors) =>
  ancestors
    .filter(Boolean)
    .flat()
    .filter(
      (ancestor) =>
        ancestor.webPageType === 'TILES_PAGE' || ancestor.webPageType === 'STANDALONE_PAGE'
    );

const getThemePages = (result) => {
  if (result.type === 'TILES_SUBPAGE') {
    return [
      {
        title: result.tilesPage?.title || result.title,
        href: result.tilesPage?.permalink || result.$$meta.permalink,
      },
    ];
  }
  if (
    !['MENU_ITEM', 'TILES_PAGE', 'STANDALONE_PAGE'].includes(result.type) &&
    result.ancestors &&
    result.ancestors.length > 0
  ) {
    const themePages = findThemPagesInAncestors(result.ancestors);
    return themePages
      .map((themePage) => ({ title: themePage.title, href: themePage.permalink }))
      .filter(
        // unique filter
        (themePage, index, self) =>
          self.findIndex((value) => themePage.href === value.href) === index
      );
  }
  return undefined;
};

const getPublicationDate = (result) => {
  if (!result.issued || !result.issued.startDate) return null;
  return new Date(result.issued.startDate).toLocaleDateString('nl-BE', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'short',
  });
};

const getGoTo = (result) => {
  if (result.$$meta.type === 'PRO_TEXT_PAGE_SECTION') {
    return { href: `${result.textPage.webInfo.path}?scrollTo=${result.key}`, target: '_self' };
  }

  if (result.$$meta.type === 'PRO_FAQ_QUESTION') {
    const faqAncestor =
      result.contentType === 'SHARED_FAQ'
        ? result.includedIn?.[0].ancestors
        : result.ancestors?.[0];
    let faqLink = faqAncestor?.find((ancestor) => ancestor.contentType === 'SECTION')?.permalink;
    faqLink = `${faqLink}?scrollTo=${generateHashForScrollTo(result.key)}`;
    return {
      href: faqLink,
      target: '_self',
    };
  }

  if (result.$$meta.type === 'PRO_ATTACHMENT' && result.attachment) {
    return { href: settings.apisAndUrls.api + result.attachment.href, target: '_blank' };
  }

  if (
    result.type === 'TEASER' &&
    result.callToAction &&
    result.callToAction[0]?.type === 'EXTERNAL'
  ) {
    const callToAction = result.callToAction[0];
    return { href: callToAction.link, target: '_blank' };
  }

  if (result.$$meta.type === 'TRAINING_MODULE') {
    return {
      href: `${settings.apisAndUrls.trainings.nascholing}redirectTo.aspx?redirectID=${
        result.$$meta.permalink
      }&returnUrl=${encodeURIComponent(`${window.location.href.split('#').shift()}`)}`,
      target: '_blank',
    };
  }

  if (result.type === 'JOB_OFFER') {
    return { href: settings.apisAndUrls.www + result.webInfo.path, target: '_blank' };
  }

  if (result.webInfo) {
    // A externalReference should be opened in a new tab when it doesn't points to pro.* domain.
    if (
      result.webInfo.externalReference &&
      !result.webInfo.externalReference.includes('pro.katholiekonderwijs.vlaanderen')
    ) {
      return { href: result.webInfo.externalReference, target: '_blank' };
    }

    return { href: result.webInfo.path, target: '_self' };
  }
  return { href: result.$$meta.permalink, target: '_self' };
};

const getContextForProDocument = (result) => {
  if (
    ['PRO_TEXT_PAGE_SECTION', 'PRO_FAQ_QUESTION'].includes(result.$$meta.type) ||
    result.type === 'NEWS_ITEM'
  ) {
    if (result.$$context && result.$$context['content.text']) {
      return result.$$context['content.text'][0];
    }
    // else if (result.$$context && result.$$context['text.ngram_partial']) {
    //   context += result.$$context['text.ngram_partial'][0];
    if (result.firstParagraph) {
      return result.firstParagraph;
    }
  }
  if (result.$$meta.type === 'PRO_ATTACHMENT') {
    if (result.$$context && result.$$context.contentAsText) {
      return result.$$context.contentAsText[0].replace(/\.{6,}/g, ' ');
    }
    if (result.$$context && result.$$context['contentAsText.ngram_partial']) {
      return result.$$context['contentAsText.ngram_partial'][0].replace(/\.{6,}/g, ' ');
    }
    if (result.contentAsText) {
      return `${result.contentAsText.substring(0, 300).replace(/\.{6,}/g, ' ')} ${
        result.contentAsText.length > 300 ? '...' : ''
      }`;
    }
  }
  if (result.$$meta.type === 'TRAINING_MODULE') {
    if (result.$$context && result.$$context.motivation) {
      return result.$$context.motivation[0];
    }
    return result.motivation;
  }
  // default cases have description: tiles_page, standalone_page, blog(item)s, mini-database(items)s, teaser
  if (result.$$context && result.$$context.description) {
    return result.$$context.description[0];
  }
  if (result.$$context && result.$$context['content.text']) {
    return result.$$context['content.text'][0];
  }
  return result.description;
};

const stripHtmlTagsAndAmps = (html) => html.replace(/<([^>]+)>/gi, '').replace(/&amp;/g, '&');

/**
 * The context on the screen should strip all html tags like lists, breaks, other words that are emphasized
 * and only the found keywords (or paragraph title for pro_text_page_section) should be emphasized
 * @returns html with no html tags except for spans with class="keyword"
 */
const sanitizeContext = (context) => {
  if (!context) {
    return context;
  }
  const contextWithKeywordsEncoded = context
    .replace(/^[^<]+>/gi, '') // search api context can sometimes return half html tags at the start if there is a very long <a href="{very long href"> in the text
    .replace(/<span class="keyword">([^<]+)<\/span>/gi, '##%##$1%%#%%'); // replace keyword tags by something else in order not lose them in the next step
  const contextWithOtherHtmlTagsRemoved = stripHtmlTagsAndAmps(contextWithKeywordsEncoded);
  return contextWithOtherHtmlTagsRemoved.replace(
    /##%##((?:(?!%%#%%).)+)%%#%%/gi,
    '<span class="keyword">$1</span>'
  ); // return context decoded again: return the keword tags
};

const addTypeSpecifClassesAndProperties = (result, ret) => {
  if (result.$$meta.type === 'PRO_ATTACHMENT') {
    ret.attachmentIcon = EXTENSION_ICON_MAP.find((entry) =>
      entry.extensions.includes(result.attachment?.name.split('.').pop())
    )?.icon;
    ret.iconClassName = ret.attachmentIcon ? undefined : 'icon-kov-pro-other-filetypes';
  }

  if (['TEASER', 'NEWS_ITEM'].includes(ret.type)) {
    ret.iconClassName = 'icon-kov-pro-nieuws';
    ret.publicationDate = result.issued ? getPublicationDate(result) : 'niet gepubliceerd';
  }
  if (
    result.type === 'TEASER' &&
    result.callToAction &&
    result.callToAction[0]?.type === 'EXTERNAL'
  ) {
    ret.extraKovProResultClass = 'kov-pro-result--external';
  }

  if (result.$$meta.type === 'PRO_FAQ_QUESTION') {
    ret.extraKovProResultClass = 'kov-pro-result--question';
    ret.titlePrefix = 'Vraag:';
  }

  if (result.type === 'JOB_OFFER') {
    ret.extraKovProResultClass = 'kov-pro-result--job-offer kov-pro-result--external';
    ret.titlePrefix = 'Vacature:';
  }

  if (result.$$meta.type === 'TRAINING_MODULE') {
    ret.iconClassName = 'icon-kov-pro-calendar';
    ret.extraKovProResultClass = 'kov-pro-result--event kov-pro-result--external';
    ret.eventInfo = getEventInfoForTrainings(result);
  }

  if (result.type === 'MENU_ITEM' && !result.icon && result.ancestors[0].length > 0) {
    ret.icon = getIconForCluster(result.$$meta.permalink);
  }
  if (ret.icon) {
    ret.icon.href = settings.apisAndUrls.api + ret.icon.href;
  }

  // A externalReference outside of pro.* domain should be highlighted with the proper icon.
  if (
    result?.webInfo?.externalReference &&
    !result?.webInfo?.externalReference.includes('pro.katholiekonderwijs.vlaanderen')
  ) {
    ret.extraKovProResultClass = 'kov-pro-result--external';
  }
};

const transformSearchResult = (result, searchTerm) => {
  try {
    // TODO: training
    if (result.$$meta.type === 'PERSON') {
      return getPersonResult(result);
    }

    const ret = {
      key: result.key,
      type: result.type || result.$$meta.type,
      contentHref: result.$$meta.permalink,
      title: stripHtmlTagsAndAmps(result.title),
      context: sanitizeContext(getContextForProDocument(result)),
      themePages: getThemePages(result), // to set => to array to not have duplicates?
      relevantAlias: getRelevantAliases(searchTerm, result),
      icon: result.icon,
      goTo: getGoTo(result),
      showDebugInfo: settings.environment === 'test',
    };

    if (result.$$meta.type === 'PRO_TEXT_PAGE_SECTION') {
      ret.title = stripHtmlTagsAndAmps(result.textPage.title);
      ret.context = `<span class="keyword">${stripHtmlTagsAndAmps(result.title)}</span> ${
        ret.context ? ret.context : ''
      }`;
    }

    addTypeSpecifClassesAndProperties(result, ret);

    return ret;
  } catch (transformationError) {
    console.error(`Error while searching for ${searchTerm}`, transformationError);
    return {
      key: result.key,
      contentHref: result.$$meta.permalink,
      type: result.type || result.$$meta.type,
      title: result.title,
      showDebugInfo: settings.environment === 'test',
      error: transformationError,
      goTo: result.$$meta.permalink,
    };
  }
};
export default transformSearchResult;
