const kovsentry = require('@kathondvla/kovsentry');
const settings = require('../config/settings.js');

if (settings.logging.sentry.enabled) {
  // add Sentry if enabled
  const Sentry = require('@sentry/browser');
  const Integrations = require('@sentry/integrations');
  const browserIntegrations = require('@sentry/tracing').Integrations;

  Sentry.init({
    dsn: settings.logging.sentry.url,
    enabled: true,
    debug: false,
    release: settings.projectVersion,
    environment: settings.environment == '' ? 'PROD' : settings.environment,
    maxValueLength: 2000,

    integrations: [new Integrations.Angular()],
    tracesSampleRate: settings.logging.sentry.sentryPerformanceSampleRate,
    ignoreErrors: ['redirected.to.login.page', '"status":401'],
    beforeSend(event, hint) {
      // console.log('beforeSend', event);
      const cookies = `; ${document.cookie}`.split('; ');
      const analytics = cookies.find((e) => e.includes('cookies__analytic'));
      if (analytics) {
        let accepted = analytics.split('=').pop() === 'true';

        // rate limit some errors we don't want in sentry.
        if (accepted) {
          if (hint && hint.originalException) {
            if (
              hint.originalException.message === 'Failed to fetch' || // Chrome network 500 error
              hint.originalException.message === 'NetworkError when attempting to fetch resource.' // FF network 500 error
            ) {
              accepted = Math.random() < 0.1; // we only want to send 10% of these errors to sentry.
            }
          }
        }
        return accepted ? event : null;
      }
      return null;
    },
  });

  kovsentry.init(Sentry, {
    addFetchInterceptor: settings.logging.sentry.fetchInterceptor,
  });
}

module.exports = kovsentry;
