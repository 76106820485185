import React from 'react';
import PropTypes from 'prop-types';
import pdfFile from '@src/assets/images/kov-pro-file-pdf.svg';
import wordFile from '@src/assets/images/kov-pro-file-word.svg';
import imageFile from '@src/assets/images/kov-pro-file-image.svg';
import powerPointFile from '@src/assets/images/kov-pro-file-ppt.svg';
import excelFile from '@src/assets/images/kov-pro-file-excel.svg';
import anyFileIcon from '@src/assets/images/icoon_alleen.svg';

import { getAttachmentType } from '@src/app/contentUtils';

const AttachmentTypeIcon = ({ attachment }) => {
  const attachmentType = getAttachmentType(attachment);
  let icon;

  switch (attachmentType) {
    case 'pdf':
      icon = pdfFile;
      break;
    case 'word':
      icon = wordFile;
      break;
    case 'image':
      icon = imageFile;
      break;
    case 'powerpoint':
      icon = powerPointFile;
      break;
    case 'excel':
      icon = excelFile;
      break;
    default:
      icon = anyFileIcon;
      break;
  }

  return <img src={icon} className="svg" />;
};

AttachmentTypeIcon.propTypes = {
  attachment: PropTypes.object,
};

export default AttachmentTypeIcon;
