import { calculateSize } from '@src/app/contentUtils';
import * as settings from '@src/config/settings';
import { SECURITYLEVELS } from '@src/components/constants';
import { hasPermissions } from '@src/helpers/accessHelper';
import { contentApi } from '@src/app/apiConfig';

export const hasSecureItems = (results = []) =>
  results.length > 0 &&
  results
    .filter((child) => child.type === 'ATTACHMENTS_GROUP')
    .some((child) => !hasPermissions(child));

export const downloadFile = (file) => {
  const isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
  const isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > -1;

  // Source: http://pixelscommander.com/en/javascript/javascript-file-download-ignore-content-type/
  const download = (sUrl) => {
    // iOS devices do not support downloading. We have to inform user about this.
    if (/(iP)/g.test(navigator.userAgent)) {
      window.open(sUrl, '_blank');
      return false;
    }

    // If in Chrome or Safari - download via virtual link click
    if (isChrome || isSafari) {
      // Creating new link node.
      const link = document.createElement('a');
      link.href = sUrl;
      link.setAttribute('target', '_blank');

      if (link.download !== undefined) {
        // Set HTML5 download attribute. This will prevent file from opening if supported.
        const fileName = sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length);
        link.download = fileName;
      }

      // Dispatching click event.
      if (document.createEvent) {
        const e = document.createEvent('MouseEvents');
        e.initEvent('click', true, true);
        link.dispatchEvent(e);
        return true;
      }
    }

    // Force file download (whether supported by server).
    if (sUrl.indexOf('?') === -1) {
      sUrl += '?download';
    }

    window.open(sUrl, '_blank');
    return true;
  };

  download(file.attachment.href);
};

const parseAttachment = (attachment) => ({
  ...attachment,
  href: attachment.href.startsWith('http')
    ? attachment.href
    : settings.apisAndUrls.downloadUrl + attachment.href,
  description: attachment.description ? attachment.description : attachment.name,
  size: calculateSize(attachment.size),
});

// this function parses the correct information for each download in an attachment group. All global attachment groups have 0 security.
const parseDownload = (download) => ({
  ...download,
  key: download.permalink,
  visible: true,
  attachment: parseAttachment(download.attachment),
  descendantsAccessRights: SECURITYLEVELS.PUBLIC,
  thumbImageM:
    download.thumbnails && download.thumbnails.length > 0
      ? { href: settings.apisAndUrls.cachedApi + download.thumbnails[0].href }
      : null,
  securityStatus: 'permitted',
});

// this function finds the correct attachmentGroups each download page is associated with,
// then parses the correct information to display them.
// local groups are matched with the correct local item. shared groups are parsed further with the above functions
export const parseAttachmentGroups = (results, downloadPage) => {
  const localDownloadHrefs = downloadPage.children?.map((dp) => dp.href) || [];
  results = results.map((res, i) => ({ ...res, readOrder: i }));
  const sharedAttachmentGroups = results.flatMap((res) => {
    const groups = res.attachmentGroups.filter(
      (ag) =>
        ag.attachmentTypeInGroup === 'DOWNLOAD' &&
        ag.contentType === 'SHARED_ATTACHMENTS_GROUP' &&
        ag.includedIn?.some((page) => page.permalink === downloadPage.href)
    );
    if (groups.length > 0) {
      return groups.map((group) => {
        let secondaryAttachments = [];
        if (group.secondaryAttachments && group.secondaryAttachments.length > 0) {
          secondaryAttachments = group.secondaryAttachments.map((secondaryAttachment) =>
            parseDownload(secondaryAttachment)
          );
        }
        return {
          ...group,
          href: group.permalink,
          children: [parseDownload(res), ...secondaryAttachments],
          key: res.key,
          readOrder: res.readOrder,
          visible: true,
        };
      });
    }
    return [];
  });

  const localAttachmentGroups = results
    .flatMap((res) =>
      res.attachmentGroups
        .filter(
          (ag) =>
            ag.attachmentTypeInGroup === 'DOWNLOAD' &&
            ag.contentType === 'ATTACHMENTS_GROUP' &&
            localDownloadHrefs.includes(ag.permalink)
        )
        .map((ag) => ({ ...ag, readOrder: res.readOrder }))
    )
    .map((res) => ({
      ...downloadPage.children.find((child) => child.href === res.permalink),
      readOrder: res.readOrder,
    }));
  return sharedAttachmentGroups.concat(localAttachmentGroups);
};

export const themeHasSharedAttachments = async (href) => {
  const res = await contentApi.getAll('/content', {
    sharedAttachmentGroupsIncludedIn: href,
    limit: 0,
    $$includeCount: true,
  });
  return res.count > 0;
};
