import React from 'react';
import { getLoginProviderIcon, redirectToLoginProvider } from '@src/helpers/userHelper';
import { setCookie } from '@helpers/cookieHelper';
import trackPiwikEvent from '@src/reduxStore/analytics/analyticsThunks';
import { transformViewOptionsToAnalyticsObject } from '@src/helpers/viewOptionsHelper';
import { useDispatch } from 'react-redux';
import ViewOptionDropdown from '../viewOptionDropdown/viewOptionDropdown';
import useEntryFlowViewOptionsHook from '../useEntryFlowViewOptionsHook';

const AnonymousEntryFlow = ({ onComplete }) => {
  const reduxDispatch = useDispatch();
  const settings = require('@src/config/settings');
  const { state, dispatch } = useEntryFlowViewOptionsHook();

  const alternateLogin = (skipViewOptionsMethod) => {
    setCookie(
      'cookies__skipInitViewOptions',
      true,
      new Date(new Date().setHours(new Date().getHours() + 24))
    );

    reduxDispatch(
      trackPiwikEvent('skip_view_options', {
        skip_view_options_method: skipViewOptionsMethod,
      })
    );
    onComplete({ EDUCATION_LEVELS: state.educationLevels, REGIONS: state.regions }, true);
  };

  const submitAnonymousPreferences = async () => {
    if (
      !state.regions.some((reg) => reg.checked) ||
      !state.educationLevels.some((lvl) => lvl.checked)
    ) {
      dispatch({ type: 'setInvalid', payload: true });
      return;
    }

    const viewOptions = {
      EDUCATION_LEVELS: state.educationLevels,
      REGIONS: state.regions,
    };

    reduxDispatch(
      trackPiwikEvent('set_view_filter', transformViewOptionsToAnalyticsObject(viewOptions))
    );
    onComplete(viewOptions, true);
  };

  return (
    <div className="view-options-first">
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 order-1 order-md-2">
          <div className="kov-pro-login">
            <div className="kov-modal-title">
              <i className="icon-kov-pro-profile"></i> Aanmelden
            </div>
            <div className="kov-pro-login__wrapper">
              <button
                onClick={() => redirectToLoginProvider('KATHONDVLA')}
                type="button"
                className="kov-pro-btn kov-pro-btn--block kov-pro-btn--split kov-pro-btn--green"
              >
                <div className="kov-pro-btn__img">
                  <img src={getLoginProviderIcon('KATHONDVLA')} />
                </div>
                <span>KathOndVla-account</span>
              </button>
              of
              <div className="kov-pro-login__providers">
                <button
                  className="kov-pro-btn"
                  type="button"
                  onClick={() => redirectToLoginProvider('SMART_SCHOOL')}
                >
                  <img src={getLoginProviderIcon('SMART_SCHOOL')} />
                </button>
                <button
                  className="kov-pro-btn"
                  type="button"
                  onClick={() => redirectToLoginProvider('SCHOOL_WARE')}
                >
                  <img src={getLoginProviderIcon('SCHOOL_WARE')} />
                </button>
                <button
                  className="kov-pro-btn"
                  type="button"
                  onClick={() => redirectToLoginProvider('OFFICE_365')}
                >
                  <img src={getLoginProviderIcon('OFFICE_365')} />
                </button>
              </div>
              <div className="kov-pro-login__links">
                <a
                  href={`${settings.apisAndUrls.mijn}/#!/registreren?redirect_url=${window.location.href}&client_name=pro`}
                >
                  Nog geen account?
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="kov-pro-viewer-options col-12 col-md-6 col-lg-6 order-2 order-md-1">
          <div className="kov-modal-title">
            <i className="icon-kov-pro-filter"></i> Niet aanmelden
          </div>
          <p>
            Stel minstens één regio en één niveau in. We passen dit toe als kijkfilter op de
            website, zodat je de gepaste inhoud ziet.
          </p>

          <ViewOptionDropdown
            placeholder={'Kies minstens een niveau'}
            invalidFeedback={'Je moet minstens één niveau kiezen.'}
            invalid={state.optionsInvalid}
            options={state.educationLevels}
            initialOptionsToSet={state.educationLevelsToSet}
            assignValues={(selectedEducationLevels) =>
              dispatch({
                type: 'assignValues',
                fieldName: 'educationLevels',
                payload: selectedEducationLevels,
              })
            }
          ></ViewOptionDropdown>

          <ViewOptionDropdown
            placeholder={'Kies minstens een regio'}
            invalidFeedback={'Je moet minstens één regio kiezen.'}
            invalid={state.optionsInvalid}
            options={state.regions}
            initialOptionsToSet={state.regionsToSet}
            assignValues={(selectedRegions) =>
              dispatch({
                type: 'assignValues',
                fieldName: 'regions',
                payload: selectedRegions,
              })
            }
          ></ViewOptionDropdown>

          <button
            type="button"
            className="kov-pro-btn kov-pro-btn--green  kov-pro-btn--block kov-pro-btn__icon--right"
            onClick={submitAnonymousPreferences}
          >
            SURF VERDER ZONDER AANMELDEN<i className="icon-kov-pro-arrow-right"></i>
          </button>
          <div className="kov-pro-login__links">
            <a href="#" onClick={() => alternateLogin('international_user')}>
              International user?
            </a>
            <a href="#" onClick={() => alternateLogin('no_education_professional')}>
              Geen onderwijsprofessional?
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnonymousEntryFlow;
