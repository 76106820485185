export const goToNewItem = (newsItem, proTheme, index) => {
  if (newsItem.isExternal) {
    return newsItem.globalForwardHref || newsItem.localForwardHref;
  }
  return (
    (proTheme ? newsItem.globalForwardHref : newsItem.localForwardHref) +
    (index !== undefined ? `#${index}` : '')
  );
};

const getRegionTags = (viewOptions, res) =>
  viewOptions.REGIONS.filter((reg) => res?.coverage?.includes(reg.href) || false).map(
    (reg) => reg.tag
  );

const getEducationLevelTags = (viewOptions, res) =>
  viewOptions.EDUCATION_LEVELS.filter(
    (edu) =>
      res?.mainstructuresOuTypeCombinations
        ?.map((MouTypes) => MouTypes.permalink)
        ?.includes(edu.mainstructuresOuTypeCombination) || false
  ).map((edu) => edu.tag);

export const transformTags = (viewOptions) => (res) => {
  if (res.coverage == null || res.mainstructuresOuTypeCombinations == null) {
    console.error(
      'SearchAPI returned a published result that does not contain coverage and/or mainstructuresOuTypeCombinations',
      res
    );
  }

  const REGIONTAGS = getRegionTags(viewOptions, res);
  const EDUCATIONLEVELTAGS = getEducationLevelTags(viewOptions, res);

  return REGIONTAGS.concat(EDUCATIONLEVELTAGS);
};
