import React, { useCallback } from 'react';
import StudyProgrammeFilter from '@components/vakkenPagina/studyProgrammeFilter/studyProgrammeFilter';
import StudyProgrammeAccordion from '@src/reactComponents/vakkenPagina/newStructureItems/studyProgrammeAccordion';
import NoResultsPlaceholder from '@UI/noResultsPlaceholder/noResultsPlaceholder';
import Loader from '@UI/loader/loader';
import AccordionBlock from '@src/reactComponents/vakkenPagina/accordionBlock/accordionBlock';

const VakkenPaginaContentTab = ({
  tabId,
  tabData,
  searchTerm,
  executeSearch,
  activeSections,
  setActiveSections,
  loading = false,
}) => {
  const updateActiveSections = useCallback(
    (index) => {
      let newSections = [];
      if (activeSections.includes(index)) {
        newSections = activeSections.filter((sect) => sect !== index);
      } else {
        newSections = [...activeSections, index];
      }
      setActiveSections(newSections);
    },
    [setActiveSections, activeSections]
  );

  const hasElements = !['first-grade', 'thirth-grade-old'].includes(tabId)
    ? tabData.some((el) => el.educationTypes.some((edType) => edType.leerplanPages.length > 0))
    : Object.entries(tabData).some(([, blockData]) => blockData.length);

  const showSearchbar = hasElements || (!hasElements && searchTerm?.length > 0);

  return (
    <div className="tab-pane active" id={tabId} aria-expanded="true">
      {loading && <Loader />}

      {!loading && !['first-grade', 'thirth-grade-old'].includes(tabId) && (
        <>
          {showSearchbar && (
            <StudyProgrammeFilter searchTerm={searchTerm} executeSearch={executeSearch} />
          )}
          {hasElements && (
            <div id="accordion" className="kov-pro-curriculum-accordion">
              {tabData
                .filter((studyProgramme) =>
                  studyProgramme.educationTypes.some((eduType) => eduType.leerplanPages.length > 0)
                )
                .map((studyProgramme, index) => (
                  <StudyProgrammeAccordion
                    key={index}
                    studyProgramme={studyProgramme}
                    updateActiveSections={() => updateActiveSections(index)}
                    tabId={tabId}
                    activeSections={activeSections}
                    index={index}
                  />
                ))}
            </div>
          )}
        </>
      )}

      {!loading &&
        hasElements &&
        ['first-grade', 'thirth-grade-old'].includes(tabId) &&
        Object.entries(tabData).map(([blockId, blockData], index) => (
          <React.Fragment key={index}>
            <AccordionBlock
              accordionId={blockId.toLowerCase().replace(/\s+/g, '')}
              accordionTitle={blockId}
              isActive={activeSections.includes(index)}
              updateActiveSections={() => updateActiveSections(index)}
              items={blockData}
              tabId={tabId}
            />
          </React.Fragment>
        ))}

      {!loading && !hasElements && <NoResultsPlaceholder />}
    </div>
  );
};

export default VakkenPaginaContentTab;
