import React, { useState, useCallback } from 'react';
import MenuItemFilters from '@UI/menuItemFilters/menuItemFilters';
import useMenuItemFilters from '@hooks/useMenuItemFilters';
import CheckboxGroup from '@UI/checkboxGroup/checkboxGroup';
import TrainingLocation from '@UI/trainings/trainingLocation/trainingLocation';
import DateRangePicker from '@UI/dateRangePicker/dateRangePicker';
import PropType from 'prop-types';
import { TRAINING_THEME_FILTERS } from '@src/components/constants';
import CurriculaSelectorFilter from '@src/reactComponents/UI/curriculaSelector/CurriculaSelectorFilter';
import { getSchoolYearEndDate } from '@src/helpers/trainingsHelper';
import { selectNavigationFirstLevel } from '@src/reduxStore/navigation/navigationSelectors';
import { useSelector } from 'react-redux';
import GlobalTrainingSelectedTheme from '../globalTrainingSelectedTheme/globalTrainingSelectedTheme';

const GlobalTrainingFilters = ({
  onSearch,
  cleanAllFilters,
  isFiltersBoxCollapsed,
  viewModel,
  themeFiltersRef,
  updateFilter,
  updateCheckboxFilter,
}) => {
  const navigationFirstLevel = useSelector(selectNavigationFirstLevel);

  const [themeOrCurriculaFilter, setThemeOrCurriculaFilter] = useState(
    viewModel.curricula.length ? 'curricula' : 'theme'
  );

  const updateMenuItemFilter = useCallback(
    (value) => updateFilter('menuItem', value),
    [updateFilter]
  );

  // the viewModel includes all the possible values available in the filters and extend the user selections
  const { selectedMenuItems, getLevelOptions, onMenuItemSelected } = useMenuItemFilters(
    viewModel,
    updateMenuItemFilter,
    TRAINING_THEME_FILTERS
  );

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  return (
    <div
      className={`kov-pro-nas-filter collapse ${isFiltersBoxCollapsed ? '' : 'show'}`}
      id="collapseFilter"
      onKeyDown={handleKeyDown}
    >
      <div className="container">
        <div className="kov-pro-nas-filter__title">
          <i className="icon-kov-pro-nas-filter" />
          <div>
            Verfijn je zoekopdracht{' '}
            <GlobalTrainingSelectedTheme viewModel={viewModel} themeFiltersRef={themeFiltersRef} />
          </div>
          <button className="filter-tag" onClick={cleanAllFilters}>
            wis alle <i className="icon-kov-pro-close" />
          </button>
        </div>

        <div className="row">
          <div className="col-12 col-md-4">
            <div className="kov-pro-nas-filter__filter">
              <span className="kov-pro-nas-filter__filter-title">Onderwijsniveau</span>
              <CheckboxGroup
                itemListClassName="kov-pro-nas-filter__list"
                itemListItemClassName="kov-pro-nas-filter__list-item"
                groupArray={viewModel.mainstructuresOuTypeCombinations}
                checkboxId={'mainstructuresOuTypeCombinations'}
                onChange={updateCheckboxFilter}
              />
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="kov-pro-nas-filter__filter">
              <span className="kov-pro-nas-filter__filter-title">Functies</span>
              <CheckboxGroup
                itemListClassName="kov-pro-nas-filter__list"
                itemListItemClassName="kov-pro-nas-filter__list-item"
                groupArray={viewModel.positions}
                checkboxId={'positions'}
                onChange={updateCheckboxFilter}
              />
              <span className="kov-pro-nas-filter__filter-title">Fysiek of online</span>
              <CheckboxGroup
                itemListClassName="kov-pro-nas-filter__list"
                itemListItemClassName="kov-pro-nas-filter__list-item"
                groupArray={viewModel.attendanceType}
                checkboxId={'attendanceType'}
                onChange={updateCheckboxFilter}
              />
            </div>
          </div>

          <div className="col-12 col-md-4">
            <span className="kov-pro-nas-filter__filter-title">Type initiatief</span>
            <CheckboxGroup
              itemListClassName="kov-pro-nas-filter__list"
              itemListItemClassName="kov-pro-nas-filter__list-item"
              groupArray={viewModel.trainingTypes}
              checkboxId={'trainingTypes'}
              onChange={updateCheckboxFilter}
            />
            <span className="kov-pro-nas-filter__filter-title">Locatie en datum</span>
            <TrainingLocation
              latitude={viewModel.lat}
              longitude={viewModel.lon}
              distanceInKm={+viewModel.distanceInKm}
              updateDistance={(filterValue) => updateFilter('distanceInKm', filterValue)}
              updateGPSPositionFilters={(latFilterValue, lonFilterValue) => {
                updateFilter('lat', latFilterValue);
                updateFilter('lon', lonFilterValue);
              }}
            />
            <div className="kov-pro-nas-filter__date">
              <DateRangePicker
                startDate={viewModel.dateFrom ? new Date(+viewModel.dateFrom) : undefined}
                endDate={viewModel.dateTo ? new Date(+viewModel.dateTo) : undefined}
                minDate={new Date()}
                maxDate={getSchoolYearEndDate()}
                onDaySelected={updateFilter}
              />
            </div>
          </div>
        </div>

        <div className="row" ref={themeFiltersRef}>
          <div className="col-12">
            <div className="kov-pro-nas-filter__build-up filter-block">
              <span className="kov-pro-nas-filter__filter-title">Verfijn verder</span>
              <ul className="kov-input-search__radio">
                <li>
                  <label
                    className={
                      themeOrCurriculaFilter === 'theme' ? 'active filter-item' : 'filter-item'
                    }
                  >
                    <a onClick={() => setThemeOrCurriculaFilter('theme')}>
                      op thema
                      <input
                        type="radio"
                        name="filter"
                        checked={themeOrCurriculaFilter === 'theme'}
                        readOnly
                      />
                      <span className="checkround" />
                    </a>
                  </label>
                </li>
                <li>
                  <label
                    className={
                      themeOrCurriculaFilter === 'curricula' ? 'active filter-item' : 'filter-item'
                    }
                  >
                    <a onClick={() => setThemeOrCurriculaFilter('curricula')}>
                      op leerplannen secundair
                      <input
                        type="radio"
                        name="filter"
                        checked={themeOrCurriculaFilter === 'curricula'}
                        readOnly
                      />
                      <span className="checkround" />
                    </a>
                  </label>
                </li>
              </ul>
              {navigationFirstLevel && themeOrCurriculaFilter === 'theme' && (
                <MenuItemFilters
                  firstLevel={navigationFirstLevel}
                  selectedMenuItems={selectedMenuItems}
                  onMenuItemSelected={onMenuItemSelected}
                  getLevelOptions={getLevelOptions}
                />
              )}
              {themeOrCurriculaFilter === 'curricula' && (
                <CurriculaSelectorFilter
                  appliedCurriculaFilterHrefs={viewModel.curricula}
                  onUpdate={(values) => {
                    updateFilter(
                      'curricula',
                      values.map((curr) => curr.href)
                    );
                  }}
                  isBlock={true}
                  fullWidthButton={false}
                />
              )}
            </div>
          </div>
        </div>
        <div className="kov-pro-nas-filter__submit">
          <button
            onClick={onSearch}
            type="button"
            className="kov-pro-btn kov-pro-btn--green kov-pro-btn--lg"
          >
            Pas toe en zoek
          </button>
        </div>
      </div>
    </div>
  );
};

GlobalTrainingFilters.prototype = {
  onSearch: PropType.func,
  cleanAllFilters: PropType.func,
  isFiltersBoxCollapsed: PropType.bool,
  viewModel: PropType.object,
  themeFiltersRef: PropType.object,
  updateFilter: PropType.func,
  updateCheckboxFilter: PropType.func,
};

export default GlobalTrainingFilters;
