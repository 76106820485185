import React from 'react';
import PropTypes from 'prop-types';
import { Compiler } from '@kathondvla/react-shared-components/src/components';
import getCompilerOptions from '../helpers/sanitizeOptions';

const ContentBlockParagraph = ({ item, children }) => {
  if (item.parent.type === 'SUMMARY') return <></>;

  return (
    item.visible && (
      <div
        className={`kov-content-block-paragraph${
          item.imageS ? ' d-sm-flex justify-content-start' : ''
        }`}
      >
        {item.imageS && (
          <figure style={{ minWidth: '180px', maxWidth: '20%' }}>
            <div className="img-article-holder left xs">
              <div className="img-article-img">
                <img src={item.imageS.href} />
              </div>
            </div>
          </figure>
        )}
        <p>
          <Compiler text={item.$$html} options={getCompilerOptions()} />
        </p>
        <span className="clearfix" />
        {children}
      </div>
    )
  );
};

ContentBlockParagraph.propTypes = {
  item: PropTypes.object,
  children: PropTypes.array,
};

export default ContentBlockParagraph;
