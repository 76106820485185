import React from 'react';
import { useDispatch } from 'react-redux';
import uiState from '@src/reduxStore/ui/uiState';

const ThemeDownloadShielded = () => {
  const stateDispatch = useDispatch();
  const openSidebar = () => stateDispatch(uiState.actions.setIsSideBarOpen(true));

  return (
    <div className="kov-pro-xl-downloadlist-item kov-pro-xl-downloadlist-item--not-logged-in">
      <div className="row">
        <div className="col-12">
          <h3>
            Deze informatie valt buiten je kijkfilter.
            <br />
            <a href="#" onClick={openSidebar}>
              Pas je kijkfilter aan
            </a>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default ThemeDownloadShielded;
