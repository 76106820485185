import React from 'react';
import PropTypes from 'prop-types';
import { getSecureAccessPlaceholderContent } from '@src/helpers/accessHelper';
import { goToPath } from '@src/helpers/utils';
import { getLoginUrl } from '@src/helpers/userHelper';
import { useSelector } from 'react-redux';
import { getUserKey } from '@src/reduxStore/user/userSelectors';

const RestrictedContent = ({ status }) => {
  const userKey = useSelector(getUserKey);
  const { text, link, callToAction } = getSecureAccessPlaceholderContent(userKey, status);

  const clickHandler = (path) => {
    if (!userKey) {
      window.location.href = getLoginUrl();
    } else {
      goToPath(path);
    }
  };

  return (
    <h3
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {text} <br />
      <object>
        <a href="#" onClick={() => clickHandler(link)} style={{ textDecoration: 'underline' }}>
          {callToAction}
        </a>
      </object>
    </h3>
  );
};

RestrictedContent.propTypes = {
  status: PropTypes.string,
};

export default RestrictedContent;
