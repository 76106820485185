class wsNavigationThirdLevel {
  constructor($scope, navigationService, $stateParams, $location) {
    'ngInject';

    this.$scope = $scope;
    // used in the template and in the view
    this.$stateParams = $stateParams;
    // used in the template
    this.$location = $location;
    this.navigationService = navigationService;
  }

  async $onInit() {
    this.navigationService.setCurrentThemeInReduxStore(this.$stateParams.source);
  }

  emitShowOutOfViewOptions = (newValue) => {
    this.$scope.$emit('showOutOfViewOptions', newValue);
  };

  emitUpdatePageMetadata = (menuItem) => {
    this.$scope.$emit('dynamicMetaDataUpdated', menuItem);
  };

  $onDestroy() {
    this.$scope.$emit('showOutOfViewOptions', false);
  }
}

module.exports = {
  template: require('./wsNavigationThirdLevel.html'),
  controllerAs: 'ctrl',
  controller: wsNavigationThirdLevel,
  bindings: {},
};
