import React from 'react';
import ThemeTile from '@UI/themeComponents/themeTile/themeTile';
import useThemeSpotlight from '@hooks/useThemeSpotlight';
import PropTypes from 'prop-types';
import { extendFiltersWithUserViewOptions, getDefaultFilters } from '@src/helpers/trainingsHelper';
import { useSelector } from 'react-redux';
import { selectUserViewOptions } from '@src/reduxStore/user/userSelectors';

const TrainingThemeSpotlight = ({ onSearch, viewModel }) => {
  const viewOptions = useSelector(selectUserViewOptions);
  const { spotlightThemes } = useThemeSpotlight();

  const spotLightSelected = async (menuItemHref) => {
    onSearch({
      ...extendFiltersWithUserViewOptions(getDefaultFilters(), viewOptions),
      menuItem: menuItemHref,
      /**
       * According to EARS, we should use the educationLevels/institutionTypes of the current user search instead of the ones from the viewOptions
       */
      mainstructuresOuTypeCombinations: viewModel.mainstructuresOuTypeCombinations
        .filter((i) => i.checked)
        .map((i) => i.key),
      /** When a theme in the spotlight is selected, all the trainings should be shown.
       * so results can't be split into pages.
       * We assume that there are not going to be more than 200 trainings linked to a theme */
      limit: 200,
    });
  };

  return (
    <section id="theme-tiles" className="accent-2" style={{ marginTop: 'unset' }}>
      {/* Check if spotlightThemes has items before rendering, check if greater than 0 to avoid that
      "a falsy left-hand side (like 0) is returned immediately" */}
      {spotlightThemes.length > 0 && (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="kov-pro__title">Professionaliseringsthema's in de kijker</div>
              <div className="divider-line" />
            </div>
          </div>
          <ul className="theme-menu">
            <div className="row">
              {spotlightThemes.map((theme, index) => (
                <ThemeTile
                  key={index}
                  onSelected={() => spotLightSelected(theme.menuItemHref)}
                  theme={theme}
                />
              ))}
            </div>
          </ul>
        </div>
      )}
    </section>
  );
};

TrainingThemeSpotlight.propTypes = {
  onSearch: PropTypes.func,
  viewModel: PropTypes.object,
};

export default TrainingThemeSpotlight;
