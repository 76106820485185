require('./wsGlobalNewsDetail.scss');
const {
  selectIsCurrentTeaserOutOfViewOptions,
  selectCurrentTeaserOrNews,
} = require('@src/reduxStore/news/newsSelectors');
const { notifyRedactiePageLoaded } = require('@src/helpers/postMessageHelper');

class wsGlobalNewsDetail {
  constructor($scope, $window, kovSentry, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.kovSentry = kovSentry;
    this.$window = $window;
    this.$ngRedux = $ngRedux;
  }

  async $onInit() {
    this.$ngRedux.connect((state) => {
      this.$scope.$emit('showOutOfViewOptions', selectIsCurrentTeaserOutOfViewOptions(state));
      return { sNew: selectCurrentTeaserOrNews(state) };
    })(this);

    this.$scope.$emit('dynamicMetaDataUpdated', this.sNew);
    this.kovSentry.endLog('load Pro');

    notifyRedactiePageLoaded();
  }
}

module.exports = {
  template: require('./wsGlobalNewsDetail.html'),
  controllerAs: 'ctrl',
  controller: wsGlobalNewsDetail,
  bindings: {
    sNew: '<',
  },
};
