import React from 'react';
import { KovImageGroup } from '@kathondvla/react-shared-components/src';
import { getAttachmentHref } from '@src/app/contentUtils';

const FaqImageGroup = ({ content }) => (
  <KovImageGroup
    item={{
      ...content,
      children: content?.children?.map((child) => {
        const href = getAttachmentHref(child?.image);
        return {
          ...child,
          image: {
            // this is necessary because of a bug in KovImageGroup, which expects image in stead of imageM for the lightbox source
            href,
          },
          imageM: {
            href,
          },
        };
      }),
    }}
  />
);

export default FaqImageGroup;
