import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useResourcePicker, KovResourcePicker } from '@kathondvla/react-shared-components/src';
import useTrainingsLocations from '@src/hooks/useTrainingsLocations';

const distances = [10, 20, 30, 40, 50];
const customStyle = {
  clearIndicator: (provided) => ({
    ...provided,
    color: '#cccccc !important',
  }),
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided) => ({
    ...provided,
    background: 'none',
    border: 0,
    boxShadow: 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    color: 'inherit',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'inherit',
  }),
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
};

const TrainingLocation = ({
  latitude,
  longitude,
  distanceInKm,
  updateDistance,
  updateGPSPositionFilters,
}) => {
  const [collapsed, setCollapsed] = useState(true);

  const { updateGPSPosition, availableLocations, selectedLocation } = useTrainingsLocations(
    updateGPSPositionFilters,
    { latitude, longitude }
  );

  const setUserGPSPosition = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude: geoLatitude, longitude: geoLongitude } = position.coords;
        updateGPSPosition({ latitude: geoLatitude.toString(), longitude: geoLongitude.toString() });
      });
    }
  };

  const { clear: clearResourcePickerSelectedValue, ...resourcePickerOptions } = useResourcePicker(
    selectedLocation,
    {
      placeholder: 'Postcode of gemeente',
      localData: availableLocations,
      multiple: false,
      onChange: (elm) => {
        updateGPSPosition(elm && elm.position);
      },
      display: (elm) => `${elm.postcode} ${elm.city}`,
      optionValue: (option) => option.key,
      filter: (option, string) => {
        if (!string || string.trim().length === 0) {
          if (selectedLocation) {
            return selectedLocation.key === option.value;
          }
        }

        return (
          option.data.postcode.includes(string.toLowerCase()) ||
          option.data.city.toLowerCase().includes(string.toLowerCase())
        );
      },
      style: customStyle,
      components: {
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      },
    }
  );

  useEffect(() => {
    if (!selectedLocation) clearResourcePickerSelectedValue();
  }, [selectedLocation]);

  const selectDistance = (value) => {
    setCollapsed(true);
    updateDistance(value);
  };

  return (
    <div className="kov-pro-nas-filter__location">
      <div className="kov-pro-nas-filter__location-search">
        <KovResourcePicker {...resourcePickerOptions} />
        <button
          onClick={setUserGPSPosition}
          type="submit"
          className="kov-pro-nas-filter__location-search-submit"
        >
          <i className="icon-kov-pro-marker"></i>
        </button>
      </div>
      <div className="kov-pro-nas-filter__location-distance">
        <div className={`btn-group bootstrap-select ${collapsed ? '' : 'show'}`}>
          <button
            type="button"
            onClick={() => setCollapsed(!collapsed)}
            className="btn dropdown-toggle btn-default"
            data-toggle="dropdown"
            role="button"
            title={`< ${distanceInKm}`}
          >
            <span className="filter-option pull-left">
              {' '}
              {distanceInKm > 50 ? '+ 50' : `< ${distanceInKm}`}
            </span>
            &nbsp;
            <span className="bs-caret">
              <span className="caret"></span>
            </span>
          </button>
          <div className={`dropdown-menu open ${collapsed ? '' : 'show'}`} role="combobox">
            <ul className="dropdown-menu inner" role="listbox" aria-expanded="false">
              <li data-original-index="0" className="disabled">
                <a
                  tabIndex="-1"
                  className=""
                  data-tokens="null"
                  role="option"
                  href="#"
                  aria-disabled="true"
                  aria-selected="false"
                >
                  <span className="text">km</span>
                  <span className="glyphicon glyphicon-ok check-mark"></span>
                </a>
              </li>
              {distances.map((dist) => (
                <li
                  className={distanceInKm === dist ? 'selected' : ''}
                  onClick={() => selectDistance(dist)}
                  key={dist}
                  data-original-index={dist / 10}
                >
                  <a
                    tabIndex={dist / 10}
                    className=""
                    data-tokens="null"
                    role="option"
                    href="#"
                    aria-disabled="false"
                    aria-selected={distanceInKm === dist}
                  >
                    <span className="text">&lt; {dist}</span>
                    <span className="glyphicon glyphicon-ok check-mark"></span>
                  </a>
                </li>
              ))}
              <li
                className={distanceInKm === 1000 ? 'selected' : ''}
                onClick={() => selectDistance(1000)}
                data-original-index="6"
              >
                <a
                  tabIndex="0"
                  className=""
                  data-tokens="null"
                  role="option"
                  aria-disabled="false"
                  aria-selected={distanceInKm === 1000}
                >
                  <span className="text">+ 50</span>
                  <span className="glyphicon glyphicon-ok check-mark"></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

TrainingLocation.propTypes = {
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  distanceInKm: PropTypes.number,
  updateDistance: PropTypes.func,
  updateGPSPositionFilters: PropTypes.func,
};

export default TrainingLocation;
