import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SearchInput from '@UI/searchInput/searchInput';
import DownloadFilters from '@components/theme/themeDownload/DownloadFilters';
import { useMediaQuery } from '@react-hook/media-query';
import Banner from '@components/sections/banner/banner';
import { stripHtml } from '@src/app/utils';
import NoResultsPlaceholder from '@UI/noResultsPlaceholder/noResultsPlaceholder';
import SidebarPortal from '@components/sections/sidebar/SidebarPortal';
import { ThemeMenu } from '@components/database/components';
import { getThemeTabs } from '@src/components/theme/templates/helpers/helpers';
import NewsSection from '../newsSection/newsSection';
import ThemeDownloadGroupList from './themeDownloadGroupList/themeDownloadGroupList';
import useDownloads from './hooks/useDownloads';

const ThemeDownload = ({ downloadPage, theme }) => {
  const isTablet = useMediaQuery('screen and (max-width: 992px)');
  const [filtersSidebarOpen, setFiltersSidebarOpen] = useState(false);

  const isStandalonePage = theme.pageType === 'DOWNLOAD_PAGE';
  const pageTabs = getThemeTabs(theme, isStandalonePage);
  const showTabs = pageTabs.length > 0;
  const showPageTitle = downloadPage.href !== theme.href;
  const pageTitle = downloadPage.title;

  const [searchTerm, setSearchTerm] = useState('');
  const { state, dispatch, showForbiddenPlaceholder } = useDownloads(downloadPage);

  const issueSearch = () => {
    dispatch({ type: 'setFilter', fieldName: 'q', payload: searchTerm });
  };

  const updateSubCurricula = (cur) => {
    dispatch({ type: 'setFilter', fieldName: 'curricula', payload: cur });
  };

  // set local searchTerm when reducer state from useDownloads is populated from url param
  useEffect(() => {
    setSearchTerm(state.filters.q);
  }, [state.filters.q]);

  return (
    <>
      <Banner menuItem={downloadPage} theme={theme} onUpdateSubCurricula={updateSubCurricula} />

      <section id="theme-page">
        {showTabs && <ThemeMenu theme={theme} items={pageTabs} current={downloadPage.href} />}
        <div className="container">
          <div className="row">
            {showPageTitle && (
              <div className="kov-pro-theme-main-content-title col-12">
                <h1>{stripHtml(pageTitle)}</h1>
              </div>
            )}
            <div className="kov-pro-theme-main-content col-12">
              <div id="xl-download">
                <div className="row">
                  {!isTablet && (
                    <DownloadFilters
                      facets={downloadPage?.facets}
                      searchTerm={searchTerm}
                      onUpdateSearchTerm={setSearchTerm}
                      onSearch={issueSearch}
                      categories={state.categories}
                      selectedCategory={state.filters.requirements}
                      onSelectCategory={(href) =>
                        dispatch({ type: 'setFilter', fieldName: 'requirements', payload: href })
                      }
                      theme={theme}
                      loadingCategories={state.loadingFilters}
                    />
                  )}
                  {isTablet &&
                    downloadPage?.facets.find((o) => o.component === 'FULL_TEXT_SEARCH') && (
                      <div className="kov-pro-filter col-12">
                        <div className="kov-pro-mini-search input-group" style={{ margin: 0 }}>
                          <SearchInput
                            placeholder="Zoek"
                            value={searchTerm}
                            onSearch={issueSearch}
                            onChange={setSearchTerm}
                            inputClassName="form-control"
                            showSearchIconAfter
                          />
                        </div>
                      </div>
                    )}
                  <div
                    className={`col-12 col-md-12 ${
                      downloadPage?.facets.find((o) =>
                        ['FULL_TEXT_SEARCH', 'SELECT_FROM_REFERENCE_FRAME'].includes(o.component)
                      )
                        ? 'col-lg-9'
                        : 'col-lg-12'
                    }`}
                  >
                    {isTablet && (
                      <div className="kov-pro-filter--mobile d-flex">
                        <button
                          onClick={() => setFiltersSidebarOpen((prev) => !prev)}
                          className="kov-pro-btn kov-pro-btn--dark kov-pro-btn__icon--left kov-pro-btn--inverted kov-pro-btn--filter kov-pro-btn--block"
                        >
                          <i className="icon-kov-pro-filter" />
                          Filter
                          {state.numFiltersApplied > 0 && (
                            <span className="filter-counter">{state.numFiltersApplied}</span>
                          )}
                        </button>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-12 kov-pro-xl-downloadlist">
                        <ThemeDownloadGroupList
                          downloadGroups={state.results}
                          showForbiddenPlaceholder={showForbiddenPlaceholder}
                          color={theme.root.color}
                        />
                        {!state.loading && state.results.length === 0 && <NoResultsPlaceholder />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {theme.referenceFrameItem && (
          <NewsSection
            sReferenceFrame={theme.referenceFrameItem}
            sThemePath={theme.pagePath}
            locationPath={window.location.path}
          />
        )}
      </section>

      {isTablet && (
        <SidebarPortal open={filtersSidebarOpen} onClose={() => setFiltersSidebarOpen(false)}>
          <DownloadFilters
            facets={downloadPage?.facets}
            searchTerm={state.filters.q}
            onUpdateSearchTerm={setSearchTerm}
            onSearch={() => {
              issueSearch();
              setFiltersSidebarOpen(false);
            }}
            categories={state.categories}
            selectedCategory={state.filters.requirements}
            onSelectCategory={(href) =>
              dispatch({ type: 'setFilter', fieldName: 'requirements', payload: href })
            }
            theme={theme}
            loadingCategories={state.loadingFilters}
          />
        </SidebarPortal>
      )}
    </>
  );
};

ThemeDownload.propTypes = {
  downloadPage: PropTypes.object,
  theme: PropTypes.object,
};

export default ThemeDownload;
