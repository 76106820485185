import React from 'react';
import PropTypes from 'prop-types';

import ThemeContactCard from './themeContactsCard/themeContactCard';

const ThemeContacts = ({ contacts, contactCardClass = 'col-12 col-sm-12 col-md-6 col-lg-4' }) => (
  <section id="contacts" className="light">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="kov-pro-section-title">
            <h1>
              <i className="icon-kov-pro-contactpersonen" />
              Contact
            </h1>
          </div>
        </div>
      </div>

      <div className="row">
        {contacts.map((person, index) => (
          <div key={index} className={contactCardClass}>
            <ThemeContactCard person={person} />
          </div>
        ))}
      </div>
    </div>
  </section>
);

ThemeContacts.propTypes = {
  contacts: PropTypes.array,
  contactCardClass: PropTypes.string,
};

export default ThemeContacts;
