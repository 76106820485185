const { selectNavigationLevelSubTab } = require('@src/reduxStore/navigation/navigationSelectors');
const { navigationActions } = require('@src/reduxStore/navigation/navigationSlice');
const { setUserStoredSelectedTabHref } = require('@src/helpers/navigationHelper');
const store = require('@src/reduxStore/store.js').default;

class wsNavigationSecondLevel {
  constructor($scope, $element, $location, navigationService, $ngRedux, $stateParams) {
    'ngInject';

    this.$scope = $scope;
    this.$element = $element;
    // used also in the template
    this.$location = $location;
    this.navigationService = navigationService;
    this.$ngRedux = $ngRedux;
    this.$stateParams = $stateParams;
  }

  $onInit() {
    this.navigationService.setCurrentThemeInReduxStore(this.sMenu.href);

    this.$element.append(
      `<style> .tabbable-line > .nav-tabs > li > a.active:after {border-bottom-color: ${this.sMenu.color};} </style>`
    );
    this.$element.append(
      `<style> .tabbable-line > .nav-tabs > li > a:after {border-bottom-color: ${this.sMenu.color};} </style>`
    );

    this.initializeSubtab(this.sMenu);
  }

  emitShowOutOfViewOptions = (newValue) => {
    this.$scope.$emit('showOutOfViewOptions', newValue);
  };

  emitUpdatePageMetadata = (menuItem) => {
    this.$scope.$emit('dynamicMetaDataUpdated', menuItem);
  };

  /**
   * This function manages everything related with the subtabs (in the context when the navigation level has the property multipleAlternativesMenu set to true)
   * @param {*} menuItem
   */
  initializeSubtab = (menuItem) => {
    // We get the "initial subtab" from the state
    const navigationLevelSubTab = selectNavigationLevelSubTab(store.getState(), menuItem.href);

    // if we have a subtab selected but we have none in the url we add it
    if (navigationLevelSubTab && !this.$stateParams.tab)
      history.replaceState(
        {},
        '',
        `${window.location.pathname}?tab=${navigationLevelSubTab.title}`
      );

    // this line is used to setup the right tab title to the url when its different from the selected one
    // this usually happens when you have a selected tab and then you modify the viewOptions and select some options that make the tab be not visible
    if (navigationLevelSubTab && this.$stateParams.tab !== navigationLevelSubTab.title) {
      this.$location.search('tab', navigationLevelSubTab.title);
      history.replaceState(
        {},
        '',
        `${window.location.pathname}?tab=${navigationLevelSubTab.title}`
      );
    }

    // this is used to setup the rigth tab when the user goes back / forward in the browser
    this.handleChange = (event) => {
      // it is very important for this method to use "this.$stateParams.tab" because this will be set once and potentially triggered in multiple occasions in time, so it needs to use the updated tab value on the url
      const tabHref = menuItem.children.find((tab) => tab.title === this.$stateParams.tab)?.href;
      // updating the value on the store
      this.$ngRedux.dispatch(navigationActions.setCurrentSubTabHref(tabHref));
      // updating the value on the localStorage (for persistance when closing the browser)
      setUserStoredSelectedTabHref(tabHref);
    };

    window.addEventListener('popstate', this.handleChange);
  };

  onSelectTab = (tab, forceApply = false) => {
    this.$location.search('tab', tab.title);
    if (forceApply) {
      this.$scope.$apply();
    }
  };

  $onDestroy() {
    window.removeEventListener('popstate', this.handleChange);
    this.$scope.$emit('showOutOfViewOptions', false);
    this.$location.search('tab', null);
  }
}

module.exports = {
  template: require('./wsNavigationSecondLevel.html'),
  controllerAs: 'ctrl',
  controller: wsNavigationSecondLevel,
  bindings: {
    sMenu: '<',
  },
};
