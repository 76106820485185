/**
 * This defines your own module for this application. If you have other dependencies
 * on other modules, add them here.
 *
 * Remark that we get the modulename from the window.vskoConfiguration object (see
 * config/constants.js), which makes it easier to update the modulename in one place !
 *
 * This configuration will be added to the application module as a constant also, so it can be
 * used 'the angular way' later on in your modules, controllers, directives (both the OAuth module
 * and the RemoteErrorLogger need some info from the 'configuration' ! )
 */

// sri clients

require('@src/css/framework/bootstrap.css');
require('@src/css/style.scss');
require('@src/css/custom.scss');

const angular = require('angular');
const settings = require('@src/config/settings');

const basicConfig = {
  name: 'api',
  baseUrl: settings.apisAndUrls.api,
};

const legacyConfig = {
  name: 'sriClient',
  baseUrl: settings.apisAndUrls.cachedApi,
};

// we need to inject the standalone-cookies modal dynamically because
// the src will be different depending on the environment
const headLastElement = document.head.children[document.head.children.length - 1];
const standaloneCookiesModalScript = document.createElement('script');
standaloneCookiesModalScript.id = 'standaloneCookiesModal';
standaloneCookiesModalScript.src = settings.cookies.standaloneModalWidgetEndpoint;
standaloneCookiesModalScript.type = 'module';
document.head.insertBefore(standaloneCookiesModalScript, headLastElement);

const Sentry = require('@sentry/browser');
const kovSentry = require('./kovSentry');

let modules = [];

// Node Modules
require('angular-sanitize');
require('angular-loading-bar');
require('@uirouter/angularjs');
require('angular-md5');
require('ui-bootstrap4/src/modal');
require('ui-bootstrap4/src/dropdown/index-nocss');
require('ui-bootstrap4/src/progressbar');
require('@src/services');
require('ng-meta');
require('@kathondvla/curriculum-manager');
require('ng-redux');

// Custom Modules

modules = [
  ...modules,
  'ng',
  'angular-loading-bar',
  'ui.router',
  'angular-md5',
  'ui.bootstrap.module.modal',
  'ui.bootstrap.module.dropdown',
  'ui.bootstrap.module.progressbar',
  'services',
  'ng-sri-client', // to make curriculumManager work
  'curriculumManager',
  'ngMeta',
  'ngRedux',
  require('@kathondvla/sri-client/ng-sri-client')([basicConfig, legacyConfig]), // to make curriculumManager work
];

if (settings.logging.sentry.enabled) {
  modules.push('ngSentry');
}

// create the module
const app = angular.module('VskoApplication', modules);

app.constant('kovSentry', kovSentry);

app.service('$notification', [
  function () {
    const that = {};

    that.error = (message) => {
      console.error(message);
    };

    that.warning = (message) => {
      console.warn(message);
    };

    that.success = (message) => {
      console.log(message);
    };

    that.info = (message) => {
      console.log(message);
    };

    return that;
  },
]);

module.exports = app;
