const jsSearch = require('js-search');

const themeColors = {
  PURPLE: '#ae2081',
  BLUE: '#4cbcc5',
  YELLOW: '#a8af37',
  ORANGE: '#ec7d23',
};

module.exports = {
  offsetFromTop: 150,
  elmYPosition(eID) {
    const elm = document.getElementById(eID);
    let position;
    if (!elm) {
      // console.warn(`${eID} is not found`);
    } else {
      position = elm.offsetTop;
      let node = elm;
      while (node.offsetParent && node.offsetParent !== document.body) {
        node = node.offsetParent;
        position += node.offsetTop;
      }
    }
    return position;
  },
  elmYEndPosition(eID) {
    const elm = document.getElementById(eID);
    let position;
    if (!elm) {
      // console.warn(`${eID} is not found`);
    } else {
      position = elm.offsetTop + elm.offsetHeight;
      let node = elm;
      while (node.offsetParent && node.offsetParent !== document.body) {
        node = node.offsetParent;
        position += node.offsetTop;
      }
    }
    return position;
  },

  arrayToMap(array) {
    const map = new Map(array.map((i) => [i.$$meta.permalink, i]));
    return map;
  },

  arrayToObject(array) {
    return array.reduce((acc, curr) => {
      acc[curr.$$meta.permalink] = curr;
      return acc;
    }, {});
  },

  flattenTreeToMap(tree, onlyVisible = true) {
    const flatMap = new Map();
    function addItems(items) {
      items.forEach((item) => {
        flatMap.set(item.href, item);
        if (item.children) {
          addItems(item.children.filter((child) => !onlyVisible || child.visible));
        }
        // @TODO: add children: [] if children is undefined
        // We don't add it now, because there might be checks on (item.children) which expects false.
        // The behaviour of those checks would change if it receives []
      });
    }

    if (tree && (!onlyVisible || tree.visible)) {
      flatMap.set(tree.href, tree);
      if (tree.children) {
        addItems(tree.children.filter((child) => !onlyVisible || child.visible));
      }
      // @TODO: add children: [] if children is undefined
      // We don't add it now, because there might be checks on (item.children) which expects false.
      // The behaviour of those checks would change if it receives []
    }
    return flatMap;
  },
  flattenTree(tree, onlyVisible = true) {
    const flatMap = [];
    function addItems(items) {
      items.forEach((item) => {
        flatMap.push(item);
        if (item.children) {
          addItems(item.children.filter((child) => !onlyVisible || child.visible));
        }
        // @TODO: add children: [] if children is undefined
        // We don't add it now, because there might be checks on (item.children) which expects false.
        // The behaviour of those checks would change if it receives []
      });
    }

    if (tree && (!onlyVisible || tree.visible)) {
      flatMap.push(tree);
      if (tree.children) {
        addItems(tree.children.filter((child) => !onlyVisible || child.visible));
      }
      // @TODO: add children: [] if children is undefined
      // We don't add it now, because there might be checks on (item.children) which expects false.
      // The behaviour of those checks would change if it receives []
    }
    return flatMap;
  },

  filterItemsByRequirements(items, filter) {
    if (filter.array.length === 0) {
      return items;
    }
    return items.filter(
      (item) =>
        item.requirements &&
        item.requirements.some((requirement) => filter.array.includes(requirement))
    );
  },

  filterItemsByKeyword(items, filter) {
    if (filter.searchIndex && filter.q) {
      return items.filter((item) =>
        filter.searchIndex
          .search(filter.q)
          .map((matchedItem) => matchedItem.href)
          .includes(item.href)
      );
    }
    return items;
  },

  filterItems(items, filters) {
    return filters.reduce((filteredItems, filter) => {
      if (filter.type === 'REQUIREMENTS') {
        return module.exports.filterItemsByRequirements(filteredItems, filter);
      }
      if (filter.type === 'FULL_TEXT') {
        return module.exports.filterItemsByKeyword(filteredItems, filter);
      }
      return items;
    }, items);
  },

  createThemeSearchIndex(item) {
    const searchIndex = new jsSearch.Search('href');
    function addItemsToIndex(items, index, rootHref) {
      items.forEach((child) => {
        let rootHrefToPass;
        if (!rootHref) {
          rootHrefToPass = child.href;
        } else {
          rootHrefToPass = rootHref;
        }
        if (child.title) {
          index.addDocument({ href: rootHrefToPass, content: child.title });
        }
        if (child.description) {
          index.addDocument({ href: rootHrefToPass, content: child.description });
        }
        if (child.$$html) {
          index.addDocument({ href: rootHrefToPass, content: child.$$html });
        }
        if (child.children) {
          addItemsToIndex(child.children, index, rootHrefToPass);
        }
      });
    }

    try {
      if (item.children) {
        addItemsToIndex(item.children, searchIndex);
        searchIndex.addIndex(['content']);
      }
      if (item.type === 'TEASER') {
        searchIndex.addIndex(['title']);
        searchIndex.addIndex(['description']);
      }

      return searchIndex;
    } catch (e) {
      console.error('create index failed', e);
    }
  },

  hrefsUnder(curriculumManager, root) {
    root.$$hrefsUnder = [root.$$meta.permalink];
    if (root.$$relationsTo) {
      const itemsUnder = root.$$relationsTo
        .filter((o) => o.$$expanded.relationtype === 'IS_PART_OF')
        .map((o) => curriculumManager.getByHrefNoPromise(o.$$expanded.from.href));

      itemsUnder.forEach((item) => {
        if (item) {
          root.$$hrefsUnder.push(item.$$meta.permalink);
          root.$$hrefsUnder = [
            ...root.$$hrefsUnder,
            ...module.exports.hrefsUnder(curriculumManager, item),
          ];
        }
      });
    }
    return root.$$hrefsUnder;
  },

  isACrawler() {
    const botPattern =
      '(prerender|googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)';
    const re = new RegExp(botPattern, 'i');
    const { userAgent } = navigator;
    return re.test(userAgent);
  },

  stripHtml(html) {
    if (!html?.length) {
      return '';
    }
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  },

  getKOVAccentIdByThemeColor(themeColor) {
    let kovAccentId = '';

    switch (themeColor) {
      case themeColors.PURPLE:
        kovAccentId = 'kov-accent-1';
        break;
      case themeColors.BLUE:
        kovAccentId = 'kov-accent-2';
        break;
      case themeColors.YELLOW:
        kovAccentId = 'kov-accent-3';
        break;
      case themeColors.ORANGE:
        kovAccentId = 'kov-accent-4';
        break;
      default:
        kovAccentId = 'kov-accent-1';
    }

    return kovAccentId;
  },

  getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  },
};
