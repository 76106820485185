import React, { useMemo } from 'react';
import { useResourcePicker, KovResourcePicker } from '@kathondvla/react-shared-components/src';
import Icon from '@UI/icon/icon';
import customStyle from './customStyle';

const customTemplate = (option) => (
  <>
    {option.icon && <Icon icon={option.icon} style={{ fill: option.color }} />}
    <span>{option.title}</span>
  </>
);

const MenuItemSelector = ({
  level,
  options,
  selected,
  onSelected,
  optionTemplate = customTemplate,
}) => {
  // We needed to extend the options and selected options to make them include a property "key". This is mandatory because we are using native React "Select" component and it needs it.
  // We also memoize the options and the selectedOption to avoid create new objects in every component re-render (it was causing an error)

  const memoizedOptions = useMemo(
    () =>
      options.map((opt) => ({
        ...opt,
        key: opt.key || opt.href,
      })),
    [options]
  );

  const memoizedSelectedOpt = useMemo(
    () => ({
      ...selected,
      key: selected.key || selected.href,
    }),
    [selected]
  );

  const { clear, ...menuItemPicker } = useResourcePicker(memoizedSelectedOpt, {
    localData: memoizedOptions,
    multiple: false,
    clearable: false,
    optionTemplate,
    onChange: (selectedMenuItem) => onSelected(level, selectedMenuItem),
    style: customStyle,
    menuShouldScrollIntoView: false,
    className: 'bootstrap-select',
  });
  return <KovResourcePicker {...menuItemPicker} />;
};

export default MenuItemSelector;
