import { getCurrentProTheme } from '@src/reduxStore/theme/themeSelectors';
import store from '@src/reduxStore/store';
import { checkIfOutOfViewOptions } from '@src/helpers/contextFilterHelper';
import { selectUserViewOptions } from '@src/reduxStore/user/userSelectors';
import { CURRICULUM_PAGE, THEMATIC_WEB_PAGE_TYPES, TYPE_FILTER_MAP } from './constants';

export const transformViewOptionsToParams = (location, viewOptions) => {
  const userViewOptions = selectUserViewOptions(store.getState());
  const outOfViewOptions = checkIfOutOfViewOptions(location, userViewOptions);

  const mainstructuresOuTypeCombinations = viewOptions.EDUCATION_LEVELS.filter(
    (educationLevel) => (location && outOfViewOptions) || educationLevel.checked
  )
    .map((educationLevel) => educationLevel.mainstructuresOuTypeCombination)
    .join(',');

  const coverage = viewOptions.REGIONS.filter(
    (region) => (location && outOfViewOptions) || region.checked
  )
    .map((region) => region.href)
    .join(',');

  return {
    mainstructuresOuTypeCombinations,
    coverage,
  };
};

export const transformUIFiltersToParams = (filters) => {
  const params = {};
  const typesFilter = new Set();
  filters.types.forEach((type) => {
    TYPE_FILTER_MAP[type]?.searchTypes.forEach(typesFilter.add, typesFilter);
  });
  params.types = Array.from(typesFilter);
  if (
    !filters.types.includes('NEWS') &&
    (filters.types.includes('CURRICULA') || filters.types.includes('PRO_PAGES'))
  ) {
    params.proWebPageTypes = THEMATIC_WEB_PAGE_TYPES;
  }
  if (
    filters.types.includes('NEWS') &&
    !filters.types.includes('CURRICULA') &&
    !filters.types.includes('PRO_PAGES')
  ) {
    params.proWebPageTypes = ['NEWS_ITEM', 'TEASER'];
  }
  if (filters.types.includes('CURRICULA') && !filters.types.includes('PRO_PAGES')) {
    params.thematicInfoOfRoot = CURRICULUM_PAGE;
  }
  if (!filters.types.includes('CURRICULA') && filters.types.includes('PRO_PAGES')) {
    params.notThematicInfoOfRoot = CURRICULUM_PAGE;
  }
  // if (filters.types.some((t) => t.key === 'TRAININGS')) {
  //   params['enrollmentPeriod.startDateBefore'] = getNow();
  // }
  if (filters.types.includes('NEWS')) {
    params['callToAction.types'] = 'EXTERNAL,NONE';
  }
  if (filters.types.includes('JOB_OFFERS')) {
    params.wwwWebPageTypes = 'JOB_OFFER';
  }
  const isPreview =
    window.sessionStorage.getItem('preview') === 'true' || window.location.search.preview;
  if (!isPreview && filters.types.some((type) => type !== 'PERSONS' && type !== 'TRAININGS')) {
    params.issuedOn = new Date().toISOString();
  }
  if (filters.searchOnlyWithinTheme) {
    const currentTheme = getCurrentProTheme(store.getState());
    if (currentTheme) {
      params.themePageRootOrThemeRoot = `${currentTheme.href};${
        currentTheme.referenceFrameItem && currentTheme.referenceFrameItem.length > 0
          ? currentTheme.referenceFrameItem.map((ref) => ref.href).join(',')
          : ''
      }`;
    } else {
      console.warn(
        '[use search api hook] The scope filter is a themeHref that is not in the store:',
        filters.searchOnlyWithinTheme
      );
    }
  }
  if (filters?.curricula) {
    params.themeRoots = filters.curricula;
  }
  return params;
};
