import React from 'react';
import PropTypes from 'prop-types';
import { stripHtml } from '@src/app/utils';
import { dateToString } from '@src/helpers/utils';
import ApplicabilityLabels from '@UI/applicabilityLabels/ApplicabilityLabels';
import Compile from '@kathondvla/react-shared-components/src/components/compiler/Compiler';
import ThemeBlogCategories from '../themeBlogFilters/themeBlogCategories/themeBlogCategories';
import { getBlogExcerpt } from '../helpers/themeBlogHelpers';

const ThemeBlogItems = ({
  blogItems = [],
  listType = 'detailedList',
  color,
  onCategorySelected,
  filters,
}) => {
  const styleTag = `.kov-pro-blogitem__info a:hover {color: ${color} !important}`;
  const shortList = listType === 'shortList';

  const urlParams = new URLSearchParams(filters);

  return (
    <div className="kov-pro-blogitem--list">
      {blogItems.map((blogItem) => {
        const forwardLink = `${blogItem.pagePath || blogItem.href}?${urlParams.toString()}`;

        return (
          <div
            key={blogItem.key}
            className={shortList ? 'kov-pro-blogitem col-12' : 'kov-pro-blogitem'}
          >
            <div className="row" style={{ minWidth: '100%' }}>
              <a
                className={`kov-pro-blogitem__image ${
                  shortList ? 'col-4 col-sm-3 col-md-2 col-lg-4' : 'col-12 col-sm-5 col-md-4'
                }`}
                href={forwardLink}
              >
                {blogItem.thumbImageM && (
                  <div
                    className="kov-pro-blogitem__holder"
                    style={{ background: `url('${blogItem.thumbImageM.href}')` }}
                  />
                )}
                {!blogItem.thumbImageM && <div className="kov-pro-blogitem__holder"></div>}
              </a>
              <div
                className={`kov-pro-blogitem__info ${
                  shortList ? 'col-8 col-sm-9 col-md-10 col-lg-8' : 'col-12 col-sm-7 col-md-8'
                }`}
              >
                <a className="kov-pro-blogitem__info-title" href={forwardLink}>
                  <h4>{stripHtml(blogItem.title)}</h4>
                </a>

                {!shortList && (
                  <div className="kov-pro-blogitem__info-excerpt">
                    <Compile text={getBlogExcerpt(blogItem)} />
                  </div>
                )}

                {!shortList && blogItem.categories?.length > 0 && (
                  <ThemeBlogCategories
                    categories={blogItem.categories}
                    onCategorySelected={onCategorySelected}
                  />
                )}

                <div className="kov-pro-blogitem__specs row">
                  {shortList && blogItem.categories?.length > 0 && (
                    <div className="col-12">
                      <ThemeBlogCategories
                        categories={blogItem.categories}
                        onCategorySelected={onCategorySelected}
                      />
                    </div>
                  )}

                  {!shortList && blogItem.tags?.length > 0 && (
                    <ApplicabilityLabels labels={blogItem.tags} />
                  )}
                  <div className="kov-pro-blogitem__specs-date col-12">
                    {dateToString(blogItem.created)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <style
        dangerouslySetInnerHTML={{
          __html: styleTag,
        }}
      />
    </div>
  );
};

ThemeBlogItems.propTypes = {
  blogItems: PropTypes.array,
  listType: PropTypes.string,
  color: PropTypes.string,
  onCategorySelected: PropTypes.func,
  filters: PropTypes.object,
};

export default ThemeBlogItems;
