class wsThemeDetail {
  constructor($scope, $stateParams, $state) {
    'ngInject';

    this.$scope = $scope;
    this.$stateParams = $stateParams;
    this.$state = $state;
  }

  openCookieModal = () => {
    this.$scope.$emit('appHome:cookies');
    this.$scope.$apply();
  };

  updateMetaData = (page) => {
    this.$scope.$emit('dynamicMetaDataUpdated', page);
    this.$scope.$applyAsync();
  };

  transitionTo = (target, targetParams, options) => {
    this.$state.transitionTo(target, targetParams, options);
  };
}

export default {
  template: require('./wsThemeDetail.html'),
  controllerAs: 'ctrl',
  controller: wsThemeDetail,
  bindings: {
    sTheme: '<',
    sSource: '<',
  },
};
