import { getSitemapItemFromSourceOrPath } from '@src/services/sitemapService';
import { SEARCH_API_LIMIT_MAX } from '@src/components/constants';
import { searchApi, uncachedSriClient } from '@src/app/apiConfig';
import { getAngularService } from '@src/module/reactMigrationUtils/angular-react-helper';
import { getTrainings } from '@src/reduxStore/trainings/trainingsData';
import { getHrefDescendantsByType } from '../content/contentDataAccess';

const sriCommonUtils = require('@kathondvla/sri-client/common-utils');
const utils = require('@src/app/utils');

export const getResponsibilities = async (persons, shouldBeFiltered, viewOptions) => {
  const searchParameters = {
    hrefs: persons,
    types: 'person',
    expand: 'full',
    coverage: shouldBeFiltered
      ? viewOptions.REGIONS.filter((e) => e.checked).map((e) => e.href)
      : null,
    mainstructuresOuTypeCombinations: shouldBeFiltered
      ? viewOptions.EDUCATION_LEVELS.filter((e) => e.checked).map(
          (e) => e.mainstructuresOuTypeCombination
        )
      : null,
    limit: SEARCH_API_LIMIT_MAX,
  };
  let responsibilities = {
    results: [],
    $$meta: {
      count: 0,
    },
  };

  const stringUrl = sriCommonUtils.parametersToString('/search', searchParameters);
  if (stringUrl.length > 1000) {
    responsibilities = await searchApi.post(
      '/search/payload',
      {
        href: stringUrl,
      },
      { timeout: 1000 }
    );
  } else {
    responsibilities = await searchApi.getRaw('/search', searchParameters, { timeout: 1000 });
  }

  return responsibilities;
};

export const getDetails = async (vskoPersonHrefs) =>
  uncachedSriClient.getAll('/contactdetails', {
    persons: vskoPersonHrefs,
    types: 'PHONE_OFFICE,PHONE_MOBILE_OFFICE,EMAILADDRESS_PRIMARY',
  });

export const getPersonsMap = async (vskoPersonHrefs) =>
  uncachedSriClient.getAllHrefs(
    vskoPersonHrefs,
    {
      omit: 'dateOfBirth,registrationNumber',
      expand: 'FULL',
    },
    {
      inBatch: '/persons/batch',
      asMap: true,
    }
  );

/**
 * This method gets the themeRootItem and extends it with a couple of extra properties that only the rootItem needs to have (referenceFrameItem and hasTrainings)
 * @param {*} themeRootItem
 */
export const getExtendedThemeRootItem = async (themeRootItem) => {
  const [referenceFrameItem, hasTrainings] = await Promise.all([
    getAngularService('referenceFrameService').getReferencedItem(themeRootItem),
    themeRootItem.themes?.length > 0 &&
      (await getTrainings(
        {
          limit: 10,
          orderby: 'issued.startDate',
        },
        themeRootItem.themes
      ).then((resp) => resp.trainingsList?.length > 0)),
    getAngularService('navigationService').getNavigationTreeAsync(),
  ]);

  return {
    ...themeRootItem,
    referenceFrameItem,
    hasTrainings,
  };
};

export const buildThemeItemList = async (themeHref, userAccess) => {
  const sitemapElement = getSitemapItemFromSourceOrPath(themeHref);
  const themeRootHref = sitemapElement && sitemapElement.rootHref;

  if (themeRootHref) {
    const themeItems = await getHrefDescendantsByType(
      themeRootHref,
      [
        'STRUCTURED_DOCUMENT',
        'UNSTRUCTURED_DOCUMENT',
        'SECTION',
        'PARAGRAPH',
        'IMAGE',
        'SUMMARY',
        'FAQ',
        'FAQ_GROUP',
        'LEGAL',
        'LINK_GROUP',
        'REFERENCE',
        'QUOTE',
        'ATTACHMENTS_GROUP',
        'ATTACHMENT',
        'IMAGE_GROUP',
        'VIDEO',
        'REFERENCE_GROUP',
        'EXAMPLE',
      ],
      userAccess
    );

    const items = utils.arrayToObject(themeItems);

    return {
      ...items,
      [themeRootHref]: await getExtendedThemeRootItem(items[themeRootHref]),
    };
  }
  return null;
};
