import React from 'react';
import PropTypes from 'prop-types';
import {
  KovContentBlock,
  KovContentBlockProvider,
} from '@kathondvla/react-shared-components/src/components/contentBlock';

import { defaultColor } from '@src/config/settings';

import ContentBlockAttachments from '../contentBlockAttachments/contentBlockAttachments';
import ContentBlockTitle from '../contentBlockTitle/contentBlockTitle';
import ContentBlockParagraph from '../contentBlockParagraph/contentBlockParagraph';
import ContentBlockReferences from '../contentBlockReferences/contentBlockReferences';
import ContentBlockCollapsed from '../contentBlockCollapsed/contentBlockCollapsed';
import ContentBlockVideo from '../contentBlockVideo/contentBlockVideo';
import getCompilerOptions from '../helpers/sanitizeOptions';
import ContentBlockQuote from '../contentBlockQuote/contentBlockQuote';
import ContentBlockSummary from '../contentBlockSummary/contentBlockSummary';

const EmptyComponent = () => <></>;

const ContentBlock = ({
  item,
  color = defaultColor,
  showCollapsed = false,
  hideAttachments = false,
  openCookieModal,
}) => {
  const styleTag = `
    a.theme-content-link {
      background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff), linear-gradient(${color}, ${color}) !important;
      background-size: .05em 1px,.05em 1px,1px 1px !important;
      background-repeat: no-repeat,no-repeat,repeat-x !important;
      background-position: 0 95%,100% 95%,0 95% !important;
    }
    #news-page .kov-title {
      color:#34495e !important;
    }
    a.theme-content-link:hover {
      background: none !important;
      color: ${color} !important;
    }
    .kov-pro-theme-content .media-wrapper .photo-gallery a {
      height: auto;
    }
    `;

  const customComponents = {
    attachments: hideAttachments ? EmptyComponent : ContentBlockAttachments,
    paragraph: ContentBlockParagraph,
    referenceGroup: ContentBlockReferences,
    example: ContentBlockReferences,
    collapsed: ContentBlockCollapsed,
    video: ContentBlockVideo,
    quote: ContentBlockQuote,
    summary: ContentBlockSummary,
    title: ContentBlockTitle,
  };

  const customFuncs = {
    showCollapsed: (i) => {
      // If this function returns true the KovContentBlock will ignore the children of the item which its being rendered and will not render its children
      const lowImportanceSections = i.type === 'SECTION' && i.importance === 'LOW';
      const exampleTypeNodes = i.type === 'EXAMPLE';
      return lowImportanceSections || exampleTypeNodes;
    },
    openCookieModal,
  };
  return (
    <>
      <KovContentBlockProvider
        color={color}
        customComponents={customComponents}
        customFuncs={customFuncs}
        compilerOptions={getCompilerOptions()}
        allTitlesH1={false}
      >
        <KovContentBlock item={item} showCollapsed={showCollapsed} />
      </KovContentBlockProvider>
      <style dangerouslySetInnerHTML={{ __html: styleTag }} />
    </>
  );
};

ContentBlock.propTypes = {
  item: PropTypes.object,
  color: PropTypes.string,
  showCollapsed: PropTypes.bool,
  hideAttachments: PropTypes.bool,
  openCookieModal: PropTypes.func,
};

export default ContentBlock;
