import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import useBreadcrumbs from '@hooks/useBreadcrumbs';
import SubAtomicThemeSelector from '@src/reactComponents/UI/subAtomicThemeSelector/subAtomicThemeSelector';
import BannerNewsletterSubscribe from '@components/sections/banner/bannerNewsletterSubscribe';
import { themeActions } from '@src/reduxStore/theme/themeSlice';
import { stripHtml } from '@src/app/utils';
import excelDocumentIcon from '@src/assets/images/kov-pro-file-excel.svg';
import NavigationBreadcrumbs from '../../navigation/navigationBreadcrumbs/navigationBreadcrumbs';
import Icon from '../../UI/icon/icon';

const bannerImageClass = {
  FALLBACK_MENU_LEVEL_2: 'kov-theme-banner-2022--bottom',
  trainings: 'kov-theme-banner-2022--bottom',
  news: 'kov-theme-banner-2022--bottom',
  search: 'kov-theme-banner-2022--bottom',
  tab: 'kov-theme-banner-2022--bottom',
  FALLBACK_MENU_LEVEL_3: 'kov-theme-banner-2022--top',
};

const printablePageTypes = [
  'THEME_DETAIL',
  'THEME_DETAIL_ROOT',
  'SHARED_MINI_DATABASE_ITEM',
  'MINI_DATABASE_ITEM',
  'BLOG_ITEM',
  'STRUCTURED_DOCUMENT',
  'TEASER',
];

/**
 * This method is used to get the theme href for the given menuItem. The theme could be on the item itself or in the parent (for items with "multiple alternative menus")
 * It will always return an array of length 1
 * @param {*} menuItem
 * @returns {Array<*>} (length one)
 */
const getMenuItemThemeHrefs = (menuItem) => {
  const menuItemTheme =
    menuItem.theme || (menuItem.parent?.multipleAlternativeMenus && menuItem.parent?.theme);
  if (!menuItemTheme?.isChannel) return [];

  return menuItemTheme.$$meta.permalink;
};

const Banner = ({
  menuItem,
  theme,
  onUpdateSubCurricula,
  subAtomicSelectorDisabled = false,
  exportTrainingsFeature = {},
}) => {
  const [value, setValue] = useState(0);
  const [subatomicThemes, setSubatomicThemes] = useState([]);
  const breadcrumbs = useBreadcrumbs(menuItem, theme, value);
  const dispatch = useDispatch();

  const curriculumThemes = useMemo(() => {
    const currThemes = theme?.referenceFrameItem?.filter(
      (items) => items.type === 'CURRICULUM_THEME'
    );
    return currThemes && currThemes.length > 0 ? currThemes : [];
  }, [theme]);

  const curriculumThemesHrefs = (
    subatomicThemes.length > 0 ? subatomicThemes : curriculumThemes
  ).map((i) => i.$$meta.permalink);

  const menuItemThemeHrefs = getMenuItemThemeHrefs(menuItem);

  useEffect(() => {
    if (curriculumThemes.length) {
      const subCurriculumThemes = curriculumThemes.filter(
        (currTheme) => currTheme.isSubCurriculumTheme
      );
      if (subCurriculumThemes.length) setSubatomicThemes(subCurriculumThemes);
    }
  }, [curriculumThemes]);

  useEffect(() => {
    let isCancelled = false;

    const reloadBreadcrumbsHandler = () => {
      if (!isCancelled) setValue(value + 1);
    };

    window.addEventListener('reloadBreadcrumbs', reloadBreadcrumbsHandler);

    return () => {
      isCancelled = true;
      window.removeEventListener('reloadBreadcrumbs', reloadBreadcrumbsHandler);
    };
  }, [value]);

  const searchTitle = useCallback(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const searchParam = urlParams.get('q');
    return searchParam && searchParam.length > 0 ? `Zoekresultaten voor: ${searchParam}` : 'Zoeken';
  }, []);

  const pageTitle = useMemo(() => {
    if (menuItem) {
      switch (menuItem.websiteType) {
        case 'search':
          return searchTitle();
        case 'news':
          return theme && theme.root ? theme.root.title : 'Nieuws';
        case 'database':
          return theme && theme.root
            ? theme.root.title
            : breadcrumbs[breadcrumbs.length - 2]?.breadcrumbTitle;
        default:
          return theme && theme.root
            ? theme.root.title
            : breadcrumbs[breadcrumbs.length - 1]?.breadcrumbTitle;
      }
    }
    return null;
  }, [breadcrumbs, menuItem, searchTitle, theme]);

  useEffect(() => {
    if (!curriculumThemes.length) {
      // clear subcurricula when navigation between a page with subcurricula to a page without
      dispatch(themeActions.setCurricula([]));
    }
  }, [curriculumThemes, dispatch]);

  const showBannerNewsletterSubcribe =
    Boolean(curriculumThemesHrefs.length) || Boolean(menuItemThemeHrefs.length);

  return (
    <>
      <section id="banner">
        <div
          className={`kov-theme-banner-2022 ${
            theme ? '' : bannerImageClass[menuItem?.websiteType] || ''
          }`}
        >
          <div className="container">
            <div className="kov-theme-banner-2022__wrapper">
              <div className="kov-theme-banner-2022__title">
                <NavigationBreadcrumbs breadcrumbItems={breadcrumbs} />
                <div className="kov-pro-page-title">
                  {breadcrumbs.length >= 1 && (
                    <a
                      className="theme"
                      href={breadcrumbs[0].breadcrumbPath}
                      style={{ marginTop: 'auto', marginBottom: 'auto' }}
                    >
                      {breadcrumbs[0].icon && (
                        <Icon
                          icon={breadcrumbs[0].icon}
                          style={{
                            fill: breadcrumbs[0].color,
                            width: '30px',
                            height: '100%', // Needed for Safari
                          }}
                        />
                      )}
                    </a>
                  )}
                  {pageTitle && (
                    <div
                      className="kov-pro-page-title__title"
                      style={menuItem.websiteType === 'trainings' ? { display: 'flex' } : {}}
                    >
                      <h1 dangerouslySetInnerHTML={{ __html: stripHtml(pageTitle) }} />
                      {menuItem.websiteType === 'trainings' &&
                        exportTrainingsFeature?.isAllowed && (
                          <button
                            disabled={!exportTrainingsFeature.isEnabled}
                            title={
                              !exportTrainingsFeature.isEnabled
                                ? 'Perform a search to be able to download the results'
                                : 'Download the search results'
                            }
                            className="kov-pro-btn kov-pro-btn--round kov-pro-btn--excel-download"
                            onClick={exportTrainingsFeature.exportTrainingsHandler}
                          >
                            <img src={excelDocumentIcon} />
                          </button>
                        )}
                    </div>
                  )}
                </div>
              </div>
              <div className="kov-theme-banner-2022__buttons">
                <div className="kov-popup">
                  {showBannerNewsletterSubcribe && (
                    <BannerNewsletterSubscribe
                      curriculumThemesHrefs={curriculumThemesHrefs}
                      menuItemThemeHrefs={menuItemThemeHrefs}
                    />
                  )}
                </div>
                {printablePageTypes.includes(
                  menuItem?.extra?.pageType || menuItem?.pageType || menuItem?.extra?.type
                ) && (
                  <button className="kov-pro-btn kov-pro-btn--round" onClick={() => window.print()}>
                    <i className="icon-kov-pro-print" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {subatomicThemes.length > 1 && (
        <SubAtomicThemeSelector
          subatomicThemes={subatomicThemes}
          onUpdateSubCurricula={onUpdateSubCurricula}
          subAtomicSelectorDisabled={subAtomicSelectorDisabled}
        />
      )}
    </>
  );
};

Banner.propTypes = {
  menuItem: PropTypes.object,
  theme: PropTypes.object,
  onUpdateSubCurricula: PropTypes.func,
  subAtomicSelectorDisabled: PropTypes.bool,
  exportTrainingsFeature: PropTypes.object,
};
export default Banner;
