const app = require('../app/app');

app.component('appRoot', require('./appRoot').default);

/* secure content */
app.component('wsNotLoggedIn', require('./secureAccess/wsNotLoggedIn'));
app.component('wsNotPermitted', require('./secureAccess/wsNotPermitted'));

app.component('wsSearch', require('./wsSearch'));

app.component('ws404', require('./errorPages/ws404'));
app.component('ws500', require('./errorPages/ws500'));

/* Navigation */
app.component('wsNavigationFirstLevel', require('./navigation/wsNavigationFirstLevel'));
app.component('wsNavigationSecondLevel', require('./navigation/wsNavigationSecondLevel'));
app.component('wsNavigationThirdLevel', require('./navigation/wsNavigationThirdLevel'));

/* Theme */
app.component('wsTheme', require('./theme/wsTheme'));
app.component('wsThemeHome1', require('./theme/templates/wsThemeHome1'));
app.component('wsThemeHome2', require('./theme/templates/wsThemeHome2'));
app.component('wsThemeHome3', require('./theme/templates/wsThemeHome3'));
app.component('wsThemeDetail', require('./theme/templates/wsThemeDetail').default);
app.component('wsThemeFaq', require('./theme/templates/wsThemeFaq').default);

app.component('wsDatabase', require('./wsDatabase'));
app.component('wsDatabaseItem', require('./wsDatabase/wsDatabaseItem'));

app.component('wsThemeDownload', require('./theme/templates/wsThemeDownload'));

app.component('wsThemeBlog', require('./theme/templates/wsThemeBlog'));
app.component('wsThemeBlogItem', require('./theme/templates/wsThemeBlogItem'));

app.component('wsThemeTrainings', require('./theme/templates/wsThemeTrainings').default);

app.component('wsTemp', require('./theme/templates/wsTemp').default);

app.component('wsThemeStatic', require('./theme/templates/wsThemeStatic'));

/* News Databank */
app.component('wsGlobalNewsDatabank', require('./news/wsGlobalNewsDatabank').default);
app.component('wsGlobalNewsDetail', require('./news/wsGlobalNewsDetail'));
app.component('wsLocalNewsDatabank', require('./news/wsLocalNewsDatabank').default);

/* Trainings */
app.component('wsTrainings', require('./wsTrainings'));

app.component('wsVakkenpagina', require('./wsVakkenpagina').default);
