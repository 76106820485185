import { useCallback, useState, useEffect } from 'react';
import { getAPILocations, getLocation } from '@src/reduxStore/trainings/trainingsData';

const useTrainingsLocations = (updateLocation, gpsPosition = {}) => {
  const { latitude, longitude } = gpsPosition;
  const [availableLocations, setAvailableLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  useEffect(() => {
    let isCancelled = false;

    getAPILocations().then((APILocations) => {
      if (!isCancelled) setAvailableLocations(APILocations);
    });

    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    let isCancelled = false;

    const hasCoordinates = Boolean(latitude && longitude);

    if (!hasCoordinates) {
      setSelectedLocation((prevState) => {
        if (prevState) {
          setSelectedLocation(null);
        }
      });
    } else if (availableLocations.length > 0) {
      const fetchOpenStreetMapMatches = async () => {
        const location = await getLocation(availableLocations, { latitude, longitude });
        if (!isCancelled) setSelectedLocation(location);
      };

      fetchOpenStreetMapMatches();
    }

    return () => {
      isCancelled = true;
    };
  }, [latitude, longitude, availableLocations]);

  const updateGPSPosition = useCallback(
    async (position) => {
      if (!position) {
        setSelectedLocation(null);
        updateLocation(null, null);
        return;
      }

      updateLocation(position.latitude, position.longitude);

      const location = await getLocation(availableLocations, position);
      setSelectedLocation(location);
    },
    [availableLocations]
  );

  return {
    updateGPSPosition,
    availableLocations,
    selectedLocation,
  };
};
export default useTrainingsLocations;
