import React from 'react';
import { useSelector } from 'react-redux';
import NoResultsPlaceholder from '@UI/noResultsPlaceholder/noResultsPlaceholder';
import ProgressBar from '@UI/progressBar/progressBar';
import LoadMore from '@UI/loadMore/loadMore';
import PropTypes from 'prop-types';
import {
  getTrainingsList,
  getTrainingsCount,
  getIsLoading,
} from '@src/reduxStore/trainings/trainingsSelectors';
import TrainingResult from './trainingResult/trainingResult';
import LoadingResultsPlaceholder from '../../loadingResultsPlaceholder/loadingResultsPlaceholder';

const TrainingResults = ({ onLoadMore }) => {
  const loading = useSelector(getIsLoading);
  const trainings = useSelector(getTrainingsList);
  const trainingsCount = useSelector(getTrainingsCount);

  const loadMore = () => {
    // offset is equal to current in view count to ensure first load limit is used
    onLoadMore(trainings.length);
  };

  return (
    <>
      {loading ? (
        <LoadingResultsPlaceholder />
      ) : (
        <div className="kov-pro-training-wrapper">
          {trainings.length > 0 ? (
            <ul className="kov-pro-training-list">
              {trainings.map((training) => (
                <TrainingResult key={training.key} training={training} />
              ))}
            </ul>
          ) : (
            <NoResultsPlaceholder />
          )}
          {trainings.length < trainingsCount && (
            <div className="kov-pro-load-more col-12">
              <ProgressBar length={trainings.length} count={trainingsCount} />
              <LoadMore onLoadMore={loadMore} actionButtonText={'Laad meer nascholingen'} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

TrainingResults.propTypes = {
  onLoadMore: PropTypes.func,
};

export default TrainingResults;
