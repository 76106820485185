import KovToolTip from '@UI/kovToolTip/kovToolTip';
import React from 'react';

const RadioOption = ({ option, onChange }) => (
  <li>
    <label className="filter-item">
      <div dangerouslySetInnerHTML={option.title}></div>
      <input
        key={option.key}
        checked={option.checked}
        disabled={option.disabled}
        onChange={() => onChange(option.key)}
        type="radio"
      ></input>
      <span className="checkround"></span>
    </label>
    {option.description && <KovToolTip contents={option.description} />}
  </li>
);

export default RadioOption;
