import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  getAttachmentType,
  isShieldedContent,
  calculateSize,
  getNameAndExtension,
} from '@src/app/contentUtils';
import AttachmentTypeIcon from '@components/theme/themeDownloadSection/attachmentTypeIcon/attachmentTypeIcon';
import Compile from '@kathondvla/react-shared-components/src/components/compiler/Compiler';
import defaultDownloadImage from '@src/assets/images/placeholder-picture2.png';
import { stripHtml } from '@src/app/utils';
import { goToPath } from '@src/helpers/utils';
import { useDispatch } from 'react-redux';
import uiState from '@src/reduxStore/ui/uiState';
import trackPiwikEvent from '@src/reduxStore/analytics/analyticsThunks';
import useDownloadGroup from '../hooks/useDownloadGroup';
import { downloadFile } from '../helpers/themeDownloadHelpers';
import ThemeDownloadShielded from '../themeDownloadShielded/themeDownloadShielded';

import './themeDownloadGroup.scss';

const ThemeDownloadGroup = ({ downloadGroup }) => {
  const stateDispatch = useDispatch();
  const openSidebar = () => stateDispatch(uiState.actions.setIsSideBarOpen(true));

  const [shieldedContent, setShieldedContent] = useState();
  const [open, setOpen] = useState(false);
  const { user, mainDownload, secondaryDownloads, forbiddenSecondaryAttachment, forbiddenMessage } =
    useDownloadGroup(downloadGroup);

  useEffect(() => {
    const displayProtectedImage = async (imageId, imageUrl) => {
      // Fetch the image.
      const response = await fetch(imageUrl, { method: 'GET' });

      if (response.ok) {
        // Create an object URL from the data.
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);

        // Update the source of the image.
        const imageElement = document.getElementById(imageId);

        if (imageElement) {
          imageElement.src = objectUrl;
          imageElement.onload = () => URL.revokeObjectURL(objectUrl);
        }
      }
    };

    if (mainDownload) {
      const { descendantsAccessRights, key, thumbImageM } = mainDownload;

      if (thumbImageM) {
        if (isShieldedContent(descendantsAccessRights)) {
          setShieldedContent(true);
          displayProtectedImage(`${key}_groupImage`, mainDownload.thumbImageM.href);
        } else {
          setShieldedContent(false);
        }
      }
    }
  }, [mainDownload]);

  const handleForbiddenAttachmentClick = (forbiddenAttachmentActions) => {
    if (!user) {
      openSidebar();
    } else if (forbiddenAttachmentActions.link) {
      goToPath(forbiddenAttachmentActions.link);
    }
  };

  const trackDownload = (file) => {
    stateDispatch(trackPiwikEvent('download_file', getNameAndExtension(file.name)));
  };

  return mainDownload ? (
    <div className="kov-pro-xl-downloadlist-item">
      <div className="row">
        <div className="dl-item-image d-none d-md-block col-12 col-md-3 col-lg-3">
          <a
            href={mainDownload?.attachment?.href}
            target="_blank"
            download
            rel="noreferrer"
            onClick={() => trackDownload(mainDownload?.attachment)}
          >
            {!mainDownload?.thumbImageM && <img src={defaultDownloadImage} />}

            {mainDownload?.thumbImageM && shieldedContent && (
              <img id={`${mainDownload.key}_groupImage`} />
            )}

            {mainDownload?.thumbImageM && shieldedContent === false && (
              <img src={mainDownload.thumbImageM.href} />
            )}
            <i className="icon-kov-pro-download" />
          </a>
        </div>
        <div className="col-12 col-lg-9 col-md-9">
          <div className="dl-item-title col-12">
            <a
              href={mainDownload?.attachment?.href}
              target="_blank"
              download
              rel="noreferrer"
              onClick={() => trackDownload(mainDownload?.attachment)}
            >
              <h3>{stripHtml(mainDownload?.title)}</h3>
              <i className="icon-kov-pro-download pull-right" />
            </a>
          </div>
          <div className="dl-item-excerpt col-12">
            <p>
              <Compile text={mainDownload?.description} />
            </p>
          </div>
          {mainDownload?.attachment && (
            <div className="dl-item-specs col-12">
              <div>
                <AttachmentTypeIcon attachment={mainDownload.attachment} />
                <span>{calculateSize(mainDownload.attachment?.size)}</span>
                {mainDownload.contact && mainDownload.contact.length > 0 && (
                  <div className="dl-item-specs__author">{mainDownload.contact[0]}</div>
                )}
              </div>
            </div>
          )}
          {secondaryDownloads?.length > 0 && (
            <div className="dl-item-attachments col-12">
              <a
                className={!open ? 'collapsed' : ''}
                data-toggle="collapse"
                role="button"
                aria-expanded="true"
                onClick={() => setOpen(!open)}
              >
                <span>
                  {`${secondaryDownloads.length} ${
                    secondaryDownloads.length === 1 ? 'bijlage' : 'bijlages'
                  }`}
                </span>
              </a>
              {
                <div
                  id="attachments-1"
                  className={`dl-item-attachments-details collapse ${open ? 'show' : ''}`}
                >
                  <div className="kov-pro-download">
                    <table className="table">
                      <tbody>
                        {forbiddenSecondaryAttachment && (
                          <tr className="kov-pro-download__shielded">
                            <td className="kov-pro-download-title" colSpan="3">
                              {forbiddenMessage.text}
                            </td>
                            <td className="kov-pro-download-login" colSpan="2">
                              <a
                                onClick={() => handleForbiddenAttachmentClick(forbiddenMessage)}
                                style={{ textDecoration: 'underline' }}
                              >
                                {forbiddenMessage.callToAction}
                              </a>
                            </td>
                          </tr>
                        )}
                        {secondaryDownloads.map((file) => (
                          <tr
                            key={file.key}
                            className="kov-pro-download-extra-downloads"
                            onClick={() => {
                              downloadFile(file);
                              trackDownload(file.attachment);
                            }}
                          >
                            <td className="kov-pro-download-thumb">
                              <AttachmentTypeIcon attachment={file.attachment} />
                            </td>
                            <td className="kov-pro-download-title">{stripHtml(file.title)}</td>
                            {file.tags && file.tags[0] && (
                              <td className="kov-pro-download-type">
                                {getAttachmentType(file.tags[0])}
                              </td>
                            )}
                            <td className="kov-pro-download-file-size">
                              {calculateSize(file.attachment.size)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              }
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    /* show this if view options are out of range */
    <ThemeDownloadShielded />
  );
};

ThemeDownloadGroup.propTypes = {
  downloadGroup: PropTypes.object,
};

export default ThemeDownloadGroup;
