import React from 'react';
import PropTypes from 'prop-types';
import { Compiler } from '@kathondvla/react-shared-components/src/components';
import getCompilerOptions from '../helpers/sanitizeOptions';

const ContentBlockSummary = ({ item }) =>
  item.visible && (
    <div className="kov-summary theme-content-synthese theme-content-synthese--importance-medium">
      <h2>
        <Compiler text={item.title} options={getCompilerOptions()} />
      </h2>
      {(item?.items || item?.children)?.map((child, i) => (
        <p key={i}>
          <Compiler text={child.$$html} options={getCompilerOptions()} />
        </p>
      ))}
    </div>
  );

ContentBlockSummary.propTypes = {
  item: PropTypes.object,
  children: PropTypes.array,
};

export default ContentBlockSummary;
