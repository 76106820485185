import React from 'react';
import TrainingOrder from '@UI/trainings/trainingOrder/trainingOrder';
import TrainingResults from '@UI/trainings/trainingResults/trainingResults';
import PropType from 'prop-types';
import GlobalTrainingSelectedTheme from '../globalTrainingSelectedTheme/globalTrainingSelectedTheme';

const GlobalTrainingResults = ({
  onSetOrder,
  onLoadMore,
  viewModel,
  collapseFiltersBoxHandler,
  themeFiltersRef,
}) => (
  <section id="training-results">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="kov-pro-training-results-wrapper">
            <h2 className="kov-pro__title">
              Gevonden voor jouw zoekopdracht{' '}
              <GlobalTrainingSelectedTheme
                viewModel={viewModel}
                collapseFiltersBoxHandler={collapseFiltersBoxHandler}
                themeFiltersRef={themeFiltersRef}
              />
            </h2>

            <TrainingOrder sorts={viewModel.orderby} onSetOrder={onSetOrder} />
          </div>
          <div className="divider-line"></div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <TrainingResults onLoadMore={onLoadMore} />
        </div>
      </div>
    </div>
  </section>
);

GlobalTrainingResults.prototype = {
  onSetOrder: PropType.func,
  onLoadMore: PropType.func,
  viewModel: PropType.object,
  collapseFiltersBoxHandler: PropType.func,
  themeFiltersRef: PropType.object,
};

export default GlobalTrainingResults;
