import React from 'react';
import SearchInput from '@UI/searchInput/searchInput';
import Loader from '@UI/loader/loader';
import { stripHtml } from '@src/app/utils';

const DownloadFilters = ({
  facets,
  searchTerm,
  onUpdateSearchTerm,
  onSearch,
  categories,
  selectedCategory,
  onSelectCategory,
  theme,
  loadingCategories,
}) => (
  <>
    <div className="col-12 col-lg-3">
      {facets.find((o) => o.component === 'FULL_TEXT_SEARCH') && (
        <div className="kov-pro-mini-search kov-pro-mini-search--downloads input-group">
          <SearchInput
            placeholder="Zoek"
            value={searchTerm}
            onChange={onUpdateSearchTerm}
            onSearch={onSearch}
            inputClassName="form-control"
          />
          <span className="input-group-btn">
            <button className="btn btn-default" type="button" onClick={onSearch}>
              <i className="icon-kov-pro-search" />
            </button>
          </span>
        </div>
      )}
      {facets.find((o) => o.component === 'SELECT_FROM_REFERENCE_FRAME') && (
        <>
          <ul className="kov-pro-xl-downloadlist-nav">
            {categories.map((category) => (
              <li
                key={category.href}
                className={selectedCategory === category.href ? 'active' : ''}
                onClick={() => onSelectCategory(category.href)}
              >
                <a
                  style={{
                    color: selectedCategory === category.href ? theme.root.color : '',
                  }}
                >
                  {stripHtml(category.title)}
                </a>
              </li>
            ))}
          </ul>
          {loadingCategories && <Loader />}
        </>
      )}
    </div>
  </>
);

export default DownloadFilters;
