import React, { useMemo } from 'react';
import NoResultsPlaceholder from '@UI/noResultsPlaceholder/noResultsPlaceholder';
import { useMediaQuery } from '@react-hook/media-query';
import SearchInput from '@UI/searchInput/searchInput';
import FaqItem from './FaqItem';

const FaqResults = ({
  searchTerm,
  onUpdateSearchTerm,
  onSearch,
  searchResults,
  sTheme,
  numFiltersApplied,
  setFiltersSidebarOpen,
}) => {
  const scrollToAnchor = useMemo(() => new URL(document.location).searchParams.get('scrollTo'), []);

  const isTablet = useMediaQuery('screen and (max-width: 992px)');

  return (
    <div className="kov-pro-theme-content kov-pro-content col-12 col-md-12 col-lg-9">
      {isTablet && (
        <div className="kov-pro-filter--mobile d-flex d-lg-none">
          <div
            className="kov-pro-mini-search kov-pro-mini-search--faq input-group"
            style={{ marginBottom: '15px' }}
          >
            <SearchInput
              placeholder="Zoek"
              value={searchTerm}
              onChange={onUpdateSearchTerm}
              onSearch={onSearch}
              inputClassName="form-control"
              showSearchIconAfter
            />
          </div>
          <button
            onClick={() => setFiltersSidebarOpen((prev) => !prev)}
            className="kov-pro-btn kov-pro-btn--dark kov-pro-btn__icon--left kov-pro-btn--inverted kov-pro-btn--filter kov-pro-btn--block"
          >
            <i className="icon-kov-pro-filter" />
            Filter
            {numFiltersApplied > 0 && <span className="filter-counter">{numFiltersApplied}</span>}
          </button>
        </div>
      )}
      <div className="kov-pro-faq__wrapper">
        {searchResults !== null && searchResults.length > 0 ? (
          searchResults.map((result) => (
            <FaqItem
              key={result.key}
              item={result}
              sColor={sTheme?.root?.color}
              scrollToAnchor={scrollToAnchor}
            />
          ))
        ) : (
          <NoResultsPlaceholder styleOverride={{ marginTop: 0 }} />
        )}
      </div>
    </div>
  );
};

export default FaqResults;
