// const SIZE_OF_SEARCH_RESULT_BATCH = 30;

// require('./wsSearch.scss');
class wsSearch {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
  }

  $onInit() {
    this.$scope.$emit('dynamicMetaDataUpdated', { title: 'Zoeken', description: null });
  }
}

module.exports = {
  template: require('./wsSearch.html'),
  controllerAs: 'ctrl',
  controller: wsSearch,
};
