import React from 'react';
import { generateHashForScrollTo } from '@helpers/utils';
import PropTypes from 'prop-types';
import Icon from '@UI/icon/icon';

const NavigationFirstLevelItem = ({ menuItem }) => {
  const keyHash = generateHashForScrollTo(menuItem.key);

  const styleTag = `#${keyHash} a:hover:before {background: ${menuItem.color} !important;}`;

  return (
    <li className="service-menu-tile" id={keyHash}>
      <a href={menuItem.path}>
        <div className="service-menu-tile__icon">
          {menuItem.icon && (
            <Icon
              icon={menuItem.icon}
              style={{
                fill: menuItem.color,
                width: '28px',
              }}
            />
          )}
        </div>
        <div
          className="service-menu-tile__title"
          dangerouslySetInnerHTML={{ __html: menuItem.title }}
        />
      </a>
      <style dangerouslySetInnerHTML={{ __html: styleTag }} />
    </li>
  );
};

NavigationFirstLevelItem.propTypes = {
  menuItem: PropTypes.object,
};

export default NavigationFirstLevelItem;
