import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ApplicabilityLabels from '@UI/applicabilityLabels/ApplicabilityLabels';
import { stripHtml } from '@src/app/utils';
import { getAngularService } from '@src/module/reactMigrationUtils/angular-react-helper';
import { useSelector } from 'react-redux';
import themeSelectors from '@src/reduxStore/theme/themeSelectors';
import trainingCardImage from '@src/assets/images/professionaliseringstile.jpg';
import ThemeCardButtons from '@components/theme/themeCard/themeCardButtons';

require('./themeCard.scss');

const ThemeCard = ({ item, color, description }) => {
  const navigationService = getAngularService('navigationService');
  /* Setting the hover color on the title */
  const [themeColor, setThemeColor] = useState(color);
  const subCurriculaHrefs = useSelector(themeSelectors.getCurricula);

  const parentHasCurriculumTheme = item.parent?.referenceFrameItem?.find(
    (i) => i.type === 'CURRICULUM_THEME'
  );

  item.tags = [...(item.regionTags || []), ...(item.educationLevelTags || [])];
  const styleTag = `.kov-pro-hometile:hover .kov-pro-hometile__title  {color: ${themeColor} !important;}`;

  useEffect(() => {
    window.addEventListener('changeThemeColor', (e) => setThemeColor(e.detail));
    return () => {
      window.removeEventListener('changeThemeColor', (e) => setThemeColor(e.detail));
    };
  }, []);

  return (
    <>
      <a
        href={navigationService.getPathWithSubCurricula(item, subCurriculaHrefs)}
        target={item.isPathExternal ? '_blank' : undefined}
        rel="noreferrer"
      >
        <div
          className={`kov-pro-hometile kov-pro-hometile--thumb ${
            item.isPathExternal ? 'kov-pro-hometile--external' : ''
          }`}
        >
          <div className="kov-pro-hometile__img-wrapper">
            {item.thumbImage && (
              <div
                className="kov-pro-hometile__img-wrapper-bg"
                style={{ background: `url(${item.thumbImageM.href})` }}
              />
            )}
            {!item.thumbImage && (
              <div
                className="kov-pro-hometile__img-wrapper-bg"
                style={
                  item.pageType === 'THEME_TRAINING'
                    ? { background: `url(${trainingCardImage})` }
                    : {}
                }
              />
            )}
            <div className="col-12">
              <ApplicabilityLabels labels={item.tags} />
            </div>
          </div>
          <div className="kov-pro-hometile__body equal-height">
            <h4 className="kov-pro-hometile__title">{stripHtml(item.title)}</h4>
            {description && (
              <p className="kov-pro-hometile__excerpt">{stripHtml(item.shortDescription)}</p>
            )}
            {item.pageType === 'CURRICULUM_PAGE' && parentHasCurriculumTheme && (
              <ThemeCardButtons item={item} />
            )}
          </div>
        </div>
      </a>
      <style dangerouslySetInnerHTML={{ __html: styleTag }} />
    </>
  );
};

ThemeCard.propTypes = {
  item: PropTypes.object,
  color: PropTypes.string,
  description: PropTypes.bool,
};

export default ThemeCard;
