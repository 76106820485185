class wsTrainings {
  constructor($scope, routerService) {
    'ngInject';

    this.$scope = $scope;
    this.routerService = routerService;
  }

  async $onInit() {
    this.searching = false;
    this.filters = {};
    this.$scope.$emit('setPageSettings');
    this.$scope.$emit('dynamicMetaDataUpdated', {
      title: 'Professionalisering',
      description: null,
    });
  }

  $onDestroy() {
    this.$scope.$emit('setPageSettings');
    this.$scope.$emit('dynamicMetaDataUpdated');
  }
}

module.exports = {
  template: require('./wsTrainings.html'),
  controllerAs: 'ctrl',
  controller: wsTrainings,
};
