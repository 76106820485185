import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import ThemeDetailMenu from '@src/reactComponents/theme/themeDetailMenu/themeDetailMenu';
import Banner from '@src/reactComponents/sections/banner/banner';
import settings from '@src/config/settings';
import * as utils from '@src/app/utils';
import { selectUser } from '@src/reduxStore/user/userSelectors';
import { useSelector } from 'react-redux';
import kovSentry from '@src/app/kovSentry';
import MailDropDown from './components/MailDropDown';
import MijnLink from './components/MijnLink';
import LoginBlock from './LoginBlock';

const NotPermittedPage = ({ theme, current }) => {
  const userDetails = useSelector(selectUser);

  const page = useMemo(
    () => utils.flattenTree(theme).find((child) => child.pagePath === window.location.pathname),
    [theme]
  );
  const securityLevel = useMemo(() => {
    if (page) {
      return page.securityStatus;
    }
    const pageType = window.location.pathname;
    switch (pageType) {
      case '/geen-toegang':
        return 'afgeschermd2';
      case '/geen-toegang-strikt':
        return 'afgeschermd3';
      case '/Informatie-voor-besturen':
        return 'afgeschermd4';
      default:
        return 'afgeschermd2';
    }
  }, [page]);

  useEffect(() => {
    kovSentry.endLog('load Pro');
  }, []);

  return (
    <>
      <Banner menuItem={{ websiteType: 'forbidden' }} theme={theme} />
      <section id="shielded-content">
        <div className="shielded-content">
          {theme && theme.securityStatus === 'permitted' && (
            <>
              <div className="container">
                <div className="row">
                  <div className={'kov-theme-title col-12'}>
                    <ThemeDetailMenu
                      sItems={theme.children}
                      sColor={theme.root.color}
                      sCurrent={current}
                    />
                  </div>
                </div>
              </div>
              <div className="divider-line" />
            </>
          )}
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className="desktop-menu__service-title">
                  <i className="icon-kov-pro-profile" /> AFGESCHERMDE INHOUD
                </h1>
              </div>

              <div className="col-12">
                <div className="shielded-content__notification">
                  Je hebt geen toegang tot deze pagina of bijlage. Wat kun je doen?
                </div>
              </div>

              {!userDetails && <LoginBlock />}

              {userDetails && (
                <div className="col-12 col-md-6 offset-md-3">
                  <div className="divider-line" />

                  <div className="kov-pro-login">
                    <div className="kov-pro-login__title">Aangemeld als...</div>
                    <div className="kov-profile">
                      {userDetails.$$profilePicture && (
                        <a
                          href={`${settings.apisAndUrls.mijn}/#!/profiel?wijzig-profiel-foto`}
                          className="kov-profile__image"
                          style={{
                            background: `url('${settings.apisAndUrls.api}${userDetails.$$profilePicture.$$expanded.href}') center center no-repeat`,
                          }}
                        />
                      )}
                      {!userDetails.$$profilePicture && (
                        <a
                          className="kov-profile__image"
                          href={`${settings.apisAndUrls.mijn}/#!/profiel?wijzig-profiel-foto`}
                          role="button"
                          id="dropdownMenuLink"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <div
                            className="kov-navbar-avatar kov-navbar-avatar--no-photo"
                            style={{
                              textAlignLast: 'center',
                              lineHeight: '60px',
                            }}
                          >
                            {userDetails.initials?.[0] && (
                              <span className="kov-initials__prename">
                                {userDetails.initials[0]}
                              </span>
                            )}
                            {userDetails.initials?.[1] && (
                              <span className="kov-initials__surname">
                                {userDetails.initials[1]}
                              </span>
                            )}
                          </div>
                        </a>
                      )}
                      <div className="kov-profile__data">
                        <div className="kov-profile__name">
                          <i className="icon-kov-pro-profile" /> {userDetails.username}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="divider-line" />

                  <div className="shielded-content__options">
                    {securityLevel !== 'afgeschermd4' && (
                      <div className="shielded-content__option">
                        <span className="shielded-content__option-number">1</span>
                        <div className="shielded-content__option-content">
                          {securityLevel === 'afgeschermd2' ? (
                            <div className="shielded-content__option-content-title">
                              Vervul je <span>één van deze rollen</span> binnen een katholieke
                              onderwijsinstelling, CLB of een toegetreden organisatie?
                            </div>
                          ) : (
                            <div className="shielded-content__option-content-title">
                              Vervul je <span>één van deze rollen</span> binnen een katholieke
                              onderwijsinstelling of een toegetreden organisatie?
                            </div>
                          )}
                          <div className="row">
                            <div className="col-12 col-md-6">
                              <ul>
                                <li>adjunct-directeur</li>
                                <li>algemeen directeur</li>
                                <li>coördinator CVO</li>
                                <li>coördinator deeltijds onderwijs</li>
                                <li>coördinerend directeur</li>
                                <li>directeur</li>
                              </ul>
                            </div>

                            <div className="col-12 col-md-6">
                              <ul>
                                {securityLevel === 'afgeschermd2' && (
                                  <li>lid van de algemene vergadering</li>
                                )}
                                <li>stafmedewerker scholengemeenschap</li>
                                <li>technisch adviseur coördinator</li>
                                <li>voorzitter</li>
                              </ul>
                            </div>
                          </div>
                          <MijnLink />
                        </div>
                      </div>
                    )}

                    {['afgeschermd2', 'afgeschermd3'].includes(securityLevel) ? (
                      <div className="shielded-content__option">
                        <span className="shielded-content__option-number">2</span>
                        <div className="shielded-content__option-content">
                          <div className="shielded-content__option-content-title">
                            Ben je een <span>bestuurder</span> in een katholieke
                            onderwijsinstelling?
                          </div>

                          {securityLevel === 'afgeschermd2' ? (
                            <>
                              <p>
                                Bestuurders en leden van het dagelijks bestuur worden door Katholiek
                                Onderwijs Vlaanderen beheerd. Het zijn die personen die{' '}
                                <b>in het Belgisch staatsblad</b> geplubiceerd zijn.
                              </p>
                              <p>
                                Ben je wel degelijk bestuurder of lid van het dagelijks bestuur?
                              </p>
                            </>
                          ) : (
                            <>
                              <p>
                                Bestuurders worden door Katholiek Onderwijs Vlaanderen beheerd. Het
                                zijn die personen die <b>in het Belgisch staatsblad</b> gepubliceerd
                                zijn.
                              </p>
                              <p>Ben je wel degelijk bestuurder?</p>
                            </>
                          )}

                          <MailDropDown user={userDetails} />
                        </div>
                      </div>
                    ) : (
                      <div className="shielded-content__option">
                        <span className="shielded-content__option-number">1</span>
                        <div className="shielded-content__option-content">
                          <div className="shielded-content__option-content-title">
                            Heb je <span>een rol in het bestuur</span> van een katholieke
                            onderwijsinstelling?
                          </div>

                          <p>
                            <b>a.</b> Bestuurders en leden van het dagelijks bestuur worden door
                            Katholoiek Onderwijs Vlaanderen beheerd. Het zijn die personen die in
                            het Belgisch Staatsblad geplubliceerd zijn.{' '}
                            <b>Ben je wel degelijk lid van het dagelijks bestuur?</b>
                          </p>
                          <MailDropDown user={userDetails} green={true} />

                          <p style={{ marginTop: '50px' }}>
                            <b>b.</b> Vervul je <b>één van deze rollen</b> binnen het bestuur van
                            een katholieke onderwijsinstelling?
                          </p>

                          <ul>
                            <li>algemeen directeur</li>
                            <li>lid van de algemene vergadering</li>
                            <li>stafmedewerker</li>
                            <li>voorzitter</li>
                          </ul>

                          <MijnLink green={true} />
                        </div>
                      </div>
                    )}

                    {securityLevel === 'afgeschermd2' && (
                      <div className="shielded-content__option">
                        <span className="shielded-content__option-number">3</span>
                        <div className="shielded-content__option-content">
                          <div className="shielded-content__option-content-title">
                            Ben je een <span>medewerker</span> in een katholieke
                            onderwijsinstelling, CLB of een toegetreden organisatie?
                          </div>

                          <p>
                            Vraag toegang aan de beheerder van je team (directeur/bestuurder). Die
                            kan je toegang geven via het veld{' '}
                            <b>
                              "Wie krijgt bijkomend toegang tot gevoelige informatie op de website?"
                            </b>{' '}
                            op{' '}
                            <a href="https://mijn.katholiekonderwijs.vlaanderen/">
                              mijn.katholiekonderwijs.vlaanderen
                            </a>
                            .
                          </p>
                        </div>
                      </div>
                    )}

                    <div className="shielded-content__option">
                      {securityLevel === 'afgeschermd2' && (
                        <span className="shielded-content__option-number">4</span>
                      )}
                      {securityLevel === 'afgeschermd3' && (
                        <span className="shielded-content__option-number">3</span>
                      )}
                      {securityLevel === 'afgeschermd4' && (
                        <span className="shielded-content__option-number">2</span>
                      )}{' '}
                      <div className="shielded-content__option-content">
                        {securityLevel === 'afgeschermd4' && (
                          <div className="shielded-content__option-content-title">
                            Heb je <span>géén rol in een bestuur</span>?
                          </div>
                        )}
                        <p>Sorry, deze informatie is niet beschikbaar voor jou.</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

NotPermittedPage.propTypes = {
  theme: PropTypes.object,
  current: PropTypes.string,
};

export default NotPermittedPage;
