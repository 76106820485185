import React from 'react';

const LoadingResultsPlaceholder = () => (
  <div className="kov-pro-news-teaser-list">
    <div className="news-item">
      <div className="animated-background">
        <div className="background-masker title-top"></div>
        <div className="background-masker title-bottom"></div>
        <div className="background-masker line-1-top"></div>
        <div className="background-masker line-1-bottom"></div>
        <div className="background-masker line-2-top"></div>
        <div className="background-masker line-2-bottom"></div>
      </div>
    </div>

    <div className="news-item d-none d-md-block">
      <div className="animated-background">
        <div className="background-masker title-top"></div>
        <div className="background-masker title-bottom"></div>
        <div className="background-masker line-1-top"></div>
        <div className="background-masker line-1-bottom"></div>
        <div className="background-masker line-2-top"></div>
        <div className="background-masker line-2-bottom"></div>
      </div>
    </div>
  </div>
);
export default LoadingResultsPlaceholder;
