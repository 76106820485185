import React from 'react';

const TrainingEventInfo = ({ eventInfo, wrapperClass }) => {
  const showEventDate = () =>
    (!eventInfo.onDemand && eventInfo.date) ||
    (eventInfo.onDemand && eventInfo.date && eventInfo.date !== 'Te bepalen');
  const showLocationName = () =>
    (!eventInfo.onDemand && eventInfo.location) ||
    (eventInfo.location && eventInfo.location !== 'Te bepalen');

  return (
    <div className={`${wrapperClass}`}>
      {showEventDate() && (
        <div className="kov-pro-training__date">
          <i className="icon-kov-pro-calendar"></i> {eventInfo.date}
        </div>
      )}
      {showLocationName() && (
        <div className="kov-pro-training__city">
          <i className="icon-kov-pro-marker"></i> {eventInfo.location}
        </div>
      )}
      {eventInfo.onDemand && (showEventDate() || showLocationName()) && (
        <div className="kov-pro-training__request"></div>
      )}
      {eventInfo.onDemand && (
        <span>
          <i className="icon-kov-pro-next"></i> Op aanvraag
        </span>
      )}
    </div>
  );
};

export default TrainingEventInfo;
