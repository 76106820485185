import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import trackPiwikEvent from '@src/reduxStore/analytics/analyticsThunks';
import { getNameAndExtension } from '@src/app/contentUtils';

const ThemeCardButtons = ({ item }) => {
  const dispatch = useDispatch();
  // Find the attachment & link group within the children
  const attachmentGroup = item.children?.filter((i) => i.type === 'ATTACHMENTS_GROUP');
  const linkGroup = item.children?.filter((i) => i.type === 'LINK_GROUP');
  // Only show each button if there is exactly one attachemnt or link
  const attachmentHref =
    attachmentGroup.length === 1 &&
    attachmentGroup[0].children?.length === 1 &&
    attachmentGroup[0].children[0]?.attachment?.href;
  const linkHref =
    linkGroup.length === 1 &&
    linkGroup[0].children?.length === 1 &&
    linkGroup[0].children[0].links.length === 1 &&
    linkGroup[0].children[0].links[0];

  return linkHref || attachmentHref ? (
    <div className="d-flex" style={{ marginTop: '10px' }}>
      {linkHref && (
        <button
          type="button"
          className="kov-pro-btn kov-pro-btn--green kov-pro-btn--sm kov-pro-btn__icon--right"
          onClick={(e) => {
            e.preventDefault();
            window.open(linkHref, '_blank');
          }}
        >
          Leerplantool <i className="icon-kov-pro-reference" />
        </button>
      )}
      {attachmentHref && (
        <button
          type="button"
          className="kov-pro-btn kov-pro-btn--dark kov-pro-btn--inverted kov-pro-btn--sm kov-pro-btn__icon--right"
          onClick={(e) => {
            e.preventDefault();
            dispatch(
              trackPiwikEvent(
                'download_file',
                getNameAndExtension(attachmentGroup[0].children[0].attachment.name)
              )
            );
            window.open(attachmentHref, '_blank');
          }}
        >
          Download <i className="icon-kov-pro-download" />
        </button>
      )}
    </div>
  ) : null;
};

ThemeCardButtons.propTypes = {
  item: PropTypes.object,
};

export default ThemeCardButtons;
