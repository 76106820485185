import React from 'react';

const NewsLoadingAnimation = () => (
  <div className="row">
    <div className="col-12 col-md-12 col-lg-8">
      <div className="kov-pro-news-teaser-list">
        <div className="news-item">
          <div className="animated-background">
            <div className="background-masker title-top" />
            <div className="background-masker title-bottom" />
            <div className="background-masker line-1-top" />
            <div className="background-masker line-1-bottom" />
            <div className="background-masker line-2-top" />
            <div className="background-masker line-2-bottom" />
          </div>
        </div>

        <div className="news-item d-none d-md-block">
          <div className="animated-background">
            <div className="background-masker title-top" />
            <div className="background-masker title-bottom" />
            <div className="background-masker line-1-top" />
            <div className="background-masker line-1-bottom" />
            <div className="background-masker line-2-top" />
            <div className="background-masker line-2-bottom" />
          </div>
        </div>
      </div>
    </div>

    <div className="col-12 col-md-12 col-lg-4">
      <div className="kov-pro-news-teaser-list kov-pro-news-teaser-list--minified">
        <div className="news-item-mini d-none d-md-block">
          <div className="animated-background">
            <div className="background-masker line-1-top" />
            <div className="background-masker line-1-bottom" />
            <div className="background-masker line-2-top" />
            <div className="background-masker line-2-bottom" />
            <div className="background-masker line-3-top" />
            <div className="background-masker line-3-bottom" />
            <div className="background-masker line-4-top" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default NewsLoadingAnimation;
