import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Banner from '@src/reactComponents/sections/banner/banner';
import ApplicabilityLabels from '@UI/applicabilityLabels/ApplicabilityLabels';
import { goToPath } from '@src/helpers/utils';
import { getAngularService } from '@src/module/reactMigrationUtils/angular-react-helper';
import { offsetFromTop } from '@src/app/utils';
import useUrlSearchParams from '@src/hooks/useUrlSearchParams';
import { ThemeMenu } from '@components/database/components';
import useLocationContacts from '@src/hooks/useLocationContacts';
import { useSelector } from 'react-redux';
import {
  selectCurrentThemeTreeWithVisibilityExceptions,
  selectItemInCurrentThemeTreeWithSearchIndex,
} from '@src/reduxStore/theme/themeSelectors';
import ThemeAbout from '../../themeAbout/themeAbout';
import ThemeBlogCategories from '../themeBlogFilters/themeBlogCategories/themeBlogCategories';
import ThemeBlogItems from '../themeBlogItems/themeBlogItems';
import {
  getBlogItems,
  getBlogCategories,
  getFilteredBlogItems,
  addCategoriesToItems,
} from '../helpers/themeBlogHelpers';
import ThemeContacts from '../../themeContacts/themeContacts';
import ThemeDownloadSection from '../../themeDownloadSection/themeDownloadSection';
import ThemeText from '../../themeText/themeText';

const ThemeBlogItemDetails = ({ blogItemHref, dynamicMetaDataUpdated }) => {
  const theme = useSelector((_) => selectCurrentThemeTreeWithVisibilityExceptions(_, blogItemHref));

  // only blogs and blogItems have searchIndex (legacy..)
  const blogItem = useSelector((_) => selectItemInCurrentThemeTreeWithSearchIndex(_, blogItemHref));

  useEffect(() => {
    if (blogItem) {
      dynamicMetaDataUpdated(blogItem);
    }
  }, [blogItem]);

  const isStandalonePage = theme?.pageType === 'BLOG';
  const routerService = getAngularService('routerService');

  const [similarBlogItems, setSimilarBlogItems] = useState([]);
  const [urlParams, setUrlParams] = useState('');
  const [scrollToPos, setScrollToPos] = useState(null);
  const [previousBlogItem, setPreviousBlogItem] = useState();
  const [nextBlogItem, setNextBlogItem] = useState();

  const { updateUrlSearchParams } = useUrlSearchParams([
    { key: 'scrollTo', state: scrollToPos, updateState: (pos) => setScrollToPos(pos) },
  ]);

  useEffect(() => {
    if (scrollToPos) {
      setTimeout(() => {
        routerService.scrollTo(scrollToPos, offsetFromTop);
      }, 500);
      setScrollToPos(null);
    } else if (!scrollToPos) {
      updateUrlSearchParams();
    }
  }, [scrollToPos, routerService, updateUrlSearchParams]);

  const issuedDate = '';
  const blog = blogItem?.parent;

  const getSimilarBlogItems = useCallback(
    (blogItems, q, categoryHref) => {
      if (!(blog && blogItem)) {
        return;
      }
      const filteredBlogItems = getFilteredBlogItems(blog, blogItems, q, {
        href: categoryHref,
      });

      const currentIndex = filteredBlogItems.findIndex((item) => item.href === blogItem.href);
      setPreviousBlogItem(currentIndex > 0 ? filteredBlogItems[currentIndex - 1] : undefined);
      setNextBlogItem(
        currentIndex < filteredBlogItems.length - 1
          ? filteredBlogItems[currentIndex + 1]
          : undefined
      );
      setSimilarBlogItems(
        filteredBlogItems.filter((item) => blogItem.href !== item.href).slice(0, 3)
      );
    },
    [blog, blogItem]
  );

  useEffect(() => {
    const loadBlogData = async () => {
      const blogItems = getBlogItems(blog);
      const { facets } = blog;

      if (facets) {
        const hasCategories =
          facets.filter((o) => o.component === 'SELECT_FROM_REFERENCE_FRAME').length > 0;

        let categories = [];
        if (hasCategories) {
          categories = await getBlogCategories(blog);
          if (categories.length) {
            addCategoriesToItems(blogItems, categories);
          }
        }
      }
    };
    if (blog) {
      loadBlogData();
    }
  }, [blog, blogItem]);

  useEffect(() => {
    const urlParamsFromUrl = new URLSearchParams(window.location.search);
    setUrlParams(urlParamsFromUrl);
    const blogItems = getBlogItems(blog);

    getSimilarBlogItems(blogItems, urlParamsFromUrl.get('q'), urlParamsFromUrl.get('requirements'));
  }, [blog, getSimilarBlogItems]);

  const { contacts } = useLocationContacts(blogItem);
  const showContactsSection = contacts.length > 0;

  return (
    <>
      <Banner menuItem={blogItem} theme={theme} />
      <section id="theme-page">
        <div id="kov-accent-1">
          {!isStandalonePage && (
            <ThemeMenu theme={theme} items={theme.children} current={blog.href} />
          )}

          <div className="container">
            <div className="row">
              <div className="kov-pro-theme-page kov-pro-blog-page col-12">
                <div className="row">
                  <div className="kov-pro-theme-main-content kov-pro-blog-item-content col-12 col-md-12 col-lg-8">
                    <div className="row">
                      <div className="kov-pro-blog-item-info col-12">
                        <div className="kov-pro-blog-item-title">{blogItem.title}</div>
                        <div className="divider-line divider-line--accent" />
                        <div className="kov-pro-blog-item-info__specs">
                          {issuedDate && (
                            <p className="text-date-time">
                              <span className="date">
                                <i className="icon-kov-pro-calendar" />
                                {issuedDate}
                              </span>
                              <span className="author">
                                <i className="icon-kov-pro-profile" /> Naam auteur vakgroep dienst
                              </span>
                            </p>
                          )}
                          <ApplicabilityLabels labels={blogItem.tags} />
                        </div>
                      </div>
                      <div className="kov-pro-theme-content col-12">
                        {blogItem.children && (
                          <ThemeText item={blogItem} color={theme.root.color} />
                        )}
                      </div>
                      <div className="col-12">
                        <ThemeDownloadSection item={blogItem} />
                        <div className="divider-line mb0" />
                      </div>
                      {blogItem.categories?.length > 0 && (
                        <>
                          <ThemeBlogCategories
                            categories={blogItem.categories}
                            onCategorySelected={(categoryHref) =>
                              goToPath(`${blog.pagePath || blog.href}?requirements=${categoryHref}`)
                            }
                            className="kov-pro-blog-categories col-12"
                          />
                          <div className="col-12">
                            <div className="divider-line mb0" />
                          </div>
                        </>
                      )}
                      <div className="kov-pro-pagination col-12 text-center no-print">
                        <div
                          className="btn-group kov-pro-btn-group"
                          role="group"
                          aria-label="Pagination"
                        >
                          {previousBlogItem && (
                            <a
                              href={`${
                                previousBlogItem.pagePath || previousBlogItem.href
                              }?${urlParams.toString()}`}
                            >
                              <button
                                type="button"
                                className="kov-pro-btn kov-pro-btn--light kov-pro-btn--inverted kov-pro-btn__icon--left"
                              >
                                <i className="icon-kov-pro-arrow-left" />
                                <span>vorige</span>
                              </button>
                            </a>
                          )}
                          <a href={`${blog.pagePath || blog.href}?${urlParams.toString()}`}>
                            <button
                              type="button"
                              className="kov-pro-btn kov-pro-btn--light kov-pro-btn--inverted"
                            >
                              <i className="icon-kov-pro-blog-list" />
                              <span>naar overzicht</span>
                            </button>
                          </a>
                          {nextBlogItem && (
                            <a
                              href={`${
                                nextBlogItem.pagePath || nextBlogItem.href
                              }?${urlParams.toString()}`}
                            >
                              <button
                                type="button"
                                className="kov-pro-btn kov-pro-btn--light kov-pro-btn--inverted kov-pro-btn__icon--right"
                              >
                                <span>volgende</span>
                                <i className="icon-kov-pro-arrow-right" />
                              </button>
                            </a>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="divider-line" />
                      </div>
                    </div>
                  </div>

                  <div className="kov-pro-theme-side-content col-12 col-md-12 col-lg-4">
                    {similarBlogItems.length > 0 && (
                      <>
                        <h2>Verwante artikels</h2>

                        <div className="kov-pro-blog-roll">
                          <ThemeBlogItems
                            blogItems={similarBlogItems}
                            color={theme.root.color}
                            listType="shortList"
                            filters={urlParams}
                          />
                        </div>
                      </>
                    )}

                    {showContactsSection && (
                      <div className="kov-pro-blog-author row">
                        <ThemeContacts
                          contacts={contacts}
                          contactCardClass="kov-pro-contact--blog col-12 col-sm-6 col-lg-12"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ThemeAbout title="OVER DEZE BLOG" description={blog.description} />
        </div>
      </section>
    </>
  );
};

ThemeBlogItemDetails.propTypes = {
  blogItemHref: PropTypes.string,
  dynamicMetaDataUpdated: PropTypes.func,
};

export default ThemeBlogItemDetails;
