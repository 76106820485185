import { createSelector } from '@reduxjs/toolkit';
import { isEqual } from 'lodash-es';
import {
  getCoverageFromViewOptions,
  getMainstructuresOuTypeCombinationsFromViewOptions,
} from '@src/helpers/viewOptionsHelper';

const newsletterPreferencesTypesMapping = {
  '/newsletter/types/974c6b12-2b90-4d8d-a98f-67b2daab0d8b': 'curriculaNewsletter',
  '/newsletter/types/f64b33d8-dbd9-49b8-845b-4f3f2a6c2b78': 'generalNewsletter',
};

// synchronise these with the login methods of the userhelper. I think we need to delete school ware
export const loginMethods = {
  '/loginproviders/9111b767-bba3-48d2-a2e8-dcbe43399e7f': 'kathondvla',
  '/loginproviders/bd0f545a-71a0-11e9-845d-9fdda140c8d8': 'smartschool',
  '/loginproviders/e6d08737-e075-4f27-96ce-66239e10bfe0': 'google',
  '/loginproviders/1b0760e8-71a1-11e9-b9f8-43f786c5ce5d': 'office365',
};

// this selector is used to check if a user exists
export const getUserKey = (state) => state.user.userKey;
export const getIsUserKOV = (state) => state.user.isUserKOV;
export const getLoginMethod = (state) => state.user.userLoginMethod;
export const getUserAccess = (state) => state.user.userAccess;
export const getUserCurriculaSelections = (state) => state.user.curriculaSelections;

// we keep the view options in a private state, this selector allows us to get them
export const selectUserViewOptions = (state) => state?.user?.userPrivateState?.state?.viewOptions;

export const selectUserTargetGroup = createSelector(
  [(state) => state.user.userTargetGroup, getIsUserKOV],
  (userTargetGroup, isUserKOV) => {
    if (userTargetGroup !== undefined && isUserKOV !== undefined) {
      if (isUserKOV) {
        return 'medewerker';
      }
      return userTargetGroup;
    }
    // undefined on this context means we still don't have enough information to give value to it
    return undefined;
  }
);

export const selectIsUserReadyForAnalytics = createSelector(
  [
    (state) => state.user.userKey,
    (state) => state.user.userPrivateState?.state,
    (state) => state.user.userLoginMethod,
    selectUserTargetGroup,
  ],
  (userKey, privateState, userLoginMethod, userTargetGroup) => {
    if (
      userKey !== undefined &&
      privateState &&
      userLoginMethod !== undefined &&
      userTargetGroup !== undefined
    ) {
      return true;
    }
    return false;
  }
);

// the following two selectors return the aplicaability labels
export const selectSelectedRegionTags = createSelector(
  [(state) => state.user.userPrivateState?.state?.viewOptions?.REGIONS],
  (REGIONS) => REGIONS?.filter((region) => region.checked).map((region) => region.tag) || []
);

export const selectSelectedEducationLevelTags = createSelector(
  [(state) => state.user.userPrivateState?.state?.viewOptions?.EDUCATION_LEVELS],
  (EDUCATION_LEVELS) =>
    EDUCATION_LEVELS?.filter((eduLevel) => eduLevel.checked).map((eduLevel) => eduLevel.tag) || []
);

// this modal decides if the entry flow modal should be shown
export const selectShouldViewOptionsModalBeOpenSelector = createSelector(
  [(state) => state.user.userPrivateState, (state, params) => params],
  (userPrivateState, params) => {
    if (params.urlSkipViewOptions && !params.skipInitViewOptions) {
      return false;
    }
    if (params.urlSkipViewOptions || params.skipInitViewOptions === 'true') {
      return false;
    }
    if (Object.entries(userPrivateState).length) {
      const { viewOptions, entryFlowComplete } = userPrivateState.state;
      if (!viewOptions || !entryFlowComplete) return true;

      const mainstructuresOuTypeCombination =
        getMainstructuresOuTypeCombinationsFromViewOptions(viewOptions);
      const coverage = getCoverageFromViewOptions(viewOptions);
      return mainstructuresOuTypeCombination.length === 0 || coverage.length === 0;
    }
    return false;
  }
);

// this selector returns the formatted user details
export const selectUser = createSelector(
  [(state) => state.user.userDetails, (state) => state.user.userKey],
  (userDetails, userKey) => {
    if (userKey) {
      if (userDetails && userDetails.lastName) {
        const lastNameParts = userDetails.lastName.split(' ');
        return {
          ...userDetails,
          username: `${userDetails.firstName} ${userDetails.lastName}`,
          initials:
            userDetails.firstName[0].toUpperCase() +
            lastNameParts[lastNameParts.length - 1][0].toUpperCase(),
          $$profilePicture:
            userDetails.attachments &&
            userDetails.attachments.filter(
              (e) =>
                e.$$expanded.type === 'PROFILE_IMAGE' && e.$$expanded.name === 'profile-small.jpg'
            )[0],
        };
      }
      return {
        key: userKey,
        $$meta: { permalink: `/persons/${userKey}` },
      };
    }
    return null;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

// this selector checks the users newsletter preferences
export const selectUserNewsletterPreferences = createSelector(
  [(state) => state.user.userNewsletterPreferencesResponse],
  (userNewsletterPreferencesResponse) => {
    if (userNewsletterPreferencesResponse.length > 0) {
      return userNewsletterPreferencesResponse.reduce((acc, curr) => {
        const newsletterPreferenceId = newsletterPreferencesTypesMapping[curr.newsletterType.href];
        if (newsletterPreferenceId) {
          acc.set(newsletterPreferenceId, curr);
        }
        return acc;
      }, new Map());
    }
    return new Map();
  }
);
