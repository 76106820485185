import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Banner from '@src/reactComponents/sections/banner/banner';
import { stripHtml } from '@src/app/utils';
import { useSelector } from 'react-redux';
import { selectItemInCurrentThemeTree } from '@src/reduxStore/theme/themeSelectors';

/**
 * As far as we know, we don't have much "static" content, and it's mostly location
 * data ( you can see it in home > over ons en contact > contacteer ons).
 */
const ThemeStatic = ({ sTheme, source, emitUpdatePageMetadata }) => {
  const page = useSelector((_) => selectItemInCurrentThemeTree(_, source));

  // So we nicely clean up the HTML with this method...
  const decodeHTML = (html) => {
    if (!html) return undefined;
    const cleanHtml = html.replace(/<br>/g, '');
    const escapedHtml = stripHtml(cleanHtml).replace(/\.\/assets\/images/g, '/images');
    return escapedHtml;
  };

  const allChildren = sTheme.children.map((child) => {
    child.$$html = decodeHTML(child.$$html);
    return child;
  });

  const handleAccordionClick = (targetId, accordionIndex) => {
    const accordion = document.querySelectorAll('.kov-pro-route__accordion')[accordionIndex];
    const accordionTextContainers = accordion.querySelectorAll('div.collapse');
    const accordionbuttons = accordion.querySelectorAll('.btn.btn-link[data-toggle="collapse"]');

    accordionTextContainers.forEach((textContainer) => {
      const isTargetTextContainer = `#${textContainer.id}` === targetId;

      if (isTargetTextContainer) {
        textContainer.classList.toggle('show');
      } else {
        textContainer.classList.remove('show');
      }
    });

    accordionbuttons.forEach((button) => {
      const isTargetButton = button.getAttribute('data-target') === targetId;

      if (isTargetButton) {
        button.classList.toggle('collapsed');
      } else {
        button.classList.add('collapsed');
      }
    });
  };

  // We know our static data comes up with Bootstrap accordions,
  // whose behavior has to be restored from React side.
  useEffect(() => {
    const accordionButtons = new Map();
    if (allChildren) {
      const accordions = document.querySelectorAll('.kov-pro-route__accordion');
      accordions.forEach((accordion, accordionIndex) => {
        const buttons = accordion.querySelectorAll('.btn.btn-link[data-toggle="collapse"]');
        buttons.forEach((button) => {
          const targetId = button.getAttribute('data-target');
          button.addEventListener('click', () => handleAccordionClick(targetId, accordionIndex));
        });
        accordionButtons.set(accordionIndex, buttons);
      });
    }

    return () => {
      accordionButtons.forEach((accordion, accordionIndex) => {
        accordion.forEach((button) => {
          const targetId = button.getAttribute('data-target');
          button.removeEventListener('click', () => handleAccordionClick(targetId, accordionIndex));
        });
      });
    };
  }, [allChildren]);

  useEffect(() => {
    emitUpdatePageMetadata(sTheme);
  }, [sTheme]);

  return (
    <>
      <Banner menuItem={page} theme={sTheme} />
      {allChildren &&
        allChildren.map(
          (child) =>
            child.type === 'PARAGRAPH' && (
              <div key={child.key} dangerouslySetInnerHTML={{ __html: child.$$html }} />
            )
        )}
    </>
  );
};

ThemeStatic.propTypes = {
  sTheme: PropTypes.object,
  emitUpdatePageMetadata: PropTypes.func,
  source: PropTypes.string,
};

export default ThemeStatic;
