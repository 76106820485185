import { searchApi, uncachedSriClient } from '@src/app/apiConfig';
import { SEARCH_API_LIMIT_MAX } from '@src/components/constants';
import * as settings from '@src/config/settings';
import { memoize } from 'lodash-es';

const sriCommonUtils = require('@kathondvla/sri-client/common-utils');

export const getContactPhone = (availableContactMethods) => {
  const phones = [];
  const phoneOffice = availableContactMethods.find(
    (contactMethod) => contactMethod.type === 'PHONE_OFFICE'
  );
  if (phoneOffice && !phones.includes(phoneOffice.number)) {
    phones.push(phoneOffice.number);
  }

  const mobileOffice = availableContactMethods.find(
    (contactMethod) => contactMethod.type === 'PHONE_MOBILE_OFFICE'
  );
  if (mobileOffice && !phones.includes(mobileOffice.number)) {
    phones.push(mobileOffice.number);
  }

  return phones;
};

export const getContactEmail = (availableContactMethods) =>
  availableContactMethods.find((contactMethod) => contactMethod.type === 'EMAILADDRESS_PRIMARY')
    ?.value;

export const getProfilePictureHref = (personAttachments) => {
  if (personAttachments?.length > 0) {
    const picture = personAttachments.find(
      (attachment) =>
        attachment.$$expanded.type === 'PROFILE_IMAGE' && attachment.$$expanded.width === 128
    );

    if (picture) {
      return settings.apisAndUrls.api + picture.$$expanded.href;
    }
  }

  return null;
};

const getVSKOPositionFromResponsibilities = (personResponsibilities = []) => {
  const vskoResponsibility = personResponsibilities.find(
    (e) =>
      e.organisationalUnit.root &&
      e.organisationalUnit.root.permalink === '/organisations/c000eaea-c451-2590-e044-d4856467bfb8'
  );
  return vskoResponsibility ? vskoResponsibility.position.name : undefined;
};

export const getLocationContacts = memoize(
  async (contacts, shouldBeFiltered, viewOptions = {}) => {
    if (!contacts || contacts.length === 0) {
      return [];
    }

    const hardcoded = contacts.filter((person) => person.firstName);
    const persons = contacts.filter((person) => !person.firstName);

    if (persons.length > 0) {
      const searchParameters = {
        hrefs: persons,
        types: 'person',
        expand: 'full',
        coverage: shouldBeFiltered
          ? viewOptions.REGIONS.filter((e) => e.checked).map((e) => e.href)
          : null,
        mainstructuresOuTypeCombinations: shouldBeFiltered
          ? viewOptions.EDUCATION_LEVELS.filter((e) => e.checked).map(
              (e) => e.mainstructuresOuTypeCombination
            )
          : null,
        limit: SEARCH_API_LIMIT_MAX,
      };

      let responsibilities;

      const stringUrl = sriCommonUtils.parametersToString('/search', searchParameters);
      if (stringUrl.length > 1000) {
        responsibilities = await searchApi.post(
          '/search/payload',
          {
            href: stringUrl,
          },
          { timeout: 1000 }
        );
      } else {
        responsibilities = await searchApi.getRaw('/search', searchParameters, { timeout: 1000 });
      }

      const vskoPersonHrefs = responsibilities.results.map((person) => person.$$meta.permalink);

      if (vskoPersonHrefs.length > 0) {
        const [personMap, contactDetails] = await Promise.all([
          uncachedSriClient.getAllHrefs(
            vskoPersonHrefs,
            {
              omit: 'dateOfBirth,registrationNumber',
              expand: 'FULL',
            },
            {
              inBatch: '/persons/batch',
              asMap: true,
            }
          ),
          uncachedSriClient.getAll('/contactdetails', {
            persons: vskoPersonHrefs,
            types: 'PHONE_OFFICE,PHONE_MOBILE_OFFICE,EMAILADDRESS_PRIMARY',
          }),
        ]);

        contactDetails.forEach((contactDetail) => {
          const personHref = contactDetail.person.href;

          personMap[personHref] = {
            ...personMap[personHref],
            availableContactMethods: [
              ...(personMap[personHref].availableContactMethods || []),
              contactDetail,
            ],
          };
        });

        responsibilities.results.forEach((responsibility) => {
          const personHref = responsibility.$$meta.permalink;
          if (personMap[personHref]) {
            const personResponsibilities = responsibility.responsibilities;

            personMap[personHref] = {
              ...personMap[personHref],
              responsibilities: personResponsibilities,
              position: getVSKOPositionFromResponsibilities(personResponsibilities),
              show: true,
            };
          }
        });

        return Object.values(personMap)
          .map((person) => ({
            href: person.$$meta.permalink,
            firstName: person.firstName,
            lastName: person.lastName,
            phones: getContactPhone(person.availableContactMethods),
            email: getContactEmail(person.availableContactMethods),
            pictureHref: getProfilePictureHref(person.$$attachments),
            position: person.position,
            responsibilities: person.responsibilities,
            show: person.show,
          }))
          .concat(hardcoded)
          .filter((e) => e.show)
          .sort((a, b) => a.lastName.localeCompare(b.lastName));
      }
    }
    return hardcoded;
  },
  (...args) => JSON.stringify(args)
);
