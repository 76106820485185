import { extendSitemapPreviewMode } from '@src/services/sitemapService';
import { getAngularService } from 'NgMigrationUtils/angular-react-helper';
import suggestionsState from './suggestionsSlice';
import { getExtendedThemeRootItem } from '../theme/themeDataAccess';

function updateRoutings(webpages) {
  const routerService = getAngularService('routerService');
  routerService.addToExtendedWebpages(webpages);
  // we need to add the new webpages (the ones in preview mode received in the Service Worker from Redactie) to the sitemap to be able to see them on PRO
  extendSitemapPreviewMode(webpages);
}

const addSuggestions = (suggestions) => async (dispatch) => {
  console.log('REDACTIE Message', suggestions);

  updateRoutings(suggestions.webpages);

  const themeRootItem = suggestions.items.find(
    (item) => item.$$meta.permalink === suggestions.documentHref
  );

  const redactieItems = await Promise.all(
    suggestions.items.map(async (item) => {
      if (item.$$meta.permalink === themeRootItem.$$meta.permalink)
        return getExtendedThemeRootItem(themeRootItem);

      return item;
    })
  );

  dispatch(suggestionsState.actions.updateRedactieItems(redactieItems));
  // Reload after rebuilding the item tree.
  window.dispatchEvent(new CustomEvent('suggestionsloaded'));
};

export default addSuggestions;
