import React from 'react';
import MenuItemSelector from './menuItemSelector/menuItemSelector';

// When the selected node is "Vakken en leerplannen" we don't show the following levels
// Exception introduced in #3854072282 (monday ticket)
const doNotShowChildren = ['/content/4329fb23-9830-4a44-912c-bf8e0d1b2d8b'];

const MenuItemFilters = ({
  firstLevel,
  selectedMenuItems,
  onMenuItemSelected,
  getLevelOptions,
}) => {
  let options = getLevelOptions(0, firstLevel?.children);

  return (
    /** the first selector always is shown, each time a valid menu item is choosen a new selector is shown.
     * the first value in option list is the placeholder, which is used to clear the selected value.
     * option values are the children of the selected value in the proceeding selector. at most 4 selectors
     * at shown since pro navigation tree has max depth = 4 */
    <>
      {options.length > 1 && (
        <MenuItemSelector
          level={0}
          options={options}
          selected={selectedMenuItems[0] || options[0]}
          onSelected={onMenuItemSelected}
        />
      )}
      {selectedMenuItems.map((selectedMenuItem, index) => {
        options = getLevelOptions(index + 1, selectedMenuItem.children);

        const showNextLevel =
          options.length > 1 && !doNotShowChildren.includes(selectedMenuItem.href);

        return (
          showNextLevel && (
            <MenuItemSelector
              key={selectedMenuItem.href}
              level={index + 1}
              options={options}
              selected={selectedMenuItems[index + 1] || options[0]}
              onSelected={onMenuItemSelected}
            />
          )
        );
      })}
    </>
  );
};

export default MenuItemFilters;
