import React from 'react';
import settings from '@src/config/settings';

const MijnLink = ({ green = false }) => (
  <form action={`${settings.apisAndUrls.mijn}/#!/aanvraag-team`} target="_blank">
    <button
      className={`kov-pro-btn ${
        green ? 'kov-pro-btn--green' : 'kov-pro-btn--block kov-pro-btn--inverted kov-pro-btn--dark'
      }`}
      type="submit"
    >
      Vraag de juiste rol aan
    </button>
  </form>
);

export default MijnLink;
