import React from 'react';
import useMainPageTheme from '@hooks/useMainPageTheme';
import { getSitemapPathFromSource } from '@src/services/sitemapService';
import { isRealExternalLink } from '@helpers/utils.js';
import { useSelector } from 'react-redux';
import { selectSectionTitle } from '@src/reduxStore/navigation/navigationSelectors';

require('./spotlightThemes.scss');

const SpotlightThemes = () => {
  const { spotLight } = useMainPageTheme();
  const sectionTitle = useSelector((_) => selectSectionTitle(_, spotLight[0]?.parentHref));

  return (
    <>
      {spotLight && spotLight.length > 0 && (
        <div className="kov-pro-shortcuts__wrapper">
          <div className="kov-pro-shortcuts__title">{sectionTitle}</div>

          <div className="card-columns card-columns--1">
            <div className="card">
              <ul className="kov-pro-shortcuts__list">
                {spotLight.map((theme) => (
                  <li
                    key={theme.key}
                    className={`kov-pro-shortcuts__list-item ${
                      isRealExternalLink(theme) && 'kov-pro-shortcuts__list-item--external'
                    }`}
                  >
                    <a
                      target={isRealExternalLink(theme) ? '_blank' : ''}
                      href={getSitemapPathFromSource(theme.references[0])}
                      rel="noreferrer"
                    >
                      {theme.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SpotlightThemes;
