module.exports = [
  {
    content: '/content/2bef89c4-be0a-4d44-8f69-ad7843670ad8',
    details: {
      href: '/persons/cf2dccb0-b676-4402-e044-d4856467bfb8',
      firstName: 'Marijke',
      lastName: 'De Meyst',
      show: true,
      email: 'evaluatiebox@katholiekonderwijs.vlaanderen',
      phones: ['02 507 08 61'],
    },
  },
  {
    content: '/content/a038196d-49d9-4f5b-9f82-e01b616beb60',
    details: {
      href: null,
      firstName: 'Mia',
      lastName: 'Vanden Waeyenbergh',
      show: true,
      email: 'mia.vandenwaeyenbergh@katholiekonderwijs.vlaanderen',
      phones: ['0493 25 12 79'],
    },
  },
  // {
  //   content: '/content/2dafde66-6585-4e44-8611-19f95bb710a3',
  //   details: {
  //     href: '/persons/cf2dccb0-79a9-4402-e044-d4856467bfb8',
  //     firstName: 'Jozefien',
  //     lastName: 'Loman',
  //     email: 'fien.loman@katholiekonderwijs.vlaanderen',
  //     phone: '02 507 08 25',
  //   },
  // },
  // {
  //   content: '/content/2dafde66-6585-4e44-8611-19f95bb710a3',
  //   details: {
  //     href: '/persons/cf2dccb0-cd00-4402-e044-d4856467bfb8',
  //     firstName: 'Marleen',
  //     lastName: 'Lippens',
  //     email: 'marleen.lippens@katholiekonderwijs.vlaanderen',
  //     phone: '02 507 06 28',
  //   },
  // },
  // {
  //   content: '/content/2dafde66-6585-4e44-8611-19f95bb710a3',
  //   details: {
  //     href: '/persons/a0655ef2-2fa7-4857-8a6b-366b179fb4da',
  //     firstName: 'Goedele',
  //     lastName: 'Vandommele',
  //     email: 'goedele.vandommele@katholiekonderwijs.vlaanderen',
  //     phone: null,
  //   },
  // },
  // {
  //   content: '/content/2dafde66-6585-4e44-8611-19f95bb710a3',
  //   details: {
  //     href: '/persons/ccc3e17a-313d-4a86-ab5a-8f1dd292dffa',
  //     firstName: 'Wouter',
  //     lastName: 'Gorissen',
  //     email: 'wouter.gorissen@katholiekonderwijs.vlaanderen',
  //     phone: null,
  //   },
  // },
  // {
  //   content: '/content/69afdbfb-2f8f-4fdd-8e45-c751500a58fa',
  //   details: {
  //     href: '/persons/cf2dccb1-1d14-4402-e044-d4856467bfb8',
  //     firstName: 'Marcel',
  //     lastName: 'Vanlommel',
  //     email: 'marcel.vanlommel@katholiekonderwijs.vlaanderen',
  //     phone: '02 507 07 45',
  //   },
  // },
  // {
  //   content: '/content/2a4050fa-9dca-4d6e-93f7-cef1a88e63db',
  //   details: {
  //     href: '/persons/b78e5000-ca34-4014-b7ea-522bac20cb1a',
  //     firstName: 'Sarah',
  //     lastName: 'Claeys',
  //     email: 'sarah.claeys@katholiekonderwijs.vlaanderen',
  //     phone: '02 507 06 44',
  //   },
  // },
  // {
  //   content: '/content/2a4050fa-9dca-4d6e-93f7-cef1a88e63db',
  //   details: {
  //     href: '/persons/cf2dccb0-fc21-4402-e044-d4856467bfb8',
  //     firstName: 'Beatrijs',
  //     lastName: 'Pletinck',
  //     email: 'beatrijs.pletinck@katholiekonderwijs.vlaanderen',
  //     phone: '02 507 08 40',
  //   },
  // },
  // {
  //   content: '/content/fe051c8d-2574-493e-b3ad-fac87a5fe6f3',
  //   details: {
  //     href: '/persons/cf2dccb2-85d7-4402-e044-d4856467bfb8',
  //     firstName: 'Jerissa',
  //     lastName: 'de Bilde',
  //     email: 'jerissa.debilde@katholiekonderwijs.vlaanderen',
  //     phone: '02 507 07 94',
  //   },
  // },
];
