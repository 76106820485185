import React from 'react';
import PropTypes from 'prop-types';
import QuickNavigationMobileItem from './quickNavigationMobileItem/quickNavigationMobileItem';
import QuickNavigationMobileQuicklinks from './quickNavigationMobileQuicklinks/quickNavigationMobileQuicklinks';

require('./quickNavigationMobile.scss');

const QuickNavigationMobile = ({
  selected,
  menu,
  quickLinks,
  closeMenu,
  onQuickNavigationMenuSelected,
  clearSelection,
}) => (
  <section id="mm-mobile" className="level-2">
    <div className="overlay-title">
      <h1>Menu</h1>
    </div>
    <div className="overlay-mobile-container">
      {selected ? (
        <a onClick={() => clearSelection()} className="back">
          <i className="icon-kov-pro-right-arrow"></i> terug
        </a>
      ) : null}

      <ul className="mobile-menu__service mobile-menu">
        {menu &&
          menu.children.map((item, index) =>
            selected === item.key || !selected ? (
              <QuickNavigationMobileItem
                item={item}
                key={index}
                selected={selected === item.key}
                onSelect={onQuickNavigationMenuSelected}
                closeMenu={closeMenu}
              />
            ) : null
          )}

        {!selected && <div className="divider-line"></div>}
        {(!selected || selected === 'quicklinks') && (
          <li className="mobile-menu__service accent-1">
            <a
              className={`nav-link ${!selected ? 'active' : ''}`}
              onClick={() => onQuickNavigationMenuSelected({ key: 'quicklinks' })}
            >
              <i className="icon-kov-pro-mm-snellinks"></i>Snellinks
            </a>
          </li>
        )}

        {!selected && (
          <li className="mobile-menu__service accent-1">
            <a className="nav-link" href="/nieuwsoverzicht" onClick={closeMenu}>
              <i className="icon-kov-pro-mm-nieuws"></i>Alle nieuws
            </a>
          </li>
        )}

        {selected === 'quicklinks' && (
          <QuickNavigationMobileQuicklinks quickLinks={quickLinks} closeMenu={closeMenu} />
        )}
      </ul>
    </div>
  </section>
);

QuickNavigationMobile.propTypes = {
  selected: PropTypes.string,
  menu: PropTypes.object,
  quickLinks: PropTypes.array,
  closeMenu: PropTypes.func,
  onQuickNavigationMenuSelected: PropTypes.func,
  clearSelection: PropTypes.func,
};

export default QuickNavigationMobile;
