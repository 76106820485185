import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { KovContentBlock } from '@kathondvla/react-shared-components/src/components/contentBlock';
import ContentBlockReferenceGroups from './contentBlockReferenceGroups/contentBlockReferenceGroups';

const ContentBlockReferences = ({ item }) => {
  const [open, setOpen] = useState(item.importance === 'HIGH');

  return (
    item.visible && (
      <div className="example-ref">
        <a
          className={`kov-pro-btn kov-pro-btn--sm kov-pro-btn--inverted kov-pro-btn--light kov-pro-btn__icon--right ${
            open ? '' : 'collapsed'
          }`}
          data-toggle="collapse"
          onClick={() => setOpen(!open)}
          role="button"
          aria-expanded="true"
          aria-controls="example-ref-illustration-tab"
        >
          voorbeeld
          <i className="icon-kov-pro-down-arrow" />
        </a>

        {open && (
          <div
            className="example-ref-blog collapse show"
            id="example-ref-db-1"
            style={{ padding: '28px 15px 0 15px' }}
          >
            {item.type === 'REFERENCE_GROUP' && <ContentBlockReferenceGroups item={item} />}
            {item.type === 'EXAMPLE' &&
              item.children &&
              item.children.map((child, index) => <KovContentBlock key={index} item={child} />)}
          </div>
        )}
      </div>
    )
  );
};

ContentBlockReferences.propTypes = {
  item: PropTypes.object,
};

export default ContentBlockReferences;
