import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getPageFromHref, goToPath } from '@src/helpers/utils';
import { getCachedContents } from '@src/reduxStore/content/contentDataAccess';
import sanitize from '@kathondvla/react-shared-components/src/helpers/compilerHelper';
import { KovContentBlockContext } from '@kathondvla/react-shared-components/src/components/contentBlock/KovContentBlockProvider';
import { changeFontColor, getReferenceExcerpt, resetFontColor } from '../../helpers/contentHelper';

const getExcerptElement = (item) =>
  item.excerpt ? (
    <div className="kov-pro-blogitem__info-excerpt">{sanitize(item.excerpt)}</div>
  ) : (
    <></>
  );

const ContentBlockReferenceGroups = ({ item }) => {
  const [references, setReferences] = useState([]);

  const { color } = useContext(KovContentBlockContext);

  useEffect(() => {
    const getReferenceContents = async () => {
      const { children } = item;
      if (children) {
        const referenceContents = await getCachedContents(
          item.children.map((child) => child.references[0])
        );

        await Promise.all(
          referenceContents.map(async (content) => {
            content.pageConfig = getPageFromHref(content.$$meta.permalink);
            content.excerpt = await getReferenceExcerpt(content);
          })
        );

        setReferences(referenceContents);
      }
    };

    getReferenceContents();
  }, [item]);

  return (
    <>
      {references?.map((reference) => (
        <div key={reference.key}>
          {reference.pageConfig?.type === 'MINI_DATABASE_ITEM' && (
            <div
              onClick={() => goToPath(reference.pageConfig.path)}
              className="kov-pro-bib-list-item"
            >
              <a className="list-item-image">
                {reference.thumbImageS && (
                  <div
                    className="list-item-image-holder"
                    style={{ background: `url('${reference.thumbImageS.href})` }}
                  ></div>
                )}
                {!reference.thumbImageS && <div className="list-item-image-holder"></div>}
              </a>
              <div className="list-item-info">
                <a
                  className="list-item-info__title"
                  onMouseEnter={(event) => changeFontColor(event, color)}
                  onMouseLeave={resetFontColor}
                >
                  <h4>{sanitize(reference.title)}</h4>
                </a>
                {getExcerptElement(reference)}
              </div>
            </div>
          )}
          {reference.pageConfig?.type === 'BLOG_ITEM' && (
            <div
              onClick={() => goToPath(reference.pageConfig.path)}
              className="kov-pro-blogitem kov-pro-blogitem--list col-12"
            >
              <div className="row" style={{ width: '100%' }}>
                <a className="kov-pro-blogitem__image col-2 col-sm-3 col-md-2 col-lg-1">
                  {reference.thumbImageS && (
                    <div
                      className="kov-pro-blogitem__holder"
                      style={{ background: `url('${reference.thumbImageS.href})` }}
                    ></div>
                  )}
                  {!reference.thumbImageS && <div className="kov-pro-blogitem__holder"></div>}
                </a>
                <div className="kov-pro-blogitem__info col-10 col-sm-9 col-md-10 col-lg-11">
                  <a
                    className="kov-pro-blogitem__info-title"
                    onMouseEnter={(event) => changeFontColor(event, color)}
                    onMouseLeave={resetFontColor}
                  >
                    <h4>{sanitize(reference.title)}</h4>
                  </a>
                  {getExcerptElement(reference)}
                </div>
              </div>
            </div>
          )}
          {reference.pageConfig?.type !== 'BLOG_ITEM' &&
            reference.pageConfig?.type !== 'MINI_DATABASE_ITEM' && (
              <div
                className="kov-pro-illustration-list-item"
                onClick={() => goToPath(reference.pageConfig.path)}
              >
                <div className="list-item-info">
                  <a
                    className="list-item-info__title"
                    onMouseEnter={(event) => changeFontColor(event, color)}
                    onMouseLeave={resetFontColor}
                  >
                    <h5>{sanitize(reference.title)}</h5>
                  </a>
                  {getExcerptElement(reference)}
                </div>
              </div>
            )}
        </div>
      ))}
    </>
  );
};

ContentBlockReferenceGroups.propTypes = {
  item: PropTypes.object,
};

export default ContentBlockReferenceGroups;
