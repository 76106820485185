import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getAngularService } from '@src/module/reactMigrationUtils/angular-react-helper';

const ContentBlockTitleTooltip = ({ sKey, visible }) => {
  const routerService = getAngularService('routerService');
  const [deepLinkCopied, setDeepLinkCopied] = useState(false);
  const [shown, setShown] = useState(false);
  const anchor = routerService.generateHashForScrollTo(sKey);

  const copyDeepLink = (e) => {
    e.preventDefault();

    const url = new URL(window.location.href);
    url.searchParams.set('scrollTo', anchor);
    const textArea = document.createElement('textarea');
    textArea.value = url.toString();
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();

    setDeepLinkCopied(true);
    return false;
  };

  const showTooltip = () => {
    setDeepLinkCopied(false);
    setShown(!shown);
  };

  useEffect(() => {
    if (!visible) {
      setShown(false);
    }
  }, [visible]);

  return (
    <div className="kov-pro-anchor">
      <a
        className="kov-pro-anchor__link"
        role="button"
        aria-controls={`tooltip-${sKey}`}
        onClick={showTooltip}
        style={{ top: '0px' }}
      >
        <i className="icon-kov-pro-klembord"></i>
      </a>
      <div
        className={`kov-pro-tooltip collapse ${visible && shown ? 'show' : ''}`}
        id={`tooltip-${sKey}`}
      >
        <p>sla link op in klembord</p>

        <div
          className={`kov-pro-btn ${
            deepLinkCopied
              ? 'kov-pro-btn--accent-3 kov-pro-btn--md kov-pro-btn__icon--right'
              : 'kov-pro-btn--md kov-pro-btn--light kov-pro-btn--inverted'
          }`}
          onClick={(e) => copyDeepLink(e)}
          disabled={deepLinkCopied}
        >
          <span>{deepLinkCopied ? 'Gekopieerd' : 'Kopieer'}</span>
          {deepLinkCopied && <i className="icon-kov-pro-checkmark"></i>}
        </div>
      </div>
    </div>
  );
};

ContentBlockTitleTooltip.propTypes = { sKey: PropTypes.string, visible: PropTypes.bool };

export default ContentBlockTitleTooltip;
