import React from 'react';
import SearchInput from '@UI/searchInput/searchInput';
import PropType from 'prop-types';

const GlobalTrainingSearch = ({
  onSearch,
  collapseFiltersBoxHandler,
  isFiltersBoxCollapsed,
  viewModel,
  trainingsFiltersCnt,
  updateSearchTerm,
}) => (
  <section id="search-nascholing-v2">
    <div className="container">
      <div className="kov-pro-nas-search-bar">
        <div className="kov-pro-nas-search-bar__input btn-group">
          <span className="search">
            <i className="icon-kov-pro-search"></i>
          </span>
          <SearchInput
            placeholder="Zoek"
            value={viewModel.q}
            onChange={updateSearchTerm}
            onSearch={onSearch}
          ></SearchInput>
        </div>

        <div className="kov-pro-nas-search-bar__submit">
          <button
            type="button"
            onClick={onSearch}
            className="kov-pro-btn kov-pro-btn--green kov-pro-btn--block kov-pro-btn--lg"
          >
            Zoek
          </button>
        </div>

        <div className="kov-pro-nas-search-bar__filter">
          <button
            onClick={collapseFiltersBoxHandler}
            className={`kov-pro-btn kov-pro-btn--light kov-pro-btn--block kov-pro-btn--lg kov-pro-btn__icon--left ${
              isFiltersBoxCollapsed ? 'collapsed' : ''
            }`}
            type="button"
            data-toggle="collapse"
            data-target="#collapseFilter"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <i className="icon-kov-pro-nas-filter"></i>
            Filter <span className="filter-counter">{trainingsFiltersCnt}</span>
          </button>
        </div>
      </div>
    </div>
  </section>
);

GlobalTrainingSearch.prototype = {
  onSearch: PropType.func,
  collapseFiltersBoxHandler: PropType.func,
  isFiltersBoxCollapsed: PropType.bool,
  viewModel: PropType.object,
  trainingsFiltersCnt: PropType.number,
  updateSearchTerm: PropType.func,
};

export default GlobalTrainingSearch;
