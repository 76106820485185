import { oauthApi, api, securityApi, privateStatesApi } from '@src/app/apiConfig';
import { SECURITYLEVELS } from '@src/components/constants';
import * as settings from '@src/config/settings';

import { generateUUID } from '@kathondvla/sri-client/common-utils';
import { loginMethods } from '@src/reduxStore/user/userSelectors';

/**
 * This function get all active responsabilities for a given personHref
 * @param {string} personHref
 * @returns {Promise.<Array>}
 */
export const getUserResponsibilities = async (personHref) =>
  api.getAll('/responsibilities', {
    persons: personHref,
    expandPosition: 'NONE',
    date: 'NOW',
  });

export const getMe = async () => {
  let user;
  try {
    user = await oauthApi.get('/me', undefined, { credentials: 'include' });
  } catch (e) {
    console.log('not logged in');
  }
  return user;
};

export const getMoreUserDetails = async (userKey) => api.get(`/persons/${userKey}`);

export const getUserSecurityAccess = async (userKey) => {
  const namedSets = new Set();
  namedSets.add(SECURITYLEVELS.PUBLIC); // /iedereen
  if (userKey) {
    const result = await securityApi.getRaw('/security/query/resources/raw', {
      ability: 'read',
      component: '/security/components/content-api',
      person: `/persons/${userKey}`,
    });
    result
      .filter((e) => e.includes('accessibleBy'))
      .forEach((acc) => {
        const urlParams = new URLSearchParams(acc);
        const myParam = urlParams.get('accessibleBy');
        if (myParam) {
          myParam.split(',').forEach((e) => namedSets.add(e));
        }
      });
  }
  return [...namedSets];
};

export const getUserNewsletterPreferences = async (userKey) =>
  api.getAll(
    '/newsletter/preferences',
    {
      person: `/persons/${userKey}`,
      limit: 500,
    },
    {}
  );

export const updateNewsletterPreferences = async (preferences) =>
  api.put(preferences.$$meta.permalink, preferences);

export const getUserLoginMethod = async (userKey) => {
  const result = await api.getList('/loginsessions', {
    persons: `/persons/${userKey}`,
    limit: 1,
    applications: 'pro',
    descending: true,
    orderBy: 'start',
    omit: '$$meta.count',
  });
  return loginMethods[result[0]?.loginProvider?.href] || null;
};

export const getUserPrivateState = async (user) =>
  privateStatesApi.getAll('/privatestates', {
    owner: `/persons/${user}`,
    context: settings.component,
  });

export const savePrivateState = async (privateStateHref, state) => api.put(privateStateHref, state);

export const createUserPrivateState = (userKey, state) => {
  const key = generateUUID();
  return {
    $$meta: {
      permalink: `/privatestates/${key}`,
    },
    key,
    owner: {
      href: `/persons/${userKey}`,
    },
    context: settings.component,
    state,
  };
};

export const saveToLocalStorage = (key, parameters) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(parameters));
  } catch (e) {
    console.log('error saving to local storage');
  }
};

export const getFromLocalStorage = (key) => {
  try {
    return JSON.parse(window.localStorage.getItem(key));
  } catch (e) {
    return null;
  }
};

export const getTargetGroups = async () =>
  api.getAll('/namedsets', {
    tags: 'doelgroepen',
  });

/**
 * This method checks for user responsibilities within KOV organization. It returns a boolean.
 * @param {string} personHref
 * @returns Boolean
 */
export const isPersonPartOfKathOndVla = async (personHref) => {
  const KOVUserResponsibilities = await api.getAll('/responsibilities', {
    persons: personHref,
    includeOrganisationsHierarchical: '/organisations/c000eaea-c451-2590-e044-d4856467bfb8',
    date: 'NOW',
    expand: 'NONE',
  });

  return KOVUserResponsibilities.length > 0;
};

/**
 * This method let us know if any of the responsibilities of the user allows him/her to export the trainings from the global trainings page (this is an "internal" feature)
 * @param {*} personHref
 * @returns
 */
export const isPersonAllowedToExportTrainings = async (personHref) => {
  // these are the organisations business told us can export the trainings
  const orgsAllowedToExportTrainings = [
    '/organisations/0e1a8f0a-4a38-4a33-af92-fe3e032b51a8', // Dienst Academie
    '/organisations/a193280c-5596-41ed-9214-1a2cde94f566', // Dienst Academie (sub-organisation)
    '/organisations/eb745d58-b818-4569-a06e-68733fe2e5b3', // Dienst ICT
    '/organisations/ad5eb7c4-b3aa-40dd-a922-74d8dfc37bec', // Dienst ICT (sub-organisation)
    '/organisations/33e12bcb-e077-42c9-acd0-1f0f62f547a4', // Pro-Webmasters
  ];

  const responsibilitiesMatches = await api.getAll('/responsibilities', {
    persons: personHref,
    organisations: orgsAllowedToExportTrainings.join(','),
    date: 'NOW',
    expand: 'NONE',
  });

  return responsibilitiesMatches.length > 0;
};
