const store = require('@src/reduxStore/store').default;
const { stateMatch } = require('redux-awaitable-state');
const {
  selectItemInCurrentThemeTree,
  selectCurrentThemeTree,
} = require('@src/reduxStore/theme/themeSelectors');

class wsThemeFaq {
  constructor($scope, $stateParams, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$stateParams = $stateParams;
    this.$ngRedux = $ngRedux;
  }

  async $onInit() {
    await stateMatch((state) => selectCurrentThemeTree(state));

    this.unsubscribe = this.$ngRedux.connect((state) => {
      const page = selectItemInCurrentThemeTree(state, this.$stateParams.source);
      this.$scope.$emit('dynamicMetaDataUpdated', page);

      return {
        page,
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }
}

export default {
  template: require('./wsThemeFaq.html'),
  controllerAs: 'ctrl',
  controller: wsThemeFaq,
  bindings: {
    sTheme: '<',
  },
};
