const store = require('@src/reduxStore/store').default;
const { stateMatch } = require('redux-awaitable-state');
const {
  selectItemInCurrentThemeTree,
  selectCurrentThemeTree,
} = require('@src/reduxStore/theme/themeSelectors');

class wsThemeStatic {
  constructor($scope, $stateParams) {
    'ngInject';

    this.$scope = $scope;
    this.$stateParams = $stateParams;
  }

  emitUpdatePageMetadata = (menuItem) => {
    this.$scope.$emit('dynamicMetaDataUpdated', menuItem);
  };
}

module.exports = {
  template: require('./wsThemeStatic.html'),
  controllerAs: 'ctrl',
  controller: wsThemeStatic,
  bindings: {
    sTheme: '<',
  },
};
