import React, { useEffect, useReducer, useMemo, useState } from 'react';
import VakkenPaginaTabs from '@src/reactComponents/vakkenPagina/vakkenPaginaTabs';
import VakkenPaginaContentTab from '@src/reactComponents/vakkenPagina/vakkenPaginaContentTab';
import Banner from '@components/sections/banner/banner';
import useUrlSearchParams from '@src/hooks/useUrlSearchParams';
import { selectStudyProgrammes } from '@src/reduxStore/content/contentSelectors';
import { useSelector } from 'react-redux';

const oldStructureData = require('./data/oldStructureData.json');
const firstGradeData = require('./data/firstGradeData.json');

const availableTabsSchema = [
  {
    tabId: 'first-grade',
    tabLabel: 'eerstegraad',
    sectionsFieldName: 'firstGradeExpandedSections',
    activeSections: [],
    data: [],
  },
  {
    tabId: 'second-grade',
    tabLabel: 'tweedegraad',
    sectionsFieldName: 'secondGradeExpandedSections',
    activeSections: [],
    data: [],
  },
  {
    tabId: 'thirth-grade',
    tabLabel: 'derdegraad',
    sectionsFieldName: 'thirdGradeExpandedSections',
    activeSections: [],
    data: [],
  },
  {
    tabId: 'thirth-grade-old',
    tabLabel: 'tweedederdegraad-oud',
    sectionsFieldName: 'oldStructureExpandedSections',
    activeSections: [],
    data: [],
  },
];

const getActiveTabState = (activeTabId, tabsData, tabExpandedSections) => {
  switch (activeTabId) {
    case 'first-grade':
      return {
        activeSections: tabExpandedSections.firstGradeExpandedSections.map((a) => parseInt(a, 10)),
        data: tabsData.firstGrade,
      };
    case 'second-grade':
      return {
        activeSections: tabExpandedSections.secondGradeExpandedSections.map((a) => parseInt(a, 10)),
        data: tabsData.secondGrade,
      };
    case 'thirth-grade':
      return {
        activeSections: tabExpandedSections.thirdGradeExpandedSections.map((a) => parseInt(a, 10)),
        data: tabsData.thirdGrade,
      };
    default:
      return {
        activeSections: tabExpandedSections.oldStructureExpandedSections.map((a) =>
          parseInt(a, 10)
        ),
        data: tabsData.oldStructure,
      };
  }
};

const getTabById = (tabId) => availableTabsSchema.find((tab) => tab.tabId === tabId);

const getTabByLabel = (tabLabel) => availableTabsSchema.find((tab) => tab.tabLabel === tabLabel);

function vakkenPaginaReducer(state, action) {
  switch (action.type) {
    case 'setParam': {
      return {
        ...state,
        params: { ...state.params, [action.fieldName]: action.payload },
      };
    }
    default:
      return state;
  }
}

const initialState = {
  params: {
    q: '',
    tab: 'eerstegraad',
    firstGradeExpandedSections: [0],
    secondGradeExpandedSections: [],
    thirdGradeExpandedSections: [],
    oldStructureExpandedSections: [0],
  },
};

const VakkenPagina = () => {
  const studyProgrammes = useSelector(selectStudyProgrammes);

  const [tabsData, setTabsDataData] = useState(null);
  const [state, vakkenReducerDispatch] = useReducer(vakkenPaginaReducer, initialState);
  const {
    params: { tab: tabLabel, q: searchQuery, ...tabExpandedSections },
  } = state;

  useEffect(() => {
    if (studyProgrammes.length === 0) return;

    const secondGrade = studyProgrammes.filter((a) =>
      a.studyProgrammeGroups?.find(
        (b) => b.href === '/sam/commons/studyprogrammegroups/2ea33583-8819-4137-bb4e-c3efd9e91346'
      )
    );

    const thirdGrade = studyProgrammes.filter((a) =>
      a.studyProgrammeGroups?.find(
        (b) => b.href === '/sam/commons/studyprogrammegroups/954369e2-99c1-4ef4-be19-f5496956a270'
      )
    );

    setTabsDataData({
      firstGrade: firstGradeData,
      secondGrade,
      thirdGrade,
      oldStructure: oldStructureData,
    });
  }, [studyProgrammes]);

  const filterObjectIntoParamStateObj = useMemo(
    () =>
      Object.entries(state.params).map(([key, value]) => ({
        key,
        state: value,
        updateState: (val) => {
          vakkenReducerDispatch({ type: 'setParam', fieldName: key, payload: val });
        },
      })),
    [vakkenReducerDispatch, state.params]
  );

  const { updateUrlSearchParams, urlParamsProcessed } = useUrlSearchParams(
    filterObjectIntoParamStateObj
  );

  const activeTabData = useMemo(() => {
    const activeTabSchema = getTabByLabel(tabLabel);

    if (tabsData) {
      const activeTabState = getActiveTabState(
        activeTabSchema.tabId,
        tabsData,
        tabExpandedSections
      );

      if (searchQuery !== '') {
        return {
          ...activeTabSchema,
          ...{
            ...activeTabState,
            data: activeTabState.data.filter((tabElement) =>
              tabElement.title.toLowerCase().includes(searchQuery.toLowerCase())
            ),
          },
        };
      }

      return {
        ...activeTabSchema,
        ...activeTabState,
      };
    }
    return activeTabSchema;
  }, [tabLabel, tabsData, tabExpandedSections, searchQuery]);

  useEffect(() => {
    if (urlParamsProcessed) updateUrlSearchParams();
  }, [urlParamsProcessed, updateUrlSearchParams]);

  return (
    <>
      <Banner
        menuItem={{
          key: 'Vakken',
          websiteType: 'vakken',
          href: activeTabData.tabLabel,
        }}
      />
      <section id="body">
        <div className="container">
          <div className="kov-pro-curriculum">
            <div className="tabbable-line">
              <VakkenPaginaTabs
                activeTabId={activeTabData.tabId}
                setActiveTab={(tabId) => {
                  vakkenReducerDispatch({ type: 'setParam', fieldName: 'q', payload: '' });
                  vakkenReducerDispatch({
                    type: 'setParam',
                    fieldName: 'tab',
                    payload: getTabById(tabId).tabLabel,
                  });
                }}
              />
              <div className="tab-content">
                <VakkenPaginaContentTab
                  activeSections={activeTabData.activeSections}
                  setActiveSections={(newSections) =>
                    vakkenReducerDispatch({
                      type: 'setParam',
                      fieldName: activeTabData.sectionsFieldName,
                      payload: newSections,
                    })
                  }
                  tabData={activeTabData.data}
                  tabId={activeTabData.tabId}
                  loading={!tabsData}
                  {...(!['first-grade', 'thirth-grade-old'].includes(activeTabData.tabId) && {
                    searchTerm: searchQuery,
                    executeSearch: (searchTerm) =>
                      vakkenReducerDispatch({
                        type: 'setParam',
                        fieldName: 'q',
                        payload: searchTerm,
                      }),
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VakkenPagina;
