import { useReducer } from 'react';
import { selectUserViewOptions } from '@src/reduxStore/user/userSelectors';
import { useSelector } from 'react-redux';

const useEntryFlowViewOptionsHook = () => {
  const viewOptions = useSelector(selectUserViewOptions);
  function entryFlowReducer(state, action) {
    switch (action.type) {
      case 'setInvalid': {
        return {
          ...state,
          optionsInvalid: [action.payload],
        };
      }
      case 'assignValues': {
        return {
          ...state,
          [action.fieldName]: [...action.payload],
        };
      }
      default:
        return state;
    }
  }

  const userRegions = viewOptions.REGIONS;
  const userEducationLevels = viewOptions.EDUCATION_LEVELS;

  const initialState = {
    educationLevels: [...userEducationLevels],
    educationLevelsToSet: userEducationLevels,
    regions: [...userRegions],
    regionsToSet: userRegions,
    optionsInvalid: false,
  };

  const [state, dispatch] = useReducer(entryFlowReducer, initialState);

  return {
    state,
    dispatch,
  };
};
export default useEntryFlowViewOptionsHook;
