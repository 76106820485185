import { useState, useEffect } from 'react';
import { getMenuItemFamilyList } from '@helpers/trainingsHelper';
import { selectNavigationFirstLevel } from '@src/reduxStore/navigation/navigationSelectors';
import { useSelector } from 'react-redux';

const useMenuItemFilters = (filters, onSelected = undefined, channelFilter = undefined) => {
  const [selectedMenuItems, setSelectedMenuItems] = useState([]);
  const navigationFirstLevel = useSelector(selectNavigationFirstLevel);

  /**
   * When the menuTree is filtered according to the view options,
   * selected values should be reset too
   */
  useEffect(() => {
    const items = getMenuItemFamilyList(filters.menuItem, navigationFirstLevel);
    setSelectedMenuItems(items);

    if (items.length && items[items.length - 1]?.href !== filters.menuItem && onSelected != null) {
      onSelected(items[items.length - 1]?.href);
    }
  }, [filters.menuItem, onSelected]);

  /**
   * Triggers this when a menu item is selected
   * When a higher menuItem is selected in the pro tree, all selected lower level menuItems should be discarded
   * @param {*} level the level of the menu item selected
   * @param {*} menuItem the value selected
   */
  const onMenuItemSelected = (level, menuItem) => {
    const items = [...selectedMenuItems];

    // menuItem with no key is the placeholder, the selected value is cleared
    items[level] = menuItem.key ? menuItem : null;

    /** when a higher menuItem is selected in the pro tree,
     * all selected lower level menuItems should be discarded */
    let i = level + 1;
    for (; i < items.length; ) {
      items[i] = null;
      i += 1;
    }

    let href = null;
    if (items[level]) {
      href = menuItem.href;
    } else if (level > 0) {
      // in case the menuItem has been deselected,
      // take the value from the previous selector
      href = items[level - 1].href;
    }
    onSelected(href);
  };

  /**
   * Get the menu items to show in a given level.
   * The first option is the placeholder, when the user selects this one, the value is cleared.
   * Only menu items with subitems are shown, except for the last level in which we check
   * if a menu item is actally linked to a pro theme
   * On level 0 there is also the check to only show items that are "allowed" from the CONSTANTS
   * @param {*} level
   * @param {*} items the list of items to filter
   * @returns the list of options
   */
  const getLevelOptions = (level, items = []) => {
    if (items.length === 0) {
      return [];
    }

    return items
      .filter((option) =>
        level === 0 && channelFilter ? channelFilter.includes(option.href) : true
      )
      .filter((item) => item.visible)
      .reduce(
        (options, option) => {
          if (option.children.length > 0 || option.theme) return [...options, option];
          return options;
        },
        [{ title: `Maak een keuze ${level > 0 ? ' (optioneel)' : ''}` }]
      );
  };

  return {
    selectedMenuItems,
    getLevelOptions,
    onMenuItemSelected,
  };
};

export default useMenuItemFilters;
