import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getSitemapPathFromSource } from '@src/services/sitemapService';
import { getRootElement } from '@helpers/utils';
import databaseSelectors from '@src/reduxStore/database/databaseSelectors';
import { useSelector } from 'react-redux';
import { selectCurriculumThemes } from '@src/reduxStore/theme/themeSelectors';
import { proGlobalDatabase } from '@src/config/settings';
import { selectProThemes } from '@src/reduxStore/content/contentSelectors';

const LinkedCurriculaThemes = ({ color = '#B72A8D', item = undefined }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [selectedCurricula, setSelectedCurricula] = useState([]);
  const [otherLinkedCurricula, setOtherLinkedCurricula] = useState([]);

  const proThemes = useSelector(selectProThemes);
  const curriculaThemes = useSelector(selectCurriculumThemes);
  const selectedCurriculaThemes = useSelector(databaseSelectors.getFilters).curricula;

  const rootElement = useMemo(() => getRootElement(item), [item]);

  const hasInspirendMaterial = useMemo(
    () =>
      rootElement?.themes
        ? rootElement.themes.includes(proGlobalDatabase.databaseTypeThemehref)
        : false,
    [rootElement]
  );

  const itemTaggedThemesHrefs = useMemo(() => {
    if (rootElement?.referenceFrameItem != null) {
      return rootElement.referenceFrameItem
        .filter((ref) => ref.type === 'CURRICULUM_THEME')
        .map((ref) => ref.$$meta.permalink);
    }
    return rootElement?.themes ? rootElement.themes : [];
  }, [rootElement]);

  useEffect(() => {
    if (proThemes.length > 0 && curriculaThemes.length > 0 && itemTaggedThemesHrefs.length > 0) {
      const selected = [];
      const other = [];
      itemTaggedThemesHrefs.forEach((themeHref) => {
        const curriculumTheme = curriculaThemes.find((crrTheme) => crrTheme.href === themeHref);
        if (curriculumTheme && curriculumTheme.type === 'CURRICULUM_THEME') {
          const isCurrThemeSelected = selectedCurriculaThemes.find(
            (selCurrTheme) => selCurrTheme === curriculumTheme.href
          );

          // this is used when the curriculumTheme is a "subCurriculumTheme".
          // In that case we need the information of the parent ProTheme to know if that ProTheme is published
          const currThemeHrefToFind = curriculumTheme.parentCurriculumTheme?.href || themeHref;
          const proTheme = proThemes.find((theme) => theme?.themes?.includes(currThemeHrefToFind));

          // we only include published Pro Themes, otherwise it makes no sense to send the user there
          if (proTheme?.issued) {
            (isCurrThemeSelected ? selected : other).push({
              ...curriculumTheme,
              proThemeHref: proTheme.$$meta.permalink,
            });
          }
        }
      });

      setSelectedCurricula(selected);
      setOtherLinkedCurricula(other);
    }
  }, [curriculaThemes, selectedCurriculaThemes, itemTaggedThemesHrefs, proThemes]);

  const getCurriculumThemePath = (curriculumTheme) => {
    const proThemePath = getSitemapPathFromSource(curriculumTheme.proThemeHref);
    // when its a subCurriculumTheme we add the "curricula" filter to the URL
    return `${proThemePath}${
      curriculumTheme.parentCurriculumTheme ? `?curricula=${curriculumTheme.href}` : ''
    }`;
  };

  return (hasInspirendMaterial && selectedCurricula.length > 0) ||
    otherLinkedCurricula.length > 0 ? (
    <div id="kov-accent-1">
      {selectedCurricula.length > 0 && (
        <div className="kov-linked-curricula">
          <ul>
            {selectedCurricula.map((curriculum, index) =>
              curriculum.proThemeHref == null ? null : (
                <li className="kov-linked-curriculum" key={index}>
                  <a href={getCurriculumThemePath(curriculum)}>
                    <h3>
                      <i className="icon-kov-pro-didactiek" style={{ color }} />
                      {curriculum.title}
                    </h3>
                  </a>
                </li>
              )
            )}
          </ul>
        </div>
      )}

      {otherLinkedCurricula.length > 0 && (
        <div className="kov-extra-linked-curricula">
          <div
            className={`kov-extra-linked-curricula__header${collapsed ? ' collapsed' : ''}`}
            data-toggle="collapse"
            data-target="#kov-extra-linked-curricula"
            aria-expanded="false"
            onClick={() => setCollapsed((prev) => !prev)}
          >
            {selectedCurricula.length > 0 ? (
              <div className="kov-extra-linked-curricula__title">Andere gekoppelde leerplannen</div>
            ) : (
              <div className="kov-extra-linked-curricula__title">Gekoppelde leerplannen</div>
            )}
            <div className="kov-extra-linked-curricula__collapse">
              <i className={`icon-kov-pro-down-arrow${collapsed ? '' : ' flip'}`} />
            </div>
          </div>

          <div
            className={`kov-extra-linked-curricula__collapse${collapsed ? ' collapse' : ''}`}
            id="kov-extra-linked-curricula"
            aria-expanded="false"
          >
            <ul>
              {otherLinkedCurricula.map((curriculum, index) =>
                curriculum.proThemeHref == null ? null : (
                  <li className="kov-linked-curriculum" key={index}>
                    <a href={getCurriculumThemePath(curriculum)}>
                      <h3>
                        <i className="icon-kov-pro-didactiek" style={{ color }} />
                        {curriculum.title}
                      </h3>
                    </a>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  ) : null;
};

LinkedCurriculaThemes.propTypes = {
  color: PropTypes.string,
  item: PropTypes.object,
};

export default LinkedCurriculaThemes;
