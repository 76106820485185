import { isBefore, getNow, isAfter } from '@kathondvla/sri-client/date-utils';
import { TRAINING_TYPES } from '@src/components/constants';
import * as settings from '@src/config/settings';

export const getEventInfoForTrainings = (training) => {
  const allDates = new Set();
  const allAttendanceTypes = new Set();
  const allPhysicalLocations = new Set();
  const allValidSeries = !training.series
    ? []
    : training.series.filter(
        (serie) =>
          serie.waitingList ||
          (serie.eventDates &&
            !serie.eventDates?.some((eventDate) => isBefore(eventDate, getNow())))
      );

  allValidSeries.forEach((serie) => {
    allAttendanceTypes.add(serie.attendanceType);
    if (serie.eventDates) {
      serie.eventDates.forEach((eventDate) => allDates.add(eventDate));
    }
    if (serie.locations) {
      serie.locations.forEach((location) => {
        if (location.address) {
          allPhysicalLocations.add(location.address.city);
        } else if (location.online) {
          allPhysicalLocations.add('Online');
        }
      });
    }
  });

  let date = 'Te bepalen';
  if (allDates.size === 1) {
    const [sriDate] = Array.from(allDates);
    date = new Date(sriDate).toLocaleDateString('nl-BE', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  } else if (allDates.size > 1) {
    date = 'Meerdere data';
  }
  let location = 'Te bepalen';

  if (allPhysicalLocations.size === 1) {
    [location] = Array.from(allPhysicalLocations);
  } else if (allPhysicalLocations.size > 1) {
    location = allPhysicalLocations.has('Online') ? 'Op locatie en online' : 'Op locatie';
  }

  const onDemand =
    training.trainingTypes.includes('TEAMGERICHT') || training.trainingTypes.includes('TEAM_BASED');
  return {
    date: !onDemand ? date : null,
    location: !onDemand || allPhysicalLocations.size > 0 ? location : null,
    onDemand,
    attendanceTypes: Array.from(allAttendanceTypes),
    waitingList: allValidSeries.length > 0 && allValidSeries.every((serie) => serie.waitingList),
    cancelled:
      allValidSeries.length > 0 &&
      allValidSeries.every((serie) => serie.cancelled && !serie.waitingList),
    full:
      allValidSeries.length > 0 &&
      allValidSeries.every((serie) => serie.full && !serie.waitingList),
  };
};

const getTrainingTitleFromTag = (trainingTag) => {
  const trainingType = TRAINING_TYPES.find((training) =>
    training.tag.toUpperCase().includes(trainingTag)
  );
  return trainingType?.title;
};

export const transformTrainingResults = (trainings) => {
  const referenceDate = getNow();
  console.log('raw training results: ', trainings);

  return trainings
    .map((training) => {
      let themeNames;
      let organiserNames;
      let motivation = '';
      // Only keep eventDates of which the series hasn't started and convert to readable formatting

      if (training.organisers && training.organisers.length > 0) {
        organiserNames = training.organisers.map((organiser) => organiser.name);
      }

      let isActual = false;
      // no event in the future that is not full
      isActual = training.themes?.some(
        (theme) =>
          theme.current &&
          theme.current.startDate &&
          theme.current.startDate <= referenceDate &&
          isAfter(theme.current.endDate, referenceDate)
      );

      const eventInfo = getEventInfoForTrainings(training);
      if (training.motivation) {
        motivation = training.motivation;
      }

      motivation = $(motivation).text();
      return {
        ...training,
        eventInfo,
        isActual,
        motivation,
        forwardLink: `${settings.apisAndUrls.trainings.nascholing}redirectTo.aspx?redirectID=${training.$$meta.permalink}`,
        typeNames: (training.trainingTypes || []).map((trainingType) =>
          getTrainingTitleFromTag(trainingType)
        ),
        organiserNames: organiserNames || undefined,
        themeNames: themeNames || undefined,
      };
    })
    .filter(Boolean);
};
