import { loaderEnd } from '@src/helpers/loaderHelper';
require('./wsVakkenpagina.scss');

class wsVakkenpagina {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
  }

  $onInit() {
    this.$scope.$emit('dynamicMetaDataUpdated', {
      title: 'Vakken en leerplannen',
      description: null,
    });
    loaderEnd();
  }
}

export default {
  template: require('./wsVakkenpagina.html'),
  controllerAs: 'ctrl',
  controller: wsVakkenpagina,
  bindings: {},
};
