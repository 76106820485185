import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { flattenTree, stripHtml } from '@src/app/utils';
import { FILE_TYPES } from '@src/components/constants';
import RestrictedContent from '@src/reactComponents/secureAccess/restrictedContent/restrictedContent';
import { getNameAndExtension } from '@src/app/contentUtils';
import { useDispatch } from 'react-redux';
import trackPiwikEvent from '@src/reduxStore/analytics/analyticsThunks';
import {
  getAttachments,
  getForbiddenAttachmentItem,
  getLinks,
} from './helpers/downloadSectionHelper';
import AttachmentTypeIcon from './attachmentTypeIcon/attachmentTypeIcon';

const ThemeDownloadSection = ({ item }) => {
  const dispatch = useDispatch();
  const [attachments, setAttachments] = useState([]);
  const [links, setLinks] = useState([]);
  const [forbiddenItem, setForbiddenItem] = useState();

  useEffect(() => {
    if (item) {
      const items = flattenTree(item);
      setLinks(getLinks(items.filter((o) => o.type === 'LINK_GROUP')));
      const attachmentsGroups = items.filter((o) => o.type === 'ATTACHMENTS_GROUP');

      const accessibleAttachmentsGroups = getAttachments(attachmentsGroups);

      const attachmentGroupChildren = accessibleAttachmentsGroups
        .flatMap((attGroup) => (attGroup.type === 'ATTACHMENTS_GROUP' ? attGroup.children : []))
        .filter((child) => child && child.visible && child.attachment);

      setAttachments(attachmentGroupChildren);
      setForbiddenItem(getForbiddenAttachmentItem(attachmentsGroups));
    }
  }, [item]);

  return (
    <>
      {(attachments.length > 0 || forbiddenItem || links.length > 0) && (
        <>
          <section id="downloads">
            <div className="container">
              <div className="row">
                {(attachments.length > 0 || forbiddenItem) && (
                  <>
                    <div className={links.length > 0 ? 'col-md-8 col-lg-8' : 'col-12 col-sm-12'}>
                      <div className="kov-pro-section-title">
                        <h1>
                          <i className="icon-kov-pro-download" />
                          Downloads
                        </h1>
                      </div>

                      <ul
                        className={
                          links.length > 4
                            ? 'kov-pro-download kov-pro-download--more'
                            : 'kov-pro-download'
                        }
                      >
                        {forbiddenItem && (
                          <li key="forbidden">
                            <a
                              href="#"
                              className="kov-pro-download__item kov-pro-download__item-not-logged-in"
                            >
                              <RestrictedContent status={forbiddenItem.securityStatus} />
                            </a>
                          </li>
                        )}
                        {attachments.map((attachment) => (
                          <li key={attachment.key}>
                            <a
                              href={attachment.attachment.href}
                              className="kov-pro-download__item"
                              target="_blank"
                              rel="noreferrer"
                              onClick={() =>
                                dispatch(
                                  trackPiwikEvent(
                                    'download_file',
                                    getNameAndExtension(attachment.attachment.name)
                                  )
                                )
                              }
                            >
                              <div className="kov-pro-download__thumb">
                                <AttachmentTypeIcon attachment={attachment.attachment} />
                              </div>
                              <div className="kov-pro-download__info">
                                <div className="kov-pro-download__info-title">
                                  {stripHtml(attachment.title || attachment.description)}
                                </div>
                                <div className="kov-pro-download__info-excerpt">
                                  {stripHtml(
                                    attachment.title
                                      ? attachment.description
                                      : attachment.attachment.description
                                  )}
                                </div>
                                <div className="kov-pro-download__info-type">
                                  {FILE_TYPES[attachment.attachment.contentType]}
                                </div>
                                <div className="kov-pro-download__info-size">
                                  {attachment.attachment.size}
                                </div>
                                <div className="kov-pro-download__info-icon">
                                  <i className="icon-kov-pro-download" />
                                </div>
                              </div>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </>
                )}

                {links.length > 0 && (
                  <div
                    className={
                      attachments.length > 0 || forbiddenItem
                        ? 'col-md-4 col-lg-4'
                        : 'col-md-12 col-lg-12'
                    }
                  >
                    <div className="kov-pro-section-title">
                      <h1>
                        <i className="icon-kov-pro-download" />
                        Links
                      </h1>
                    </div>
                    <ul className="kov-pro-links">
                      {links.map((link, index) => (
                        <li key={index} className="extern">
                          <a href={link.externalReference} target="_blank" rel="noreferrer">
                            {stripHtml(link.title)}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

ThemeDownloadSection.propTypes = {
  item: PropTypes.object,
};

export default ThemeDownloadSection;
