const { getHrefOfSecurePath } = require('@src/helpers/accessHelper');

class wsNotLoggedIn {
  constructor($scope) {
    'ngInject';
    this.$scope = $scope;
    this.current = null;
  }

  $onInit() {
    this.$scope.$emit('dynamicMetaDataUpdated', {
      title: 'niet aangemeld',
      description: null,
    });

    this.current = getHrefOfSecurePath(this.sTheme);

    if (this.current) {
      this.$scope.$emit('setPageSettings', this.sTheme);
    }
  }
}

module.exports = {
  template: require('./wsNotLoggedIn.html'),
  controllerAs: 'ctrl',
  controller: wsNotLoggedIn,
  bindings: {
    sTheme: '<',
  },
};
