import React from 'react';
import PropTypes from 'prop-types';
import { dateToString } from '@helpers/utils';
import ApplicabilityLabels from '@UI/applicabilityLabels/ApplicabilityLabels';
import { goToNewItem } from '@components/news/helpers/newsDatabank';

const RelatedTeasers = ({ teasers }) => (
  <>
    {teasers && teasers.length && (
      <div>
        <h2>Recent verwant nieuws</h2>
        <ul className="kov-pro-news-teaser-list kov-pro-news-teaser-list--more">
          {teasers.map((teaser) => (
            <a
              key={teaser.href}
              href={goToNewItem(teaser)}
              target={'_blank'}
              rel="noreferrer"
              className="kov-pro-news-teaser"
            >
              <div className="kov-pro-news-teaser__info">
                <p className="text-date-time">
                  <span className="date">
                    <i className="icon-kov-pro-bookmark-full" />{' '}
                    {dateToString(teaser.issued, {
                      weekday: 'long',
                      day: '2-digit',
                      month: 'long',
                    })}
                  </span>
                </p>
                <div className="kov-pro-news-teaser__info-title">
                  <span>{teaser.title}</span>
                  <i className="icon-kov-pro-right-arrow" />
                </div>
                <div
                  className="kov-pro-news-teaser__info-excerpt"
                  dangerouslySetInnerHTML={{ __html: teaser.description }}
                />
                <ApplicabilityLabels labels={teaser.tags} />
              </div>
            </a>
          ))}
        </ul>
      </div>
    )}
  </>
);

RelatedTeasers.propTypes = {
  teasers: PropTypes.array,
  proTheme: PropTypes.object,
};

export default RelatedTeasers;
