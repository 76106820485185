import { useEffect, useState } from 'react';
import { getAngularService } from 'NgMigrationUtils/angular-react-helper';
import settings from '@src/config/settings';
import analyticsState from '@src/reduxStore/analytics/analyticsSlice';
import store from '@src/reduxStore/store';

const { dispatch } = store;

const getUniqueBreadcrumbs = (breadcrumbs) =>
  breadcrumbs.reduce((accBreadcrumbs, breadcrumb) => {
    // This is ugly but it is to "control" the properties we deliver for every breadcrumb (before we were sending all the property of the element (theme, section, etc..))
    const builtBreadcrumb = {
      breadcrumbTitle: breadcrumb.breadcrumbTitle,
      breadcrumbPath: breadcrumb.breadcrumbPath || breadcrumb.path || breadcrumb.pagePath, // themes have pagePath, elements on "nagivation service" (homepageItems, etc) have path...
      hasThemesOrCurriculumThemes:
        breadcrumb.referenceFrameItem?.length > 0 || breadcrumb.theme?.length > 0, // this is used to render "subCurriculumThemes"
      icon: breadcrumb.icon, // this is used to render the icon in the first breadcrumb
      color: breadcrumb.color, // this is used to render the color of the icon in the first breadcrumb
    };

    const alreadyAdded = accBreadcrumbs.find(
      (b) => b.breadcrumbPath === builtBreadcrumb.breadcrumbPath
    );

    if (alreadyAdded) {
      // not cool, but when setting up unique breadcrumbs sometimes we leave out the "themeBreadcrumb" (depending on the order of the elements) in favor of one element (section) we found with the same path that comes from the "homepageElements" and the "theme" one has the following property that we need later. So we need to "potentially" extend the previous added element.
      alreadyAdded.hasThemesOrCurriculumThemes =
        alreadyAdded.hasThemesOrCurriculumThemes || builtBreadcrumb.hasThemesOrCurriculumThemes;
    } else {
      accBreadcrumbs.push(builtBreadcrumb);
    }

    return accBreadcrumbs;
  }, []);

const edgeCases = {
  trainings: { breadcrumbTitle: 'Professionalisering', key: 'trainings' },
  news: { breadcrumbTitle: 'Nieuws', key: 'news', path: 'nieuwsoverzicht' },
  search: { breadcrumbTitle: 'Zoeken', key: 'search', path: 'zoeken' },
};

const setBreadcrumbTags = (breadcrumbs, dispatchEvent) => {
  const breadcrumbProperties = {
    ...breadcrumbs.reduce((acc, curr, index) => {
      acc[`page_category_${index + 2}`] = curr.breadcrumbTitle?.toLowerCase();
      return acc;
    }, {}),
  };

  dispatchEvent(analyticsState.actions.updateCommonProperties(breadcrumbProperties));
  dispatchEvent(analyticsState.actions.updateBreadcrumbReadyStatus(true));
};

export const getRecursivePath = (referenceItem, toLevel) => {
  const arrayToReturn = [referenceItem];
  function add(i) {
    arrayToReturn.unshift(i);
    if (i.level > toLevel && i.parent) {
      add(i.parent);
    }
  }
  if (referenceItem && referenceItem.level && referenceItem.parent) {
    if (referenceItem.level > toLevel) {
      add(referenceItem.parent);
    }
  }

  return arrayToReturn;
};

export const checkForMultipleMenus = (breadcrumbArray) => {
  let indexToRemove = null;
  return breadcrumbArray.flatMap((breadcrumb, index) => {
    if (index === indexToRemove) {
      return [];
    }
    let breadcrumbTitle;
    let breadcrumbPath;
    if (breadcrumb.multipleAlternativeMenus) {
      indexToRemove = index + 1;
      breadcrumbTitle = `${breadcrumb.title} - ${breadcrumbArray[indexToRemove]?.title}`;
      breadcrumbPath = `${breadcrumb.path}?tab=${breadcrumbArray[indexToRemove]?.title}`;
    } else {
      breadcrumbTitle = breadcrumb.title;
      breadcrumbPath = breadcrumb.path;
    }
    return {
      ...breadcrumb,
      breadcrumbTitle,
      breadcrumbPath,
    };
  });
};

const getBreadcrumbsFromElementBackwards = (element, deepLevel) =>
  checkForMultipleMenus(getRecursivePath(element, deepLevel));

export const checkForMultipleEntryPoints = (theme, navigationService) => {
  if (theme) {
    const homepageItems = navigationService.getItems();
    const storedEntryPoint = navigationService.getMatchingLatestClusterChild(theme);
    return storedEntryPoint || homepageItems.find((I) => I.references?.includes(theme.href));
  }
  return undefined;
};

const getGenericBreadcrumbs = (theme, item) => {
  const navigationService = getAngularService('navigationService');
  let genericBreadcrumbs = [];
  if (theme) {
    const themeHomePageSource = checkForMultipleEntryPoints(theme, navigationService);

    if (themeHomePageSource) {
      const newThemeHomePageSource = {
        ...themeHomePageSource,
        path:
          themeHomePageSource?.path == null
            ? theme.pagePath || theme.path
            : themeHomePageSource.path,
      };

      let themeBreadcrumbsToHomepage = getBreadcrumbsFromElementBackwards(
        newThemeHomePageSource,
        1
      );
      // news and trainings keep the theme in breadcrumbs since they exist outside the themes reference frame
      if (!edgeCases[item.websiteType]) {
        // remove theme if single page theme
        if (theme.pageType !== 'THEME_HOME') {
          themeBreadcrumbsToHomepage = themeBreadcrumbsToHomepage.filter(
            (i) => !i.references?.some((r) => theme.href === r)
          );
        }
      }
      genericBreadcrumbs = genericBreadcrumbs.concat(themeBreadcrumbsToHomepage);
    } else if (item.websiteType === 'trainings') {
      // if we are in the professionalisering page of a not-linked-to-homepage theme (so not "themeHomePageSource" value) we need to still add the theme in the breadcrumb
      genericBreadcrumbs = genericBreadcrumbs.concat(getBreadcrumbsFromElementBackwards(theme, 0));
    }
  }

  if (item) {
    if (item.websiteType === 'FALLBACK_MENU_LEVEL_3') {
      navigationService.setLatestClusterChildren(item.children);
    }
    if (edgeCases[item.websiteType]) {
      const edgeCrumb = edgeCases[item.websiteType];
      edgeCrumb.breadcrumbPath = `${window.location.origin}/${edgeCases[item.websiteType].path}`;
      genericBreadcrumbs.push(edgeCrumb);
      if (item.extra) {
        genericBreadcrumbs.push({ ...item.extra, breadcrumbTitle: item.extra.title });
      }
    } else if (item.websiteType === 'forbidden') {
      if (theme) {
        genericBreadcrumbs.push({
          breadcrumbTitle: theme.title,
          breadcrumbPath: theme.pagePath || theme.path,
          pagePath: theme.pagePath || theme.path,
        });
        if (theme.pagePath !== window.location.pathname) {
          genericBreadcrumbs.push({
            breadcrumbTitle: 'Afgeschermde Inound',
            breadcrumbPath: window.location.pathname,
            pagePath: window.location.pathname,
          });
        }
      }
    } else if (item.websiteType !== 'database') {
      genericBreadcrumbs = genericBreadcrumbs.concat(
        getBreadcrumbsFromElementBackwards(item, theme ? 0 : 1)
      );
    }
  }

  return getUniqueBreadcrumbs(genericBreadcrumbs);
};

const getVakkenBreadcrumbs = async (tab) => {
  const navigationService = getAngularService('navigationService');
  const location =
    window.location.hostname === 'localhost'
      ? `https://testpro.katholiekonderwijs.vlaanderen/vakken-en-leerplannen?tab=${tab}`
      : `${window.location.origin + window.location.pathname}?tab=${tab}`;
  const vakkenPosition = await navigationService.getBreadCrumbItems(location);
  const vakkenBreadcrumbsToHomepage = checkForMultipleMenus(vakkenPosition);
  return vakkenBreadcrumbsToHomepage;
};

const getDatabaseItemBreadcrumbs = async (databaseItem) => {
  const referenceFrameService = getAngularService('referenceFrameService');
  const databaseItemBreadcrumbs = [];
  if (databaseItem.extra.pageType === 'SHARED_MINI_DATABASE_ITEM') {
    if (databaseItem.extra?.parent?.title) {
      // Comes from a database via a refDB param, see wsDatabaseItem/index.js
      databaseItemBreadcrumbs.push({
        breadcrumbTitle: databaseItem.extra.parent.title,
        breadcrumbPath: databaseItem.extra.parent.pagePath,
      });
    } else {
      // Otherwise only shared items tagged with Inspirerend materiaal and a curriculum theme must have the database on the breadcrumbs
      const referenceFrameItem = await referenceFrameService.getReferencedItem(databaseItem.extra);
      const hasCurriculaTheme = referenceFrameItem.some(
        (items) => items.type === 'CURRICULUM_THEME'
      );
      const hasGlobalDatabase = databaseItem.extra.themes.includes(
        settings.proGlobalDatabase.databaseTypeThemehref
      );
      if (hasCurriculaTheme && hasGlobalDatabase) {
        databaseItemBreadcrumbs.push({
          breadcrumbTitle: settings.proGlobalDatabase.title,
          breadcrumbPath: settings.proGlobalDatabase.url,
        });
      }
    }
  } else {
    const extraCrumbParentOrItem = databaseItem.extra?.parent || databaseItem.extra;
    databaseItemBreadcrumbs.push({
      breadcrumbTitle: extraCrumbParentOrItem.title,
      breadcrumbPath: window.location.origin + extraCrumbParentOrItem.pagePath,
    });
  }

  databaseItemBreadcrumbs.push({ breadcrumbTitle: databaseItem.extra.title });

  return databaseItemBreadcrumbs;
};

const UseBreadcrumbs = (item, theme, reload) => {
  // The initial state of "breadcrumbs" needs to be undefined. Otherwise the useEffect will trigger in the initial setup and we don't want that
  // On the other hand you could think on changing the condition on the useEffect to be "breadcrumbs.length>0" but
  // that will break the tracking on pages that has a banner but no breadcumbs (breadcrumbs final length === 0). It will break those because the "variable" that "allows" PIWIK to track
  // is set up in "setBreadcrumbTags" and that will never be fired
  const [breadcrumbs, setBreadcrumbs] = useState();

  useEffect(() => {
    if (item?.websiteType === 'vakken') {
      getVakkenBreadcrumbs(item.href).then((vakkenBreadcrumbs) =>
        setBreadcrumbs(vakkenBreadcrumbs)
      );
    } else if (item?.websiteType === 'database') {
      getDatabaseItemBreadcrumbs(item).then((databaseItemBreadcrumbs) => {
        setBreadcrumbs([...getGenericBreadcrumbs(theme, item), ...databaseItemBreadcrumbs]);
      });
    } else {
      setBreadcrumbs(getGenericBreadcrumbs(theme, item));
    }
  }, [item, theme, reload]);

  useEffect(() => {
    if (breadcrumbs) setBreadcrumbTags(breadcrumbs, dispatch);
  }, [breadcrumbs]);

  return breadcrumbs || [];
};

export default UseBreadcrumbs;
