export function loaderStart(tag = '#page-loader') {
  const loader = document.querySelector(tag);
  if (loader) loader.classList.add('loading');
  loader.scrollIntoView();
  if (tag === '#page-loader') loaderStart('#main-app-view');
}

export function loaderEnd(tag = '#page-loader') {
  const loader = document.querySelector(tag);
  if (loader) loader.classList.remove('loading');
  if (tag === '#page-loader') loaderEnd('#main-app-view');
}
