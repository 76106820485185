import React from 'react';
import TrainingOrder from '@UI/trainings/trainingOrder/trainingOrder';
import TrainingResults from '@UI/trainings/trainingResults/trainingResults';
import PropType from 'prop-types';

const LocalTrainingResults = ({
  onSetOrder,
  onLoadMore,
  viewModel,
  setMobileFilterShow,
  trainingsFiltersCnt,
}) => (
  <div className="kov-pro-trainings col-12 col-md-12 col-lg-9">
    <div className="kov-pro-filter--mobile d-flex d-lg-none">
      <button
        onClick={setMobileFilterShow}
        className="kov-pro-btn kov-pro-btn--dark kov-pro-btn__icon--left kov-pro-btn--inverted kov-pro-btn--filter kov-pro-btn--block"
      >
        <i className="icon-kov-pro-filter" />
        Filter <span className="filter-counter">{trainingsFiltersCnt}</span>
      </button>
    </div>
    <div className="kov-pro-sorter">
      <TrainingOrder sorts={viewModel.orderby} onSetOrder={onSetOrder} />
    </div>
    <TrainingResults onLoadMore={onLoadMore} />
  </div>
);

LocalTrainingResults.prototype = {
  onSetOrder: PropType.func,
  onLoadMore: PropType.func,
  viewModel: PropType.object,
  setMobileFilterShow: PropType.func,
  trainingsFiltersCnt: PropType.number,
};

export default LocalTrainingResults;
