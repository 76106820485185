import React, { useState, useEffect } from 'react';

const StudyProgrammeFilter = ({ searchTerm, executeSearch }) => {
  const [term, setTerm] = useState('');

  useEffect(() => {
    setTerm(searchTerm);
  }, [searchTerm]);

  const handleClear = () => {
    setTerm('');
    executeSearch('');
  };

  return (
    <div className="kov-pro-curriculum__search">
      <div className="kov-pro-mini-search input-group">
        <input
          className="form-control"
          placeholder="Naam van de studierichting"
          value={term}
          onKeyPress={(event) => (event.key === 'Enter' ? executeSearch(term) : null)}
          onChange={(e) => {
            setTerm(e.target.value);
          }}
        />
        <span className="close">
          <i className="icon-kov-pro-close" onClick={handleClear} />
        </span>
        <span className="input-group-btn">
          <button className="btn btn-default" onClick={() => executeSearch(term)} type="button">
            <i className="icon-kov-pro-search" />
          </button>
        </span>
      </div>
    </div>
  );
};

export default StudyProgrammeFilter;
