export const CURRICULUM_PAGE = '/content/4329fb23-9830-4a44-912c-bf8e0d1b2d8b';
export const THEMATIC_WEB_PAGE_TYPES = [
  'MENU_ITEM',
  'STANDALONE_PAGE',
  'TILES_PAGE',
  'TILES_SUBPAGE',
  'BLOG_ITEM',
  'MINI_DATABASE_ITEM',
];

export const TYPE_FILTER_MAP = {
  DOCUMENTS: { label: 'Documenten', searchTypes: ['pro_attachment'] },
  CURRICULA: { label: 'Leerplanpagina’s secundair', searchTypes: ['pro_web_page'] },
  NEWS: {
    label: 'Nieuws (tot 12 maanden terug)',
    searchTypes: ['pro_web_page'],
    proWebPageTypes: ['NEWS_ITEM', 'TEASER'],
  },
  PERSONS: { label: 'Personen', searchTypes: ['person'] },
  TRAININGS: { label: 'Professionaliseringen', searchTypes: ['training_module'] },
  PRO_PAGES: {
    label: 'Themapagina’s (mededelingen)',
    searchTypes: ['pro_web_page', 'pro_text_page_section', 'pro_faq_question'],
  },
  JOB_OFFERS: { label: 'Vacatures', searchTypes: ['www_web_page'] },
};
