import React from 'react';
import RadioOption from '../radioOption/radioOption';

const RadioGroup = ({ groupArray, onChange, itemListClassName }) => (
  <ul className={itemListClassName}>
    {groupArray.map((option) => (
      <RadioOption key={option.key} option={option} onChange={onChange}></RadioOption>
    ))}
  </ul>
);

export default RadioGroup;
