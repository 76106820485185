import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  latestClusterChildren: null,
  navigationItems: [],
  currentSubTabHref: null,
};

const navigationState = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setLatestClusterChildren: (state, action) => {
      state.latestClusterChildren = action.payload;
    },
    setNavigationItems: (state, action) => {
      state.navigationItems = action.payload;
    },
    setCurrentSubTabHref: (state, action) => {
      state.currentSubTabHref = action.payload;
    },
  },
});

export const navigationActions = navigationState.actions;
export default navigationState;
